import React from "react"
// import TextareaAutosize from 'react-textarea-autosize';
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartBtn } from '@/part/type/PartBtn';


export const Price_A = React.memo(({ part, textStates, textHandlers }) => {
  
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  // const judge2 = part.class_name == "price_2-1"
  
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  const priceCSS = css`
    ${outlineCSS(part)}
    
    ${part.class_name == "price_2-1" && `
      .price_header {
        background: ${accentColors[0]};
        
        ${accentColors.length > 1 && `
          background: linear-gradient(130deg, ${accentColors[0] || "transparent"} 35%, ${accentColors[1]} 100%);
        `}
      }
    `}
    
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      color: ${part.font_color};
      ${textCSS(subtitle)}
    }
    li svg {
      color: ${accentColors[0]};
    }
    p {
      ${textCSS(content)}
    }
  `

  return (
    <>
      <div 
        className={`price_1 ${part.class_name}`}
        css={priceCSS}
      >
        <div className="price_header">
          <h3 className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder="Standard"
              clickHandler={titleHandler}
            />
          </h3>
        </div>
        
        <div className="price_body">
          
          <div className="price">
            <div className="subtitle">
              <Textarea 
                part_id={part.id}
                text_type="subtitle"
                obj={subtitle}
                setObj={setSubtitle}
                placeholder="￥15,000"
                clickHandler={subtitleHandler}
              />
            </div>
          </div>
          
          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              placeholder="こちらに内容を「箇条書き」で入力してください。"
              clickHandler={contentHandler}
            />
          </p>
          
          {part.link && <PartBtn/>}
        </div>
      </div>
    </>
  );
  
});



// export const Price_A = React.memo(({ part, InputChange, setOpen }) => {
  
//   const judge2 = part.class_name == "price_2-1"
  
//   const priceCSS = css`
//     ${outlineCSS(part)}
//     font-family: ${part.font};
    
//     .price_header {
//       background: ${ judge2 && part.border_color};
//     }
//     .title {
//       ${fontCSS(part)}
//       color: ${part.sub_color};
//     }
//     .subtitle {
//       color: ${part.font_color};
//     }
    
//     li {
//       color: ${part.font_color};
//       color: ${part.border_color};
//     }
//     a, p {
//       color: ${part.font_color};
//     }
//   `

//   return (
//     <>
//       <div 
//         className={`price_1 ${part?.class_name} ${part?.add_class || ""}`}
//         css={priceCSS}
//         id={part.add_id}
//       >
//         <div className="price_header">
//           <h3 className="title">
//             <TextareaAutosize
//               type="text" 
//               name="title" 
//               value={part.title || 'プラン'} 
//               onChange={InputChange}
//             />
//           </h3>
//         </div>
        
//         <div className="price_body">
          
//           <div className="price">
//             <div className="subtitle">
//               <input
//                 type="text" 
//                 name="subtitle" 
//                 value={part.subtitle || '￥15,000'} 
//                 onChange={InputChange} 
//                 // placeholder="15,000"
//               />
//             </div>
//           </div>
          
//           <p>
//             <TextareaAutosize
//               type="text"
//               name="content" 
//               value={part.content || ''} 
//               onChange={InputChange} 
//               placeholder="こちらに内容を「箇条書き」で入力してください。"
//             />
//           </p>
          
//           {part.link &&
//             <a className="part_btn">MORE</a>
//           }
          
//         </div>
//       </div>
//     </>
//   );
  
// });