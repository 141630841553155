import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { AddonJudg } from '@/addon/AddonJudg';

export const LightSetting = React.memo(({page, InputChange, updatePage, Clear}) => {


  return (
    <div className="wrapper">
      <div className="page_setting_box">
        <h2 className="page_label" style={{left:"0"}}>ページの設定</h2>
      
        <label>概要説明（description）
          <div className="btn_clear" type="button" onClick={()=>Clear("description")}>クリア</div>
        </label>
        <TextareaAutosize 
          className="input" 
          type="text"
          minRows={3}
          name="description" 
          value={page.description || ''} 
          onChange={InputChange} 
          placeholder="概要説明を入力..."
        />
        
        <AddonJudg terms={/ビジネス|制作代行/} boolean={true}>
          <label>スタイル（CSS）
            <div className="btn_clear" type="button" onClick={()=>Clear("add_style")}>クリア</div>
          </label>
          <TextareaAutosize
            className="input input_code"
            type="text"
            minRows={6}
            name="add_style" 
            value={page.add_style || ''} 
            onChange={InputChange} 
            placeholder="コードを入力..."
          />
          
          <label>ヘッドに出力するコード
            <div className="btn_clear" type="button" onClick={()=>Clear("add_code")}>クリア</div>
          </label>
          <TextareaAutosize
            className="input input_code"
            type="text"
            minRows={6}
            name="add_code" 
            value={page.add_code || ''} 
            onChange={InputChange} 
            placeholder="コードを入力..."
          />
          
          <div className="input_fields">
            <div className="field_2">
              <label>リダイレクトURL
                <div className="btn_clear" type="button" onClick={()=>Clear("redirect")}>クリア</div>
              </label>
              <input
                className="input"
                type="text"
                name="redirect" 
                value={page.redirect || ''} 
                onChange={InputChange} 
                placeholder="例）https://example.com/redirect"
              />
            </div>
            <div className="field_2">
              <label>カロニカルURL
                <div className="btn_clear" type="button" onClick={()=>Clear("canonical")}>クリア</div>
              </label>
              <input
                className="input"
                type="text"
                name="canonical" 
                value={page.canonical || ''} 
                onChange={InputChange} 
                placeholder="例）https://example.com/canonical"
              />
            </div>
          </div>
        </AddonJudg>
        
        <AddonJudg terms={/パスワード機能/} boolean={true}>
          <div className="form_field">
            <label>パスワードの設定
              <div className="btn_clear" type="button" onClick={()=>Clear("pw")}>クリア</div>
            </label>
            <input
              className="input"
              type="text"
              name="pw" 
              value={page.pw || ''} 
              onChange={InputChange} 
              placeholder="半角英数字を入力..."
              // pattern="^[a-zA-Z0-9]+$"
            />
            <p>半角英数字のみ有効</p>
          </div>
        </AddonJudg>

      </div>
    </div>
  );
});

// <div style={{textAlign: "right"}}>
//   <button className="btn" onClick={updatePage}>設定を保存</button>
// </div>