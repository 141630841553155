import React, { useState } from 'react';
import { BiPlus, BiX } from "react-icons/bi";


export const Modal = React.memo(({children, btn_name, btn_class}) => {

  const [modal, setModal] = useState(false);
  
  const openModal = () => {
    setModal(true);
  };
  
  const closeModal = () => {
    setModal(false)
  };

  // const modalContent = () => {
  //   if (modal) {
  //     return (
  //       <div className="modal" onClick={closeModal}>
  //         <div className="modal_window" onClick={(e) => e.stopPropagation()}>
  //           <div className="modal_content">
  //             {children}
  //           </div>
  //           <div className="close_btn" onClick={closeModal}>✕</div>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // };
  
  return (
    <>
      <button className={btn_class} onClick={openModal}>
        <span><BiPlus/>{btn_name}</span>
      </button>

      {modal && 
        <div className="modal" onClick={closeModal}>
          <div className="modal_window" onClick={(e) => e.stopPropagation()}>
            <div className="modal_content">
              {children}
            </div>
            <div className="close_btn" onClick={closeModal}><BiX/></div>
          </div>
        </div>
      }
    </>
  );
  
});