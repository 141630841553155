import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';


export const BlockCopy = React.memo(({ setBlocks }) => {

  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const [ blockId, setBlockId ] = useState(0);

  const InputChange = e => {
    // const { name, value } = e.target;
    setBlockId(e.target.value);
  };
  
  const copy_data = {
    id: blockId,
    subdomain: productId,
  }
  // console.log(copy_data);
  
  
  //ブロックのコピー
  const blockCopy = () => {
    const sure = window.confirm(`このブロック(${copy_data.id})を複製しますか?`);
    if (sure) {
      client.post(`/api/v1/blocks/copy`, copy_data )
      .then(resp => {
        setBlocks((prev)=>[ resp.data, ...prev ]);
        setToastObj({message: "ブロックを複製しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };

  return (
    <>
      <h3>ブロックの複製</h3>
      <div className="input_inline">
        <input 
          type="number"
          className="input"
          value={blockId} 
          onChange={InputChange}
        />
        <button onClick={blockCopy}>複製する</button>
      </div>
    </>
  );
});
