import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Url } from '@/app/Url';
// import { SaveAll } from '@/page/SaveAll';
import { PageSetting } from '@/page/PageSetting';
import { EditPages } from '@/page/EditPages';
import { WidthSwitch } from '@/app/WidthSwitch';

import { BiChevronLeft } from "react-icons/bi";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

// import { useSetRecoilState } from 'recoil'
// import { pageWidthAtom } from '@/providers/Atoms';


export const Layout = React.memo(({children, product, pageState, InputChange, Clear, type}) => {
  
  // console.log("ページレイアウト", "レンダ");
  const { productId, slug } = useParams();
  
  // const setClickPart = useSetRecoilState(clickPartAtom)
  // const setClickBlock = useSetRecoilState(clickBlockAtom)
  
  const [ active, setActive ] = useState(false);
  // const activeState = {
  //   active, setActive
  // }
  const [ display, setDisplay ] = useState("閉じる");
  
  const ToggleHandler = () => {
    setActive((prev)=> !prev);
    // setClickPart(0);
    // setClickBlock(0);
    setDisplay( active ? "閉じる": <BiChevronLeft size={30}/>);
  };
  
  // 前のページに戻る
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  
  // 他ページのリンクを表示
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };
  
  
  const EditAreaCSS = css`
    .edit_main {
      width: ${active && "100%"}; 
    }
    .edit_sidebar {
      position: ${active && "fixed"}; 
      right: ${active && "-320px"}; 
    }
    .sidebar_toggle_btn {
      width: ${active && "50px"};
      left: ${active && "-50px"};
    }
    .part_menu {
      width: ${active && "calc(100% - 50px)"};
    }
    
    @media screen and (max-width: 820px) {
      .edit_sidebar {
        top: ${active && "50px"};
        right: 0;
      }
    }
  `
  
  return (
    <>
      <div className="edit_area" css={EditAreaCSS}>
        <div className="edit_main">
          {children}
        </div>
        <div className="edit_sidebar">
        
          <div className="edit_sidebar_top">
            <button className="toggle_btn" onClick={ToggleHandler}>{ active ? "閉じる" : "ページ設定" }</button>
            {/*<SaveAll className="all_save_btn"/>*/}

            <Url slug={slug ||""}>
              <button className="all_save_btn" style={{width: "100%"}}>公開ページ</button>
            </Url>
          </div>
          <div className="edit_sidebar_inner">
            <Url slug={slug ||""}>
              <button className="clear_btn mb_2">
                公開ページを見る
              </button>
            </Url>
            
            <WidthSwitch/>
            
            <div className="select_type">
              <Link to={`/product/${productId}/header/edit`} className="type_btn">ヘッダー</Link>
              <Link to={`/product/${productId}/footer/edit`} className="type_btn">フッター</Link>
              <Link to={`/product/${productId}/menus`} className="type_btn">メニュー</Link>
              <Link to={`/product/${productId}/images`} className="type_btn">画像</Link>
              <Link to={`/product/${productId}/blocks`} className="type_btn">共有ブロック</Link>
              <Link to={`/product/${productId}/setting`} className="type_btn">プロダクト設定</Link>
              <Link to="" className="type_btn"onClick={()=>setOpen(true)}>他のページ</Link>
              <Link to="" className="type_btn" onClick={goBack}>前に戻る</Link>
            </div>
            
            {open &&
              <EditPages setOpen={setOpen}/> 
            }
      
            {type !== "sharing" &&
              <PageSetting pageState={pageState} InputChange={InputChange} Clear={Clear}/>
            }
          </div>
           
          <div className="sidebar_toggle_btn" onClick={ToggleHandler}>{display}</div>
        </div>
      </div>
      
      <style>
      {`
        .footer {
          background: #091724;
          color: #ccc;
        }
        .footer_title {
          color: #eee;
        }
      `}
      </style>
    </>
  );
});


      // <style>
      // {`
      //   .main_area {
      //     max-width: 100%;
      //     padding: 0;
      //     margin: 0 auto;
      //   }
      // `}
      // </style>
      
      

      // <div className="float_btn_box">
      //   <button className="btn_white_line">ヘッダー編集へ</button>
      //   <SaveAll/>
      // </div>
      
    // @media screen and (max-width: 500px) {
    //   .edit_sidebar {
    //     top: ${active && "50px"};
    //     right: 0;
    //   }
    //   .sidebar_toggle_btn {
    //     top: ${active && "-50px"};
    //     left: ${active && "auto"};
    //   }
    // }
      
