import React, { useState } from 'react';
// import { ImgVideo } from '@/app/ImgVideo';
import { ImgVideo } from '@/part/ImgVideo';
// import { ImgSetting } from '@/image/ImgSetting';

import { useSetRecoilState } from 'recoil'
import { openSidebarAtom, partImgStatesAtom } from '@/providers/Atoms';


export const PartImg = React.memo(({ obj, setObj }) => {
  
  const setPartImgStates = useSetRecoilState(partImgStatesAtom);
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);

  const imgHandler = ()=> {
    // setOpen(true)
    setOpenSidebar("partImg");
    setPartImgStates([ obj, setObj ]);
  };
  

  return (
    <>
      <span onClick={imgHandler}>
        <ImgVideo obj={obj}/>
      </span>
    </>
  );

});

// <span onClick={()=>setOpen(true)}>
//   <ImgVideo obj={obj}/>
// </span>

// { open &&
//   <ImgSetting 
//     obj={obj}
//     setObj={setObj}
//     type="part"
//     setOpen={setOpen}
//     spSwitchState={spSwitchState}
//   />
// }