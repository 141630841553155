import React from 'react';
// import TextareaAutosize from 'react-textarea-autosize';
import { outlineCSS, textCSS } from '@/part/type/Style';
// import { BiLinkAlt } from "react-icons/bi";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';


export const Link_A = React.memo(({ part, textStates, textHandlers }) => {
  
  // console.log("テキストリンクパーツレンダ");
  const { content, setContent } = textStates;
  const { contentHandler } = textHandlers
  
  // let fontColors = content.font_color ? content.font_color.split("／") : [];
  
  const linkCSS = css`
    ${outlineCSS(part)}
    
    a {
      color: ${part.font_color};
      ${textCSS(content)}
    }
    svg {
      color: ${part.font_color};
    }
  `


  return (
    <>
      <div 
        className={`link_1 ${part.class_name}`}
        css={linkCSS}
      >
        <a>
          <Textarea
            part_id={part.id}
            text_type="content"
            obj={content}
            setObj={setContent}
            placeholder="テキストを入力..."
            clickHandler={contentHandler}
          />
        </a>
        
        <div className="link_path">
          URL：{part.link || "未設定"}
        </div>
      </div>
    </>
  );
  
});


// export const Link_A = React.memo(({ partState, InputChange }) => {
  
//   // console.log("テキストリンクパーツレンダ");
  
//   // const { client } = useContext(StateContext);
//   // const { setToastObj } = useContext(ToastContext);
  
//   const { part, setPart } = partState
  
//   // 変更
//   // const updatePart = () => {
//   //   client.patch(`/api/v1/parts/${part.id}`, part)
//   //   .then(resp => {
//   //     setToastObj({message: "変更しました。", color: "#D1E7DD"});
//   //   })
//   //   .catch(e => {
//   //     setToastObj({message: "失敗しました", color: "#ffedab"});
//   //     console.log(e);
//   //   });
//   // };
  
//   const linkCSS = css`
//     ${outlineCSS(part)}
//     a {
//       ${fontCSS(part)}   
//     }
//     svg {
//       color: ${part.font_color};
//     }
//   `
//   // const [ open, setOpen ] = useState("");
  
  
//   return (
//     <>
//       <div 
//         className={`link_1 ${part.class_name} ${part.add_class||""}`}
//         css={linkCSS}
//         id={part.add_id}
//       >
//         <BiLinkAlt/>
//         <a>
//           <TextareaAutosize
//             type="text" 
//             name="title" 
//             value={part.title ||''}
//             onChange={InputChange} 
//             placeholder="テキストを入力..."
//           />
//         </a>
        
//         <div className="link_path">
//           URL：{part.link || "未設定"}
//         </div>
//       </div>
//     </>
//   );
  
// });