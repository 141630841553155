import React, { useContext } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { AddonJudg } from '@/addon/AddonJudg';
import { fontFamilys } from '@/app/DefaultData';
import { BiMinus, BiPlus } from "react-icons/bi";
import { StateContext } from '@/providers/StateProviders';


export const AddSetting = ({ register, getValues, setValue, errors, Clear }) => {
  
  const { currentUser } = useContext(StateContext);
  
  const spinUp = (name, value) => {
    setValue(name, value +1);
  };
  
  const spinDown = (name, value) => {
    setValue(name, value -1);
  };
  
  
  return (
    <>
      <h2 className="H1 mb_2">追加設定</h2>
      
      <AddonJudg terms={/ビジネス|制作代行/} boolean={true}>
        <div className="input_fields">
            
          <div className="field_3">
            <label>文字サイズ（PC＆タブレット時）
              <div className="btn_clear" onClick={()=>Clear("font_size")}>クリア</div>
            </label>
            <div className="spin_btn">
              <span onClick={()=>spinDown("font_size", getValues('font_size') || 15)}><BiMinus/></span>
              <input 
                id="font_size"
                className="input"
                type="number"
                {...register('font_size')}
              />
              <span onClick={()=>spinUp("font_size", getValues('font_size') || 15)}><BiPlus/></span>
            </div>
          </div>
          
          <div className="field_3">
            <label>文字サイズ（スマホ時）
              <div className="btn_clear" onClick={()=>Clear("font_size_s")}>クリア</div>
            </label>
            <div className="spin_btn">
              <span onClick={()=>spinDown("font_size_s", getValues('font_size_s') || 15)}><BiMinus/></span>
              <input 
                id="font_size_s"
                className="input"
                type="number"
                {...register('font_size_s')}
              />
              <span onClick={()=>spinUp("font_size_s", getValues('font_size_s') || 15)}><BiPlus/></span>
            </div>
          </div>
          
  
          <div className="field_3">
            <label>文字フォント
              <div className="btn_clear" onClick={()=>Clear("font")}>クリア</div>
            </label>
            <div className="selectbox">
              <select
                id="font"
                className="" 
                {...register('font')}
              > 
                {fontFamilys.map((font, index) => {
                  return (
                    <option value={font.value} key={index}>{font.name}</option>
                  );
                })}
              </select>
            </div>
          </div>
  
          <div className="field_2">
            <label>
              リダイレクトURL
              <div className="btn_clear" onClick={()=>Clear("redirect")}>クリア</div>
            </label>
            <input
              id="redirect"
              className={`input ${errors.redirect?.message && "error"}`}
              type="text"
              placeholder="例）https://example.com"
              {...register('redirect', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.redirect?.message && 
              <div className="color_red">{errors.redirect.message}</div>
            }
          </div>
          
          <div className="field_2">
            <label>
              カロニカルURL
              <div className="btn_clear" onClick={()=>Clear("canonical")}>クリア</div>
            </label>
            <input
              id="redirect"
              className={`input ${errors.canonical?.message && "error"}`}
              type="text"
              placeholder="例）https://example.com"
              {...register('canonical', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.canonical?.message && 
              <div className="color_red">{errors.canonical.message}</div>
            }
          </div>
        
        </div>
          
        <div className="form_field">
          <label>
            サイト全体に適用されるスタイル（CSS）
            <div className="btn_clear" type="button" onClick={()=>Clear("add_style")}>クリア</div>
          </label>
          <TextareaAutosize
            minRows={3}
            id="add_style"
            className={`input input_code ${errors.add_style?.message && "error"}`}
            // style={{border: errors.add_style ? "1px solid red" :""}}
            type="text"
            placeholder="コードを入力...　例）p { color: #000; }"
            {...register('add_style', {
              // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
            })}
          />
          {errors.add_style?.message && 
            <div className="color_red">{errors.add_style.message}</div>
          }
        </div>
      </AddonJudg>
      
      {currentUser.admin &&
        <div className="form_field">
          <label>
            予約フォームのプラン設定
            <div className="btn_clear" type="button" onClick={()=>Clear("otherpages_type")}>クリア</div>
          </label>
          <TextareaAutosize
            minRows={3}
            id="otherpages_type"
            className={`input ${errors.otherpages_type?.message && "error"}`}
            type="text"
            // placeholder=""
            {...register('otherpages_type', {
            })}
          />
        </div>
      }
      
    </>
  );
};


// <div className="form_field">
//   <label>
//     javascriptを出力
//     <div className="btn_clear" type="button" onClick={()=>Clear("add_script")}>クリア</div>
//   </label>
//   <TextareaAutosize
//     minRows={3}
//     id="add_script"
//     className={`input ${errors.add_script?.message && "error"}`}
//     // style={{border: errors.add_script ? "1px solid red" :""}}
//     type="text"
//     placeholder="コードを入力..."
//     {...register('add_script', {
//       // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
//     })}
//   />
//   {errors.add_script?.message && 
//     <div className="color_red">{errors.add_script.message}</div>
//   }
// </div>



          // <div className="field_3">
          //   <label>サイト全体の幅</label>
          //   <input 
          //     className="input" 
          //     type="number"
          //     name="wrapper" 
          //     // value={setting.wrapper || '1000'} 
          //     // onChange={SettingChange}
          //   />
          // </div>
          
          // <div className="field_3">
          //   <label>padding</label>
          //   <input 
          //     className="input" 
          //     type="number"
          //     name="padding" 
          //     // value={setting.padding || '0'} 
          //     // onChange={SettingChange}
          //   />
          // </div>
          
          

// <div className="input_fields">
//           <div className="field_3">
//             <label>文字サイズ</label>
//             <input 
//               className="input" 
//               type="number"
//               name="font_size" 
//               value={setting.font_size || '16'} 
//               onChange={SettingChange}
//             />
//           </div>
          
//           <div className="field_3">
//             <label>文字サイズ（スマホ時）</label>
//             <input 
//               className="input" 
//               type="number"
//               name="font_size_s" 
//               value={setting.font_size_s || '16'} 
//               onChange={SettingChange}
//             />
//           </div>
          
//           <div className="field_3">
//             <label>文字フォント</label>
//             <select 
//               className="input" 
//             　name="font"
//             　value={setting.font || ''} 
//             　onChange={SettingChange}
//             > 
//               <option value="">未設定</option>
//               <option value="'游明朝','Yu Mincho',YuMincho">游明朝</option>
//               <option value="'游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN'">ゴシック</option>
//               <option value="'メイリオ', 'Meiryo'">メイリオ</option>
//             </select>
//           </div>
        
//           <div className="field_3">
//             <label>サイト全体の幅</label>
//             <input 
//               className="input" 
//               type="number"
//               name="wrapper" 
//               value={setting.wrapper || '1000'} 
//               onChange={SettingChange}
//             />
//           </div>
          
//           <div className="field_3">
//             <label>padding</label>
//             <input 
//               className="input" 
//               type="number"
//               name="padding" 
//               value={setting.padding || '0'} 
//               onChange={SettingChange}
//             />
//           </div>
          
//           <div className="field_3">
//           </div>
          
//           <div className="field_2">
//             <label>リダイレクトURL</label>
//             <input 
//               className="input" 
//               type="text" 
//               name="redirect" 
//               value={setting.redirect || ''} 
//               onChange={SettingChange}
//               placeholder="リダイレクトURLを入力..."
//             />
//           </div>
          
//           <div className="field_2">
//             <label>カロニカル</label>
//             <input 
//               className="input" 
//               type="text" 
//               name="canonical" 
//               value={setting.canonical || ''} 
//               onChange={SettingChange}
//               placeholder="カロニカルを入力..."
//             />
//           </div>