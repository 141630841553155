import React from "react"
import { outlineCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";


export const Sitemap_A = React.memo(({ part }) => {
  
  // const { title, setTitle } = textStates;
  // const { titleHandler } = textHandlers
  
  const sitemapCSS = css`
    ${outlineCSS(part)}
  `
  
  return (
    <>
      <div
        className={`sitemap_1 ${part.class_name}`}
        css={sitemapCSS}
      >
        <a>サイトマップ</a>
      </div>
    </>
  );
  
});
