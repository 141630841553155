import React, { useEffect, useContext } from 'react';
import { StateContext } from '../providers/StateProviders';
import { useParams } from 'react-router-dom';
import { Upload } from '@/image/Upload';
import { ImgItem } from '@/image/ImgItem';

import { useRecoilState } from 'recoil'
import { imagesAtom, productIDAtom } from '@/providers/Atoms';


export const UploadImgs = React.memo(({ obj_img, InputChange }) => {
  
  const { productId } = useParams();
  const { client } = useContext(StateContext);

  const [ images, setImages ] = useRecoilState(imagesAtom)
  const [ productID, setProductID ] = useRecoilState(productIDAtom)
  
  // 画像リスト取得
  useEffect(() => {
    if(images == "" || productId !== productID){
      console.log("画像取得");
      client.get(`/api/v1/imageslist?subdomain=${productId}`)
      .then(resp => {
        setProductID(resp.data.product.subdomain);
        setImages(resp.data.imgs);
      });
    }
  }, []);
  

  return (
    <>
      <Upload/>
      
      {images.map((image, index) => {
        return (
          <ImgItem
            // obj={obj}
            obj_img={obj_img}
            image={image} 
            InputChange={InputChange}
            key={image.id}
          />
        );
      })}
    </>
  );

});