import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TopicPath } from '@/app/TopicPath';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { useDateTime } from '@/hooks/useDateTime';


export const Notices = React.memo(() => {
  
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);

  const [ notices, setNotices ] = useState([]);


  useEffect(() => {
    client.get(`/api/v1/notices`)
    .then(resp => {
      setNotices(resp.data);
    })
    .catch(e => {
      setToastObj({message: "エラー", color: "#ffedab"});
      console.log(e);
    });
  }, []);

  
  return (
    <>
      <div className="main_layer"></div>
      <TopicPath topic="お知らせ"/>

      <div className="base_area">
        <h1 className="H1">お知らせ</h1>
        
        {currentUser.admin &&
          <Link to={`/notice/new`}>新規作成</Link>
        }
        { notices.length == 0 &&
          <div>現在、お知らせはありません。</div>
        }
        
        <div className="notices">
          {notices.map((notice) => {
            return (
              <Link className="item" key={notice.id} to={`/notice/${notice.id}`}>
                <span className="day">{useDateTime(notice.created_at)}</span>
                <span className="item_subtitle">{notice.subtitle || "お知らせ"}</span>
                <div className="item_title">{notice.title || "ノータイトル"}</div>
              </Link>
            );
          })}
        </div>
        
      </div>
    </>
  );
});