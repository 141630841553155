import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { BiX } from "react-icons/bi";


export const MenuSelect = React.memo(({ part, InputChange, GetMenu, setItems, Clear, setActive }) => {
  
  // console.log("メニューセレクトレンダ");
  
  const { productId } = useParams();
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
//   const [ items, setItems ] = useState([]);
  const [ menus, setMenus ] = useState([]);
  
  useEffect(() => {
    if(menus == ""){
      client.get(`/api/v1/menuslist?subdomain=${productId}`)
      .then(resp => {
        setMenus(resp.data.menus);
      })
    }
  }, []);
  

  // 変更
  const updatePart = () => {
    client.patch(`/api/v1/parts/${part.id}`, part)
    .then(resp => {
      setToastObj({message: "変更しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  const itemsClear = () => {
    Clear("menu_id");
    setItems([]);
  };
  
  const CloseHandler = () => {
    setActive(false);
  };
  
  
  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
       
        <div className="items_bar_top">
          <div className="sidebar_title">
            メニューから選択
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
    
        <div className="items_box select_type" name={`partlist_${part.id}`}>
        
          <Link to={`/product/${productId}/menus`}>メニューの作成・編集へ</Link>
          <div className="btn_clear" type="button" onClick={itemsClear}>クリア</div>
          <div className="mb_2 mt_2">ダブルクリックで選択してください</div>
          
          { menus.map((menu) => {
            return (
              <label 
                className={`item_w ${menu.id == part.menu_id && "active"}`} 
                key={menu.id}
                onClick={()=>GetMenu(menu.id)}
              >
                <input 
                  type="radio"
                  name="menu_id"
                  value={menu.id}
                  onChange={InputChange}
                  // checked={menu.id == part?.menu_id}
                />
                {menu.name}
                
                <ul>
                  {menu.items.map((item) => {
                    return (
                      <li key={item.id}>{item.name}</li>
                    );
                  })}
                </ul>
              </label>
            );
          })}
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={updatePart}>保存</button>
        </div>
      </div>
    </>
  );
  
});

  // const OpenHandler = () => {
  //   if(menus == ""){
  //     client.get(`/api/v1/menuslist?subdomain=${productId}`)
  //     .then(resp => {
  //       setMenus(resp.data);
  //     })
  //   }
  //   setActive(true);
  // };