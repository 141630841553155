import React from 'react';
import { ImgPreview } from '@/image/ImgPreview';

export const ImgItem = React.memo(({ obj_img, image, InputChange }) => {

    
  return (
    <label 
      className={`item ${obj_img == image.img && "active"}`} 
    >
      <div className="img_box">
        <ImgPreview obj_img={image.img} obj_name={image.name}/>
      </div>
      <input
        type="radio"
        name="img"
        value={image.img}
        onChange={InputChange}
      />
    </label>
  );

});