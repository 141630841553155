import React, { useState, useContext, useEffect, useRef } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { UploadImgs } from '@/image/UploadImgs';
import { FreeImgs } from '@/image/FreeImgs';
import { BiX } from "react-icons/bi";
import { ImgEdit } from '@/image/ImgEdit';
import { ImgPreview } from '@/image/ImgPreview';
// import { isMobile } from "react-device-detect";
// import { useAutoSave } from '@/hooks/useAutoSave';

import { useSetRecoilState } from 'recoil'
import { saveStatusAtom } from '@/providers/Atoms';


export const ImgSetting = React.memo(({ obj, setObj, type, setOpen, spSwitchState }) => {
  
  const { client } = useContext(StateContext);
  const setSaveStatus = useSetRecoilState(saveStatusAtom);
  
  // console.log("画像の選択リスト", "レンダ");
  const [ imgType, setImgType ] = useState("upload");
  const [ show, setShow ] = useState("");
  const { spSwitch, setSpSwitch } = spSwitchState
  
  // useEffect(() => {
  //   if(isMobile) {
  //     setSpSwitch("SP"); 
  //   } 
  // }, []);

  const InputChange = event => {
    const { name, value } = event.target;
    setObj((prev)=> ({ ...prev, [name]: value}));
  };
  
  const Img_sChange = event => {
    const { name, value } = event.target;
    setObj((prev)=> ({ ...prev, img_s: value}));
  };
  
  
  // 保存
  const update = () => {
    // let data;
    // if (spSwitch == "SP"){
    //   data = {...obj, img_type: "SP"}
    // } else {
    //   data = obj;
    // }
    
    client.patch(`/api/v1/imgs/${obj.id}`, obj)
    .then(resp => {
      if(resp.data.id !== obj.id ) {
        setObj(resp.data);
        console.log("セット");
      }
      setSaveStatus(false);
      console.log("画像の保存完了");
    })
    .catch(e => {
      console.log(e);
    });
  };
  

  // refをtrueで初期化。
  const Ref = useRef("初回");
  // オートセーブ
  useEffect(() => {
    if (Ref.current === "初回") {
      Ref.current = true;
      console.log("初回スキップ");
      return;
    }
    
    if (Ref.current) {
      clearTimeout(Ref.current);
      setSaveStatus(true);
      console.log("画像自動保存待ち");
    }
    
    Ref.current = setTimeout(update, 3000); 
  }, [obj]);
  
  
  // パソコン時に切り替え
  const PcSwitchimg = ()=> {
    setSpSwitch("BASE")
    // 初期化※これがないと切り替え毎に保存が実行される
    // Ref.current = "初回"
  };
  // スマホ時に切り替え
  const SpSwitchimg = ()=> {
    setSpSwitch("SP")
    // 初期化※これがないと切り替え毎に保存が実行される
    // Ref.current = "初回"
  };
  

  // 閉じるボタン
  const CloseHandler = ()=> {
    setOpen(false);
  };

  
  // クリア
  const Clear = (column) => {
    setObj((prev)=> ({ ...prev, [column]: null}));
  }
  
  

  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            { type == "part" ? "パーツ画像" : "ブロック背景画像"}
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>

        <div className="items_box">
          
          { type == "part" &&
            <div className="tab_menu" style={{marginBottom: "10px"}}>
              <button 
                className={`tab_menu_btn ${spSwitch == "BASE" && "active"}`}
                onClick={PcSwitchimg}
              >通常設定</button> 
              <button
                className={`tab_menu_btn ${spSwitch == "SP" && "active"}`}
                onClick={SpSwitchimg}
              >スマホ時の設定</button>
            </div>
          }
          
          { type == "part" &&
            <>
              {spSwitch == "BASE"
                ? <label>デフォルトで表示される画像／動画</label>
                : <label>スマホ時に表示される画像／動画</label>
              }
            </>
          }

          <div className="img_preview">
            { spSwitch == "BASE"
              ?
                <>
                  { obj.img
                    ?
                      <>
                        <ImgPreview obj_img={obj.img}/>
                        <textarea
                          className="input"
                          type="text"
                          name="img"
                          value={obj.img || ""} 
                          onChange={InputChange}
                          placeholder="画像はありません。"
                        />
                        <button className="clear_btn mb_1" onClick={()=>Clear("img")}>画像をクリア</button>
                      </>
                    : <div className="color_red mb_2">画像が未設定です。</div>
                  }
                </>
              :
                <>
                  { obj.img_s
                    ?
                      <>
                        <ImgPreview obj_img={obj.img_s}/>
                        <textarea
                          className="input"
                          type="text"
                          name="img_s"
                          value={obj.img_s || ""} 
                          onChange={InputChange}
                          placeholder="画像はありません。"
                        />
                        <button className="clear_btn mb_1" onClick={()=>Clear("img_s")}>画像をクリア</button>
                      </>
                    : <div className="color_red mb_2">スマホ時の画像設定がない場合、<br/>【通常設定】の画像が表示されます。</div>
                  }
                </>
            }
          </div>
          
          {obj.img &&
            <button className="btn_line w_100 mb_2" onClick={()=>setShow("Edit")}>画像の編集</button>
          }
          
          { show == "Edit" && 
            <ImgEdit
              obj={obj}
              setObj={setObj}
              type={type}
              InputChange={InputChange}
              Clear={Clear}
              setShow={setShow}
            />
          }
          
          { show == "" && 
            <>
              {obj.img &&
                <>
                  <label>画像の説明（alt）
                    <div className="btn_clear" type="button" onClick={()=>Clear("alt")}>クリア</div>
                  </label>
                  <input 
                    className="input"
                    type="text"
                    name="alt"
                    value={obj.alt || ""} 
                    onChange={InputChange}
                    placeholder="画像の説明文を入力..."
                  />
                </>
              }
              
              {/*
              <label>写真
                <div className="btn_clear" type="button" onClick={()=>Clear("img")}>クリア</div>
              </label>
              */}
              
              <div className="tab_menu">
                <button 
                  className={`tab_menu_btn ${imgType == "upload" && "active"}`}
                  onClick={()=>setImgType("upload")}
                >アップロード</button> 
                <button
                  className={`tab_menu_btn ${imgType == "free" && "active"}`}
                  onClick={()=>setImgType("free")}
                >フリー</button>
              </div>
    
              <div className="select_type">
                { spSwitch == "BASE"
                  ?
                    <>
                      {imgType == "free" && 
                        <FreeImgs obj_img={obj.img} InputChange={InputChange}/>
                      }
                      {imgType == "upload" &&
                        <UploadImgs obj_img={obj.img} InputChange={InputChange}/>
                      }
                    </>
                  :
                    <>
                      {imgType == "free" && 
                        <FreeImgs obj_img={obj.img_s} InputChange={Img_sChange}/>
                      }
                      {imgType == "upload" &&
                        <UploadImgs obj_img={obj.img_s} InputChange={Img_sChange}/>
                      }
                    </>
                }
              </div>
            </>
          }
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={CloseHandler}>閉じる</button>
        </div>
          
      </div>
    </>
  );

});


// <label>横幅（％）
//   <div className="btn_clear" type="button" onClick={()=>Clear("width")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="width"
//   objValue={obj.width || 100}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>高さ（px）
//   <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="height"
//   objValue={obj.height || 200}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />