import React, { useEffect, useContext, useRef } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { SpinBtn } from '@/app/SpinBtn';
import { BiX } from "react-icons/bi";
import { useAutoSave } from '@/hooks/useAutoSave';

import { useSetRecoilState } from 'recoil'
import { saveDataAtom, clickSectionAtom, saveStatusAtom } from '@/providers/Atoms';



export const SectionSetting = React.memo(({ sectionState, blocksState }) => {
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  // const { sections, setSections } = sectionsState
  const { section, setSection } = sectionState
  const { blocks, setBlocks } = blocksState
  
  // const setSaveData = useSetRecoilState(saveDataAtom)
  const setClickSection = useSetRecoilState(clickSectionAtom)
  const setSaveStatus = useSetRecoilState(saveStatusAtom);
  
  // console.log("セッション設定", section.id);
  
  // セーブデータを更新
  // const SaveDataUpdate = (newData) => {
  //   setSaveData((prev)=> ({ 
  //   ...prev, 
  //     sections: prev.sections.map((obj) => (
  //       obj.id === section.id ? newData : obj
  //     ))
  //   }));
  // };
  
  const InputChange = event => {
    const { name, value } = event.target;
    setSection((prev)=> ({ ...prev, [name]: value }));
    
    // const newSection = {...section, [name]: value };
    // setSection(newSection);
    // SaveDataUpdate(newSection);
  };
  
  // 保存
  const SaveSection = () => {
    client.patch(`/api/v1/sections/${section.id}`, section)
    .then(resp => {
      setSaveStatus(false);
      console.log("保存完了");
      // setToastObj({message: "保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(section, SaveSection, 1000);
  
  // // refをtrueで初期化。
  // const Ref = useRef(null);
  
  // // 自動保存
  // useEffect(() => {
  //   if (Ref.current === null) {
  //     setTimeout(() =>{
  //       Ref.current = true;
  //     }, 1000);
  //     return;
  //   }
    
  //   if (Ref.current) {
  //     clearTimeout(Ref.current); 
  //     console.log("Section自動保存待ち");
  //   }
    
  //   Ref.current = setTimeout(SaveSection, 3000);
  // }, [section]);
  
  
  // クリア
  const Clear = (name) => {
    setSection((prev)=> ({ ...prev, [name]: null }));
    
    // const newSection = {...section, [name]: null};
    // setSection(newSection);
    // SaveDataUpdate(newSection);
  }

  // スライドブロックを複製追加
  const AddBlock = () => {
    
    const Copyblock= {
      // ...blocks[0],
      section_id: section.id,
      block_id: blocks[0].id,
    }
    client.post('/api/v1/blocks/copy', Copyblock)
    .then(resp => {
      setBlocks((prev)=> ([...prev, resp.data]));
      
      // const newParts = [ ...saveData.parts, resp.data.part]
      // setSaveData({ ...saveData, blocks: newBlocks , parts: newParts});
      // window.location.reload();
      // setToastObj({message: "スライドブロックを追加しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e)
    })
  };
  
  
  // 削除
  // const DeleteSection = () => {
  //   const sure = window.confirm(`（${section.id}）このセクションを本当に削除しますか?`);
  //   if (sure) {
  //     client.delete(`/api/v1/sections/${section.id}`)
  //     .then(resp => {
  //       const newSections = [...sections];
  //       newSections.splice(index, 1);
  //       setSections(newSections);
    
  //       setToastObj({message: "セクションを削除しました", color: "#D1E7DD"});
  //     })
  //     .catch(e => {
  //       setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
  //       console.log(e);
  //     });
  //   }
  // };
  
  const SectionType = [
    {name: "スライダー", type:"Slide"},
    {name: "フェード", type:"Fade"},
    {name: "フローリング", type:"Flowing"},
    // {name: "スイッチ", type:"Switch"},
    {name: "スライドを解除する", type: ""},
  ];
  
  
  const CloseHandler = ()=> {
    // setActive(false);
    setClickSection(0);
  };
  
  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            スライド設定
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
        
          <label>スライドタイプ
            <div className="btn_clear" type="button" onClick={()=>Clear("section_type")}>クリア</div>
          </label>
          <form className="radio_box">
            {SectionType.map((item) => {
              return (
                <label key={item.type}
                  className={`w_100 ${item.type == section.section_type && "is_on"}`}
                  // style={{border: item.type == section.section_type && "1px solid #00ff2c"}}
                >
                  <input
                    type="radio"
                    id={item.type}
                    name="section_type"
                    value={item.type}
                    onChange={InputChange}
                    checked={item.type == section.section_type}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>
          
          <label>切替間隔（秒）
            <div className="btn_clear" type="button" onClick={()=>Clear("seconds")}>クリア</div>
          </label>
          <SpinBtn
            // obj={section}
            setObj={setSection}
            column="seconds"
            objValue={section.seconds || 2}
            InputChange={InputChange}
            // SaveDataUpdate={SaveDataUpdate}
          />
          
          { section.section_type &&
            <div className="form_field">
              <label>スライドするブロックを追加</label>
              <button className="btn_line w_100" onClick={AddBlock}>複製してスライドブロックを追加</button>
            </div>
          }
          
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={SaveSection}>保存</button>
        </div>
      
      </div>
    </>
  );
});


// <label>順番</label>
// <SpinBtn
//   obj={section}
//   setObj={setSection}
//   column="order"
//   objValue={section.order || 0}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>スライド画像</label>
//           {blocks.map((block, index) => {
//             return (
//               <img src={block.img} className="switch_preview_img" key={index}/>
//             );
//           })}

// <label>セクションの削除</label>
// <button className="btn_delete" onClick={DeleteSection}>削除する</button>