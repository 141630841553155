import React from 'react'
import { useParams, Link } from 'react-router-dom';
import { BiDotsVerticalRounded, BiAddToQueue, BiTrash, BiChevronRight, BiCaretUp, BiCaretDown } from "react-icons/bi";
import { useDateTime } from '@/hooks/useDateTime';
import { Url } from '@/app/Url';

export const PageCard = React.memo(({ page, PageCopy, PageDelete, orderUp, orderDown, index }) => {
  
  const { productId } = useParams();


  return (
    <div 
      className="page_card"
      style={{background: page.status == "非公開" && "#F6F7F9"}}
      key={page.id}
    >
      <div className="card_img">
        <img src={page.img || "/no_img.png"}/>
      </div>
      
      <div className="card_body">
        <div className="card_title">{page.title || "ページタイトル未設定"}</div>
        
        {page.status == "非公開"
          ? <div className="badge badge_purple">非公開</div>
          : <div className="badge badge_dark">公開</div>
        }
        {!page.index &&
          <div className="badge badge_purple">ノーインデックス</div>
        }
        
        {page.toppage 
          ? <div className="badge badge_key">トップページ</div>
          :""
        }
        
        <div className="badge">
          <Url slug={page.slug}/>
        </div>
        
        <div className="btn_box">
          <div className="date_box">
            <div className="">作成日：{useDateTime(page.created_at)}</div>
          
            <div className="drop_btn">
              <button className="btn_icon"><BiDotsVerticalRounded/></button>
              
              <div className="drop_box">
                <div className="drop_list">
                  <div className="drop_item" onClick={()=>orderUp(index)}><span><BiCaretUp/>前に移動</span></div>
                  <div className="drop_item" onClick={()=>orderDown(index)}><span><BiCaretDown/>後に移動</span></div>
                  <div className="drop_item" onClick={()=>PageCopy(page.id)}><span><BiAddToQueue/>ページの複製</span></div>
                  <div className="drop_item" onClick={()=>PageDelete(page)}><span><BiTrash/>ページの削除</span></div>
                </div>
              </div>
            </div>
          </div>
          
          <Link to={`/product/${productId}/${page.slug}/edit`}>
            <button className="btn_edit">ページの編集<BiChevronRight/></button>
          </Link>
        </div>
        
      </div>
      
    </div>
  );
});