import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Sections } from '@/section/Sections';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";


export const HeaderSections = React.memo(({ sections, setSections, SettingCSS }) => {
  
  const { productId } = useParams();
  
  return (
    <>
      {sections.length !== 0 
        ? <div 
            className="page_area"
            // style={{width: width}}
            css={SettingCSS}
          >
            <div className="page_label">ヘッダー</div>
            <Link to={`/product/${productId}/header/edit`} className="page_label_btn">ヘッダー編集へ</Link>
            <Sections 
              sections={sections}
              setSections={setSections}
              sharing="header"
            />
          </div>
        : <div className="wrapper">
            <div className="page_setting_box" style={{padding:"1.5rem 3.5rem 1.5rem"}}>
              <div className="page_label" style={{left:"0"}}>ヘッダー</div>
              共通ヘッダーはありません。
              <Link to={`/product/${productId}/header/edit`} className="">作成する</Link>
              <div className="info">ヘッダーは全てのページの上部に共通して表示されます。</div>
            </div>
          </div>
      }
    </>
  );
  
});
