import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { BiLaptop, BiTab, BiMobile, BiX } from "react-icons/bi";

import { useRecoilState } from 'recoil'
import { productAtom } from '@/providers/Atoms';


export const PagePreview = React.memo(() => {
  
  const { productId } = useParams();
  const { slug } = useParams();
  
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const [ product, setProduct ] = useRecoilState(productAtom);
  const [ page, setPage ] = useState([]);
  
  const [ iframeKey, setIframeKey ] = useState(1);
  // console.log("iframeKey", iframeKey);
  const [ width, setWidth ] = useState("100%");
  const [ height, setHeight ] = useState("95%");
  
  
  useEffect(() => {
    // client.get(`/api/v1/products/${productId}`)
    client.get(`/api/v1/products/${productId}/pages/${slug}/preview`)
    .then(resp => {
      setProduct(resp.data.product);
      setPage(resp.data.page);
      // console.log(resp.data);
    })
    .catch(e => {
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
    
    window.setTimeout(() =>{
      setIframeKey((prev)=> prev+1)
      console.log("更新")
    }, 100);
  }, []);
  
  
  const smartPhone = () => {
    setWidth("360px");
    setHeight("640px");
  };
  const tablet = () => {
    setWidth("820px");
    setHeight("95%");
  };
  const tabletWide = () => {
    setWidth("1180px");
    setHeight("95%");
  };
  const computer = () => {
    setWidth("100%");
    setHeight("95%");
  };
  
  
  const iframe = () => {
    let SSL;
    if(product.ssl) {
      SSL = "https"
    } else {
      SSL = "http"
    }
    
    let page_slug = page.toppage ? "" : slug;
    
    let URL;
    if(product.domain) {
      URL = `${SSL}://${product.domain}/${page_slug}?preview=true`
    } else if (product.subdomain) {
      URL = `https://${product.subdomain}.pstock.me/${page_slug}?preview=true`
    }
    
    console.log("URL", URL)
    
    return (
      <iframe key={iframeKey} src={URL} width="100%" height="100%"></iframe>
    );
  };
  

  return (
    <div className="preview_area">
      <div className="preview_box">
      
        <div className="switching_btns">
          <div 
            className={`item_btn ${width == "360px" && "active"}`} 
            onClick={smartPhone}
          >
            <span><BiMobile/>スマホ</span>
          </div>
          <div 
            className={`item_btn ${width == "820px" && "active"}`} 
            onClick={tablet}
          >
            <span><BiTab/>タブレット縦</span>
          </div>
          <div 
            className={`item_btn ${width == "1180px" && "active"}`}  
            onClick={tabletWide}
          >
            <span><BiTab/>タブレット横</span>
          </div>
          <div 
            className={`item_btn ${width == "100%" && "active"}`}  
            onClick={computer}
          >
            <span><BiLaptop/>パソコン</span>
          </div>
          <div 
            className="item_btn"
            onClick={() => setIframeKey((prev)=> prev+1)}
          >
            <span>更新</span>
          </div>
        </div>
        
        <div className="iframe_box" style={{maxWidth: width, height: height}}>
          {iframe()}
        </div>
        
      </div>
    </div>
  );
  
});
