import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { TopicPath } from '@/app/TopicPath';
import { Template } from '@/template/Template';

import { useRecoilState } from 'recoil'
import { limitPagesAtom } from '@/providers/Atoms';


export const Templates = React.memo(() => {
  
  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);
  const [ limitPages ] = useRecoilState(limitPagesAtom)

  const [ product, setProduct ] = useState([]);
  const [ pages, setPages ] = useState([]);
  const [ templates, setTemplates ] = useState([]);
  
  // 画面遷移のため
  const navigate = useNavigate();
  

  useEffect(() => {
    client.get(`/api/v1/products/${productId}/templates`)
    .then(resp => {
      if (productId) {
        setProduct(resp.data.product);
        setPages(resp.data.pages);
        setTemplates(resp.data.templates);
      } else {
        setTemplates(resp.data.templates);
      };
    })
    .catch(e => {
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  

  //許可ページ数の判定
  let pagesJudg = limitPages > pages.length || currentUser.admin == true
  
  // コピーデータ
  const copy_data = {
    product_id: product.id,
    // slug: pages.length + 1,
    pages: pages.length,
  }

  //ページのコピー
  const pageCopy = (id) => {
    if(!pagesJudg) {
      setToastObj({message: `利用可能なページ数「${limitPages}ページ」まで。`, color: "#ffedab"});
      return;
    };
    
    const sure = window.confirm(`このテンプレートを追加しますか?`);
    if (sure) {
      client.post(`/api/v1/pages/copy/${id}`, copy_data )
      .then(resp => {
        setPages((prev)=>[resp.data, ...prev]);
        navigate(`/product/${productId}/dashboard`);
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };


  return (
    <>
      <TopicPath topic="テンプレート"/>
      <h1 className="H1">テンプレート</h1>
      <p>テンプレートの内容は後から自由にカスタマイズ可能です。ご自身のビジネスにあわせて自由に変更してください。</p>
      
      <div className="template_card_area">
        {templates.map((page) => {
          return (
            <Template page={page} pageCopy={pageCopy} key={page.id}/>
          );
        })}
      </div>
      
      <style>
        {`
          .main_area{
            max-width: 1300px;
          }
        `}
      </style>
    </>
  );
});

// body {
//             background: #091520;
//           }