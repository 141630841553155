import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { Sections } from '@/site/Sections';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";


export const Toppage = () => {
  console.log("トップページ");
  
  const productId = "brush"
  const { slug } = useParams();
  
  const { client } = useContext(StateContext);
  
  // const [ product, setProduct ] = useState([]);
  const [ setting, setSetting ] = useState([]);
  // const [ page, setPage ] = useState([]);
  // const pageState = { 
  //   page, setPage
  // };
  
  const [ headerSections, setHeaderSections ] = useState([]);
  const [ mainSections, setMainSections ] = useState([]);
  const [ footerSections, setFooterSections ] = useState([]);

  
  useEffect(() => {
    client.get(`/api/v1/products/${productId}/pages/${slug || 1}`)
    .then(resp => {
      // setProduct(resp.data.product);
      setSetting(resp.data.setting);
      // setPage(resp.data.page);
      setMainSections(resp.data.main_sections);
      setHeaderSections(resp.data.header_sections);
      setFooterSections(resp.data.footer_sections);
    })
  }, []);
  
  
  const SettingCSS = css`
   font-family: ${setting.font};
   background: ${setting.back_color};
   
   p, a {
    font-size: ${setting.font_size && `${setting.font_size}px`};
   }
  `
  
  return (
    <>
      <div css={SettingCSS}>
        <Sections   
          sections={headerSections} 
          setSections={setHeaderSections} 
          sharing="header"
        />
  
        <Sections 
          // page_id={page.id} 
          sections={mainSections} 
          setSections={setMainSections}
          sharing={null}
        />
        
        <Sections 
          sections={footerSections} 
          setSections={setHeaderSections} 
          sharing="footer"
        />

      </div>
    </>
  )
}