import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';

export const Users = () => {

  const { client, currentUser } = useContext(StateContext);
  
  const [ users, setUsers ] = useState([]);
  // console.log(user);
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!currentUser.admin) {
      navigate(`/home`);
    }
  }, []);
  
  useEffect(() => {
    client.get(`/api/v1/users`)
    .then(resp => {
      setUsers(resp.data);
    })
    .catch(e => {
      navigate(`/home`);
      console.log(e);
    });
  }, []);
  

  return (
    <>
      <div className="billing">
        {users.map((user) => {
          return (
            <div className="item" key={user.id}>
              <div className="item_name">【{user.id}】{user.name || "名前なし"}</div>
              <div className="">{user.email}</div>
              <div className="item_btn">
                <Link to={`/account/${user.account}`}>{user.account}</Link>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
