import React from 'react';
// import TextareaAutosize from 'react-textarea-autosize';
import { BiCamera } from "react-icons/bi";


export const ImgSetting = ({ FileUpload, watch, Clear }) => {
  
  return (
    <>
        <h2 className="H1 mb_2">画像設定</h2>
        
        <div className="input_fields">
          
          <div className="field_3">
            <label>サムネイル画像
              <div className="btn_clear" type="button" onClick={()=>Clear("img")}>クリア</div>
            </label>
            <div className="product_img">
              <img src={watch('img') || "/no_img.png"} />
              <label className="up_btn">
                <BiCamera/>
                <input 
                  type="file" 
                  name="img" 
                  accept="image/*"
                  onChange={FileUpload}
                />
              </label>
              <p>最大サイズ：500KBまで</p>
            </div>
          </div>
          
          <div className="field_3">
            <label>ファビコン画像
              <div className="btn_clear" type="button" onClick={()=>Clear("icon")}>クリア</div>
            </label>
            <div className="product_img">
              <img src={watch('icon') || "/no_img.png"} />
              <label className="up_btn">
                <BiCamera/>
                <input 
                  type="file" 
                  name="icon" 
                  accept="image/*"
                  onChange={FileUpload}
                />
              </label>
              <p>最大サイズ：100KBまで</p>
            </div>
          </div>
          
          <div className="field_3">
            
          </div>
          
        </div>
    </>
  );
};



// <div className="field_3">
//   <label>ローディング画像
//     <div className="btn_clear" type="button" onClick={()=>Clear("loading")}>クリア</div>
//   </label>
//   <div className="product_img">
//     <img src={watch('loading') || "/no.png"} />
//     <label className="up_btn">
//       <BiCamera/>
//       <input 
//         type="file" 
//         name="loading" 
//         accept="image/*"
//         onChange={FileUpload}
//       />
//     </label>
//   </div>
// </div>




// <div className="input_fields">
          
//           <div className="field_3">
//             <label>サムネイル画像</label>
//             <div className="product_img">
//               <img src={product.img || "/1.jpg"} />
              
//               getValues('status')
//               <label className="up_btn">
//                 <BiCamera/>
//                 <input 
//                   type="file" 
//                   name="img" 
//                   accept="image/*"
//                   onChange={FileUpload}
//                 />
//               </label>
//             </div>
//           </div>
          
//           <div className="field_3">
//             <label>ファビコン画像</label>
//             <div className="product_img">
//               <img src={product.icon || "/1.jpg"} />
//               <label className="up_btn">
//                 <BiCamera/>
//                 <input 
//                   type="file" 
//                   name="icon" 
//                   accept="image/*"
//                   onChange={FileUpload}
//                 />
//               </label>
//             </div>
//           </div>
          
//           <div className="field_3">
//             <label>ローディング画像</label>
//             <div className="product_img">
//               <img src={product?.loading || setting?.loading || "/1.jpg"} />
//               <label className="up_btn">
//                 <BiCamera/>
//                 <input 
//                   type="file" 
//                   name="loading" 
//                   accept="image/*"
//                   onChange={FileUpload}
//                 />
//               </label>
//             </div>
//           </div>
          
//         </div>