import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { BiPlus } from "react-icons/bi";


export const NewMenu = ({ menusState }) => {

  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  const { productId } = useParams();
  
  const initial = {subdomain: productId}
  const [ newMenu, setNewMenu ] = useState(initial);
  const { menus, setMenus } = menusState
  
  // console.log(newMenu);

  const InputChange = event => {
    const { name, value } = event.target;
    setNewMenu({ ...newMenu, [name]: value });
  };


  // メニュー作成
  const AddMenu = () => {
    if(newMenu.name == null){
      setToastObj({message: "メニュー名を入力してください。", color: "#ffedab"});
      return;
    }
    
    client.post('/api/v1/menus', newMenu)
    .then(resp => {
      setNewMenu(initial);
      setMenus([resp.data.menu, ...menus]);
      setToastObj({message: "新しいメニューを追加しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    });
  };


  return (
    <>
      <div className="base_box">
        <h2 className="H2">メニューの作成</h2>
        <input 
        　className="input" 
        　style={{marginBottom: "5px"}}
        　type="text" 
        　name="name"
        　value={newMenu?.name || ''} 
        　onChange={InputChange}
        　placeholder="メニュー名を入力..."
        />
        <p className="info">
          ※このメニュー名は表示されません。例）ナビバー、フッターなど  
        </p>
        
        <button className="btn_add" onClick={AddMenu}>
          <span><BiPlus/>メニューを作成</span>
        </button>
      </div>
    </>
  );
};
