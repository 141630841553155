import React, { useEffect, useContext } from 'react';
import { StateContext } from '../providers/StateProviders';
import { ImgItem } from '@/image/ImgItem';

import { useRecoilState } from 'recoil'
import { freeImagesAtom } from '@/providers/Atoms';

export const FreeImgs = React.memo(({ obj_img, InputChange }) => {
  
  const { client } = useContext(StateContext);

  const [ freeImages, setFreeImages ] = useRecoilState(freeImagesAtom)
  
  // console.log("フリー", freeImages);
  
  // 画像リスト取得
  useEffect(() => {
    if(freeImages == ""){
      console.log("フリー画像取得");
      client.get(`/api/v1/images/free`)
      .then(resp => {
        setFreeImages(resp.data || []);
      });
    }
  }, []);
  

  return (
    <>
      {freeImages.map((image, index) => {
        return (
          <ImgItem
            // obj={obj}
            obj_img={obj_img}
            image={image}
            InputChange={InputChange}
            key={image.id}
          />
        );
      })}
    </>
  );

});