import React from 'react'
import { PartTypes } from '@/part/PartTypes';
import parse from 'html-react-parser';
// import { isMobile } from "react-device-detect";
import { PartTypePreview } from '@/part/PartTypePreview';


export const PartSelect = React.memo(({ obj, show, PartTypeChange, CloseHandler }) => {

  // console.log("モバイル", isMobile);
  
  return (
    <>
      {PartTypes.map((item, index) => (
        item.type.includes(show) &&
          <label 
            className={`item ${item.type == `${obj.component_name} ${obj.class_name}` && "active"}`}  
            key={index}
            onClick={()=>PartTypeChange(item.type)}
          >
            <div className="img_box">
              <PartTypePreview item={item}/>
            </div>
            {/* <div className="type_name">{parse(item.name)}</div> */}
          </label>
      ))}
    </>
  );
});

// <>
//   {isMobile 
//     ?
//       <>
//         {PartTypes.map((item, index) => (
//           item.type.includes(show) &&
//             <label 
//               className={`item ${item.type == `${obj.component_name} ${obj.class_name}` && "active"}`}  
//               key={index}
//               onClick={()=>PartTypeChange(item.type)}
//             >
//               <div className="img_box">
//                 {React.createElement(item.svg || "span")}
//               </div>
//               <div className="type_name">{parse(item.name)}</div>
//             </label>
//         ))}
//       </>
//     :
//       <>
//         {PartTypes.map((item, index) => (
//           item.type.includes(show) &&
//             <label 
//               className={`item ${item.type == `${obj.component_name} ${obj.class_name}` && "active"}`}  
//               key={index}
//               onMouseEnter={()=>PartTypeChange(item.type)}
//               onClick={CloseHandler}
//             >
//               <div className="img_box">
//                 {React.createElement(item.svg || "span")}
//               </div>
//               <div className="type_name">{parse(item.name)}</div>
//             </label>
//         ))}
//       </>
//   }
// </>