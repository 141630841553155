import React, { useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContext } from '../providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { useForm } from 'react-hook-form';

// import { useSetRecoilState } from 'recoil'
// import { saveDataAtom } from '@/providers/Atoms';


export const SlugEdit = React.memo(({ product, page, setPage, PathToggle }) => {
  
  // console.log("パス編集レンダ");
  
  // バリデーションフォーム
  const { 
    register, 
    handleSubmit,
    reset, 
    formState: { errors }, 
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
    
  // const { productId } = useParams();
  const { slug } = useParams();
  
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  // const setSaveData = useSetRecoilState(saveDataAtom)

  // const [ active, setActive ] = useState(false);
  // const [ display, setDisplay ] = useState("編集");
  
  useEffect(() => {
    reset({slug: page.slug});
  }, []);

  
  // const OpenToggle = ()=> {
  //   setActive((prev)=> !prev);
  //   setDisplay(active ? "編集" : "閉じる");
  // };
  
  // const CloseHandler = ()=> {
  //   // setActive(false);
  // };
  
  
  // 画面遷移のため
  const navigate = useNavigate();

  // 編集
  const SlugUpdate = (data) => {

    if (data.slug == "contact" || data.slug == "sitemap" || data.slug == "mail"  || data.slug == "pricing") {
      setToastObj({message: "パスに使用できない文字です。", color: "#ffedab"});
      return;
    } else if (data.slug == "template" || data.slug == "templates") {
      setToastObj({message: "パスに「template／templates」は使用できません。", color: "#ffedab"});
      return;
    } else if (data.slug == "category" || data.slug == "categories") {
      setToastObj({message: "パスに「category／categories」は使用できません。", color: "#ffedab"});
      return;
    } else if (data.slug == "tag" || data.slug == "tags") {
      setToastObj({message: "パスに「tag／tags」は使用できません。", color: "#ffedab"});
      return;
    } 

    client.patch(`/api/v1/pages/${page.id}`, data)
    .then(resp => {
      setPage(resp.data);
      // setSaveData((prev)=> ({...prev, page: resp.data}));
      
      if(slug !== resp.data.slug ){
        navigate(`/product/${product.subdomain}/${resp.data.slug}/edit`);
      }
      setToastObj({message: "パスを変更しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  

  return (
    <div className="path_edit_box">
      <form onSubmit={handleSubmit(SlugUpdate)}>

        <label>このページの「URLパス」</label>
        <div className="path_input_box">
          <div className="host_name">https://{ product.domain || `${product.subdomain}.pstock.me`}/</div>
      
          <input 
            id="slug"
            className={`input ${errors.slug?.message && "error"}`}
            style={{border: errors.slug?.message ? "1px solid red" :""}}
            type="text"
            placeholder="半角英数字を入力..."
            {...register('slug', {
              required: { value: true, message: '【エラー】必須項目です。'},
              pattern: { value: /^[a-z0-9\-]+$/, message: '【エラー】半角英数字と「-」のみ使用可能です。'},
            })}
          />
        </div>
        {errors.slug?.message && 
          <div className="color_red">{errors.slug.message}</div>
        }
        <p>半角英数字と「-」のみ使用可。<br/>
        変更すると「ページURL」が変更されますのでご注意ください。</p>
        
        <div className="mt_1" style={{textAlign: "right"}}>
          <button className="btn_text mr_2" onClick={PathToggle}>閉じる</button>
          <button type="submit" className="btn">保存</button>
        </div>
      </form>
    </div>
  );

});

// <button className="btn" onClick={updatePage}>保存</button>

    // if (/contact|sitemap|mail|template|pricing/.test(data.slug)) {
    //   setToastObj({message: "パスに使用できない文字が含まれています。", color: "#ffedab"});
    //   return;
    // } 