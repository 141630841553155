import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { Nav } from '@/product/Nav';
import { Subject } from '@/product/Subject';

import { useSetRecoilState } from 'recoil'
import { productAtom, addonsAtom } from '@/providers/Atoms';


export const CustomForms = React.memo(() => {
  
  // console.log("共有ブロック", "レンダリング");
  
  const { productId } = useParams();
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const setProduct = useSetRecoilState(productAtom);
  const setAddons = useSetRecoilState(addonsAtom)

  const [ blocks, setBlocks ] = useState([]);
  const blocksState = { 
    blocks, setBlocks
  };

  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    client.get(`/api/v1/blocks/sharing?subdomain=${productId}`)
    .then(resp => {
      setProduct(resp.data.product);
      setBlocks(resp.data.blocks);
      setAddons(resp.data.addons);
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  
  
  return (
    <>
      <div className="main_layer"></div>
      <Nav/>
      <Subject/>
      
      <h1 className="H1 color_white pb_1">カスタムフォーム</h1>
      <div className="color_white mb_1">
        各ページで共通して表示できるブロックの「作成」「編集」。
        作成したブロックは、ページ編集の「パーツを追加」より、選択して使用できます。
      </div>
      
        <div className="base_box">
          

        </div>
 
    </>
  );
});

        //   { blocks.length !== 0 &&
        //     <div className="block_card_box">
        //       {blocks.map((block) => {
        //         return (
        //           <div className="block_card" key={block.id}>
        //             <div className="card_title">
        //               {block.name || "ブロック名が未設定です。"}
        //               <span className="badge badge_dark">{block.status || "公開"}</span>
        //             </div>
      
        //             <Link to={`/product/${productId}/block/${block.id}`}>
        //               <button className="btn_edit">編集<BiChevronRight/></button>
        //             </Link>
        //           </div>
        //         );
        //       })}
        //     </div>
        //   }