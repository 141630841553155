import React from 'react';
import { Link } from 'react-router-dom';
import { BiHome, BiChevronRight } from "react-icons/bi";


export const TopicPath = ({ children, topic }) => {
  
  return (
    <>
      <div className="topic_path">
        <Link to="/home"><BiHome/>ホーム</Link>

        {children &&
          <>
            <BiChevronRight/>
            {children}
          </>
        }
        
        {topic &&
          <>
            <BiChevronRight/>
            <Link>{topic}</Link>
          </>
        }
      </div>
    </>
  );
};