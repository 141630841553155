import React, { useEffect} from 'react';
import { AddonJudg } from '@/addon/AddonJudg';


export const SnsSetting = ({ register, errors, Clear }) => {
  
  // useEffect(() => {
  //   reset(setting);
  // }, []);
  
  // console.log(getValues());
  
  return (
    <>
      <h2 className="H1 mb_2">SNS設定</h2>
      
      <AddonJudg terms={/スタンダード|ビジネス|制作代行/} boolean={true}>
        <div className="input_fields">
          
          <div className="form_field field_2">
            <label>Instagram</label>
            <input 
              id="instagram"
              className={`input ${errors.instagram ?.message && "error"}`}
              // style={{border: errors.instagram ? "1px solid red" :""}}
              type="text"
              placeholder="プロフィールURLを入力..."
              {...register('instagram', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.instagram?.message && 
              <div className="color_red">{errors.instagram.message}</div>
            }
            <p>入力例）https://www.instagram.com/◯◯◯</p>
          </div>
          
          <div className="form_field field_2">
            <label>Twitter</label>
            <input 
              id="twitter"
              className={`input ${errors.twitter ?.message && "error"}`}
              // style={{border: errors.twitter ? "1px solid red" :""}}
              type="text"
              placeholder="アカウントURLを入力..."
              {...register('twitter', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.twitter?.message && 
              <div className="color_red">{errors.twitter.message}</div>
            }
            <p>入力例）https://twitter.com/◯◯◯</p>
          </div>
          
          <div className="form_field field_2">
            <label>YouTube</label>
            <input 
              id="youtube"
              className={`input ${errors.youtube ?.message && "error"}`}
              // style={{border: errors.youtube ? "1px solid red" :""}}
              type="text"
              placeholder="チャンネルURLを入力..."
              {...register('youtube', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.youtube?.message && 
              <div className="color_red">{errors.youtube.message}</div>
            }
            <p>入力例）https://www.youtube.com/channel/◯◯◯</p>
          </div>
          
          <div className="form_field field_2">
            <label>LINE</label>
            <input 
              id="line"
              className={`input ${errors.line ?.message && "error"}`}
              // style={{border: errors.line ? "1px solid red" :""}}
              type="text"
              placeholder="アカウントURLを入力..."
              {...register('line', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.line?.message && 
              <div className="color_red">{errors.line.message}</div>
            }
            <p>入力例）https://lin.ee/◯◯◯</p>
          </div>
          
          <div className="form_field field_2">
            <label>TikTok</label>
            <input 
              id="tiktok"
              className={`input ${errors.tiktok ?.message && "error"}`}
              // style={{border: errors.tiktok ? "1px solid red" :""}}
              type="text"
              placeholder="アカウントURLを入力..."
              {...register('tiktok', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.tiktok?.message && 
              <div className="color_red">{errors.tiktok.message}</div>
            }
            <p>入力例）https://www.tiktok.com/@◯◯◯</p>
          </div>
          
          <div className="form_field field_2">
            <label>Facebook</label>
            <input 
              id="facebook"
              className={`input ${errors.facebook ?.message && "error"}`}
              // style={{border: errors.facebook ? "1px solid red" :""}}
              type="text"
              placeholder="プロフィールURLを入力..."
              {...register('facebook', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.facebook?.message && 
              <div className="color_red">{errors.facebook.message}</div>
            }
            <p>入力例）https://www.facebook.com/◯◯◯</p>
          </div>

        </div>
      </AddonJudg>
    </>
  );
};
// <div className="input_fields">
      
//         <div className="field_2">
//           <label>Instagram</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="instagram" 
            
//             placeholder="アカウントを入力..."
//           />
//         </div>
        
//         <div className="field_2">
//           <label>Twitter</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="twitter" 
           
//             placeholder="アカウントを入力..."
//           />
//         </div>
        
//         <div className="field_2">
//           <label>YouTube</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="youtube" 
           
//             placeholder="アカウントを入力..."
//           />
//         </div>
        
//         <div className="field_2">
//           <label>LINE</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="line" 
            
//             placeholder="アカウントを入力..."
//           />
//         </div>
        
//         <div className="field_2">
//           <label>TikTok</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="tiktok" 
            
//             placeholder="アカウントを入力..."
//           />
//         </div>
        
//         <div className="field_2">
//           <label>Facebook</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="facebook" 
           
//             placeholder="アカウントを入力..."
//           />
//         </div>
        
//       </div>