import React from 'react';
import { Link } from 'react-router-dom';
// import { PlanTypes } from '@/addon/AddonTypes';
import { BiCheckCircle } from "react-icons/bi";


export const Plans = ({ plans }) => {
  
  const List = (content) => {
    if(content == null) {
      return [];
    } else {
      const texts = content.split(/\n/);
      return texts; 
    }
  }

  return (
    <>
      <div className="plan_card_area">
        {plans.map((plan) => {
          return (
            <div 
              className={`plan_card ${plan.addon_key == "Business" && "card_line"}`} 
              key={plan.id}
            >
              <div className="plan_body">
                <div className="title">{plan.addon_key}</div>
                <div className="subtitle">{plan.addon_name}</div>
                <div className="price">￥<span>{plan.price}</span>/月</div>
                <ul>
                  {List(plan.content).map((text, index) => {
                    return (
                      <li key={index}>{text}<BiCheckCircle/></li>
                    );
                  })}
                </ul>
                
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

      // <div className="plan_card" style={{width: "100%"}}>
      //   <div className="plan_body">
      //     <div className="title">Free</div>
      //     <div className="subtitle">フリープラン</div>
      //     <div className="price">無料</div>
      //     <ul>
      //       <li>広告表示<BiCheckCircle/></li>
      //       <li>１ページまで<BiCheckCircle/></li>
      //     </ul>
      //   </div>
      // </div>

// {plan.addon_key == "BasePlan" &&
//                 <div className="plan_header">おすすめ</div>
//               }
              
// <Link to={`/addons`}>
//   <button className="btn_entry">詳細</button>
// </Link>
