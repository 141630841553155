import React, { useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { PartCategory } from '@/part/PartTypes';
import { PartSelect } from '@/part/PartSelect';
import { ShareBlocksSelect } from '@/section/ShareBlocksSelect';
import { BiPlus, BiX } from "react-icons/bi";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { useRecoilState, useSetRecoilState } from 'recoil'
import { sectionsStatesAtom, openSidebarAtom, sharingAtom, sectionIndexAtom } from '@/providers/Atoms';


export const NewSectionGlobal = React.memo(({ page_id }) => {

  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  const [ sharing ] = useRecoilState(sharingAtom);
  const [ sectionIndex ] = useRecoilState(sectionIndexAtom);
  
  const [ sectionsStates ] = useRecoilState(sectionsStatesAtom);
  const sections = sectionsStates[0]
  const setSections = sectionsStates[1]
  
  // console.log("sections", sections);
  // console.log("sharing", sharing);
  // console.log("sectionIndex", sectionIndex);

  const [ shareBlocks, setShareBlocks ] = useState([]);
  
  const new_section_date = {
    page_id: page_id,
    sharing: sharing,
    subdomain: productId,
  };
  const [ section, setSection ] = useState(new_section_date);
  
  const PartTypeChange = (value) => {
    let type = value.split(" ")
    setSection((prev)=> ({ ...prev, component_name: type[0], class_name: type[1]}));
  };
  
  // 共有ブロックを選択
  const SelectItem = event => {
    const { name, value } = event.target;
    setSection((prev)=> ({ ...prev, [name]: value }));
  };
  
  //並び順を保存
  const OrderUpdate = (new_objs, new_data) => {
    client.patch(`/api/v1/sections/order`, {sections: new_objs})
    .then(resp => {
      console.log("並び替え完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
    });
  };

  const AddSection = (path) => {
    // console.log("section", section);
    client.post(`/api/v1/${path}`, section)
    .then(resp => {
      setOpenSidebar("");
      const new_objs = [...sections];
      new_objs.splice(sectionIndex+1, 0, resp.data.section)
      setSections(new_objs);
      console.log("セクション追加");
      // 順番を保存
      OrderUpdate(new_objs, resp.data);
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    })
  };
  
  
  const [ show, setShow ] = useState("Part");
  
  const GetShareBlocks = () => {
    setShow("Block")
    
    if(shareBlocks == ""){
      client.get(`/api/v1/blocks/sharing?subdomain=${productId}`)
      .then(resp => {
        setShareBlocks(resp.data.blocks);
      })
    }
  };
  
  const closeHandler = ()=> {
    setOpenSidebar("");
  };


  const BarCSS = css`
    .items_bar_top {
      height: 130px;
    }
   .items_box {
      top: 130px;
      height: calc(100% - 200px);
    }
    .items_bar_bottom {
      display: block;
    }
    
    @media screen and (max-width: 500px) {
      .items_bar_top {
        height: 85px;
      }
      .items_box {
        top: 85px;
        height: calc(100% - 145px);
        padding: 1rem 2rem 2rem;
      }
    }
  `

  return (
    <>
      <div className="items_bar" css={BarCSS}>
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            ブロックの追加
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
          
          <div className="tab_menu">
            <button
              className={`tab_menu_btn ${show !== "Block" && "active"}`}
              style={{width: "calc(100% / 2 - 5px)"}}
              onClick={()=>setShow("Part")}
            >パーツ選択</button>
            <button
              className={`tab_menu_btn ${show == "Block" && "active"}`}
              style={{width: "calc(100% / 2 - 5px)"}}
              onClick={GetShareBlocks}
            >共有ブロック</button>
          </div>
        </div>
        
        <div className="items_box select_type" 
          name={`section_${page_id}`}
        >
          {show == "Part" &&
            <>
              {PartCategory.map((category, index) => {
                return (
                  <button key={index}
                    className={`type_btn ${show == category.type && "active"}`}
                    onClick={()=>setShow(category.type)}
                  >
                    {category.name}
                  </button>
                );
              })}
            </>
          }

          {show !== "Block" &&
            <>
              {show !== "Part" &&
                <button className="clear_btn mb_2" onClick={()=>setShow("Part")}>戻る</button>
              }
              
              <PartSelect 
                obj={section}
                show ={show}
                PartTypeChange={PartTypeChange}
              />
            </>
          }
    
          {show == "Block" &&
            <ShareBlocksSelect 
              obj={section} 
              shareBlocks={shareBlocks} 
              SelectItem={SelectItem} 
            />
          }
        </div>

        {show !== "Block" 
          ? 
            <div className="items_bar_bottom">
              <button className="save_btn" onClick={()=>AddSection("sections")}>ブロックを追加する</button>
            </div>
          : 
            <div className="items_bar_bottom" style={{display:"flex"}}>
              <button className="save_btn add_copy_btn mr_1" onClick={()=>AddSection("sections/add_shareblock")}>
                追加する
              </button>
              <button className="save_btn" onClick={()=>AddSection("sections/add_copy_shareblock")}>
                複製して追加
              </button>
            </div>
        }
        
      </div>
    </>
  );
});

// <div className="items_bar_bottom">
//   <button className="save_btn" onClick={()=>AddSection("sections/add_copy_shareblock")}>ブロックを複製追加</button>
//   {show !== "Block" 
//     ? <button className="save_btn" onClick={()=>AddSection("sections")}>ブロックを追加する</button>
//     : <button className="save_btn" onClick={()=>AddSection("sections/add_shareblock")}>共有ブロックを追加する</button>
//   }
// </div>