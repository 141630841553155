import React, { useState, useEffect, useRef } from "react"
// import { StateContext } from '@/providers/StateProviders';
// import { ToastContext } from '@/providers/ToastProvider';
// import { ImgSelect } from '@/image/ImgSelect';
// import { PartChange } from '@/part/PartChange';
import { ColorPicker } from '@/part/ColorPicker';
import { PartSetting } from '@/part/PartSetting';
import { fontFamilys } from '@/app/DefaultData';
import { RangeSpin } from '@/app/RangeSpin';
import { RangeSpinSplit } from '@/app/RangeSpinSplit';

import { BiReset, BiCog, BiX, BiBorderRadius } from "react-icons/bi";
import { MdFormatColorFill, MdOutlineFormatColorText, MdOutlineColorLens, } from "react-icons/md";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { BsFileFont } from "react-icons/bs";
import { TbBoxMargin } from "react-icons/tb";

// import { useSetRecoilState } from 'recoil'
// import { saveDataAtom } from '@/providers/Atoms';

// import { useRecoilState } from 'recoil'
// import { clickTextAtom } from '@/providers/Atoms';
// import { TextPicker } from '@/text/TextPicker';

export const PartEdit = React.memo(({ partState, partsState, 
  InputChange, updatePart, CloseHandler, openState, index }) => {

  // console.log("パーツ編集メニュー", "レンダ");

  // const { client } = useContext(StateContext);
  // const { setToastObj } = useContext(ToastContext);
  
  const { part, setPart } = partState;
  // const { parts, setParts } = partsState;
  const { open, setOpen } = openState;
  
  // ドロップダウンの非表示用
  const [ none, setNone ] = useState(false);
  
  // カラー
  const [ fontColor, setFontColor ] = useState("");
  const [ backColor_0, setBackColor_0 ] = useState("");
  const [ backColor_1, setBackColor_1 ] = useState("");
  
  const [ borderColor, setBorderColor ] = useState("");
  
  const [ accentColor_0, setAccentColor_0 ] = useState("");
  const [ accentColor_1, setAccentColor_1 ] = useState("");

  useEffect(() => {
    setFontColor(part.font_color ||"");
    setBorderColor(part.border_color ||"");
    
    let backColors = part.back_color ? part.back_color.split("／") : [];
    setBackColor_0(backColors[0] ||"");
    setBackColor_1(backColors[1] ||"");
    
    let accentColors = part.sub_color ? part.sub_color.split("／") : [];
    setAccentColor_0(accentColors[0] ||"");
    setAccentColor_1(accentColors[1] ||"");
  }, []);

  // console.log(titleColor_0, titleColor_1, "カラー");

  
  // フォーカスを外す
  const removeFocus = () => {
    const elem = document.activeElement;
    elem.blur();
  };
  
  // マウスオンで変更
  const changeOnMouse = (name, value) => {
    setPart((prev)=> ({ ...prev, [name]: value }));
    
    // const newPart = {...part, [name]: value };
    // setPart(newPart);
    // SaveDataUpdate(newPart);
    removeFocus();
  };

  // ドロップダウンを非表示に
  const hiddenDrop = () => {
    setNone(true);
  };
  

  // refをtrueで初期化。
  const Reff = useRef(true);
  
  // カラー変更時
  useEffect(() => {
    // 初回レンダリング時はrefをfalseにして、return。
    if (Reff.current || 
        part.font_color == fontColor && 
        // part.back_color == backColor && 
        part.border_color == borderColor && 
        // part.sub_color == subColor && 
        part.back_color == `${backColor_0}／${backColor_1}` &&
        part.sub_color == `${accentColor_0}／${accentColor_1}`
      ) {
      Reff.current = false;
      return;
    }

    console.log("カラー変更処理");
    const newPart = {
      ...part, 
      font_color: fontColor, 
      back_color: `${backColor_0}／${backColor_1}`, 
      border_color: borderColor, 
      sub_color: `${accentColor_0}／${accentColor_1}`,
    };
    setPart(newPart);
    // SaveDataUpdate(newPart);
  }, [fontColor, borderColor, backColor_0, backColor_1, accentColor_0, accentColor_1]);
  
  
  // リセット
  const Reset = () => {
    setFontColor("");
    setBorderColor("");
    // setBackColor("");
    setBackColor_0("");
    setBackColor_1("");
    setAccentColor_0("");
    setAccentColor_1("");

    const newPart = {
      ...part, 
      font_color: null,
      back_color: null,
      border_color: null,
      sub_color: null,
      font_size: null,
      font_weight: null,
      font: null,
      text_shadow: null,
      box_shadow: null,
      radius: null,
      border: null,
      width: null,
      height: null,
      padding: null,
      margin: null,
      position: null,
      align: null,
      delay_seconds: null,
    };
    setPart(newPart);
    // SaveDataUpdate(newPart);
  }
  
  // クリア
  const Clear = (column) => {
    setPart((prev)=> ({ ...prev, [column]: null }));
    
    // const newPart = {...part, [column]: null};
    // setPart(newPart);
    // SaveDataUpdate(newPart);
  }
  
  // カラークリア
  const coloClear = (column) => {
    if(column == "font_color") {
      setFontColor("");
    } else if(column == "back_color_0") {
      setBackColor_0("");
    } else if(column == "back_color_1") {
      setBackColor_1("");
    } else if(column == "border_color") {
      setBorderColor("");
    } else if(column == "accent_color_0") {
      setAccentColor_0("");
    } else if(column == "accent_color_1") {
      setAccentColor_1("");
    }
  }
  
  
  const typeChangeHandler = ()=> {
    setOpen("Type");
    removeFocus();
  };
  
  const settingHandler = ()=> {
    setOpen("Setting");
    removeFocus();
  };
  

  const [ colorStateSwitch, setColorStateSwitch ] = useState([]);
  const [ inputName, setInputName ] = useState("");
  
  const fontColorHandler = ()=> {
    setOpen("Color");
    setColorStateSwitch([fontColor, setFontColor]);
    setInputName("font_color");
    // setInputValue(fontColor);
    removeFocus();
  };
  
  const borderColorHandler = ()=> {
    setOpen("Color");
    setColorStateSwitch([borderColor, setBorderColor]);
    setInputName("border_color");
    // setInputValue(borderColor);
    removeFocus();
  };
  
  const backColor_0_Handler = ()=> {
    setOpen("Color");
    setColorStateSwitch([backColor_0, setBackColor_0]);
    setInputName("back_color_0");
    removeFocus();
  };
  
  const backColor_1_Handler = ()=> {
    setOpen("Color");
    setColorStateSwitch([backColor_1, setBackColor_1]);
    setInputName("back_color_1");
    removeFocus();
  };
  
  const accentColor_0_Handler = ()=> {
    setOpen("Color");
    setColorStateSwitch([accentColor_0, setAccentColor_0]);
    setInputName("accent_color_0");
    removeFocus();
  };
  
  const accentColor_1_Handler = ()=> {
    setOpen("Color");
    setColorStateSwitch([accentColor_1, setAccentColor_1]);
    setInputName("accent_color_1");
    removeFocus();
  };
  
  
  const widthValue = [
    { name: "width", value: 100 },
    { name: "width", value: 90 }, 
    { name: "width", value: 80 }, 
    { name: "width", value: 70 }, 
    { name: "width", value: 60 },
    { name: "width", value: 50 }, 
    { name: "width", value: 48 }, 
    { name: "width", value: 40 },
    { name: "width", value: 34 },
    { name: "width", value: 33 },
    { name: "width", value: 32 },
    { name: "width", value: 30 },
    { name: "width", value: 25 },
    { name: "width", value: 24 },
    { name: "width", value: 20 }, 
  ]

  
  // marginの分割
  let margins = part.margin ? part.margin.split("／") : [];
  
  const marginChange = event => {
    const { name, value } = event.target;

    if (name == "margin_top") {
      setPart((prev)=> ({ ...prev, margin: `${value}／${margins[1] ||""}` }));
    } else if (name == "margin_bottom") {
      setPart((prev)=> ({ ...prev, margin: `${margins[0] ||""}／${value}` }));
    }
  };
  
  // marginクリア
  const marginClear = (column) => {
    if (column == "margin_top") {
      setPart((prev)=> ({ ...prev, margin: `／${margins[1] ||""}` }));
    } else if (column == "margin_bottom") {
      setPart((prev)=> ({ ...prev, margin: `${margins[0] ||""}` }));
    }
  }
  
  
  return (
    <>
      <div className="part_menu">
        <div className="tooltip">
          <div className="part_badge">パーツ／{part.id}</div>
          <div className="tooltip_text">パーツID</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={typeChangeHandler}>タイプ<br/>変更</button>
          <div className="tooltip_text">パーツタイプ変更<br/>{part.class_name || ""}</div>
        </div>
        
        {/* 
        <div className="drop_btn nobtn">
          <button className="menu_btn"><AiOutlineFontSize/></button>
          <div 
            className="drop_box drop_box_center" 
            style={{display: none && "none"}}
            onClick={hiddenDrop}
            onMouseLeave={()=>setNone(false)}
          >
            <div className="drop_list">
              <div className="drop_item">文字サイズ</div>
              <div className="drop_item" onClick={()=>Clear("font_size")}>クリア</div>
              {fontSizeValue.map((item, index) => (
                <div
                  className={`drop_item ${part.font_size == item.value && "active"}`} 
                  onMouseEnter={()=>changeOnMouse(item.name, item.value)}
                  key={index}
                >
                  {item.value}px
                </div>
              ))}
            </div>
          </div>
        </div>
        */}
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><BsFileFont/></button>
          <div 
            className="drop_box drop_box_center" 
            style={{display: none && "none"}}
            onClick={hiddenDrop}
            onMouseLeave={()=>setNone(false)}
          >
            <div className="drop_list">
              <div className="drop_title">フォント</div>
              <div className="drop_item" onClick={()=>Clear("font")}>クリア</div>
              {fontFamilys.map((item, index) => (
                <div 
                  className={`drop_item ${part.font == item.value && "active"}`} 
                  onMouseEnter={()=>changeOnMouse("font", item.value)}
                  key={index}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* 
        <select 
          className="font_select" 
        　name="font"
        　value={part.font || ''} 
        　onChange={InputChange}
        > 
          {fontFamilys.map((font, index) => {
            return (
              <option value={font.value} key={index}>{font.name}</option>
            );
          })}
        </select>
        */}

        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: fontColor, color: `${fontColor ? "#ccc":""}`}}
            onClick={fontColorHandler}
          ><MdOutlineFormatColorText/></button>
          <div className="tooltip_text">文字（色・フォント）</div>
        </div>
        
        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: backColor_0, color: `${backColor_0 ? "#ccc":""}`}}
            onClick={backColor_0_Handler}
          ><MdFormatColorFill/></button>
          <div className="tooltip_text">背景色</div>
        </div>
        
        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: borderColor, color: `${borderColor ? "#ccc":""}`}}
            onClick={borderColorHandler}
          ><BiBorderRadius/></button>
          <div className="tooltip_text">枠線（色・幅・角丸）</div>
        </div>
        
        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: accentColor_0, color: `${accentColor_0 ? "#ccc":""}`}}
            onClick={accentColor_0_Handler}
          ><MdOutlineColorLens/></button>
          <div className="tooltip_text">アクセント色</div>
        </div>
        
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><AiOutlineColumnWidth/></button>
          <div 
            className="drop_box drop_box_center" 
            style={{display: none && "none"}}
            onClick={hiddenDrop}
            onMouseLeave={()=>setNone(false)}
          >
            <div className="drop_list">
              <div className="drop_title">横幅</div>
              <div className="drop_item" onClick={()=>Clear("width")}>クリア</div>
              {widthValue.map((item, index) => (
                <div 
                  className={`drop_item ${part.width == item.value && "active"}`} 
                  onMouseEnter={()=>changeOnMouse(item.name, item.value)}
                  key={index}
                >
                  {item.value}%
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><TbBoxMargin /></button>
          <div className="drop_box drop_box_center">
            <div className="drop_list" style={{width: "300px"}}>
              <div className="drop_title">パーツのスペース（余白）</div>
              
              <label>外側のスペース【上】（{margins[0] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>marginClear("margin_top")}>クリア</div>
              </label>
              <RangeSpinSplit
                setObj={setPart}
                InputChange={marginChange}
                targetName="margin"
                column="margin_top"
                objValueMain={margins[0] ||10}
                objValueOther={margins[1] ||""}
                beforeAfter="before"
              　max="150"
              />
              
              <label>外側のスペース【下】（{margins[1] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>marginClear("margin_bottom")}>クリア</div>
              </label>
              <RangeSpinSplit
                setObj={setPart}
                InputChange={marginChange}
                targetName="margin"
                column="margin_bottom"
                objValueMain={margins[1] ||10}
                objValueOther={margins[0] ||""}
                beforeAfter="after"
              　max="150"
              />
              
              <label>内側のスペース（{part.padding ||""}px）
                <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
              </label>
              <RangeSpin
                setObj={setPart}
                column="padding"
                objValue={part.padding ||30} 
                InputChange={InputChange}
              　max="150"
              />
          
            </div>
          </div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={settingHandler}><BiCog/></button>
          <div className="tooltip_text">パーツ設定</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={Reset}><BiReset/></button>
          <div className="tooltip_text">パーツのリセット</div>
        </div>

        <div className="closebtn" onClick={CloseHandler}><BiX/></div>
      </div>

      { open == "Color" &&
        <ColorPicker 
          partState={partState} 
          InputChange={InputChange} 
          // colorState={colorState}
          colorStateSwitch={colorStateSwitch}
          backColor_0_Handler={backColor_0_Handler}
          backColor_1_Handler={backColor_1_Handler}
          accentColor_0_Handler={accentColor_0_Handler}
          accentColor_1_Handler={accentColor_1_Handler}
          inputName={inputName}
          // inputValueState={inputValueState}
          updatePart={updatePart} 
          // SaveDataUpdate={SaveDataUpdate}
          Clear={Clear}
          coloClear={coloClear}
          setOpen={setOpen}
        />
      }
      
      { open == "Setting" && 
        <PartSetting 
          partState={partState} 
          InputChange={InputChange} 
          updatePart={updatePart}
          // SaveDataUpdate={SaveDataUpdate}
          Clear={Clear}
          setOpen={setOpen}
        /> 
      }
      
    </>
  );

});


      // { open == "Text" &&
      //   <TextPicker
      //     clickText={clickText}
      //     setClickText={setClickText}
      //     setOpen={setOpen}
      //   />
      // }


        // <div className="tooltip">
        //   <button
        //     className="menu_btn"
        //     style={{ background: clickText.font_color, color: `${clickText.font_color !== null ? "#ccc":""}`}}
        //     onClick={textHandler}
        //   ><MdOutlineFormatColorText/></button>
        //   <div className="tooltip_text">テキスト{clickText.font_size}/{clickText.font_size_s}</div>
        // </div>

        // <div className="tooltip">
        //   <button
        //     className="menu_btn"
        //     style={{ background: subColor, color: `${subColor !== "" ? "#ccc":""}`}}
        //     onClick={subColorHandler}
        //   ><BiMinusBack/></button>
        //   <div className="tooltip_text">その他のカラー</div>
        // </div>

        // <div className="tooltip">
        //   <button
        //     className="menu_btn"
        //     style={{ background: backColor, color: `${backColor !== "" ? "#ccc":""}`}}
        //     onClick={ColorHandler2}
        //   ><MdFormatColorFill/></button>
        //   <div className="tooltip_text">背景色</div>
        // </div>

        // <div className="tooltip">
        //   <select
        //     className="menu_btn" 
        //   　name="width"
        //   　value={part.width || '100'} 
        //   　onChange={InputChange}
        //   >
        //     <option value={100}>横幅</option>
        //     <option value={100}>100%</option>
        //     <option value={70}>70%</option>
        //     <option value={50}>50%</option>
        //     <option value={48}>48%</option>
        //     <option value={31}>31%</option>
        //     <option value={30}>30%</option>
        //     <option value={25}>25%</option>
        //     <option value={23}>23%</option>
        //   </select>
        //   <div className="tooltip_text">横幅（%）</div>
        // </div>
        
        
        // <div className="tooltip">
        //   <select 
        //     className="menu_btn nobtn" 
        //   　name="font_size"
        //   　value={part.font_size ||""} 
        //   　onChange={InputChange}
        //   >
        //     <option value="">文字</option>
        //     <option value={10}>10px</option>
        //     <option value={12}>12px</option>
        //     <option value={14}>14px</option>
        //     <option value={16}>16px</option>
        //     <option value={18}>18px</option>
        //     <option value={20}>20px</option>
        //     <option value={22}>22px</option>
        //     <option value={24}>24px</option>
        //     <option value={26}>26px</option>
        //     <option value={28}>28px</option>
        //     <option value={30}>30px</option>
        //     <option value={32}>32px</option>
        //     <option value={34}>34px</option>
        //     <option value={36}>36px</option>
        //     <option value={38}>38px</option>
        //     <option value={40}>40px</option>
        //   </select>
        //   <div className="tooltip_text">文字サイズ（px）</div>
        // </div>

// <div className="tooltip">
//   <button className="menu_btn" onClick={addPart}><BiPlus/></button>
//   <div className="tooltip_text">パーツ追加</div>
// </div>

// <div className="tooltip">
// <button className="menu_btn" onClick={copyPart}><BiAddToQueue/></button>
// <div className="tooltip_text">パーツ複製</div>
// </div>

// <div className="tooltip">
//   <button className="menu_btn" onClick={deletePart}><BiTrash/></button>
//   <div className="tooltip_text">削���</div>
// </div>

      
      // <div className="tooltip">
      //   <button className="menu_btn" onClick={()=>setOpen("Img")}><BiImageAlt/></button>
      //   <div className="tooltip_text">画像・写真</div>
      // </div>
      
      // { open == "Img" &&
      //   <ImgSelect 
      //     obj={part}
      //     setObj={setPart}
      //     InputChange={InputChange} 
      //     update={updatePart}
      //     SaveDataUpdate={SaveDataUpdate}
      //     Clear={Clear} 
      //     type="part"
      //     setOpen={setOpen}
      //   />
      // }


// <button onClick={updatePart}>保存</button>
// <button onClick={AddPart}>追加</button>
// <span className="">タイプ：{part.component_name}</span>
// <span className="">クラス：{part.class_name}</span>