import React, { useState} from "react"
// import { TextGlobalSetting } from '@/editerMenu/TextGlobalSetting';
import { PartGlobalBar } from '@/editerMenu/PartGlobalBar';
// import { ImgGlobalSetting } from '@/editerMenu/ImgGlobalSetting';
import { BlockGlobalBar } from '@/editerMenu/BlockGlobalBar';
import { NewSectionGlobal } from '@/editerMenu/NewSectionGlobal';

import { useRecoilState } from 'recoil'
import { openSidebarAtom, blockStatesAtom, partStatesAtom } from '@/providers/Atoms';


export const GlobalMenus = React.memo(({ page_id }) => {
  
  // console.log("グローバルメニュー");
  
  const [ openSidebar, setOpenSidebar ] = useRecoilState(openSidebarAtom);
  const [ blockStates, setBlockStates ] = useRecoilState(blockStatesAtom);
  const [ partStates, setPartStates ] = useRecoilState(partStatesAtom);
  // const [ textStates, setTextStates ] = useRecoilState(textStatesAtom);
  // const [ imgStates, setImgStates ] = useRecoilState(imgStatesAtom);
  
  return (
    <>
      { blockStates[0] &&
        <BlockGlobalBar/> 
      }
        
      { partStates[0] &&
        <PartGlobalBar/> 
      }
      
      { openSidebar == "newSection" &&
        <NewSectionGlobal page_id={page_id}/>
      }
      
    </>
  ); 
});