import React, { useState } from 'react';
import { UploadImgs } from '@/image/UploadImgs';
import { FreeImgs } from '@/image/FreeImgs';
import { BiX } from "react-icons/bi";
import { ImgPreview } from '@/image/ImgPreview';


export const ImgSelect = React.memo(({ obj, InputChange, update, Clear, setOpen }) => {
  
  // console.log("画像の選択リスト", "レンダ");
  const [ imgType, setImgType ] = useState("upload");

  // 閉じるボタン
  const CloseHandler = ()=> {
    setOpen(false);
  };
  
  const SaveHandler = ()=> {
    update();
    setOpen(false);
  };
  

  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            サムネイル画像
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
          
          <div className="img_preview">
            { obj.img
              ?
                <>
                  <ImgPreview obj_img={obj.img}/>
                  <button className="clear_btn mb_2" onClick={()=>Clear("img")}>画像をクリア</button>
                </>
              : <div className="color_red mb_2">画像が未設定です。</div>
            }
          </div>
          
          <div className="tab_menu">
            <button 
              className={`tab_menu_btn ${imgType == "upload" && "active"}`}
              onClick={()=>setImgType("upload")}
            >アップロード</button> 
            <button
              className={`tab_menu_btn ${imgType == "free" && "active"}`}
              onClick={()=>setImgType("free")}
            >フリー</button>
          </div>

          <div className="select_type mt_2">
            {imgType == "free" &&
              <FreeImgs obj_img={obj.img} InputChange={InputChange}/>
            }
            
            {imgType == "upload" &&
              <UploadImgs obj_img={obj.img} InputChange={InputChange}/>
            }
          </div>
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={SaveHandler}>保存</button>
        </div>
        
      </div>
    </>
  );

});

// <label>画像
//   <div className="btn_clear" type="button" onClick={()=>Clear("img")}>クリア</div>
// </label>

// <label>横幅（％）
//   <div className="btn_clear" type="button" onClick={()=>Clear("width")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="width"
//   objValue={obj.width || 100}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>高さ（px）
//   <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="height"
//   objValue={obj.height || 200}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />