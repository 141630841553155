import React from 'react';
import { useParams, Link } from 'react-router-dom';


export const Plans = () => {
  
  let { productId } = useParams();
  
  if (productId == undefined) {
    productId = "account"
  }
  
  return (
    <>
      <div className="main_layer"></div>
      
      <div className="topic_path">
        <Link to="/" className="">ホーム</Link>
      </div>
      <Link to={`/${productId}/menu`}>
        <button className="btn">ダッシュボード</button>
      </Link>
      <Link to={`/${productId}/addons`}>
        <button className="btn">プラン＆オプション</button>
      </Link>
      
      <h1 className="H1">プラン</h1>
      
      <div className="plan_card_area">

        <div className="plan_card">
          <div className="plan_body">
            <div className="title">ライトプラン</div>
            <p>年額払い1,100円／月</p>
            <p>月額990円／月</p>
            <Link to={`/${productId}/payment?type=LightPlan`}>
              <button className="btn_entry">申し込み</button>
            </Link>
          </div>
        </div>
        
        <div className="plan_card card_line">
          <div className="plan_header">
            人気
          </div>
          <div className="plan_body">
            <div className="title">ベースプラン</div>
            <p>年額払い1,100円／月</p>
            <p>月額990円／月</p>
            <Link to={`/${productId}/payment?type=BasePlan`}>
              <button className="btn_entry">申し込み</button>
            </Link>
          </div>
        </div>
        
        <div className="plan_card">
          <div className="plan_body">
            <div className="title">ビジネスプラン</div>
            <p>年額払い1,100円／月</p>
            <p>月額990円／月</p>
            <Link to={`/${productId}/payment?type=BizPlan`}>
              <button className="btn_entry">申し込み</button>
            </Link>
          </div>
        </div>
        
      </div>
      
      <h1 className="H1">各機能</h1>
      
      <div className="add_ons">
      
        <div className="item">
          <div className="item_name">問い合わせ機能</div>
          <div className="overview">お問い合わせフォーム機能が利用できます。</div>
          <div className="price">月額990円</div>
          <button className="btn">機能を追加</button>
        </div>
        
        <div className="item">
          <div className="item_name">決済機能</div>
          <div className="overview">お問い合わせフォーム機能が利用できます。</div>
          <div className="price">月額990円</div>

          <Link to={`/${productId}/payment?type=PaymentOption`}>
            <button className="btn">機能を追加</button>
          </Link>
        </div>
        
        <div className="item">
          <div className="item_name">ページを追加</div>
          <div className="overview">お問い合わせフォーム機能が利用できます。</div>
          <div className="price">月額990円</div>
          <button className="btn">機能を追加</button>
        </div>
        
      </div>
      
    </>
  );
};

      // <stripe-buy-button
      //   buy-button-id="buy_btn_1N4PWFAnaRcsdqb7erRjYSEM"
      //   publishable-key="pk_test_51N4NDkAnaRcsdqb70WLTWN4lXEkO6zMeNXK1g5i1kkW9MKTT3kNzds4auHhosOeZgyZkcH1HJcDjcp3OjgczqANF00GG0YYWOp"
      // >
      // </stripe-buy-button>