import React from "react"

import { useSetRecoilState } from 'recoil'
import { openSidebarAtom } from '@/providers/Atoms';

export const NoPart = React.memo(({ }) => {

  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  
  return (
    <>
      <center>
        <button className="btn_add" onClick={()=>setOpenSidebar("partType")}>パーツタイプを選択</button>
      </center>
    </>
  );
  
});