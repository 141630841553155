import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import TextareaAutosize from 'react-textarea-autosize';


export const EditNotice = () => {
  
  const { noticeId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);

  const [ notice, setNotice ] = useState([]);
  
  // 画面遷移のため
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser.admin) {
      navigate(`/home`);
    }
  }, []);
  
  
  useEffect(() => {
    client.get(`/api/v1/notices/${noticeId}`)
    .then(resp => {
      setNotice(resp.data.notice);
    })
    .catch(e => {
      setToastObj({message: "エラー", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  
  const InputChange = event => {
    const { name, value } = event.target;
    setNotice((prev)=> ({ ...prev, [name]: value }));
  };

  const update = () => {
    client.patch(`/api/v1/notices/${notice.id}`, notice)
    .then(resp => {
      navigate(`/notice/${notice.id}`);
      setToastObj({message: "保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    });
  };
  
  return (
    <>
      <div className="base_area">
        <h1 className="H1">お知らせ編集</h1>
        <Link to={`/notices`}>お知らせ一覧へ</Link>
        
        <label>タイトル</label>
        <input 
          className="input" 
          type="text" 
          name="title"
          value={notice.title ||""} 
          onChange={InputChange}
        />
        
        <label>サブタイトル</label>
        <input 
          className="input" 
          type="text" 
          name="subtitle"
          value={notice.subtitle ||""} 
          onChange={InputChange}
        />
        
        <label>内容</label>
        <TextareaAutosize
          className="input" 
          type="text" 
          name="content"
          value={notice.content ||""} 
          onChange={InputChange}
          minRows={15}
        />
        
        <button className="btn_add" onClick={update}>保存</button>
      </div>
    </>
  );
};