import React from 'react';
import { useRecoilValue } from 'recoil'
import { productAtom } from '@/providers/Atoms';


export const Url = ({ children, product_obj, slug }) => {
  
  const product = product_obj || useRecoilValue(productAtom);
  
  let SSL;
  if(product?.ssl) {
    SSL = "https"
  } else {
    SSL = "http"
  }
  
  let URL;
  if(product?.domain) {
    URL = `${SSL}://${product.domain}/${slug ||""}`
  } else {
    URL = `https://${product.subdomain}.pstock.me/${slug ||""}`
  }
  
  let TEXT
  if(children) {
    TEXT = children
  } else if(slug) {
    TEXT = `/${slug || ""}`
  } else {
    TEXT = product?.domain || `${product.subdomain}.pstock.me`
  }
  
  return (
    <a href={URL} target="_blank" rel="noopener noreferrer">
      {TEXT}
    </a>
  );

};