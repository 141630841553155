import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { BlocksData } from '@/manage/BlocksData';
import { TextsData } from '@/manage/TextsData';

export const Manage = React.memo(() => {
  
  const { client, currentUser } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
    // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!currentUser.admin) {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"}); 
    }
  }, []);
  
  
  // // データ移行
  // const partTransfer = () => {
  //   client.get(`/api/v1/parts/transfer`)
  //   .then(resp => {
  //     setToastObj({message: "テキスト移行完了", color: "#D1E7DD"});
  //     console.log("移行完了")
  //   })
  //   .catch(e => {
  //     setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
  //     console.log(e);
  //   });
  // };
  
  // // テキストデータ削除
  // const textDestroyAll = () => {
  //   client.post(`/api/v1/texts/destroy_all`)
  //   .then(resp => {
  //     setToastObj({message: "すべて削除完了", color: "#D1E7DD"});
  //     console.log("テキストすべて削除完了")
  //   })
  //   .catch(e => {
  //     setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
  //     console.log(e);
  //   });
  // };
  
  // // 画像データ移行
  // const partTransferImg = () => {
  //   client.get(`/api/v1/parts/transfer_img`)
  //   .then(resp => {
  //     setToastObj({message: "画像移行完了", color: "#D1E7DD"});
  //     console.log("移行完了")
  //   })
  //   .catch(e => {
  //     setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
  //     console.log(e);
  //   });
  // };
  
  // // 画像データ削除
  // const imgDestroyAll = () => {
  //   client.post(`/api/v1/imgs/destroy_all`)
  //   .then(resp => {
  //     setToastObj({message: "すべて削除完了", color: "#D1E7DD"});
  //     console.log("画像すべて削除完了")
  //   })
  //   .catch(e => {
  //     setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
  //     console.log(e);
  //   });
  // };
  
  
  // // パーツの不使用カラムをnilに
  // const partColumnNil = () => {
  //   client.get(`/api/v1/parts/part_column_nil`)
  //   .then(resp => {
  //     setToastObj({message: "すべて削除完了", color: "#D1E7DD"});
  //   })
  //   .catch(e => {
  //     setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
  //     console.log(e);
  //   });
  // };
  
  
  // パーツのナビバーを変更
  const partNavbar = () => {
    client.get(`/api/v1/parts/navbar`)
    .then(resp => {
      setToastObj({message: "Navbar移行完了", color: "#D1E7DD"});
      console.log("移行完了")
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
 
  return (
    <> 
      <h1>管理画面</h1>
      <button className="btn" onClick={partNavbar}>ナビバーを変更</button>
      
      <BlocksData/>
      <TextsData/>
    </>
  );
});

      // <button className="btn" onClick={partTransfer}>テキストデータ移行</button>
      // <button className="btn" onClick={textDestroyAll}>テキストすべて削除</button>
      
      // <button className="btn" onClick={partTransferImg}>画像データ移行</button>
      // <button className="btn" onClick={imgDestroyAll}>画像すべて削除</button>
      // <button className="btn" onClick={partColumnNil}>パーツの不使用カラムをnill</button>