import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// 編集ページへリダイレクト用ページ
export const PageEditRedirect = React.memo(() => {
  
  const { productId } = useParams();
  const { slug } = useParams();

  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    // 編集ページにリダイレクト
    navigate(`/product/${productId}/${slug}/edit`);
    // console.log("リダイレクト");
  }, []);

  // return (
  //   <></>
  // );
});
