import React, { useContext } from 'react'
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';

import { useRecoilValue } from 'recoil'
import { addonsAtom } from '@/providers/Atoms';


export const AddonJudg = ({ children, terms, boolean, booleanOnly }) => {
  
  const addons = useRecoilValue(addonsAtom);
  
  const { productId } = useParams();
  const { currentUser } = useContext(StateContext);
  
  // アドオンの名前を配列に
  let AddonNames;
  let judg;
  
  if (currentUser.admin == true){
    judg = true
  } else if(booleanOnly) {
    judg = boolean
  } else if(addons.length == 0) {
    judg = terms.test("フリー") && boolean
  } else {
    AddonNames = addons.map((obj) => {
      return obj.addon_name
    }); 
    judg = terms.test(AddonNames) && boolean
  }

  // const judg = terms.test(AddonNames) && boolean
  
  // console.log("条件", terms);
  // console.log("契約済み", addons);
  // console.log("ジャッジ", judg);
  
  return (
    <>
      {judg
        ? <>{children}</>
        : 
        <div className="addon_judg">
           {children}
          <div className="overlay">
            <span>
              <Link to={`/product/${productId}/addons`} className="overlay_btn">プラン・オプションの変更へ</Link>
            </span>
          </div>
        </div>
      }
    </>
  );
};
// <p>「プラン」もしくは「オプション」の変更が必要です。</p>