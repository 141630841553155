import React from 'react';


export const ImgPreview = ({ obj_img, obj_name }) => {

  const imgJudg = /.jpg|.jpeg|.JPG|.JPEG|.png|.gif|.tiff|.tif|.webp|.svg/.test(obj_img)
  const videoJudg = /.mp4|.ogv|.webm/.test(obj_img)

  if (imgJudg || !obj_img) {
    return <img src={obj_img} className="image"/>;
  } else if (videoJudg) {
    return <video src={obj_img} autoPlay loop muted playsInline ></video>;
  } else {
    return <div className="file_badge">{ obj_name || "ファイル"}</div>;
  }
  
}