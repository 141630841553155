import React, { useState, useContext } from 'react'
import { StateContext } from '@/providers/StateProviders';
import { CheckoutForm } from '@/payment/CheckoutForm';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


export const Payment = () => {
  // const { client } = useContext(StateContext);
  // const [ clientSecret, setClientSecret ] = useState("");
  const { stripekey } = useContext(StateContext);
  const stripePromise = loadStripe(stripekey);
  // const stripePromise = loadStripe('pk_test_51N4NDkAnaRcsdqb70WLTWN4lXEkO6zMeNXK1g5i1kkW9MKTT3kNzds4auHhosOeZgyZkcH1HJcDjcp3OjgczqANF00GG0YYWOp');

  const appearance = {
    theme: 'stripe',
  };
  
  const options = {
    mode: 'subscription',
    amount: 0,
    currency: "jpy",
    // clientSecret,
    appearance,
  };

  // useEffect(() => {
  //   client.get('/api/v1/payment_intent')
  //     .then(resp => {
  //       setClientSecret(resp.data.clientSecret);
  //     });
  // }, []);
  

  return (
    <>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm/>
      </Elements>
    </>
  )
};