import React from 'react'
import { useParams, Link } from 'react-router-dom';

export const ShareBlocksSelect = React.memo(({ obj, shareBlocks, SelectItem }) => {
  
  const { productId } = useParams();
  
  return (
    <>
      {shareBlocks.length == 0 &&
        <>
          <div>共有ブロックがありません。</div>
          <Link to={`/${productId}/sharings`}>
            <button className="btn_line mt_1">共有ブロックの作成へ</button>
          </Link>
        </>
      }
      
      {shareBlocks.map((item) => {
        return (
          <label
            className={`item ${item.id == obj.block_id && "active"}`}  
            key={item.id}
          >
            <div className="img_box">
              <div className="type_name">{item.name || "ブロック名未設定"}</div>
            </div>
            <input
              type="radio"
              name="block_id"
              value={item.id}
              onChange={SelectItem}
              checked={item.id == obj.block_id}
            />
          </label>
        );
      })}
    </>
  );
});
