import React, { useState } from "react"
import { outlineCSS } from '@/part/type/Style';
import parse from 'html-react-parser';
import { Textarea } from '@/text/Textarea';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";



export const Html_A = React.memo(({ part, InputChange, textStates, textHandlers }) => {
  
  // console.log("HTMLパーツレンダ");
  const { content, setContent } = textStates;
  const { contentHandler } = textHandlers
  
  const [ open, setOpen ] = useState("html");
  
  const htmlCSS = css`
    ${outlineCSS(part)}
  `

  return (
    <>
      <div className="visual_switch html_visual_switch">
        <button onClick={()=>setOpen("html")}
          className={ open == "html" ? "active" :""}
        >入力</button>
        <button onClick={()=>setOpen("visual")}
          className={ open == "visual" ? "active" :""}
        >表示</button>
      </div>
        
      {open == "html"
       ?
        <>
          <div className="html_title">
            { part.class_name == "" && "HTMLを入力" }
            { part.class_name == "youTube_1" && "YouTubeを入力" }
            { part.class_name == "maps_1" && "Googleマップを入力" }
          </div>
          <div className="html_input_box">
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              clickHandler={contentHandler}
              placeholder={
                part.class_name == "" && "HTMLを入力してください。" ||
                part.class_name == "youTube_1" && "YouTubeの共有コード <iframe...></iframe> をコピー＆ペーストしてください。"  ||
                part.class_name == "maps_1" && "Googleマップの共有コード <iframe...></iframe> をコピー＆ペーストしてください。"
              }
            />
          </div>
        </>
      :
        <div 
          className={`html_1 ${part.class_name}`}
          css={htmlCSS}
        >
          {parse(`${content.content || "<p>こちらに出力されます。</p>"}`)}
        </div>
      }
    </>
  );
  
});

// export const Html_A = React.memo(({ part, InputChange }) => {
  
//   // console.log("HTMLパーツレンダ");

//   return (
//     <>
//       <div className="html_title">HTMLを入力</div>
      
//       <div className="html_input_box">
      
//         <form className="radio_box">
//           {["HTML", "YouTube"].map((item) => {
//             return (
//               <label key={item}
//                 style={{border: item == part.class_name && "1px solid blue"}}
//               >
//                 <input
//                   type="radio"
//                   id={item}
//                   name="class_name"
//                   value={item}
//                   onChange={InputChange}
//                   checked={item == part.class_name}
//                 />
//                 {item}
//               </label>
//             );
//           })}
//         </form>
        
//         <TextareaAutosize 
//           type="text"
//           minRows={4}
//           name="content" 
//           value={part.content || ''} 
//           onChange={InputChange} 
//           placeholder={part.class_name == "YouTube"
//             ? "YouTubeの共有コード <iframe...></iframe> をコピー＆ペーストしてください。"
//             : "HTMLを入力してください。"
//           }
//         />
//       </div>

//       <div 
//         className={`${part.class_name} ${part.add_class||""}`}
//         id={part.add_id}
//       >
//         {parse(`${part.content || "<p>こちらに出力されます。</p>"}`)}
//       </div>
//     </>
//   );
  
// });
