import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { BiX } from "react-icons/bi";

import { useSetRecoilState } from 'recoil'
import { openSidebarAtom } from '@/providers/Atoms';


export const PartMenuSetting = React.memo(({ part, InputChange, Clear}) => {
  
  // console.log("メニューセレクトレンダ");
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  
  const { productId } = useParams();
  const { client } = useContext(StateContext);

  const [ menus, setMenus ] = useState([]);
  
  useEffect(() => {
    if(menus == ""){
      client.get(`/api/v1/menuslist?subdomain=${productId}`)
      .then(resp => {
        setMenus(resp.data.menus);
      })
    }
  }, []);
  
  const itemsClear = () => {
    Clear("menu_id");
  };
  
  const closeHandler = () => {
    setOpenSidebar("");
  };
  
  
  return (
    <>
      <div className="items_bar">
       
        <div className="items_bar_top">
          <div className="sidebar_title">
            メニューから選択
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
        </div>
    
        <div className="items_box select_type" name={`partlist_${part.id}`}>
          
          <Link to={`/product/${productId}/menus`} className="mb_1">メニューの作成・編集へ</Link>
          <button className="clear_btn mb_2" onClick={itemsClear}>クリア</button>
          
          { menus.map((menu) => {
            return (
              <label 
                className={`item_w ${menu.id == part.menu_id && "active"}`} 
                key={menu.id}
              >
                <input 
                  type="radio"
                  name="menu_id"
                  value={menu.id}
                  onChange={InputChange}
                />
                {menu.name}
                
                <ul>
                  {menu.items.map((item) => {
                    return (
                      <li key={item.id}>{item.name}</li>
                    );
                  })}
                </ul>
              </label>
            );
          })}
        </div>
        
        <div className="items_bar_bottom"></div>
      </div>
    </>
  );
  
});