import React, { useState, useEffect, useContext } from 'react';
// import { useParams } from 'react-router-dom';
// import { StateContext } from '@/providers/StateProviders';
// import { ToastContext } from '@/providers/ToastProvider';


export const Invoices = ({ invoices }) => {
  
  // const { account } = useParams();
  // const { client } = useContext(StateContext);
  // const { setToastObj } = useContext(ToastContext);

  // const [ invoices, setInvoices ] = useState([]);
  // console.log(invoices);

  // useEffect(() => {
  //   client.get(`/api/v1/payments/invoices?account=${account}`)
  //   .then(resp => {
  //     setInvoices(resp.data.invoice_list.data);
  //   })
  //   .catch(e => {
  //     setToastObj({message: "取得できません", color: "#ffedab"});
  //     console.log(e);
  //   });
  // }, []);

  console.log(invoices);
  
  return (
    <>
      <h2>インボイス情報</h2>
      <div className="billing">
        {invoices.map((invoice) => {
          return (
            <div className="item" key={invoice.id}>
              {invoice.id}（ステータス：{invoice.status}） {invoice.amount_paid}円<br/>
              {invoice.customer}｜
              {invoice.account_name}｜
              {invoice.customer_email}｜
              {invoice.payment_intent}｜
              {invoice.subscription}｜
              {invoice.charge}｜
              {invoice.created}
            </div>
          );
        })}
      </div>
    </>
  );
};
