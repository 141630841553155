import React from "react"
import { ContactForm } from '@/contact/ContactForm';
import { Recaptcha } from '@/contact/Recaptcha';


export const Inquiry = ({ }) => {

  // console.log("問い合わせ");
  
  return (
    <>
      <div className="contact_form">
        <h2 className="form_title">CONTACT<span>お問い合わせ</span></h2>
        <p className="mb_3">
          下記に必要事項をご入力の上、お問い合わせください。<br/>
          お問い合わせ内容を確認し、担当者より折り返しご連絡させて頂きます。<br/>
          自動返信メールが届かない場合はご入力のメールアドレスが間違っている可能性があります。
        </p>
        
        <Recaptcha>
          <ContactForm path="contact"/>
        </Recaptcha>
        
        <div className="form_notice ">このサイトはreCAPTCHAによって保護されています。</div>
      </div>
    </>
  );
  
};