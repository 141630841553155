import { atom, selector } from 'recoil';


export const saveDataAtom = atom({
  key: 'saveDataAtom',
  default: []
});


export const clickPartAtom = atom({
  key: 'clickPartAtom',
  default: 0
});

export const clickBlockAtom = atom({
  key: 'clickBlockAtom',
  default: 0
});

export const clickSectionAtom = atom({
  key: 'clickSectionAtom',
  default: 0
});

export const imagesAtom = atom({
  key: 'imagesAtom',
  default: []
});

export const productIDAtom = atom({
  key: 'productIDAtom',
  default: ""
});

export const freeImagesAtom = atom({
  key: 'freeImagesAtom',
  default: []
});

export const productAtom = atom({
  key: 'productAtom',
  default: []
});

export const addonsAtom = atom({
  key: 'addonsAtom',
  default: []
});

export const optionsAtom = atom({
  key: 'optionsAtom',
  default: []
});

// export const textDataAtom = atom({
//   key: 'textDataAtom',
//   default: []
// });

// export const textOpenAtom = atom({
//   key: 'textOpenAtom',
//   default: false
// });


export const saveStatusAtom = atom({
  key: 'saveStatusAtom',
  default: false
});

export const limitPagesAtom = atom({
  key: 'limitPagesAtom',
  default: 1
});


// const allDataSelector = selector({
//   key: 'allDataSelector',
//   get: ({ get }) => {
//     const data = get(saveDataAtom);
//     const newSaveData = {...data, setDataAtom}
//     return newSaveData;
//   },
// });


// 編集画面のサイドバー開閉用
export const openSidebarAtom = atom({
  key: 'openSidebarAtom',
  default: ""
});

// グローバル設定
export const sectionsStatesAtom = atom({
  key: 'sectionsStatesAtom',
  default: []
});

export const sectionStatesAtom = atom({
  key: 'sectionStatesAtom',
  default: []
});

// NewSection用
export const sharingAtom = atom({
  key: 'sharingAtom',
  default: null
});

// NewSection用
export const sectionIndexAtom = atom({
  key: 'sectionIndexAtom',
  default: null
});

export const blocksStatesAtom = atom({
  key: 'blocksStatesAtom',
  default: []
});

// ブロックグローバル設定
export const blockStatesAtom = atom({
  key: 'blockStatesAtom',
  default: []
});

export const blockImgStatesAtom = atom({
  key: 'blockImgStatesAtom',
  default: []
});

// パーツグローバル設定
export const partStatesAtom = atom({
  key: 'partStatesAtom',
  default: []
});

export const partImgStatesAtom = atom({
  key: 'partImgStatesAtom',
  default: []
});

// テキストグローバル設定
export const textStatesAtom = atom({
  key: 'textStatesAtom',
  default: []
});

export const pageWidthAtom = atom({
  key: 'pageWidthAtom',
  default: "100%"
});