import React, { useState, useContext } from 'react';
import { Block } from '@/block/Block';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { useSetRecoilState } from 'recoil'
import { clickSectionAtom } from '@/providers/Atoms';


export const Flowing = React.memo(({ section, blocksState }) => {
  
  const { blocks } = blocksState
  const [ active, setActive ] = useState(true);
  // const [ display, setDisplay ] = useState("停止する");
  
  const setClickSection = useSetRecoilState(clickSectionAtom)
  // const setClickPart = useSetRecoilState(clickPartAtom)
  // const setClickBlock = useSetRecoilState(clickBlockAtom)

  const StopHandler = ()=> {
    setActive((prev)=> !prev);
    setClickSection(active && section.id);
    // const newDisplay = active ? "再生する" : "停止する"
    // setDisplay(newDisplay);
    // setClickPart(0);
    // setClickBlock(0);
  };
  
  // const Style = {
  //   animationPlayState: active ? "running" : "paused",
  //   // animationName: active == false && "none",
  //   animationDuration: `${section.seconds}s`,
  //   // transform: active == false && `translateX(${nextShow}px)`,
  //   animation: active == false && "none",
  //   // display: active == false && "block",
  // };
  
  const flowingCSS = css`
    animation-playState: ${active ? "running" : "paused"};
    animation-duration: ${section.seconds}s;
    animation: ${active == false && "none"};
  `
  
  return (
    <>
      <button className="switch_stop_btn" onClick={StopHandler}>
        {active ? "編集する" : "編集終了"}
      </button>
      
      {active
       ?
          <div className="flowing_slide">
            <div className="flowing_wrap" css={flowingCSS}>
              {blocks.map((block, index) => {
                return (
                  <div 
                    className={`flowing_box ${block.column > 1 && "w_auto"}`}
                    style={{maxWidth: block.column > 1 && "1260px"}}
                    key={block.id}
                  >
                    <Block 
                      block_obj={block} 
                      blocksState={blocksState}
                      section_id={section.id}
                      slide="flowing"
                      index={index}
                    />
                  </div>
                );
              })}
            </div>
            
            <div className="flowing_wrap" css={flowingCSS}>
              {blocks.map((block, index) => {
                return (
                  <div 
                    className={`flowing_box ${block.column > 1 && "w_auto"}`}
                    style={{maxWidth: block.column > 1 && "1260px"}}
                    key={block.id}
                  >
                    <Block 
                      block_obj={block} 
                      blocksState={blocksState}
                      section_id={section.id}
                      slide="flowing"
                      index={index}
                    />
                  </div>
                );
              })}
            </div>
          </div>
      :
        <>
          {blocks.map((block, index) => {
            return (
              <Block 
                block_obj={block} 
                blocksState={blocksState} 
                section_id={section.id} 
                index={index}
                key={block.id}
              />
            );
          })}
        </>
      }
    </>
  );
});


// <div className="flowing_wrap" style={Style}>
//         {blocks.map((block, index) => {
//           return (
//             <PartsProvider key={index}>
//               <div className="flowing_box">
//                 <Block block_obj={block} blocksState={blocksState} index={index}/>
//               </div>
//             </PartsProvider>
//           );
//         })}
//       </div>
      
//       <div className="flowing_wrap" style={Style}>
//         {blocks.map((block, index) => {
//           return (
//             <PartsProvider key={index}>
//               <div className="flowing_box">
//                 <Block block_obj={block} blocksState={blocksState} index={index}/>
//               </div>
//             </PartsProvider>
//           );
//         })}
//       </div>