import React from 'react';
import { BiPlus, BiMinus } from "react-icons/bi";

export const RangeSpin = React.memo(({ obj, setObj, InputChange, 
  column, objValue, max, min }) => {
  
  const spinUp = (name, value) => {
    setObj((prev)=> ({ ...prev, [name]: ++value }));
  };
  
  const spinDown = (name, value) => {
    setObj((prev)=> ({ ...prev, [name]: --value }));
  };
  
  
  return (
    <div className="spin_btn range_spin">
      <span onClick={()=>spinDown(column, objValue)}><BiMinus/></span>
      <input 
      　type="range"
      　id={column}
      　name={column}
      　value={objValue || 0} 
      　onChange={InputChange}
      　max={max || 100}
      　min={min || 0}
      />
      <span onClick={()=>spinUp(column, objValue)}><BiPlus/></span>
    </div>
  );
});