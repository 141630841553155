import React, { useState } from 'react';
import { BiLaptop, BiTab, BiMobile, BiX } from "react-icons/bi";

import { useRecoilState } from 'recoil'
import { pageWidthAtom } from '@/providers/Atoms';


export const WidthSwitch = React.memo(({ }) => {
  
  const [ pageWidth , setPageWidth] = useRecoilState(pageWidthAtom);
  // const [ width, setWidth ] = useState("100%");
  // const [ height, setHeight ] = useState("95%");
  
  const smartPhone = () => {
    // setWidth("360px");
    setPageWidth("360px");
    // setHeight("640px");
  };
  const tablet = () => {
    // setWidth("820px");
    setPageWidth("820px");
    // setHeight("95%");
  };
  // const tabletWide = () => {
  //   setWidth("1180px");
  //   setHeight("95%");
  // };
  const computer = () => {
    // setWidth("100%");
    setPageWidth("100%");
    // setHeight("95%");
  };
  
  return (
    <div className="switching_btns">
      <div 
        className={`item_btn ${pageWidth == "360px" && "active"}`} 
        onClick={smartPhone}
      >
        <span><BiMobile/>スマホ</span>
      </div>
      <div 
        className={`item_btn ${pageWidth == "820px" && "active"}`} 
        onClick={tablet}
      >
        <span><BiTab/>タブレット</span>
      </div>
      <div 
        className={`item_btn ${pageWidth == "100%" && "active"}`}  
        onClick={computer}
      >
        <span><BiLaptop/>パソコン</span>
      </div>
    </div>
  );
});