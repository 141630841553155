import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { TopicPath } from '@/app/TopicPath';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';


export const CardForm = () => {
  
  const { client, currentUser } = useContext(StateContext);
  
  const [ customer, setCustomer ] = useState([]);
  const [ card, setCard ] = useState([]);
  
  const [ errorMessage, setErrorMessage ] = useState("");
  const [ loading, setLoading ] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  
  useEffect(() => {
    client.get(`/api/v1/payments/card`)
    .then(resp => {
      setCard(resp.data.card);
      setCustomer(resp.data.customer);
      // console.log(resp.data.payment_methods);
      console.log("default_source", resp.data.customer.default_source ||"なし");
      console.log("default_payment_method", resp.data.customer.invoice_settings.default_payment_method ||"なし");
    })
    .catch(e => {
      console.log(e);
    });
  }, []);
  
  // console.log("カスタマー", customer);
  // console.log("カード", card);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    
    setLoading(true);
    
    const stripe_card = elements.getElement(CardElement);
    const result = await stripe.createToken(stripe_card);
    
    if (result.error) {
      setLoading(false);
      setErrorMessage(result.error.message);
      console.log("result.error");
      return;
    }

    // const resp = await client.post(`/api/v1/payments/cardchange`, {token: result.token.id})
    
    client.post(`/api/v1/payments/card_setting`, {token: result.token.id})
    .then(resp => {
      console.log("成功");
      setCard(resp.data);
      setLoading(false);
      setErrorMessage("設定が完了しました。");
    })
    .catch(e => {
      console.log("失敗");
      setLoading(false);
      setErrorMessage(e.response.data);
    })

  };
  
  const options = {
    hidePostalCode: true,
  };


  return (
    <>
      <TopicPath topic="カード設定">
        <Link to={`/account/${currentUser.account}`}>アカウント</Link>
      </TopicPath>
      
      <h1 className="H1">クレジットカード設定</h1>
      
      {card.id &&
        <>
          <h2 className="H2">デフォルトのクレジットカード情報</h2>
          <p className="info">現在、こちらのクレジットカードがデフォルトの決済として使用されます。</p>
          <div className="card-element-wrapper">
            <div className="card_number">{card.brand}／・・・・{card.last4}</div>
            有効期限：{card.exp_year}年{card.exp_month}月
          </div>
          
          <div className="advice_box" style={{maxWidth: "700px"}}>
            各プラン／オプションごとに決済カードを設定している場合は、そのカードが優先されます。
          </div>
        </>
      }
      
      <h2 className="H2 mt_4">新しく設定するクレジットカード情報を入力してください。</h2>
      <p className="info">今後、こちらのクレジットカードがデフォルトの決済として使用されます。</p>
      
      <form className="payment_form card-element-wrapper" onSubmit={handleSubmit}>
        <CardElement options={options} id="payment-element"/>
        
        <button type="submit" disabled={loading || !stripe || !elements} id="submit">
          <span id="button-text">
           {loading ? <div className="spinner" id="spinner"></div> : "設定する"}
          </span>
        </button>
      </form>
      
      {errorMessage && 
        <div className="color_red mt_1">{errorMessage}</div>
      }
      
    </>
  );
}