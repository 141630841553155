import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'; //Google認証

export const Recaptcha = ({ children }) => {
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfeCk8mAAAAAIxejPebIbjOplE5WpivxEY7ulrJ" language="ja">
      {children}
    </GoogleReCaptchaProvider> 
  );
};