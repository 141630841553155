import React from 'react';
import { Link } from 'react-router-dom';

export const NoMatch = () => {
  
  return (
    <>
      <h2>このページは存在しません。</h2>
      <Link to="/home">ホームへ</Link>
    </>
  );
};