import React from 'react';


export const About = () => {
  
  return (
    <div className="mb_3">
      <h2 className="H2">各項目の説明</h2>
      <div className="default_box">
        <div className="title">ブロックの共有化</div>
        作成したブロックを他の場所や他ページで共有して使いまわすことが可能になります。<br/>
        この機能の利点は、何度も同じ内容のパーツを作成する必要が無くなることと、
        別々のページで表示している共有ブロックを「一括編集」できることです。<br/>
        例）「商品の特徴を説明したブロック」を別のページでも表示し、かつ一括編集が可能。
      </div>
      <div className="default_box">
        <div className="title">スライド設定</div>
        横に流れるスライドショーの設定が可能です。<br/>
        スライド・フェード・フローリングの３種類のタイプが設定可能です。
      </div>
      <div className="default_box">
        <div className="title">追加設定</div>
        ・サイト全体の「文字サイズ」＆「フォント」の一括設定<br/>
        ・リダイレクト設定<br/>
        ・カロニカル設定<br/>
        ・サイト全体に適応されるCSS設定
      </div>
      <div className="default_box">
        <div className="title">外部コードの設定</div>
        ・Google Analytics（グーグル アナリティクス）の設定<br/>
        ・Search Console（サーチコンソール）の設定<br/>
        ・headに出力する外部コードの設定
      </div>
    </div>
  );
};
