import React, { useState, useEffect, useRef } from "react"
// import { StateContext } from '@/providers/StateProviders';
// import { ToastContext } from '@/providers/ToastProvider';
// import { ImgSelect } from '@/image/ImgSelect';
// import { PartChange } from '@/part/PartChange';
import { PartColorSetting } from '@/editerMenu/PartColorSetting';
import { PartGlobalSetting } from '@/editerMenu/PartGlobalSetting';
import { PartGlobalChange } from '@/editerMenu/PartGlobalChange';
import { ImgGlobalSetting } from '@/editerMenu/ImgGlobalSetting';
import { TextGlobalSetting } from '@/editerMenu/TextGlobalSetting';
import { PartMenuSetting } from '@/editerMenu/PartMenuSetting';
import { PartLinkSetting } from '@/editerMenu/PartLinkSetting';

import { RangeSpin } from '@/editerMenu/RangeSpin';
import { RangeSpinSplit } from '@/editerMenu/RangeSpinSplit';
import { fontFamilys } from '@/app/DefaultData';

import { BiReset, BiCog, BiX, BiBorderRadius } from "react-icons/bi";
import { MdFormatColorFill, MdOutlineFormatColorText, MdOutlineColorLens, } from "react-icons/md";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { BsFileFont } from "react-icons/bs";
import { TbBoxMargin } from "react-icons/tb";

import { useRecoilState, useSetRecoilState } from 'recoil'
import { openSidebarAtom, partStatesAtom, blockStatesAtom } from '@/providers/Atoms';


export const PartGlobalBar = React.memo(({}) => {

  const [ openSidebar, setOpenSidebar ] = useRecoilState(openSidebarAtom);
  const [ partStates, setPartStates ] = useRecoilState(partStatesAtom);
  const setBlockStates = useSetRecoilState(blockStatesAtom);
  
  const part = partStates[0]
  const setPart = partStates[1]
  const partState = { part, setPart }
  // console.log("パーツバー", part); 
  
  const setStates = (newPart) => {
    setPart(newPart);
    setPartStates([ newPart, setPart ]);
  } 


  const InputChange = event => {
    const { name, value } = event.target;
    const newPart = { ...part, [name]: value};
    setStates(newPart);
  };

  
  const closeHandler = ()=> {
    setPartStates([]);
    setBlockStates([]);
  };
  
  // ドロップダウンの非表示用
  const [ none, setNone ] = useState(false);
  
  let backColors = part?.back_color ? part.back_color.split("／") : ["",""];
  let accentColors = part?.sub_color ? part.sub_color.split("／") : ["",""];
  
  // カラー
  // const [ fontColor, setFontColor ] = useState("");
  // const [ borderColor, setBorderColor ] = useState("");
  // const [ backColor_0, setBackColor_0 ] = useState("");
  // const [ backColor_1, setBackColor_1 ] = useState("");
  // const [ accentColor_0, setAccentColor_0 ] = useState("");
  // const [ accentColor_1, setAccentColor_1 ] = useState("");
  
  
  // これがないと、正常にクリアできない
  // useEffect(() => {
  //   setFontColor(part.font_color);
  //   setBorderColor(part.border_color);
  //   setBackColor_0(backColors[0]);
  //   setBackColor_1(backColors[1]);
  //   setAccentColor_0(accentColors[0]);
  //   setAccentColor_1(accentColors[1]);
  // }, [part.id]);
  
  // const [ colorStateSwitch, setColorStateSwitch ] = useState([]);
  const [ inputName, setInputName ] = useState("");

  
  // // refをtrueで初期化。
  // const Reff = useRef(true);
  
  // // カラー変更時
  // useEffect(() => {
  //   // 初回レンダリング時はrefをfalseにして、return。
  //   if (Reff.current
  //       // part.font_color == fontColor && 
  //       // part.border_color == borderColor && 
  //       // part.back_color == `${backColor_0}／${backColor_1}` &&
  //       // part.sub_color == `${accentColor_0}／${accentColor_1}`
  //     ) {
  //     Reff.current = false;
  //     return;
  //   }

  //   console.log("カラー変更処理");
  //   // const newPart = {
  //   //   ...part, 
  //   //   font_color: fontColor, 
  //   //   back_color: `${backColor_0}／${backColor_1}`, 
  //   //   border_color: borderColor, 
  //   //   sub_color: `${accentColor_0}／${accentColor_1}`,
  //   // };
    
  //   let newPart;
  //   if(inputName == "font_color") {
  //     newPart = { ...part, font_color: fontColor };
  //   } else if(inputName == "border_color") {
  //     newPart = { ...part, border_color: borderColor };
  //   } else if(inputName == "back_color_0") {
  //     newPart = { ...part, back_color: `${backColor_0}／${backColors[1]}` };
  //   } else if(inputName == "back_color_1") {
  //     newPart = { ...part, back_color: `${backColors[0]}／${backColor_1}` };
  //   } else if(inputName == "accent_color_0") {
  //     newPart = { ...part, sub_color: `${accentColor_0}／${accentColors[1]}` };
  //   } else if(inputName == "accent_color_1") {
  //     newPart = { ...part, sub_color: `${accentColors[0]}／${accentColor_1}` };
  //   }
    
  //   setStates(newPart);

  // }, [fontColor, borderColor, backColor_0, backColor_1, accentColor_0, accentColor_1]);

  
  // フォーカスを外す
  const removeFocus = () => {
    const elem = document.activeElement;
    elem.blur();
  };
  
  // マウスオンで変更
  const changeOnMouse = (name, value) => {
    const newPart = { ...part, [name]: value};
    setStates(newPart);
    removeFocus();
  };

  // ドロップダウンを非表示に
  const hiddenDrop = () => {
    setNone(true);
  };
  
  
  // リセット
  const Reset = () => {
    // setFontColor("");
    // setBorderColor("");
    // setBackColor_0("");
    // setBackColor_1("");
    // setAccentColor_0("");
    // setAccentColor_1("");

    const newPart = {
      ...part, 
      font_color: null,
      back_color: null,
      border_color: null,
      sub_color: null,
      font_size: null,
      font_weight: null,
      font: null,
      text_shadow: null,
      box_shadow: null,
      radius: null,
      border: null,
      width: null,
      height: null,
      padding: null,
      margin: null,
      position: null,
      align: null,
      delay_seconds: null,
    };
    // setPart(newPart);
    setStates(newPart);
  }
  
  // クリア
  const Clear = (column) => {
    const newPart = { ...part, [column]: null };
    setStates(newPart);
  }
  
  // // カラークリア
  // const coloClear = (column) => {
  //   let newPart;
    
  //   if(column == "font_color") {
  //     setFontColor("");
  //     newPart = { ...part, [column]: null };
  //   } else if(column == "border_color") {
  //     setBorderColor("");
  //     newPart = { ...part, [column]: null };
  //   } else if(column == "back_color_0") {
  //     setBackColor_0("");
  //     newPart = { ...part, back_color: `／${backColors[1]}` };
  //   } else if(column == "back_color_1") {
  //     setBackColor_1("");
  //     newPart = { ...part, back_color: `${backColors[0]}／` };
  //   } else if(column == "accent_color_0") {
  //     setAccentColor_0("");
  //     newPart = { ...part, sub_color: `／${accentColors[1]}` };
  //   } else if(column == "accent_color_1") {
  //     setAccentColor_1("");
  //     newPart = { ...part, sub_color: `${accentColors[0]}／` };
  //   }
    
  //   setStates(newPart);
  // }
  
  
  const typeChangeHandler = ()=> {
    setOpenSidebar("partType");
    removeFocus();
  };
  
  const settingHandler = ()=> {
    setOpenSidebar("partSetting");
    removeFocus();
  };

  
  const fontColorHandler = ()=> {
    setOpenSidebar("partColor");
    // setColorStateSwitch([fontColor, setFontColor]);
    setInputName("font_color");
    removeFocus();
  };
  
  const borderColorHandler = ()=> {
    setOpenSidebar("partColor");
    // setColorStateSwitch([borderColor, setBorderColor]);
    setInputName("border_color");
    removeFocus();
  };
  
  const backColor_0_Handler = ()=> {
    setOpenSidebar("partColor");
    // setColorStateSwitch([backColor_0, setBackColor_0]);
    setInputName("back_color_0");
    removeFocus();
  };
  
  const backColor_1_Handler = ()=> {
    setOpenSidebar("partColor");
    // setColorStateSwitch([backColor_1, setBackColor_1]);
    setInputName("back_color_1");
    removeFocus();
  };
  
  const accentColor_0_Handler = ()=> {
    setOpenSidebar("partColor");
    // setColorStateSwitch([accentColor_0, setAccentColor_0]);
    setInputName("accent_color_0");
    removeFocus();
  };
  
  const accentColor_1_Handler = ()=> {
    setOpenSidebar("partColor");
    // setColorStateSwitch([accentColor_1, setAccentColor_1]);
    setInputName("accent_color_1");
    removeFocus();
  };
  
  
  const widthValue = [
    { name: "width", value: 100 },
    { name: "width", value: 90 }, 
    { name: "width", value: 80 }, 
    { name: "width", value: 70 }, 
    { name: "width", value: 60 },
    { name: "width", value: 50 }, 
    { name: "width", value: 48 }, 
    { name: "width", value: 40 },
    { name: "width", value: 34 },
    { name: "width", value: 33 },
    { name: "width", value: 32 },
    { name: "width", value: 30 },
    { name: "width", value: 25 },
    { name: "width", value: 24 },
    { name: "width", value: 20 }, 
  ]

  
  // marginの分割
  let margins = part?.margin ? part.margin.split("／") : ["",""];
  
  const marginChange = event => {
    const { name, value } = event.target;

    if (name == "margin_top") {
      const newPart = { ...part, margin: `${value}／${margins[1] ||""}` };
      setStates(newPart);
    } else if (name == "margin_bottom") {
      const newPart = { ...part, margin: `${margins[0] ||""}／${value}` };
      setStates(newPart);
    }
  };
  
  // marginクリア
  const marginClear = (column) => {
    if (column == "margin_top") {
      const newPart = { ...part, margin: `／${margins[1] ||""}` };
      setStates(newPart);
    } else if (column == "margin_bottom") {
      const newPart = { ...part, margin: `${margins[0] ||""}` };
      setStates(newPart);
    }
  }
  
  
  return (
    <>
      <div className="part_menu">
        <div className="tooltip">
          <div className="part_badge">パーツ／{part.id}</div>
          <div className="tooltip_text">パーツID</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={typeChangeHandler}>タイプ<br/>変更</button>
          <div className="tooltip_text">パーツタイプ変更<br/>{part.class_name || ""}</div>
        </div>
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><BsFileFont/></button>
          <div 
            className="drop_box drop_box_center" 
            style={{display: none && "none"}}
            onClick={hiddenDrop}
            onMouseLeave={()=>setNone(false)}
          >
            <div className="drop_list">
              <div className="drop_title">フォント</div>
              <div className="drop_item" onClick={()=>Clear("font")}>クリア</div>
              {fontFamilys.map((item, index) => (
                <div 
                  className={`drop_item ${part.font == item.value && "active"}`} 
                  onMouseEnter={()=>changeOnMouse("font", item.value)}
                  key={index}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: part.font_color, color: `${part.font_color ? "#00ff2c":""}`}}
            onClick={fontColorHandler}
          ><MdOutlineFormatColorText/></button>
          <div className="tooltip_text">文字（色・フォント）</div>
        </div>
        
        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: `${backColors[0] || backColors[1] ||""}`, color: `${backColors[0] ? "#00ff2c":""}`}}
            onClick={backColor_0_Handler}
          ><MdFormatColorFill/></button>
          <div className="tooltip_text">背景色</div>
        </div>
        
        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: part.border_color, color: `${part.border_color ? "#00ff2c":""}`}}
            onClick={borderColorHandler}
          ><BiBorderRadius/></button>
          <div className="tooltip_text">枠線（色・幅・角丸）</div>
        </div>
        
        <div className="tooltip">
          <button
            className="menu_btn"
            style={{ background: `${accentColors[0] || accentColors[1] ||""}`, color: `${accentColors[0] ? "#00ff2c":""}`}}
            onClick={accentColor_0_Handler}
          ><MdOutlineColorLens/></button>
          <div className="tooltip_text">アクセント色</div>
        </div>
        
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><AiOutlineColumnWidth/></button>
          <div 
            className="drop_box drop_box_center" 
            style={{display: none && "none"}}
            onClick={hiddenDrop}
            onMouseLeave={()=>setNone(false)}
          >
            <div className="drop_list">
              <div className="drop_title">横幅</div>
              <div className="drop_item" onClick={()=>Clear("width")}>クリア</div>
              {widthValue.map((item, index) => (
                <div 
                  className={`drop_item ${part.width == item.value && "active"}`} 
                  onMouseEnter={()=>changeOnMouse(item.name, item.value)}
                  key={index}
                >
                  {item.value}%
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><TbBoxMargin /></button>
          <div className="drop_box drop_box_center">
            <div className="drop_list" style={{width: "300px"}}>
              <div className="drop_title">パーツのスペース（余白）</div>
              
              <label>外側のスペース【上】（{margins[0] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>marginClear("margin_top")}>クリア</div>
              </label>
              <RangeSpinSplit
                obj={part}
                setObj={setPart}
                InputChange={marginChange}
                targetName="margin"
                column="margin_top"
                objValueMain={margins[0] ||10}
                objValueOther={margins[1] ||""}
                beforeAfter="before"
              　max="150"
              　setStates={setStates}
              />
              
              <label>外側のスペース【下】（{margins[1] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>marginClear("margin_bottom")}>クリア</div>
              </label>
              <RangeSpinSplit
                obj={part}
                setObj={setPart}
                InputChange={marginChange}
                targetName="margin"
                column="margin_bottom"
                objValueMain={margins[1] ||10}
                objValueOther={margins[0] ||""}
                beforeAfter="after"
              　max="150"
              　setStates={setStates}
              />
              
              <label>内側のスペース（{part.padding ||""}px）
                <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
              </label>
              <RangeSpin
                obj={part}
                setObj={setPart}
                column="padding"
                objValue={part.padding ||30} 
                InputChange={InputChange}
              　max="150"
              　setStates={setStates}
              />
          
            </div>
          </div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={settingHandler}><BiCog/></button>
          <div className="tooltip_text">パーツ設定</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={Reset}><BiReset/></button>
          <div className="tooltip_text">パーツのリセット</div>
        </div>

        <div className="closebtn" onClick={closeHandler}><BiX/></div>
      </div>
      
      { openSidebar == "partType" &&
        <PartGlobalChange 
          partState={partState}
          setStates={setStates}
          setOpenSidebar={setOpenSidebar}
          key={part.id}
        />
      }

      { openSidebar == "partColor" &&
        <PartColorSetting
          partState={partState} 
          InputChange={InputChange} 
          // colorStateSwitch={colorStateSwitch}
          backColor_0_Handler={backColor_0_Handler}
          backColor_1_Handler={backColor_1_Handler}
          accentColor_0_Handler={accentColor_0_Handler}
          accentColor_1_Handler={accentColor_1_Handler}
          inputName={inputName}
          Clear={Clear}
          // coloClear={coloClear}
          setStates={setStates}
          setOpenSidebar={setOpenSidebar}
          key={part.id}
        />
      }
      
      { openSidebar == "partSetting" && 
        <PartGlobalSetting
          partState={partState} 
          InputChange={InputChange}
          margins={margins}
          marginChange={marginChange}
          marginClear={marginClear}
          Clear={Clear}
          setStates={setStates}
          setOpenSidebar={setOpenSidebar}
          key={part.id}
        /> 
      }
      
      { openSidebar == "partMenu" &&
        <PartMenuSetting
          part={part} 
          InputChange={InputChange} 
          Clear={Clear}
          key={part.id}
        />
      }
      
      { openSidebar == "partLink" &&
        <PartLinkSetting
          obj={part} 
          InputChange={InputChange} 
          Clear={Clear}
          key={part.id}
        />
      }
      
      { openSidebar == "partImg" &&
        <ImgGlobalSetting 
          type="part" 
          key={part.id}
        />
      }
      
      { openSidebar == "partText" &&
        <TextGlobalSetting key={part.id}/>
      }
      
    </>
  );

});