import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { Plans } from '@/addon/Plans';
import { Options } from '@/addon/Options';
import { TopicPath } from '@/app/TopicPath';
import { About } from '@/addon/About';


export const Addons = () => {
  
  const { client } = useContext(StateContext);
  
  const [ plans, setPlans ] = useState([]);
  const [ options, setOptions ] = useState([]);
  
  const FreePlan =  {
    id: 0,
    addon_key: "Free",
    addon_name: "フリープラン", 
    addon_type: "Plan",
    content: "広告表示\n１ページまで",
    price: 0, 
  }
  
  useEffect(() => {
    client.get(`/api/v1/addons`)
    .then(resp => {
      setPlans([FreePlan, ...resp.data.plans]);
      setOptions(resp.data.options);
    })
  }, []);
  

  return (
    <>
      
      <TopicPath topic="料金プラン"/>
      
      <center>
        <h1 className="H1">料金プラン</h1>
        <h2 className="H2">ご自身のビジネスに合わせた最適なプランが選択可能です。</h2>
        <p className="mb_4">サイト単位での料金体系</p>
      </center>

      <div className="add_plan_area">
        <Plans plans={plans}/>
      </div>
      
      <About/>
      
      <h2 className="H2">オプション</h2>
      <Options options={options}/>
      
      <style>
        {`
          .main_area{
            max-width: 1200px;
          }
        `}
      </style>
      
    </>
  );
};
