import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { PutObjectCommand, GetObjectCommand, DeleteObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { Nav } from '@/product/Nav';
import { Subject } from '@/product/Subject';
import { Image } from '@/image/Image';
import { BiPlus } from "react-icons/bi";
// import Resizer from "react-image-file-resizer";
import imageCompression from 'browser-image-compression';


import { useRecoilState } from 'recoil'
import { imagesAtom, productAtom, productIDAtom } from '@/providers/Atoms';


export const Images = () => {
  
  // console.log("画像リスト");

  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client, s3key } = useContext(StateContext);
  // const { images, setImages } = useContext(ImagesContext);
  
  const [ product, setProduct ] = useRecoilState(productAtom)
  const [ images, setImages ] = useRecoilState(imagesAtom)
  const [ productID, setProductID ] = useRecoilState(productIDAtom)
  
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    if(images == "" || productId !== productID){
      console.log("画像取得");
      
      client.get(`/api/v1/imageslist?subdomain=${productId}`)
      .then(resp => {
        setProduct(resp.data.product);
        setProductID(resp.data.product.subdomain);
        setImages(resp.data.imgs);
      })
      .catch(e => {
        navigate(`/home`);
        setToastObj({message: "アクセスできません。", color: "#ffedab"});
        console.log(e);
      });
    }
  }, []);
  

  //アクセスキー設定
  const s3Client = new S3Client({
    region: 'ap-northeast-1',
    credentials: {
      accessKeyId: s3key.accesskey,
      secretAccessKey: s3key.secretkey,
    },
  });


  // 画像保存
  const AddImage = (save_date) => {
    client.post('/api/v1/images', save_date)
    .then(resp => {
      setImages([resp.data, ...images]);
      setToastObj({message: "アップロード完了", color: "#D1E7DD"});
      console.log("アップロードしました");
    })
    .catch(e => {
      setToastObj({message: "アップロードに失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };

  // s3に画像アップ
  const InputFileUp = (file) => {
    
    const params = {
      ACL: 'public-read',
      Bucket: s3key.bucket,
      Key: `image/product/${productId}/files/${file.name}`,
      Body: file,
      ContentType: file.type,
    };
    
    const save_date = {
      img: `https://${params.Bucket}.s3.ap-northeast-1.amazonaws.com/${params.Key}`,
      img_type: "user",
      size: file.size,
      name: file.name,
      img_key: params.Key,
      subdomain: productId,
    };
    
    s3Client.send(new PutObjectCommand(params))
    .then(resp => {
      AddImage(save_date);
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  
  
  // 画像サイズの合計
  let totalSize = images.reduce(function(sum, obj){
    return sum + obj.size;
  }, 0);
  
  let totalSize_MB = Math.round(totalSize/1024/1024*100) /100;
  
  // 画像の合計容量の判定
  const totalSizeCheck = (fileSize) => {
    
    let total_size = totalSize + fileSize
    let total_size_MB = Math.round(total_size/1024/1024*10) /10;
    console.log("合計容量", total_size_MB);
    
    if (!product.plan && total_size_MB > 3) {
      setToastObj({message: "フリープランでは合計容量「3MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "ライトプラン" && total_size_MB > 7) {
      setToastObj({message: "ライトプランでは合計容量「7MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "スタンダードプラン" && total_size_MB > 15) {
      setToastObj({message: "スタンダードプランでは合計容量「15MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "ビジネスプラン" && total_size_MB > 30) {
      setToastObj({message: "ビジネスプランでは合計容量「30MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (product.plan == "制作代行プラン" && total_size_MB > 30) {
      setToastObj({message: "制作代行プランでは合計容量「30MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else if (total_size_MB > 50) {
      setToastObj({message: "合計容量「50MB」以上はアップロードできません。", color: "#ffedab"});
      return false;
    } else {
      return true;
    }
  }
  
  const totalSizeInfo = () => {
    if (!product.plan) {
      return "フリープランの合計容量：3MBまで";
    } else if (product.plan == "ライトプラン") {
      return "ライトプランの合計容量：7MBまで";
    } else if (product.plan == "スタンダードプラン") {
      return "スタンダードプランの合計容量：15MBまで";
    } else if (product.plan == "ビジネスプラン") {
      return "ビジネスプランの合計容量：30MBまで";
    } else if (product.plan == "制作代行プラン") {
      return "制作代行プランの合計容量：30MBまで";
    } else {
      return "";
    }
  }
  

  // 画像取得＆プレビュー
  const InputFile = async (e) => {
    const file = e.target.files[0];
    
    try {
      // 動画判定
      if(/.mp4|.MP4|.ogv|.webm|.gif/.test(file.name)) {
        console.log("動画アップ", file.name);
        
        if (product.plan == "スタンダードプラン") {
          if (file.size/1024/1024 > 5) {
            setToastObj({message: "スタンダードプランでは動画サイズ「5MB」以上はアップロードできません。", color: "#ffedab"});
            return; 
          } else {
            const judg = await totalSizeCheck(file.size); 
            if(judg) {
              InputFileUp(file);
            }
            return;
          }
        } else if (product.plan == "ビジネスプラン" || product.plan == "制作代行プラン") {
          if (file.size/1024/1024 > 10) {
            setToastObj({message: "動画サイズ「10MB」以上はアップロードできません。", color: "#ffedab"});
            return; 
          } else {
            const judg = await totalSizeCheck(file.size); 
            if(judg) {
              InputFileUp(file);
            }
            return;
          }
        } else {
          setToastObj({message: "フリープラン／ライトプランでは動画のアップロードができません。", color: "#ffedab"});
          return;
        };
      }
      
      if (file.size/1024 > 800) {
        console.log("画像リサイズ");
        // リサイズ内容
        const options = await {
          maxSizeMB: 0.8,
          maxWidthOrHeight: 3000,
          useWebWorker: true,
        }
        // リサイズ処置
        const ResizeFile = await imageCompression(file, options);
        // 合計容量の判定
        const judg = await totalSizeCheck(ResizeFile.size); 
        if(judg) {
          InputFileUp(ResizeFile);
        }
      } else {
        console.log("画像リサイズなし");
        // 合計容量の判定
        const judg = await totalSizeCheck(file.size); 
        if(judg) {
          InputFileUp(file);
        }
      }
      
    } catch (e) {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.error(e);
    }
    
  };
  

  // 画像削除
  const deleteImage = (img_id, index) => {
    // データベースから削除
    client.delete(`/api/v1/images/${img_id}`)
    .then(resp => {
      const new_imgs = [...images];
      new_imgs.splice(index, 1);
      setImages(new_imgs);
      setToastObj({message: "削除しました。", color: "#D1E7DD"});
      console.log("画像を削除しました。");
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // s3のファイル削除
  const s3FileDelete = (img_id, img_url, index) => {
    const sure = window.confirm(`この写真「${img_id}」を本当に削除しますか?`);
    
    if (sure) {
      const key = img_url.replace(`https://${s3key.bucket}.s3.ap-northeast-1.amazonaws.com/`, "");
      const Params = { 
        Bucket: s3key.bucket,
        Key: key
      };

      s3Client.send(new DeleteObjectCommand(Params))
      .then(resp => {
        deleteImage(img_id, index);
        console.log("s3から削除しました");
      })
      .catch(e => {
        setToastObj({message: "写真ファイルの削除に失敗しました。", color: "#ffedab"});
      });
    }
  };
  

  // s3のファイル取得
  const s3FileGet = async (img_url) => {
    const key = img_url.replace(`https://${s3key.bucket}.s3.ap-northeast-1.amazonaws.com/`, "");
    const Params = { 
      Bucket: s3key.bucket,
      Key: key
    };
    
    try {
      const data = await s3Client.send(new GetObjectCommand(Params))
      const d = await data.Body.transformToString();
      console.log(d);
      
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <>
      <div className="main_layer"></div>
      <Nav/>
      <Subject/>

      <h1 className="H1 color_white pb_1">画像／動画</h1>
      <div className="color_white mb_1">
        各ページで使用する画像／動画の「アップロード／削除」<br/>
        {totalSizeInfo()}
      </div>
      
      <div className="base_box">
        <center><div className="mb_1">現在の使用合計容量：{totalSize_MB} MB</div></center>
        
        <label className="btn_add" style={{marginBottom: "0"}}>
          <span><BiPlus/>画像／動画アップロード</span>
          <input 
            type="file" 
            name="img" 
            // accept="image/*, video/*"
            onChange={InputFile}
          />
        </label>
        
        { images.length > 0 &&
          <div className="img_list">
            {images.map((image, index) => {
              return (
                <Image 
                  image={image} 
                  index={index} 
                  s3FileDelete={s3FileDelete}
                  s3FileGet={s3FileGet}
                  key={index}
                />
              );
            })}
          </div>
        }
        
      </div>
      
    </>
  );
};


  // const GetFile = event => {
  //   const result = s3Client.send(
  //       new GetObjectCommand({
  //         Bucket: "matchapp-test",
  //         Key: 's3img/BONABONA『BZ-MC81』.jpg'
  //       })
  //   );
  //   console.log(result);
  // }


  // ファイアベースアップ
  // const FileUpload = () => {
  //   // const file = e.target.files[0];
  //   const file = s3Params.Body;
  //   const storageRef = ref(storage, 'img/' + file.name);

  //   uploadBytes(storageRef, file)
  //   .then((snapshot) => {
  //     console.log('アップロード成功');
  //     // const imageURL = snapshot.ref.getDownloadURL();
  //     // console.log(imageURL);
  //     getDownloadURL(storageRef)
  //       .then((url) => {
  //         console.log(url);
  //         setImage(url);
  //       })
  //   });
  // }
  
  

// <span className="delete_btn" onClick={()=>deleteImage(image.id, index)}>削除</span>


        // <button className="base_btn" onClick={InputFileUp}>
        //   <span><BiPlus/>画像をアップロード</span>
        // </button>

        // {image && <img src={image} height="100" width="100"/>}
        // {s3Params && <div>{s3Params.Key}</div>}
        
        

// <button className="btn" type="submit" onClick={FileUpload}>ファイアベースにUP</button>

// <div className="outerBox">
//         <div className="title">
//           <h2>firebaseアップローダー</h2>
//           <p>JpegかPngの画像ファイル</p>
//         </div>
//         <div className="imageUplodeBox">
//           <div className="imageLogoAndText">
//             <img src="1.jpg" alt="imagelogo" />
//             <p>ここにドラッグ＆ドロップしてね</p>
//           </div>
//           <input
//           className="imageUploadInput"
//           multiple
//           name="imageURL"
//           type="file"
//           accept=".png, .jepg, .jpg"
//           onChange={FileUpload}
//           />
//         </div>
//         <p>または</p>
//         <button>
//           ファイルを選択
//           <input
//           className="imageUploadInput"
//           multiple
//           name="imageURL"
//           type="file"
//           accept=".png, .jepg, .jpg"
//           onChange={FileUpload}
//           />
//         </button>
//       </div>