import React from 'react';
import { Link } from 'react-router-dom';
import { TopicPath } from '@/app/TopicPath';


export const Guide = () => {
  
  return (
    <>
      <div className="main_layer"></div>
      <TopicPath topic="ガイド"/>
    
      <div className="base_area">
        <h1 className="H1">ガイド</h1>
        
        <h2 className="H2_line mt_2">作成マニュアル</h2>
        <a href="https://pstock.jp/manual" target="_blank" rel="noopener noreferrer">作成マニュアルはこちら</a>
        
        <h2 className="H2_line">ヘッダーとは</h2>
        <p>ヘッダーとは、サイト最上部に表示される部分で、主に「サイト名・ロゴ・メニューなど」を表示するのが一般的です。</p>
        
        <h2 className="H2_line">フッターとは</h2>
        <p>フッターとは、サイト最下部に表示される部分で、主に「メニュー・各ページ・特商法・お問い合わせ・会社情報など」を表示するのが一般的です。</p>
        
        <h2 className="H2_line">独自ドメインの取得</h2>
        <p>
         {/* 独自ドメインの取得は、「ALIAS」が使用できる下記のサービスより取得してください。<br/>*/}
          設定がわかりやすいため、下記サービスでドメインを取得することをおすすめしております。<br/>
          ドメイン取得 ➡：<a href="https://muumuu-domain.com/" target="_blank" rel="noopener noreferrer">ムームードメイン</a>
        </p>
        
        <p>
          他社のドメインサービスで取得したドメインも利用可能です。<br/>
        {/*「ALIAS」が使用できないサービスは、サポート外となります。*/}
        </p>
        
        <h2 className="H2_line">独自ドメインの設定方法</h2>
          <p>注）フリープランでは、独自ドメインは設定できません。</p>
          
          <h3 className="H3">【ムームードメイン】側の設定</h3>
          <p>「ムームーＤＮＳのセットアップ情報変更」に進んで頂き、該当ドメインの変更をクリック。<br/>
          「カスタム設定のセットアップ情報変更」の【設定２】を変更してください。<br/>
          【設定１】は変更不要です。</p>
          
          <p>【設定２】の変更内容</p>
          <ul>
            <li>サブドメイン：「空欄」もしくは「お好きな英数字」</li>
            <li>種別：「ALIAS」もしくは「CNAME」</li>
            <li>内容：product.pstock.me</li>
            <li>優先度：空欄</li>
          </ul>
          <p>以上</p>
          <p>
            注）種別に「CNAME」を設定する場合は、サブドメインの入力が必見になります。
          </p>
          {/*
          <p>
            注）内容「○○○.pstock.me」は、対象プロダクトの初期ドメインを入力してください。<br/>
            ○○○の部分は「プロダクトID」になります。<br/>
            例）example.pstock.me
          </p>
          */}
          
          <h3 className="H3">【ページストック】側の設定</h3>
          <p>
            各プロダクトの設定に進んで頂き、【ドメイン設定】画面へ。<br/>
            独自ドメインの入力欄に、取得したドメインを入力して保存してください。
          </p>
          <p>
            入力例）example.com
          </p>
          <p>
            【独自ドメインのSSL化】<br/>
            独自ドメインのSSL化は、無料で自動設定されます。<br/>
            独自ドメイン設定後、反映まで約24時間程度お待ちください。<br/>
          </p>
          <p>以上</p>
      </div>
    </>
  );
};


// <h1 className="H1">作成ガイド</h1>
        
// <h2 className="H2_line">作成から公開まで</h2>
// <h3 className="H3 color_key">ステップ１</h3>
// <p>プロダクトの作成ボタンから<span className="color_key">「プロダクトを作成」</span>してください。</p>


// <h3 className="H3 color_key">ステップ２</h3>
// <p>
//   プロダクトのダッシュボードへ移動し、<span className="color_key">「ページの編集」</span>に進んでください。<br/>
//   「ヘッダー」「フッター」の作成は必須ではありません。必要に応じて作成ください。
// </p>

// <h3 className="H3 color_key">ステップ３</h3>
// <p>
//   ページタイトルを入力し、<span className="color_key">「ブロックの追加」</span>からお好きなパーツ素材を選択してください。<br/>
//   文字・色・形の編集後は、必ず<span className="color_key">「保存」</span>してください。
// </p>

// <h3 className="H3 color_key">ステップ４</h3>
// <p><span className="color_key">「公開ページを見る」」</span>から、作成したページを確認できます。</p>

// <h3 className="H3 color_key">よくわからない場合</h3>
// <p>作成方法や使い方がよくわからない場合は、気軽にお問い合わせください。</p>
// <Link to="/contact">お問い合わせはこちら</Link>