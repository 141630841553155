import React from 'react';
import { Part } from '@/part/Part';
import { Splide, SplideSlide } from '@splidejs/react-splide';


export const BlockSplide = React.memo(({block, partsState}) => {
  
  const { parts } = partsState
  
  return (
      <>
        <Splide
          hasTrack={ false }
          options={{
            type: `${block.block_type}`,
            rewind: true,
            autoplay: true, // 自動再生を有効
            interval: `${block.seconds}000`, // 自動再生の間隔を3秒に設定
            speed: "1000",
            drag: true,
            pauseOnHover: true,
            padding: '5rem',
            perPage: 2,
            perMove: 1,
            classes: {
          		arrows: 'splide__arrows',
          		arrow : 'splide__arrow slide_arrow_btn',
          		prev  : 'splide__arrow--prev',
          		next  : 'splide__arrow--next',
            },
          }} 
          aria-label={`Block_${block.id}`}
        >
          <div className="splide__track">
  		      <ul className="splide__list">
  		      
              {parts.map((part, index) => {
                return (
                  <li className="splide__slide" style={{padding: "0 1rem"}} key={index}>
                    <Part 
                      part_obj={part} 
                      partsState={partsState}
                      column={block.column} 
                      slide={true} 
                      index={index} 
                      key={part.id}
                    />
                  </li>
                );
              })}
              
            </ul>
          </div>
        </Splide>
        
        <style>{`
          .slide_arrow_btn {
            width: 50px;
            height: 100%;
            border-radius: 0;
            background: transparent;
          }
          .slide_arrow_btn:hover {
            background: transparent;
            border: 0;
          }
          .slide_arrow_btn svg {
            fill: rgba(80,192,164,0.8);
            height: 5em;
            width: 5em;
          }
          .slide_arrow_btn svg:hover {
            fill: rgba(80,192,164,1);
          }
          .splide__arrow--prev {
            left: 0;
          }
          .splide__arrow--next {
            right: 0;
          }
        `}</style>
    </>
  );
});