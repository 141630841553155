import React, { useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom';
import { ToastContext } from '../providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { BiPlus } from "react-icons/bi";
// import { Modal } from '@/app/Modal';

export const NewPage = ({ product_id, pagesState, pagesJudg, setWarning }) => {
  
  const { productId } = useParams();
  const { pages, setPages } = pagesState;
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const new_page_date = {
    product_id: product_id,
    // slug: pages.length + 1,
    pages: pages.length
  };

  const AddPage = () => {
    if(!pagesJudg) {
      // setToastObj({message: "「プランの変更」もしく「オプション」が必要です。", color: "#ffedab"});
      setWarning(true);
      return;
    };
    
    client.post('/api/v1/pages', new_page_date)
    .then(resp => {
      setPages([resp.data, ...pages]);
      setToastObj({message: "ページを追加しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };


  return (
    <>
      <div className="new_page_btns">
        <button className="btn" onClick={AddPage}>
          <span><BiPlus/>新規ページを追加</span>
        </button>
        
        <Link to={`/product/${productId}/templates`} className="abtn">
          <span><BiPlus/>テンプレートから追加</span>
        </Link>
      </div>
    </>
  );

};

// <button className="btn" onClick={AddPage} disabled={!pagesJudg}>
//   <span><BiPlus/>新規ページを追加</span>
// </button>

// <Modal btn_name="ページを追加" btn_class="btn_add">
//   <button className="btn_add" onClick={AddPage} disabled={!pagesJudg}>
//     <span><BiPlus/>新規ページを追加</span>
//   </button>
  
//   <Link to={`/product/${productId}/templates`} className="abtn btn_dark">
//     テンプレートから追加
//   </Link>
// </Modal>