import React, { useState, useEffect, useRef } from "react"
import { RgbaStringColorPicker } from "react-colorful";
import { PresetColors } from '@/app/PresetColors';
import { RangeSpin } from '@/editerMenu/RangeSpin';
import { fontFamilys } from '@/app/DefaultData';
import { BiX } from "react-icons/bi";


export const PartColorSetting = React.memo(({ 
  partState, InputChange, 
  backColor_0_Handler, backColor_1_Handler,
  accentColor_0_Handler, accentColor_1_Handler,
  inputName, updatePart, Clear, setStates, setOpenSidebar }) => {

  // console.log("パーツカラーピック", "レンダ");
  
  const { part, setPart } = partState;
  
  let backColors =  part?.back_color ? part.back_color.split("／") : ["",""];
  let accentColors = part?.sub_color ? part.sub_color.split("／") : ["",""];

  let colorValue = "";
  if(inputName == "font_color"){
    colorValue = part.font_color ||"";
  } else if(inputName == "border_color"){
    colorValue = part.border_color ||"";
  } else if(inputName === "back_color_0"){
    colorValue = backColors[0] ||"";
  } else if(inputName === "back_color_1"){
    colorValue = backColors[1] ||"";
  } else if(inputName === "accent_color_0"){
    colorValue = accentColors[0] ||"";
  } else if(inputName === "accent_color_1"){
    colorValue = accentColors[1] ||"";
  }
  
  
  // 変更カラーセット
  const colorSet = (color) => {
    let newPart;
    if(inputName == "font_color") {
      newPart = { ...part, font_color: color };
    } else if(inputName == "border_color") {
      newPart = { ...part, border_color: color };
    } else if(inputName == "back_color_0") {
      newPart = { ...part, back_color: `${color}／${backColors[1]}` };
    } else if(inputName == "back_color_1") {
      newPart = { ...part, back_color: `${backColors[0]}／${color}` };
    } else if(inputName == "accent_color_0") {
      newPart = { ...part, sub_color: `${color}／${accentColors[1]}` };
    } else if(inputName == "accent_color_1") {
      newPart = { ...part, sub_color: `${accentColors[0]}／${color}` };
    }
    
    setStates(newPart);
  }


  // カラーのインプットチェンジ
  const colorChange = event => {
    const { name, value } = event.target;
    
    colorSet(value);
    // let newPart;
    // if (name == "back_color_0") {
    //   newPart = {...part, back_color: `${value}／${backColors[1]}` };
    // } else if (name == "back_color_1") {
    //   newPart = {...part, back_color: `${backColors[0]}／${value}` };
    // } else if (name == "accent_color_0") {
    //   newPart = {...part, sub_color: `${value}／${accentColors[1]}` };
    // } else if (name == "accent_color_1") {
    //   newPart = {...part, sub_color: `${accentColors[0]}／${value}` };
    // }

    // setStates(newPart);
  };
  
  // const colorButton = (color) => {
  //   colorStateSwitch[1](color)
  //   setBtnClickDetect(`${part.id}_${inputName}_${color}`)
  // }


  // カラーピッカーでの変更
  const [ colorPicker, setColorPicker ] = useState("");
  // refをtrueで初期化。
  const Reff = useRef(true);
  // カラー変更時
  useEffect(() => {
    // 初回レンダリング時はrefをfalseにして、return。
    if (Reff.current) {
      Reff.current = false;
      return;
    }

    console.log("ピッカーカラー変更処理");
    colorSet(colorPicker);

  }, [colorPicker]);
  


  // カラークリア
  const coloClear = () => {
    let newPart;
    if(inputName == "font_color") {
      newPart = { ...part, font_color: null };
    } else if(inputName == "border_color") {
      newPart = { ...part, border_color: null };
    } else if(inputName == "back_color_0") {
      newPart = { ...part, back_color: `／${backColors[1]}` };
    } else if(inputName == "back_color_1") {
      newPart = { ...part, back_color: `${backColors[0]}／` };
    } else if(inputName == "accent_color_0") {
      newPart = { ...part, sub_color: `／${accentColors[1]}` };
    } else if(inputName == "accent_color_1") {
      newPart = { ...part, sub_color: `${accentColors[0]}／` };
    }
    
    setStates(newPart);
  }


  const radiusValue = [
    { name: "0px", value: 0 },
    { name: "5px", value: 5 },
    { name: "10px", value: 10 },
    { name: "30px", value: 30 },
  ]
  
  // 閉じるボタン
  const closeHandler = ()=> {
    setOpenSidebar("");
  };
  


  return (
    <>
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            {inputName == "font_color" && "文字（色・フォント）"}
            {inputName == "border_color" && "枠線（色・幅・角丸）"}
            {inputName == "back_color_0" && "背景色（パーツ）"}
            {inputName == "back_color_1" && "背景色（グラデーション）"}
            {inputName == "accent_color_0" && "アクセント色"}
            {inputName == "accent_color_1" && "サブアクセント色"}
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
          
          {/back/.test(inputName) &&
            <div className="tab_menu">
              <button 
                className={`tab_menu_btn ${inputName == "back_color_0" && "active"}`}
                onClick={backColor_0_Handler}
              >背景色</button> 
              <button
                className={`tab_menu_btn ${inputName == "back_color_1" && "active"}`}
                onClick={backColor_1_Handler}
              >グラデーション</button>
            </div>
          }
          
          {/accent/.test(inputName) &&
            <div className="tab_menu">
              <button 
                className={`tab_menu_btn ${inputName == "accent_color_0" && "active"}`}
                onClick={accentColor_0_Handler}
              >アクセント</button> 
              <button
                className={`tab_menu_btn ${inputName == "accent_color_1" && "active"}`}
                onClick={accentColor_1_Handler}
              >サブアクセント</button>
            </div>
          }
        
          {inputName == "font_color" &&
            <>
              <label>文字フォント
                <div className="btn_clear" type="button" onClick={()=>Clear("font")}>クリア</div>
              </label>
              <div className="selectbox">
                <select 
                  className="" 
                　name="font"
                　value={part?.font || ''} 
                　onChange={InputChange}
                > 
                  {fontFamilys.map((font, index) => {
                    return (
                      <option value={font.value} key={index}>{font.name}</option>
                    );
                  })}
                </select>
              </div>
              
              <label>文字色（パーツ内の全文字に適用）</label>
            </>
          }
          
          {inputName == "border_color" &&
            <>
              <label>線幅（{part.border ||""}px）
                <div className="btn_clear" type="button" onClick={()=>Clear("border")}>クリア</div>
              </label>
              <RangeSpin
                obj={part}
                setObj={setPart}
                column="border"
                objValue={part.border ||1}
                InputChange={InputChange}
              　max="20"
              　setStates={setStates}
              />
            
              <label>角の丸み（{part.radius ||""}px）
                <div className="btn_clear" type="button" onClick={()=>Clear("radius")}>クリア</div>
              </label>
              <RangeSpin
                obj={part}
                setObj={setPart}
                column="radius"
                objValue={part.radius ||1}
                InputChange={InputChange}
                setStates={setStates}
              />
              <form className="radio_box" style={{marginTop:"-20px"}}>
                {radiusValue.map((item) => {
                  return (
                    <label key={item.name} className={item.value == part.radius ? "is_on": ""}>
                      <input
                        type="radio"
                        id={item.name}
                        name="radius"
                        value={item.value}
                        onChange={InputChange}
                      />
                      {item.name}
                    </label>
                  );
                })}
              </form>

              <label>線色</label>
            </>
          }
          
          {inputName == "back_color" &&
            <>
              <label>背景色</label>
            </>
          }
          
          {inputName == "back_color_0" &&
            <>
              <label>背景色</label>
            </>
          }
          
          {inputName == "back_color_1" &&
            <>
              <label>背景のグラデーション色</label>
            </>
          }
          
          {inputName == "accent_color_0" &&
            <>
              <label>アクセント</label>
            </>
          }
          
          {inputName == "accent_color_1" &&
            <>
              <label>サブアクセント</label>
            </>
          }
          
          <div className="input_inline mb_1">
            <button className="color_preview_btn" 
              style={{ background: colorValue || "#fff" }}
            />
              <input
                type="text"
                className="input"
                style={{ width: "100%" }}
                name={inputName}
                value={colorValue ||""}
                onChange={colorChange}
                placeholder="カラーコード"
              />
          </div>
          
          <div className="picker__swatches">
            <button className="clear_btn mb_1" onClick={coloClear}>クリア</button>
            <div
              className="picker__swatch"
              onClick={()=> colorSet("rgba(255, 255, 255, 0)")}
            >
              <img src="/transparent.jpg"/>
            </div>
            
            {PresetColors.map((color) => (
              <button
                key={color}
                className={`picker__swatch ${color == colorValue && "active"}`}
                style={{ background: color }}
                onClick={()=> colorSet(color)}
                // onMouseEnter={()=> PickerSwatch(color)}
              />
            ))}
          </div>
          
          <div className="picker">
            <RgbaStringColorPicker color={colorValue} onChange={setColorPicker}/>
            
            {/font|border/.test(inputName) &&
              <input
                type="text"
                className="input"
                name={inputName}
                value={colorValue ||""}
                onChange={InputChange}
                placeholder="カラーコード"
              />
            }
            
            {/back|accent/.test(inputName) &&
              <input
                type="text"
                className="input"
                name={inputName}
                value={colorValue ||""}
                onChange={colorChange}
                placeholder="カラーコード"
              />
            }
          </div>
          
        </div>
        
        <div className="items_bar_bottom">

        </div>
        
      </div>
    </>
  );

});