import React, { useState, createContext } from 'react'

export const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
  // const { children } = props;

  const initialToastObj = {
    message: null,
    color: "#fff"
  };
  const [toastObj, setToastObj] = useState(initialToastObj);
  
  const toastState = {
    toastObj,
    setToastObj
  };

  const close = () => {
    setToastObj(initialToastObj);
  };
  
  const toastContent = () => {
    if (toastObj.message != null) {
      window.setTimeout(() =>{
        setToastObj(initialToastObj);
      }, 8000);
      
      return (
        <div className="toast" style={{ background: toastObj.color }} onClick={close}>
          <div className="toast_message">{toastObj.message}</div>
          <div className="close_btn" onClick={close}>✕</div>
        </div>
      );
    } else {
      return;
    }
  };
  
  return (
    <ToastContext.Provider value={toastState}>
      {toastContent()}
      {children}
    </ToastContext.Provider>
  );

};