import React from 'react';
import { useParams, Link } from 'react-router-dom';
// import { OptionTypes } from '@/addon/AddonTypes';

export const ProductOptions = ({ options, addings }) => {
  
  const { productId } = useParams();
  
  // オプションの名前を配列
  const currentAddonKeys = addings.map((obj) => {
    return obj.addon_key
  });
  

  return (
      <>
        {options.map((option, index) => {
          return (
            <div className="addon" key={index}>
              <div className="addon_body">
                <div className="addon_name">{option.addon_name}</div>
                <p>{option.content}</p>
                <div className="addon_price">￥<span>{option.price}</span>/月</div>
              </div>

              <Link to={`/product/${productId}/payment?key=${option.addon_key}`}>
                <button className="btn_entry" 
                  disabled={currentAddonKeys.includes(option.addon_key) && true}
                >{currentAddonKeys.includes(option.addon_key) ? "利用中" : "機能を追加"}</button>
              </Link>
            </div>
          );
        })}
      </>
  );
};


      // <div className="add_ons">
      //   {Object.values(OptionTypes).map((option, index) => {
      //     return (
      //       <div className="item" key={index}>
      //         <div className="item_name">{option.addon_name}</div>
      //         <div className="item_summary">{option.addon_name}</div>
      //         <div className="item_price">月額{option.price}円</div>
      //         <div className="item_btn">
      //           <Link to={`/${productId}/payment?type=${option.key}`}>
      //             <button className="btn" 
      //               disabled={OptionNames.includes(option.addon_name) && true}
      //             >{OptionNames.includes(option.addon_name) ? "利用中" : "機能を追加"}</button>
      //           </Link>
      //         </div>
      //       </div>
      //     );
      //   })}
      // </div>