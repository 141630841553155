import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { LinkSelect } from '@/part/LinkSelect';


export const Item = ({ item_obj, itemsState, index }) => {

  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const { items, setItems } = itemsState
  const [ item, setItem ] = useState([]);
  
  
  useEffect(() => {
    client.get(`/api/v1/items/${item_obj.id}`)
    .then(resp => {
      setItem(resp.data);
    })
  }, [items]);
  
//   useEffect(() => {
//     setItem(item_obj);
//   }, [items]);
  
  // console.log(`アイテム:${item_obj.id}`);
  
  const InputChange = event => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };
  
  // アイテム更新
  const updateitem = () => {
    client.patch(`/api/v1/items/${item.id}`, item)
    .then(resp => {
      CloseHandler();
      setToastObj({message: "保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    });
  };
  
  // 削除
  const deleteitem = () => {
    const sure = window.confirm(`この項目「${item?.name}」を本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/items/${item.id}`)
      .then(resp => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);

        setToastObj({message: "削除しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
    
  const [ active, setActive ] = useState(false);
  const [ display, setDisplay ] = useState("編集");
  
  const OpenToggle = ()=> {
    setActive((prev)=> !prev);
    setDisplay(active ? "編集" : "閉じる");
  };
  
  const CloseHandler = ()=> {
    setActive(false);
    setDisplay("編集");
  };
  
  const [ open, setOpen ] = useState(false);


  return (
    <div className="menu_item">  
      <div className="menu_item_top">
        <div className="menu_item_title">
          {item?.name || "メニューの項目名を編集してください。"}
          {item?.sub_name && `（${item.sub_name}）`}
          <a href={`/${item.link}`} className="menu_item_link">{item.link}</a>
        </div>
        <button className="btn_text" onClick={OpenToggle}>{display}</button>
      </div>
      
      {active && 
        <div className="menu_item_edit" >
          <div className="input_fields">
            <div className="field_2">
              <label>メニューの項目名</label>
              <input 
                className="input" 
                type="text" 
                name="name"
                value={item?.name || ''} 
                onChange={InputChange}
                placeholder="項目名を入力...（例）トップ、お問い合わせ 等"
              />
            </div>
            
            <div className="field_2">
              <label>メニュー項目の別名（※ナビバーのみ表示されます。）</label>
              <input 
                className="input" 
                type="text" 
                name="sub_name"
                value={item?.sub_name || ''} 
                onChange={InputChange}
                placeholder="項目の別名を入力...（例）TOP、CONTACT 等"
              />
            </div>
          </div>
          
          <div className="form_field">
            <label>リンクURL</label>
            <input 
              onClick={()=>setOpen(true)}
              className="input" 
              type="text" 
              name="link"
              value={item?.link || ''} 
              onChange={InputChange}
              placeholder="リンクURLを入力..."
            />
            <p>項目名をクリックした際の遷移先URL</p>
          </div>
          
          <div style={{textAlign: "right"}}>
            <button className="btn_delete" onClick={deleteitem}>削除</button>
            <button className="btn ml_2" onClick={updateitem}>保存</button>
          </div>
        </div>
      }
      
      { open &&
        <LinkSelect
          obj={item}
          InputChange={InputChange}
          update={updateitem }
          setOpen={setOpen}
        />
      }
    </div>
  );
};
