import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ProductCard } from '@/product/ProductCard';
import { Url } from '@/app/Url';


export const Products = React.memo(() => {

  const { client, currentUser } = useContext(StateContext);
  const [ products, setProducts ] = useState([]);
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!currentUser.admin) {
      navigate(`/home`);
    }
  }, []);

  useEffect(() => {
    client.get(`/api/v1/products`)
    .then(resp => {
      setProducts(resp.data);
    })
    .catch(e => {
      navigate(`/home`);
      console.log(e);
    });
  }, []);
  
  
  // 全プロダクトの取得
  const getProducts = () => {
    client.get(`/api/v1/products`)
    .then(resp => {
      setProducts(resp.data);
    })
  };
  
  // 有料プロダクトの取得
  const getPaidproducts = () => {
    client.get(`/api/v1/products?plan=有料`)
    .then(resp => {
      setProducts(resp.data);
    })
  };



  return (
    <>
      <h2 className="H2 mt_3">プロダクト一覧</h2>
      <button className="" onClick={getProducts}>全プロダクト</button>
      <button className="" onClick={getPaidproducts}>有料プロダクト</button>
      
      {products.map((product) => {
        return (
          <ProductCard product={product} key={product.id}/>
        );
      })}
    </>
  );
});
