import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { Normal } from '@/section/type/Normal';
import { Flowing } from '@/section/type/Flowing';
import { Switch } from '@/section/type/Switch';
import { Slide } from '@/section/type/Slide';


export const Section = React.memo(({ section_id }) => {
  
  console.log("セクション", section_id, "レンダ");
  
  const { client } = useContext(StateContext);

  const [ section, setSection ] = useState([]);

  const [ blocks, setBlocks ] = useState([]);
  const blocksState = { 
    blocks, setBlocks
  };
  

  useEffect(() => {
    client.get(`/api/v1/sections/${section_id}`)
    .then(resp => {
      setSection(resp.data);
      setBlocks(resp.data.blocks);
    })
    .catch(e => {
      console.log(e);
    });
  }, []);

  
  const selectSection = () => {
    const type = section?.section_type || ""
    
    switch(type) {
      case "":
        return <Normal section_id={section_id} blocksState={blocksState}/>;
        break;
      case "Slide":
        return <Slide section={section} blocksState={blocksState} key={`Slide_${section.id}`}/>;
        break;
      case "Fade":
        return <Slide section={section} blocksState={blocksState} key={`Fade_${section.id}`}/>;
        break;
      case "Flowing":
        return <Flowing section={section} blocksState={blocksState}/>;
        break;
      case "Switch":
        return <Switch section_id={section_id} blocksState={blocksState}/>;
        break;
      default:
        return <div>セクションがありません。</div>;
    };
  };
  

  return (
    <>
      {selectSection()}
    </>
  );
});

// {selectSection()}