import React, { useState, useEffect, useContext, useRef } from "react"
import { useParams, Link } from 'react-router-dom';
// import { StateContext } from '@/providers/StateProviders';
// import { ToastContext } from '@/providers/ToastProvider';
// import { ColorPicker } from '@/block/ColorPicker';

import { BlockGlobalSetting } from '@/editerMenu/BlockGlobalSetting';
import { ImgGlobalSetting  } from '@/editerMenu/ImgGlobalSetting';
import { BlockColorSetting  } from '@/editerMenu/BlockColorSetting';
import { SectionGlobalSetting  } from '@/editerMenu/SectionGlobalSetting';
import { SpinBtn } from '@/editerMenu/SpinBtn';
import { RangeSpinSplit } from '@/editerMenu/RangeSpinSplit';

import { SaveStatus } from '@/page/SaveStatus';
// import { useAutoSave } from '@/hooks/useAutoSave';

import { BiReset, BiChevronLeft, BiImageAlt, BiCog, BiCarousel } from "react-icons/bi";
import { MdFormatColorFill } from "react-icons/md";
import { TbBoxPadding } from "react-icons/tb";

import { useRecoilState } from 'recoil'
import { openSidebarAtom, blockStatesAtom } from '@/providers/Atoms';


export const BlockGlobalBar = React.memo(({ }) => {
  
  const [ openSidebar, setOpenSidebar ] = useRecoilState(openSidebarAtom);
  const [ blockStates, setBlockStates ] = useRecoilState(blockStatesAtom);
  // const setSectionStates = useSetRecoilState(sectionStatesAtom);
  
  const block = blockStates[0]
  const setBlock = blockStates[1]
  const blockState = { block, setBlock }
  // console.log(`ブロックバー`, block);
  
  const setStates = (newblock) => {
    setBlock(newblock);
    setBlockStates([ newblock, setBlock ]);
  } 

  const { productId, blockId } = useParams();
  
  // const { setToastObj } = useContext(ToastContext);
  // const { client } = useContext(StateContext);

  // const setClickSection = useSetRecoilState(clickSectionAtom);
  // const setSaveStatus = useSetRecoilState(saveStatusAtom);
  
  // フォーカスを外す
  const removeFocus = () => {
    const elem = document.activeElement;
    elem.blur();
  };
  
  const InputChange = event => {
    const { name, value } = event.target;
    // setBlock((prev)=> ({ ...prev, [name]: value }));
    
    const newBlock = { ...block, [name]: value};
    setStates(newBlock);
  };


  // カラー
  // const [ color, setColor ] = useState("");
  // const [ subColor, setSubColor ] = useState("");
  
  // const [ colorStateSwitch, setColorStateSwitch ] = useState([]);
  const [ inputName, setInputName ] = useState("");

  // これがないと、正常にクリアできない
  // useEffect(() => {
  //   setColor(block.color);
  //   setSubColor(block.sub_color);
  // }, [block.id]);
  
  
  // // refをtrueで初期化。
  // const ref = useRef(true);
  
  // // カラー変更時
  // useEffect(() => {
  //   if (ref.current || block.color == color && block.sub_color == subColor) {
  //     ref.current = false;
  //     return;
  //   }
    
  //   console.log("カラー変更処理");
  //   const newBlock = { ...block, color: color, sub_color: subColor };
  //   setStates(newBlock);
  // }, [color, subColor]);


  // リセット
  const Reset = () => {
    // setColor("");
    // setSubColor("");
    
    const newBlock = {
      ...block, 
      color: null,
      sub_color: null,
      third_color: null,
      color_stop: null,
      deg: null,
      column: 1,
      img: null,
      padding: null,
      height: null,
      wrapper: null,
    };
    // setBlock(newBlock);
    setStates(newBlock);
  }
  
  // クリア
  const Clear = (column) => {
    const newBlock = { ...block, [column]: null };
    setStates(newBlock);
  }
  
  //   // カラークリア
  // const coloClear = (column) => {
  //   if(column == "color") {
  //     setColor("");
  //   } else if(column == "sub_color") {
  //     setSubColor("");
  //   }
  // }
  
  // 公開する
  const Release = () => {
    const newBlock = { ...block, status: "公開" };
    setStates(newBlock);
  }

  // const [ open, setOpen ] = useState("");
  
  const imgChangeHandler = ()=> {
    setOpenSidebar("blockImg");
    removeFocus();
  };
  
  const settingHandler = ()=> {
    setOpenSidebar("blockSetting");
    removeFocus();
  };
  
  const sectionHandler = ()=> {
    setOpenSidebar("sectionSetting");
    removeFocus();
  };
  

  // カラー設定オープン
  const ColorHandler = ()=> {
    setOpenSidebar("blockColor");
    // setColorStateSwitch([color, setColor]);
    setInputName("color");
    removeFocus();
  };
  
  const ColorHandler2 = ()=> {
    setOpenSidebar("blockColor");
    // setColorStateSwitch([subColor, setSubColor]);
    setInputName("sub_color");
    removeFocus();
  };
  
  
  // paddingの分割
  let paddings = block.padding ? block.padding.split("／") : ["",""];
  
  const paddingChange = event => {
    const { name, value } = event.target;

    if (name == "padding_top") {
      const newBlock = { ...block, padding: `${value}／${paddings[1] ||""}` };
      setStates(newBlock);
    } else if (name == "padding_bottom") {
      const newBlock = { ...block, padding: `${paddings[0] ||""}／${value}` };
      setStates(newBlock);
    }
  };
  
  // paddingクリア
  const paddingClear = (column) => {
    if (column == "padding_top") {
      const newBlock = { ...block, padding: `／${paddings[1] ||""}` };
      setStates(newBlock);
    } else if (column == "padding_bottom") {
      const newBlock = { ...block, padding: `${paddings[0] ||""}` };
      setStates(newBlock);
    }
  }
  

  return (
    <>
      <div className="block_menu">
        <Link to={`/product/${productId}/dashboard`} className="back_btn"><BiChevronLeft/></Link>
        
        <div className="block_badge block_badge_color nobtn">ブロック／{block.id}</div>
        
        <div className="tooltip block_spin">
          <SpinBtn
            obj={block}
            setObj={setBlock}
            column="column"
            objValue={block.column ||1}
            InputChange={InputChange}
            setStates={setStates}
          />
          <div className="tooltip_text">横並び数（カラム）</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn"
            onClick={imgChangeHandler}
          ><BiImageAlt/></button>
          <div className="tooltip_text">ブロック背景写真</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn"
            style={{ background: block.color, color: `${block.color ? "#00ff2c":""}`}}
            onClick={ColorHandler}
          ><MdFormatColorFill/></button>
          <div className="tooltip_text">ブロック背景色</div>
        </div>
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><TbBoxPadding/></button>
          <div className="drop_box drop_box_center">
            <div className="drop_list" style={{width: "300px"}}>
              <div className="drop_title">ブロックのスペース（余白）</div>
              
              <label>内側のスペース【上】（{paddings[0] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_top")}>クリア</div>
              </label>
              <RangeSpinSplit
                obj={block}
                setObj={setBlock}
                InputChange={paddingChange}
                targetName="padding"
                column="padding_top"
                objValueMain={paddings[0] ||100}
                objValueOther={paddings[1] ||""}
                beforeAfter="before"
              　max="200"
              　setStates={setStates}
              />
              
              <label>内側のスペース【下】（{paddings[1] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_bottom")}>クリア</div>
              </label>
              <RangeSpinSplit
                obj={block}
                setObj={setBlock}
                InputChange={paddingChange}
                targetName="padding"
                column="padding_bottom"
                objValueMain={paddings[1] ||100}
                objValueOther={paddings[0] ||""}
                beforeAfter="after"
              　max="200"
              　setStates={setStates}
              />

            </div>
          </div>
        </div>
        
        { !blockId &&
          <div className="tooltip">
            <button className="menu_btn" onClick={sectionHandler}><BiCarousel/></button>
            <div className="tooltip_text">スライド設定</div>
          </div>
        }
        
        <div className="tooltip">
          <button className="menu_btn" onClick={settingHandler}><BiCog/></button>
          <div className="tooltip_text">ブロック設定</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={Reset}><BiReset/></button>
          <div className="tooltip_text">ブロックのリセット</div>
        </div>

        
        {block.status == "非公開" &&
          <button className="block_badge activity_btn" onClick={Release}>公開する</button>
        }
        
        <div className="t_view">
          <SaveStatus/>
        </div>
        
        <button className="block_menu_savebtn" onClick={removeFocus}>キーボード閉</button>
        
        {/*<SaveAll className="block_menu_savebtn"/>*/}
      </div>
      
      { openSidebar == "blockColor" &&
        <BlockColorSetting
          blockState={blockState}
          InputChange={InputChange} 
          // colorStateSwitch={colorStateSwitch}
          inputName={inputName}
          ColorHandler={ColorHandler}
          ColorHandler2={ColorHandler2}
          Clear={Clear}
          // coloClear={coloClear}
          setStates={setStates}
          setOpenSidebar={setOpenSidebar}
          key={block.id}
        />
      }
      
      { openSidebar == "blockSetting" &&
        <BlockGlobalSetting
          blockState={blockState} 
          InputChange={InputChange}
          Clear={Clear}
          paddings={paddings}
          paddingChange={paddingChange}
          paddingClear={paddingClear}
          setStates={setStates}
          setOpenSidebar={setOpenSidebar}
          key={block.id}
        />
      }
      
      { openSidebar == "blockImg" &&
        <ImgGlobalSetting
          type="block"
          key={block.id}
        />
      }
      
      { openSidebar == "sectionSetting" &&
        <SectionGlobalSetting key={block.id}/>
      }

    </>
  );
  
});