import React from 'react';

export const Loading = React.memo(() => {
  
  return (
    <div className="loader_wrapper">
      <div className="loader"></div>
    </div>
  );
});

// <div className="loader_wrapper">
//   <div className="spinner type1">
//     <span>Loading...</span>
//   </div>
// </div>