import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
// import { ToastContext } from '@/providers/ToastProvider';
import { BiX } from "react-icons/bi";

export const LinkSelect = React.memo(({ obj, InputChange, update, setOpen, Clear }) => {
  
  // console.log("リンクセレクトレンダ");
  
  const { productId } = useParams();
  const { client } = useContext(StateContext);
  // const { setToastObj } = useContext(ToastContext);
  
  const [ pages, setPages ] = useState([]);
  const [ images, setImages ] = useState([]);
  
  useEffect(() => {
    if(pages == ""){
      client.get(`/api/v1/products/${productId}/pages`)
      .then(resp => {
        setPages(resp.data.pages);
        // console.log("GET:pages");
      })
    }
  }, []);
  
  
  const getImages = (column) => {
    if(images == ""){
      client.get(`/api/v1/products/${productId}/images`)
      .then(resp => {
        setImages(resp.data.images);
      })
    }
  }

  
  const CloseHandler = () => {
    setOpen(false);
  };
  
  
  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
       
        <div className="items_bar_top">
          <div className="sidebar_title">
            リンクの設定
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
    
        <div className="items_box" name={`link_${obj.id}`}>
          
          <div className="form_field">
            <label>URLを入力
              <div className="btn_clear" type="button" onClick={()=>Clear("link")}>クリア</div>
            </label>
            
            <input 
              className="input" 
              type="text"
              name="link"
              value={obj.link || ""} 
              onChange={InputChange}
              placeholder="URLを入力..."
            />
            <p>入力例）https://example.com/<br/>
            既存ページの場合「/〇〇」のみでOK</p>
          </div>
          
          <label>既存ページから選択</label>
          <label className={`item_w ${"/" == obj.link && "active"}`}>
            <input 
              type="radio"
              name="link"
              value="/"
              onChange={InputChange}
            />
            トップページ
          </label>
          
          <label className={`item_w ${"/sitemap" == obj.link && "active"}`}>
            <input 
              type="radio"
              name="link"
              value="/sitemap"
              onChange={InputChange}
            />
            サイトマップ：/sitemap
          </label>
          
          {/*
          <label className={`item_w ${"/contact" == obj.link && "active"}`}>
            <input 
              type="radio"
              name="link"
              value="/contact"
              onChange={InputChange}
            />
            お問い合わせ
          </label>
          */}
          
          { pages.map((page) => {
            return (
              <label 
                className={`item_w ${`/${page.slug}` == obj.link && "active"}`} 
                key={page.id}
              >
                <input 
                  type="radio"
                  name="link"
                  value={`/${page.slug}`}
                  onChange={InputChange}
                />
                { "contact" == page.slug 
                  ? <div>お問い合わせ：/{page.slug}</div>
                  : <div>{page.title || "タイトル未設定"}：/{page.slug}</div>
                }
              </label>
            );
          })}
          
          
          <label className="mt_3">ファイルを選択</label>
          
          { images == "" && 
            <button className="btn_line w_100 mb_2" onClick={getImages}>ファイルを選ぶ</button>
          }
          
          { images.map((image) => {
            return (
              <label 
                className={`item_w ${`${image.img}` == obj.link && "active"}`} 
                key={image.id}
              >
                <input 
                  type="radio"
                  name="link"
                  value={`${image.img}`}
                  onChange={InputChange}
                />
                {image.name || "ファイル"}
              </label>
            );
          })}
          
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={update}>保存</button>
        </div>
      </div>
    </>
  );
  
});

// <div className="btn_clear" type="button" onClick={()=>Clear("link")}>クリア</div>
// <div className="mb_2 mt_2">ダブルクリックで選択してください</div>