import React, { useState } from 'react';
import { DefaultPayment } from '@/addon/DefaultPayment';


export const ProductAdding = ({ adding, deleteAdding }) => {
  
  const [ open, setOpen ] = useState(false);
  // const [ display, setDisplay ] = useState("決済変更");
  
  const OpenToggle = ()=> {
    setOpen((prev)=> !prev);
    // setDisplay(open ? "決済変更" : "閉じる");
  };
  
  const limitDateTime = () => {
    const today = new Date();
    
    const addingDay = new Date(adding.created_at); 
    addingDay.setMonth(addingDay.getMonth() + adding.term);
    
    let termJudg = true
    if(adding.status) {
      termJudg = true
    } else if(adding.term) {
      termJudg = today > addingDay
    }
    // console.log(termJudg);
    
    const limitDay = addingDay.getFullYear() + '年' +　(addingDay.getMonth() + 1) + '月' + addingDay.getDate() + '日';
    
    return (
      <>
        {termJudg 
          ? <div className="item_btn">
              <button 
                // className={adding.status || ""}
                onClick={()=>deleteAdding(adding)}
                disabled={adding.status && true}
              >
                { adding.status || "解除"}
              </button>
            </div>
          : <div className="item_term">最低利用期間：{adding.term||""}ヵ月<br/>{limitDay ||""}まで</div>
        }
      </>
    );
  }
  

  return (
    <div className="adding">
      <div className="item_name">{adding.addon_name}</div>
      <div className="item_type">{adding.addon_type}</div>
      <div className="item_price">{adding.cycle && `${adding.cycle}額／`}{adding.price}円</div>
      
      <div className="item_payment" onClick={OpenToggle}>決済変更</div>
      {limitDateTime()}
      
      { open && <DefaultPayment adding={adding} setOpen={setOpen}/>}
    </div>
  );
};
