import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
// import { PlanTypes } from '@/addon/AddonTypes';
import { BiCheckCircle } from "react-icons/bi";


export const ProductPlans = ({ plans, currentPlan, changeDataState }) => {
  
  const { changeData, setChangeData } = changeDataState
  
  let { productId } = useParams();
  
  if (productId == undefined) {
    productId = "account"
  }
  
  const List = (content) => {
    if(content == null) {
      return [];
    } else {
      const texts = content.split(/\n/);
      return texts; 
    }
  }


  return (
    <>
      <div className="plan_card_area">
        {plans.map((plan) => {
          return (
              <div
                className={`plan_card ${changeData.addon_key == plan.addon_key && "active"}`} 
                // className="plan_card"
                // style={{border: updateData.key == plan.key && "3px solid blue"}}
                key={plan.id}
              >
                <div className="plan_body">
                  <div className="title">{plan.addon_key}</div>
                  <div className="subtitle">{plan.addon_name}</div>
                  <div className="price">￥<span>{plan.price}</span>/月</div>
                
                  <ul>
                    {List(plan.content).map((text, index) => {
                      return (
                        <li key={index}>{text}<BiCheckCircle/></li>
                      );
                    })}
                  </ul>
                  
                  {/* フリープランの場合 */}
                  {currentPlan.addon_key == "Free"
                  ?
                    <Link to={`/product/${productId}/payment?key=${plan.addon_key}`}>
                      <button className="btn_entry"
                        disabled={currentPlan.addon_key == plan.addon_key && true}
                      >
                        {currentPlan.addon_key == plan.addon_key ? "利用中" : "申し込み"}
                      </button>
                    </Link>
                  :
                    <button 
                      className={changeData.addon_key == plan.addon_key ? "btn_selected" : "btn_entry"}
                      // style={{border: changeData.addon_key == plan.addon_key && "2px solid #03c494"}}
                      onClick={()=>setChangeData(plan)}
                      disabled={currentPlan.addon_key == plan.addon_key && true}
                    >
                      {currentPlan.addon_key == plan.addon_key ? "利用中" : "選択"}
                    </button>
                  }
                </div>
              </div>
          );
        })}
      </div>

    </>
  );
};



// <div className="plan_card_area">
//         {Object.values(PlanTypes).map((plan) => {
//           return (
//               <div
//                 className={`plan_card ${updateData.key == plan.key && "active"}`} 
//                 // className="plan_card"
//                 // style={{border: updateData.key == plan.key && "3px solid blue"}}
//                 key={plan.key}
//               >
//                 <div className="plan_body">
//                   <div className="title">{plan.addon_name}</div>
//                   <p>月額{plan.price}円</p>
                  
//                   {currentPlan.price == 0
//                   ?
//                     <Link to={`/${productId}/payment?type=${plan.key}`}>
//                       <button className="btn_entry"
//                         disabled={currentPlan.price == plan.price && true}
//                       >
//                         {currentPlan.price == plan.price ? "利用中" : "申し込み"}
//                       </button>
//                     </Link>
//                   :
//                     <button className="btn_entry" 
//                       onClick={()=>setUpdateData(plan)}
//                       disabled={currentPlan.addon_name == plan.addon_name && true}
//                     >
//                       {currentPlan.addon_name == plan.addon_name ? "利用中" : "選択"}
//                     </button>
//                   }
//                 </div>
//               </div>
//           );
//         })}
//       </div>