import React, { useContext } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
// import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';


export const BaseSetting = React.memo(({ deleteProduct, register, errors, watch, Clear }) => {
  
  // const { productId } = useParams();
  const { currentUser } = useContext(StateContext);
  
  return (
    <>
        <h2 className="H1 mb_2">基本設定</h2>

        <div className="form_field">
          <label>ステータス</label>
          <div className="radio_box">
            {["公開", "非公開"].map((item) => {
              return (
                <label key={item}
                  className={item === watch('status') ? "is_on":""} 
                >
                  <input 
                    id="status"
                    className="input"
                    type="radio"
                    value={item}
                    {...register('status')}
                    // defaultChecked={item === getValues('status')}
                  />
                  {item}
                </label>
              );
            })}
          </div>
          <p>非公開の場合、このサイトのすべてのページが非表示になります。</p>
        </div>
        
        <div className="form_field">
          <label>サイト名
            <div className="btn_clear" type="button" onClick={()=>Clear("name")}>クリア</div>
          </label>
          <input 
            id="name"
            className={`input ${errors.name?.message && "error"}`}
            // style={{border: errors.name ? "1px solid red" :""}}
            type="text"
            placeholder="サイト名を入力..."
            {...register('name', {
              required: { value: true, message: '【エラー】必須項目です。'},
              maxLength: { value: 40, message: '【エラー】40文字以内で入力してください。'},
            })}
          />
          {errors.name?.message && 
            <div className="color_red">{errors.name.message}</div>
          }
          <p>サイト名を入力してください。</p>
        </div>
        
        <div className="form_field">
          <label>タイトル（title）
            <div className="btn_clear" type="button" onClick={()=>Clear("title")}>クリア</div>
          </label>
          <input 
            id="title"
            className={`input ${errors.title?.message && "error"}`}
            // style={{border: errors.title ? "1px solid red" :""}}
            type="text"
            placeholder="タイトルを入力..."
            {...register('title', {
              maxLength: { value: 60, message: '【エラー】60文字以内で入力してください。'},
            })}
          />
          {errors.title?.message && 
            <div className="color_red">{errors.title.message}</div>
          }
          <p>トップページのタイトルに設定されます。(最大60文字まで)<br/>
          ※ページタイトルが設定されている場合は、ページタイトルが優先されます。
          </p>
        </div>
        
        <div className="form_field">
          <label>サイトの説明（description）
            <div className="btn_clear" type="button" onClick={()=>Clear("description")}>クリア</div>
          </label>
          <TextareaAutosize
            minRows={4}
            id="description"
            className={`input ${errors.description?.message && "error"}`}
            // style={{border: errors.description ? "1px solid red" :""}}
            type="text"
            placeholder="概要説明を入力..."
            {...register('description', {
              maxLength: { value: 120, message: '【エラー】120文字以内で入力してください。'},
            })}
          />
          {errors.description?.message && 
            <div className="color_red">{errors.description.message}</div>
          }
          <p>(最大120文字まで)</p>
        </div>
        
        <div className="form_field">
          <label>SEO最適化</label>
          <div className="radio_box">
            <label className={true == watch('optimize') || "true" == watch('optimize') ? "is_on":""}>
              <input 
                id="optimize"
                className="input"
                type="radio"
                value={true}
                {...register('optimize')}
              />
              SEO最適化
            </label>
            <label className={false == watch('optimize') || "false" == watch('optimize') ? "is_on":""}>
              <input 
                id="optimize"
                className="input"
                type="radio"
                value={false}
                {...register('optimize')}
              />
              最適化しない
            </label>
          </div>
          <p>SEO対策を自動で最適化します。</p>
        </div>
        
        <div className="form_field">
          <label>インデックス</label>
          <div className="radio_box">
            <label className={true == watch('index') || "true" == watch('index') ? "is_on":""}>
              <input 
                id="index"
                className="input"
                type="radio"
                value={true}
                {...register('index')}
              />
              検索エンジンに登録する
            </label>
            <label className={false == watch('index') || "false" == watch('index') ? "is_on":""}>
              <input 
                id="index"
                className="input"
                type="radio"
                value={false}
                {...register('index')}
              />
              登録しない
            </label>
          </div>
          <p>検索エンジン（Googleなど）にこのサイトの情報取得を許可します。
          インデックスを許可しない場合、検索に表示されません。</p>
        </div>
        
        <div className="input_fields">
          <div className="field_2">
            <label>新着ページの表示</label>
            <div className="selectbox">
              <select
                id="otherpages"
                className="" 
                {...register('otherpages')}
              >
                <option value="">非表示</option>
                <option value="all">全ページに表示</option>
                <option value="top">トップページのみ表示</option>
                <option value="others">トップページ以外に表示</option>
              </select>
            </div>
          </div>
        </div>
        
        {currentUser.admin &&
          <>
            <label>プラン
              <div className="btn_clear" type="button" onClick={()=>Clear("plan")}>クリア</div>
            </label>
            <input 
              id="title"
              className="input"
              type="text"
              placeholder="プランを入力..."
              {...register('plan')}
            />
          </>
        }

    </>
  );
});

        // <div className="form_field">
        //   <label>プロダクトの削除</label>
        //   <button className="btn_delete" onClick={deleteProduct}>削除する</button>
        // </div>

        // <div className="form_field">
        //   <label>SEO最適化</label>
        //   <div className="radio_box">
        //     <label
        //       htmlFor="optimize"
        //       className={ watch('optimize') == true ? "is_on" :""} 
        //     >
        //       <input
        //         id="optimize"
        //         type="checkbox"
        //         {...register('optimize')}
        //         // value={watch('optimize') == "false" || watch('optimize') == false ? true : false}
        //         defaultChecked={ watch('optimize') == true}
        //       />
        //       {watch('optimize') == true ? "最適化する" : "最適化しない"}
        //     </label>
        //   </div>
        //   <p>SEO対策を自動で最適化します。</p>
        // </div>
        
        // <div className="form_field">
        //   <label>インデックス</label>
        //   <div className="radio_box">
        //     <label
        //       htmlFor="index"
        //       className={watch('index') == true ? "is_on" :""}
        //     >
        //       <input
        //         id="index"
        //         type="checkbox"
        //         {...register('index')}
        //         // value={watch('index') == "false" || watch('index') == false ? true : false}
        //         defaultChecked={watch('index') == true}
        //       />
        //       {watch('index') == true ? "インデックス許可" : "インデックスを許可しない"}
        //     </label>
        //   </div>
        //   <p>検索エンジン（Googleなど）にこのサイトの情報取得を許可します。
        //   インデックスを許可しない場合、検索に表示されません。</p>
        // </div>



        // <div className="form_field">
        //   <label>プラン＆オプション</label>
        //   <div className="">
        //     {addons.length == 0 
        //       ? <div className="product_badge">フリープラン</div>
        //       :
        //         <>
        //           {addons.map((addon) => {
        //             return (
        //               <div className="product_badge" key={addon.id}>
        //                 {addon.addon_name}
        //               </div>
        //             );
        //           })}
        //         </>
        //     }
        //   </div>
        //   <Link to={`/product/${productId}/addons`} className="">プラン・オプションの変更へ</Link>
        // </div>


// <div className="form_field">
//   <label>アドオン
//     <div className="btn_clear" type="button" onClick={()=>Clear("plan")}>クリア</div>
//   </label>
//   <input 
//     id="name"
//     className="input"
//     style={{border: errors.name ? "1px solid red" :""}}
//     type="text"
//     {...register('plan')}
//   />
// </div>



// <div className="form_field">
//           <label>プラン＆オプション</label>
//           <div className="">
//             {addons.length == 0 
//               ? <div className="domain_badge">フリープラン</div>
//               :
//                 <>
//                   {addons.map((addon) => {
//                     return (
//                       <div className="domain_badge" key={addon.id}>
//                         {addon.addon_name}
//                       </div>
//                     );
//                   })}
//                 </>
//             }
//           </div>
//           <Link to={`/${productId}/addons`} className="">プラン・オプションの変更へ</Link>
//         </div>
        
//         <div className="form_field">
//           <label>ステータス</label>
//           <div className="radio_box">
//             {["公開", "非公開"].map((item) => {
//               return (
//                 <label key={item}
//                   className={product.status == item ? "active":""} 
//                   // style={{border: product.status == item ? "1px solid blue" :""}}
//                 >
//                   <input
//                     type="radio"
//                     id="status"
//                     name="status"
//                     value={item}
//                     onChange={InputChange}
//                     checked={item == product.status}
//                   />
//                   {item}
//                 </label>
//               );
//             })}
//           </div>
//           <p>非公開の場合、このサイトのすべてのページが非表示になります。</p>
//         </div>
        
//         <div className="form_field">
//           <label>サイト名</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="name" 
//             value={product.name || ''} 
//             onChange={InputChange}
//             placeholder="サイト名を入力..."
//           />
//           <p>サイト名を入力してください。</p>
//         </div>
        
//         <div className="form_field">
//           <label>タイトル（title）</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="title" 
//             value={product.title || ''} 
//             onChange={InputChange} 
//             placeholder="タイトルを入力..."
//           />
//           <p>トップページのタイトルタグに設定されます。</p>
//         </div>
        
//         <div className="form_field">
//           <label>サイトの説明（description）</label>
//           <TextareaAutosize
//             type="text" 
//             className="input" 
//             minRows={4}
//             name="description" 
//             value={product.description || ''} 
//             onChange={InputChange} 
//             placeholder="概要説明を入力..."
//           />
//         </div>
        
//         <div className="form_field">
//           <label>キーワード</label>
//           <input 
//             className="input" 
//             type="text" 
//             name="keywords" 
//             value={product.keywords || ''} 
//             onChange={InputChange} 
//             placeholder="キーワードを入力..."
//           />
//         </div>
        
//         <div className="form_field">
//           <label>SEO最適化</label>
//           <div className="radio_box">
//             <label 
//               htmlFor="optimize"
//               className={product.optimize == "true" || product.optimize == true ? "active" :""} 
//               // style={{border: product.optimize == "true" || product.optimize == true ? "1px solid blue" :""}}
//             >
//               <input
//                 type="checkbox"
//                 id="optimize"
//                 name="optimize"
//                 value={product.optimize == "false" || product.optimize == false ? true : false}
//                 onChange={InputChange}
//                 checked={product.optimize == "true" || product.optimize == true}
//               />
//               {product.optimize == "true" || product.optimize == true
//                 ? "最適化する"
//                 : "最適化しない"
//               }
//             </label>
//           </div>
//           <p>SEO対策を自動で最適化します。</p>
//         </div>
          
//         <div className="form_field">
//           <label>インデックス</label>
//           <div className="radio_box">
//             <label 
//               htmlFor="index"
//               className={product.index == "true" || product.index == true ? "active" :""} 
//               // style={{border: product.index == "true" || product.index == true ? "1px solid blue" :""}}
//             >
//               <input
//                 type="checkbox"
//                 id="index"
//                 name="index"
//                 value={product.index == "false" || product.index == false ? true : false}
//                 onChange={InputChange}
//                 checked={product.index == "true" || product.index == true}
//               />
//               {product.index == "true" || product.index == true
//                 ? "インデックス許可"
//                 : "インデックスを許可しない"
//               }
//             </label>
//           </div>
//           <p>検索エンジン（Googleなど）にこのサイトの情報取得を許可します。
//           インデックスを許可しない場合、検索に表示されません。</p>
//         </div>
        
//         <div className="form_field">
//           <label>プロダクトの削除</label>
//           <button className="btn_delete" onClick={deleteProduct}>削除する</button>
//         </div>