import React from 'react';
import { BiPlus, BiMinus } from "react-icons/bi";

export const RangeSpinSplit = React.memo(({ obj, setObj, InputChange, 
  targetName, column, objValueMain, objValueOther, beforeAfter, max, min, setStates }) => {
    
    
  const StateChange = (newValue) => {
    if (beforeAfter == "before") {
      const newObj = { ...obj, [targetName]: `${newValue}／${objValueOther ||""}` };
      setStates(newObj);
    } else if (beforeAfter == "after") {
      const newObj = { ...obj, [targetName]: `${objValueOther ||""}／${newValue}` };
      setStates(newObj);
    }
  };
  
  const spinUp = (value) => {
    let newValue = ++value
    StateChange(newValue);
  };
  
  const spinDown = (value) => {
    let newValue = --value
    StateChange(newValue);
  };

  
  return (
    <div className="spin_btn range_spin">
      <span onClick={()=>spinDown(objValueMain)}><BiMinus/></span>
      <input 
      　type="range"
      　id={column}
      　name={column}
      　value={objValueMain || 0} 
      　onChange={InputChange}
      　max={max || 100}
      　min={min || 0}
      />
      <span onClick={()=>spinUp(objValueMain)}><BiPlus/></span>
    </div>
  );
});