import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { ProductPlans } from '@/addon/ProductPlans';
import { ProductOptions } from '@/addon/ProductOptions';
// import { UseAddons } from '@/addon/UseAddons';
import { ProductAdding } from '@/addon/ProductAdding';
import { Nav } from '@/product/Nav';
import { Subject } from '@/product/Subject';
import { Loading } from '@/app/Loading';
import { About } from '@/addon/About';

import { useSetRecoilState } from 'recoil'
import { productAtom } from '@/providers/Atoms';



export const ProductAddons = () => {
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);

  // const [ product, setProduct ] = useRecoilState(productAtom)
  const setProduct = useSetRecoilState(productAtom);
  const [ currentPlan, setCurrentPlan ] = useState([]);
  // const [ currentAddons, setCurrentAddons ] = useState([]);
  const [ addings, setAddings ] = useState([]);
  const [ plans, setPlans ] = useState([]);
  const [ options, setOptions ] = useState([]);
  
  const [ changeData, setChangeData ] = useState([]);
  const changeDataState = {
    changeData, setChangeData
  }
  
  const [loading, setLoading] = useState(false);
  
  const [ open, setOpen ] = useState(false);
  const OpenToggle = ()=> {
    setOpen((prev)=> !prev);
  };
  
  const { productId } = useParams();
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  const FreePlan =  {
    id: 0,
    addon_key: "Free",
    addon_name: "フリープラン", 
    addon_type: "Plan",
    content: "広告表示\n１ページまで",
    price: 0, 
  }
  
  useEffect(() => {
    client.get(`/api/v1/products/${productId}/addons`)
    .then(resp => {
      setProduct(resp.data.product);
      setCurrentPlan(resp.data.current_plan || FreePlan);
      // setCurrentAddons(resp.data.current_addons);
      setAddings(resp.data.addings);
      setPlans([FreePlan, ...resp.data.plans]);
      setOptions(resp.data.options);
      // console.log(resp.data.subscription);
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  
  
  // const Addings = (adding) => {
  //   const tesuto2 = currentAddons.map((addon) => {
  //     return addon.id === adding.addon_id ? {...addon, term: adding.id} : null;
  //   });
  //   return tesuto2;
  // }
  
  // const tesuto = addings.map((adding) => {
  //   return Addings(adding);
  // });
  
  // // 2次元配列を１次元に変換
  // const newArray = tesuto.flat();
  // // nullを削除
  // const newArray2 = newArray.filter(Boolean)
  // console.log(newArray2);
  
  
  //データの更新を反映
  // const AddonsDataUpdate = (newData) => {
  //   const newAddons = currentAddons.filter((obj) => obj.id !== currentPlan.id);
  //   setCurrentAddons([newData, ...newAddons]);
  // };

  
  // プラン変更
  const PlanChange = () => {
    if (changeData.length === 0) {
      setToastObj({message: "プランを選択してください。", color: "#ffedab"});
      return;
    }
    
    const newData = {
      current_plan_id: currentPlan.id,
      subdomain: productId,
      ...changeData
    }

    const sure = window.confirm(`本当にプランを変更しますか?（※アップグレードの場合、料金の差額が自動的に決済されます。）`);
    if (sure) {
      setLoading(true);
      
      client.post(`/api/v1/payments/plan_change`, newData)
      .then(resp => {
        setCurrentPlan(resp.data.addon);
        // AddonsDataUpdate(resp.data.addon);
        setAddings(resp.data.addings);
        setProduct(resp.data.product);
        setLoading(false);
        setToastObj({message: resp.data.message || "プランを更新しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setLoading(false);
        if (e.response.data) {
          setToastObj({message: `【エラー】${e.response.data}`, color: "#ffedab"});
        } else {
          setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        }
        console.log(e);
      });
    }
  };
  

  // プラン解除
  const deleteAdding = (adding) => {
    const deleteData = {
      subdomain: productId,
      ...adding
    }
    
    const sure = window.confirm(`本当に解除しますか?`);
    if (sure) {
      client.post(`/api/v1/payments/adding_cancel`, deleteData)
      .then(resp => {
        if (resp.data.message == null) {
          setAddings(resp.data.addings);
  
          if(resp.data.addon.id == currentPlan.id) {
            setCurrentPlan(FreePlan);
          }
        }
        setProduct(resp.data.product);
        setToastObj({message: resp.data.message || "解除しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        if (e.response.data) {
          setToastObj({message: `【エラー】${e.response.data}`, color: "#ffedab"});
        } else {
          setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        }
        console.log(e);
      });
    }
  };
  
  
  // const DeleteAddon = (adding) => {
  //   const DeleteData = {
  //     subdomain: productId,
  //     ...adding
  //   }
    
  //   const sure = window.confirm(`本当に解除しますか?`);
  //   if (sure) {
  //     client.post(`/api/v1/payments/addon_cancel`, DeleteData)
  //     .then(resp => {
  //       if (resp.data.message == null) {
  //         setCurrentAddons((prev) =>
  //           prev.filter((obj) => obj.id !== resp.data.id)
  //         );
          
  //         if(resp.data.id == currentPlan.id) {
  //           setCurrentPlan(FreePlan);
  //         }
  //       }
  //       setToastObj({message: resp.data.message || "解除しました", color: "#D1E7DD"});
  //       // console.log(resp);
  //     })
  //     .catch(e => {
  //       setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
  //       console.log(e);
  //     });
  //   }
  // };

  
  return (
    <>
      { loading && <Loading/> }
      
      <div className="main_layer"></div>
      <Nav/>
      <Subject/>
      
      {addings.length !== 0 &&
        <>
          <h2 className="H2 color_white">利用中のプラン／オプション</h2>
          <div className="addings">
            {addings.map((adding) => {
              return (
                <ProductAdding adding={adding} deleteAdding={deleteAdding} key={adding.id}/>
              );
            })}
          </div>
        </>
      }
      
      <h1 className="H1 color_white">料金プラン</h1>
      
      <div className="add_plan_area">
        <ProductPlans plans={plans} currentPlan={currentPlan} changeDataState={changeDataState}/>
         
        {currentPlan.addon_key !== "Free" && changeData.length !== 0 &&
          <center>
            {changeData?.addon_key == "Free"
            ? 
              <div className="advice_box">
                上記「利用中」の該当プランを【解除】すると「フリープラン」に変更できます。
              </div>
            :
              <button 
                className="btn_add"
                onClick={PlanChange}
                disabled={currentPlan?.status && true}
              >選択のプランに変更する</button>
            }
          </center>
        }
      </div>
      
      <center>
        <button className="btn_edit mb_2" style={{width: "200px"}} onClick={OpenToggle}>
          { open ? "閉じる": "各項目の説明を見る"}
        </button>
      </center>
      { open && <About/> }

      <h2 className="H2">オプション</h2>
      <ProductOptions options={options} addings={addings}/>
      
      <style>
        {`
          .main_area{
            max-width: 1200px;
          }
        `}
      </style>
    </>
  );
};


// <center>
//   {changeData?.addon_key == "Free"
//   ? 
//     <button 
//       className="btn_delete"
//       onClick={()=>DeleteAddon(currentPlan)}
//       disabled={currentPlan?.status && true}
//     >フリープランに戻す</button>
//   :
//     <button 
//       className="btn_add"
//       onClick={PlanChange}
//       disabled={currentPlan?.status && true}
//     >プランを変更</button>
//   }
// </center>

// <UseAddons currentAddons={currentAddons} DeleteAddon={DeleteAddon}/>


          // <div className="billing">
          //   {newArray2.map((adding) => {
          //     return (
          //       <div className="item" key={adding.id}>
          //         <div className="item_name">{adding.addon_name}</div>

          //       </div>
          //     );
          //   })}
          // </div>


// <h2 className="H2 color_white">利用中のプラン</h2>
// <div className="add_ons">
//   <div className="item item_noline">
//     <div className="item_name">{currentPlan.addon_name}</div>
//     <div className="item_summary">{currentPlan.addon_type}</div>
//     <div className="item_price">{currentPlan.cycle && `${currentPlan.cycle}額`}</div>
//     <div className="item_btn">{currentPlan.price == 0 ? "無料" : `${currentPlan.price}円`}</div>
//   </div>
// </div>