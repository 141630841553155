import React, { useState } from 'react';
import { Link } from 'react-router-dom';


export const Options = ({ options }) => {
  
  // const [ open, setOpen ] = useState("");

  return (
      <>
        {options.map((option, index) => {
          return (
            <div className="addon" key={index}>
              <div className="addon_body">
                <div className="addon_name">{option.addon_name}</div>
                <p>{option.content}</p>
                <div className="addon_price">￥<span>{option.price}</span>/月</div>
              </div>
            </div>
          );
        })}
        
        
      </>
  );
};


// <button className="btn_entry" onClick={()=>setOpen(option.id)}>詳細</button>

// { open == option.id && 
//   <div className="modal" onClick={()=>setOpen("")}>
//     <div className="img_modal">
//       <img src={option.img || "/noimg.png"}/>
//       <div className="addon_name">{option.addon_name}</div>
//     </div>
//   </div>
// }
              
              

      // <div className="add_ons">
      //   {Object.values(OptionTypes).map((option, index) => {
      //     return (
      //       <div className="item" key={index}>
      //         <div className="item_name">{option.addon_name}</div>
      //         <div className="item_summary">{option.addon_name}</div>
      //         <div className="item_price">月額{option.price}円</div>
      //         <div className="item_btn">
      //           <Link to={`/${productId}/payment?type=${option.key}`}>
      //             <button className="btn" 
      //               disabled={OptionNames.includes(option.addon_name) && true}
      //             >{OptionNames.includes(option.addon_name) ? "利用中" : "機能を追加"}</button>
      //           </Link>
      //         </div>
      //       </div>
      //     );
      //   })}
      // </div>