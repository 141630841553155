import React from 'react'

export const PaymentShow = ({ payment }) => {
  
  return (
    <div style={{width: "100%"}}>
      <div>ID：{payment.id}｜{payment.profit_amount}円</div>
      <div>{payment.customer_id}</div>
      <div>{payment.subscription_id}</div>
      <div>{payment.price_id}</div>
      <div>{payment.payment_intent_id}</div>
      <div>{payment.invoice_id}</div>
    </div>
  )
};