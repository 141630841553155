import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Url } from '@/app/Url';
// import { Preview } from '@/page/Preview';
import { BiMobile, BiChevronLeft } from "react-icons/bi";
import { SaveStatus } from '@/page/SaveStatus';

import { useRecoilValue } from 'recoil'
import { productAtom } from '@/providers/Atoms';


export const PageNav = React.memo(({ toppage }) => {
  
  const product = useRecoilValue(productAtom);
  const { slug } = useParams();

  // const [ previewOpen, setPreviewOpen ] = useState(false);
  
  return (
    <>
      <div className="page_nav">
        <Link to={`/product/${product.subdomain}/dashboard`} className="back_btn"><BiChevronLeft/></Link>
        
        <div className="page_nav_inner">
          
          <div className="t_hide">
            <SaveStatus/>
          </div>
          
          <a href={`/product/${product.subdomain}/${slug}/preview`} 
            target="_blank" rel="noopener noreferrer"
            className="block_badge nobtn"
          >
            <BiMobile/>プレビュー
          </a>
          
          {/*
          <div className="block_badge nobtn" onClick={()=>setPreviewOpen(true)}>
            <BiMobile/>プレビュー
          </div>
          */}
          
          <div className="block_badge">
            { product.domain || `${product.subdomain}.pstock.me`}
            {slug &&
              <>&nbsp;<Url slug={slug}/></>
            }
          </div>
          
          <Link to={`/product/${product.subdomain}/addons`}>
            <div className="block_badge nobtn">
              {product.plan || "フリープラン"}
            </div>
          </Link>
        </div>
      </div>
      
      {/*
      { previewOpen && 
        <Preview setPreviewOpen={setPreviewOpen} product={product} slug={toppage ? "" : slug}/>
      }
      */}
    </>
  );
  
});

// <Link to={`/product/${product.subdomain}/dashboard`} className="back_btn"><BiChevronLeft/></Link>
