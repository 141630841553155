import React from 'react'

export const Footer = React.memo(() => {

  return (
    <>
      <div className="footer">
        <div className="footer_title">PAGE STOCK</div>
        <div>COPYRIGHT © WELFILL & CO.,LTD. ALL RIGHTS RESERVED</div>
      </div>
    </>
  )
});