import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { BiCamera, BiChevronRight } from "react-icons/bi";
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { TopicPath } from '@/app/TopicPath';
import imageCompression from 'browser-image-compression';

export const Account = () => {
  
  const { account } = useParams();
  // console.log(account);
  
  const { client, setCurrentUser, s3key } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const [ user, setUser ] = useState([]);
  // console.log(user);
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    client.get(`/api/v1/users/${account}`)
    .then(resp => {
      setUser(resp.data);
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  
  
  const InputChange = event => {
    const { name, value } = event.target;
    setUser((prev)=> ({ ...prev, [name]: value }));
    setCurrentUser((prev)=> ({ ...prev, [name]: value }));
  };
  
  // 編集
  const updateUser = () => {
    client.patch(`/api/v1/users/${user.id}`, user)
    .then(resp => {
      setToastObj({message: "保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // 画面遷移のため
  // const navigate = useNavigate();
  
  // 退会
  // const deleteProduct = () => {
  //   const sure = window.confirm(`本当に退会しますか?`);
  //   if (sure) {
  //     client.delete(`/api/v1/users/${currentUser.id}`)
  //     .then(resp => {
  //       navigate('/');
  //       // window.location.reload(false);
  //       setToastObj({message: "退会しました", color: "#D1E7DD"});
  //     })
  //     .catch(e => {
  //       setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
  //       console.log(e);
  //     });
  //   }
  // };
  
  
  // アクセスキー設定
  const s3Client = new S3Client({
    region: 'ap-northeast-1',
    credentials: {
      accessKeyId: s3key.accesskey,
      secretAccessKey: s3key.secretkey,
    },
  });
  
  
    // s3に画像アップ
  const InputFileUp = (file) => {
    const Params = {
      ACL: 'public-read',
      Bucket: s3key.bucket,
      Key: `image/profile/${user.account}/${file.name}`,
      Body: file,
      ContentType: file.type,
    };
    
    s3Client.send(new PutObjectCommand(Params))
    .then(resp => {
      const url = `https://${Params.Bucket}.s3.ap-northeast-1.amazonaws.com/${Params.Key}`
      console.log("画像アップロード完了");
      setUser((prev)=> ({ ...prev, icon: url }));
      setCurrentUser((prev)=> ({ ...prev, icon: url }));
    })
    .catch(e => {
      setToastObj({message: "アップロードに失敗しました", color: "#ffedab"});
      console.log(e);
    });
  };
  
  
  // 画像取得＆アップロード
  const InputFile = async (e) => {
    const file = e.target.files[0];
    
    // if (file.size/1024 > 300) {
    //   setToastObj({message: "画像サイズ：300KBまで", color: "#ffedab"});
    //   console.log("画像サイズオーバー");
    //   return;
    // }
    
    try {
      if (file.size/1024 > 130) {
        console.log("リサイズ");
        
        // リサイズ内容
        const options = await {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 130,
          useWebWorker: true,
        }
        // リサイズ処置
        const ResizeFile = await imageCompression(file, options);
        InputFileUp(ResizeFile);
        
      } else {
        console.log("リサイズなし");
        InputFileUp(file);
      }
    } catch (e) {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.error(e);
    }
    
  };


  return (
    <>
      <div className="main_layer"></div>

      <TopicPath topic="アカウント"/>
      
        <h1 className="H1 color_white">アカウント設定</h1>
        
        <div className="account_card">
          <div className="icon">
            <img src={user.icon || "/no.png"} />
            <label className="up_btn">
              <BiCamera/>
              <input 
                type="file" 
                name="icon" 
                accept="image/*"
                onChange={InputFile}
              />
            </label>
          </div>
          
          <div className="account_body">
            <div className="account_id">アカウントID：{user.account}</div>
            
            <input 
              className="input_title" 
              type="text" 
              name="name" 
              value={user.name || ''} 
              onChange={InputChange}
              placeholder="ユーザーネームを入力..."
            />
            
            <div className="" style={{textAlign: "right"}}>
              <button className="btn" onClick={updateUser}>保存</button>
            </div>
          </div>
        </div>
        
        <div className="card_area">
          <Link to={`/account/${account}/billing`} className="card">
            <div className="card_title">お支払い情報</div>
            お支払い情報の確認<BiChevronRight/>
          </Link>
          <a href="/users/edit?type=email" className="card">
            <div className="card_title">メールアドレス</div>
            メールアドレスの変更<BiChevronRight/>
          </a>
          <a href="/users/edit?type=password" className="card">
            <div className="card_title">パスワード</div>
            パスワードの変更<BiChevronRight/>
          </a>
           
          <Link to="/account/card_setting" className="card">
            <div className="card_title">クレジットカード</div>
            クレジットカードの設定<BiChevronRight/>
          </Link>
          
          <a href="/users/edit?type=delete" className="card">
            <div className="card_title">退会</div>
            退会に進む<BiChevronRight/>
          </a>
        </div>

    </>
  );
};

// <label>退会</label>
// <button className="btn_delete" onClick={deleteProduct}>退会する</button>

// <Link to="/plans" className="card fc">
//   <div className="card_title">プランの変更</div>
//   プランの変更<BiChevronRight/>
// </Link>