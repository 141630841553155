import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import TextareaAutosize from 'react-textarea-autosize';


export const SendMail = () => {
  
  const { currentUser } = useContext(StateContext);
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const [ data, setData ] = useState({
  　title: "",
  　content: "",
  });
  
  // console.log(data);
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!currentUser.admin) {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"}); 
    }
  }, []);
  
  
  const InputChange = event => {
    const { name, value } = event.target;
    setData((prev)=> ({ ...prev, [name]: value }));
  };
  
  // 編集
  const send = () => {
    client.post(`/api/v1/mails/send_mail`, data)
    .then(resp => {
      setToastObj({message: "送信しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  

  return (
    <>
      <div className="contact_form">
        <h1 className="H1">一斉メール送信</h1>
        
        <label>タイトル</label>
        <input 
          className="input" 
          type="text" 
          name="title" 
          value={data.title} 
          onChange={InputChange}
        />
        
        <label>内容</label>
        <TextareaAutosize
          className="input" 
          type="text" 
          name="content" 
          value={data.content} 
          onChange={InputChange}
          minRows={15}
        />
        
        <button className="base_btn" onClick={send}>送信</button>
      </div>
    </>
  );
};
