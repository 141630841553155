import React, { useContext } from "react"
import { StateContext } from '@/providers/StateProviders';
import { useAutoSave } from '@/hooks/useAutoSave';

import { useSetRecoilState } from 'recoil'
import { saveStatusAtom, textStatesAtom } from '@/providers/Atoms';


export const Inputarea = React.memo(({ part_id, text_type, obj, setObj, placeholder, clickHandler }) => {
  
  // console.log("テキストレンダ", obj);
  const setSaveStatus = useSetRecoilState(saveStatusAtom)
  const setTextStates = useSetRecoilState(textStatesAtom);
  
  const { client } = useContext(StateContext);

  const InputChange = event => {
    const { name, value } = event.target;
    const newObj = { ...obj, [name]: value};
    setObj(newObj);
    setTextStates([ newObj, setObj ]);
  };
  
  
  // 保存
  const update = () => {
    client.patch(`/api/v1/texts/${obj.id}`, obj)
    .then(resp => {
      setSaveStatus(false);
      console.log("保存しました");
    })
    .catch(e => {
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(obj, update, 3000);
  
  return (
    <>
      <input
        type="text"
        name="content"
        value={obj.content || ""}
        onChange={InputChange}
        placeholder={placeholder ||""}
        onClick={clickHandler}
      />
    </>
  );
});