import React from 'react';
import { useParams } from 'react-router-dom';

export const Template = React.memo(({ page, pageCopy }) => {
  
  const { productId } = useParams();

  return (
    <div className="template_card">
      <div className="card_img">
        <img src={page.img || "/no_img.png"}/>
      </div>
      
      <div className="card_body">
        <div className="title">{page.title || "タイトル"}</div>
        
        <div className="btn_box">
          <a href={`https://templates.pstock.me/${page.slug}`} target="_blank" rel="noopener noreferrer">
            <button className="btn_line">デモを見る</button>
          </a>
          
          { productId &&
            <button className="btn" onClick={()=>pageCopy(page.id)}>追加する</button>
          }
        </div>
      </div>
    </div>
  );
});
