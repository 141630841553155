import React from "react"
import { RgbaStringColorPicker } from "react-colorful";
import { PresetColors } from '@/app/PresetColors';
// import { SpinBtn } from '@/app/SpinBtn';
import { RangeSpin } from '@/app/RangeSpin';
import { BiX } from "react-icons/bi";


export const ColorPicker = React.memo(({ 
  blockState, InputChange, colorStateSwitch, inputName,
  ColorHandler, ColorHandler2, updateBlock, Clear, coloClear, setOpen }) => {

  // console.log("ブロックカラーピック" , "レンダ");

  const { block, setBlock } = blockState
  // const { color, subColor } = colorState;
  // const { inputValue, setInputValue } = inputValueState;
  
  let colorValue = "";
  if(inputName == "color"){
    colorValue = block.color ||"";
  } else if(inputName == "sub_color"){
    colorValue = block.sub_color ||"";
  }
  
  // // refをtrueで初期化。
  // const ref = useRef(true);
  
  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current = false;
  //     return;
  //   }
  //   setInputValue(color);
  // }, [block?.color]);
  
  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current = false;
  //     return;
  //   }
  //   setInputValue(subColor);
  // }, [block?.sub_color]);
  
  
  // const ValueChange = event => {
  //   colorStateSwitch[1](event.target.value)
  // };
  
  const PickerSwatch = (color) => {
    colorStateSwitch[1](color)
    // setInputValue(color)
  }
  
  const CloseHandler = ()=> {
    setOpen("");
  };
  
    
  // const ShowOpen = (type)=> {
  //   setShow(type);
  // };
  
  // const deg = block.deg || 0
  

  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">

        <div className="items_bar_top">
          <div className="sidebar_title">
            ブロック背景色
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
        
          <div className="tab_menu">
            <button 
              className={`tab_menu_btn ${inputName == "color" && "active"}`}
              // style={{width: "calc(100% / 2 - 5px)"}}
              onClick={ColorHandler}
            >背景色</button> 
            <button
              className={`tab_menu_btn ${inputName == "sub_color" && "active"}`}
              // style={{width: "calc(100% / 2 - 5px)"}}
              onClick={ColorHandler2}
            >グラデーション</button>
          </div>
          
          {inputName == "sub_color" && colorValue !== "" &&
            <>
              <label>グラデーションの角度（{block.deg ||""}度）
                <div className="btn_clear" type="button" onClick={()=>Clear("deg")}>クリア</div>
              </label>
              <RangeSpin
                obj={block}
                setObj={setBlock}
                column="deg"
                objValue={block.deg ||0} 
                InputChange={InputChange}
              　max="360"
              />
              
              <label>グラデーションの位置（{block.color_stop ||""}%）
                <div className="btn_clear" type="button" onClick={()=>Clear("color_stop")}>クリア</div>
              </label>
              <RangeSpin
                obj={block}
                setObj={setBlock}
                column="color_stop"
                objValue={block.color_stop ||0}
                InputChange={InputChange}
              />
            </>
          }
          
          {inputName == "color" &&
            <>
              <label>背景色</label>
            </>
          }
          
          {inputName == "sub_color" &&
            <>
              <label>グラデーション色</label>
            </>
          }
          
          <div className="input_inline mb_1">
            <button className="" 
              style={{ background: colorValue || "#fff", margin:"0 10px 0 0", border: "solid 1px #ccc" }}
            />
            
            <input
              type="text"
              className="input"
              style={{ width: "100%" }}
              name={inputName}
              value={colorValue}
              onChange={InputChange}
              placeholder="カラーコード"
            />
          </div>
          
          <div className="picker__swatches">
            <button className="clear_btn mb_1" onClick={()=>coloClear(inputName)}>クリア</button>
            <div
              className="picker__swatch"
              onClick={()=> PickerSwatch("rgba(255, 255, 255, 0)")}
            >
              <img src="/transparent.jpg"/>
            </div>
            
            {PresetColors.map((Color) => (
              <button
                key={Color}
                className={`picker__swatch ${Color == colorValue && "active"}`}
                style={{ background: Color }}
                onClick={()=> PickerSwatch(Color)}
              />
            ))}
          </div>
          
          <div className="picker">
            <RgbaStringColorPicker color={colorValue} onChange={colorStateSwitch[1]}/>
            
            <input
              type="text"
              className="input"
              name={inputName}
              value={colorValue}
              onChange={InputChange}
              placeholder="カラーコード"
            />
          </div>
          
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={updateBlock}>保存</button>
        </div>

      </div>
    </>
  );

});


  // <label>背景色
  //   <div className="btn_clear" type="button" onClick={()=>Clear("color")}>クリア</div>
  // </label>

  // <label>グラデーション色
  //   <div className="btn_clear" type="button" onClick={()=>Clear("sub_color")}>クリア</div>
  // </label>

  // const ColorHandler = ()=> {
  //   setActive(true);
  //   setColorStateSwitch([color, setColor]);
  //   setInputName("color");
  //   setInputValue(block?.color || '');
  // };
  
  // const ColorHandler2 = ()=> {
  //   setActive(true);
  //   setColorStateSwitch([subColor, setSubColor]);
  //   setInputName("sub_color");
  //   setInputValue(block?.sub_color || '');
  // };
  

            // <div className="picker">
            //   <RgbaStringColorPicker color={nowColor} onChange={colorStateSwitch[1]}/>
              
            //   <div className="picker__swatches">
            //     {presetColors.map((presetColor) => (
            //       <button
            //         key={presetColor}
            //         className="picker__swatch"
            //         style={{ background: presetColor }}
            //         onClick={()=> colorStateSwitch[1](presetColor)}
            //       />
            //     ))}
            //   </div>
            // </div>