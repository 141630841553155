import React from "react"
/** @jsx jsx */
import { css } from "@emotion/react";

export const outlineCSS = (part) => {
  let backColors = part.back_color ? part.back_color.split("／") : [];

  return (
    css`
      color: ${part.font_color};
      font-family: ${part.font};
      border-color: ${part.border_color};
      border-width : ${part.border && `${part.border}px`};
      border-radius: ${part.radius && `${part.radius}px`};
      padding: ${part.padding && `${part.padding}px`};
      text-align: ${part.align};
      box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.1)`};
      
      background: ${backColors[0]};
      ${backColors.length > 1 && `
        background: linear-gradient(130deg, ${backColors[0] || "transparent"} 35%, ${backColors[1]} 100%);
      `}
    `
  );
};

// height: ${part.height && `${part.height}px`};
// margin-bottom: ${part.margin && `${part.margin}px`};

// export const fontCSS = (part) => {
//   return (
//     css`
//       color: ${part.font_color};
//       font-size: ${part.font_size && `${part.font_size}px`};
//       font-family: ${part.font};
//       text-shadow: ${part.text_shadow && `2px 2px ${part.text_shadow}px rgba(0, 0, 0, 0.3)`};
      
//       @media screen and (max-width: 500px) {
//         font-size: ${part.font_size_s && `${part.font_size_s}px`};
//       }
//     `
//   );
// };

export const backgroundCSS = (part) => {
  let backColors = part.back_color ? part.back_color.split("／") : [];
  
  return (
    css`
      background: ${backColors[0]};
      ${backColors.length > 1 && `
        background: linear-gradient(130deg, ${backColors[0] || "transparent"} 35%, ${backColors[1]} 100%);
      `}
    `
  );
};

// export const accentCSS = (part) => {
//   let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
//   return (
//     css`
//       color: ${accentColors[0]};
      
//       ${accentColors[1] && `
//         background: linear-gradient(130deg, ${accentColors[0]} 35%, ${accentColors[1]} 100%);
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//       `}
//     `
//   );
// };

export const textCSS = (obj) => {
  let fontColors = obj.font_color ? obj.font_color.split("／") : ["",""];
  
  return (
    css`
      color: ${fontColors[0]};
      font-size: ${obj.font_size && `${obj.font_size}px`};
      font-family: ${obj.font};
      font-weight: ${obj.font_weight};
      text-shadow: ${obj.text_shadow && `2px 2px ${obj.text_shadow}px rgba(0, 0, 0, 0.3)`};
      margin-bottom: ${obj.margin && `${obj.margin}px`};
      
      
      ${fontColors[0] && fontColors[1] && `
        background: linear-gradient(130deg, ${fontColors[0]} 35%, ${fontColors[1]} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `}
      
      @container (width <= 500px) {
        font-size: ${obj.font_size_s && `${obj.font_size_s}px`};
      }
    `
  );
};

export const imgCSS = (obj) => {

  return (
    css`
      filter: ${obj.blur && `blur(${obj.blur}px)`} 
      ${obj.opacity && `opacity(${obj.opacity}%)`}
      ${obj.grayscale && `grayscale(${obj.grayscale}%)`}
      ${obj.brightness && `brightness(${obj.brightness}%)`}
      ${obj.saturate && `saturate(${obj.saturate}%)`}
      ${obj.sepia && `sepia(${obj.sepia}%)`}
      ${obj.shadow && `drop-shadow(0 2px ${obj.shadow}px rgba(0, 0, 0, 0.2))`}
      ;
      transform: ${obj.flip};
      aspect-ratio: ${obj.aspect_ratio};
      border-radius: ${obj.radius && `${obj.radius}px`};
    `
  );
};


export const settingCSS = (obj) => {

  return (
    css`
      font-family: ${obj.font};
      background: ${obj.back_color};
      
      p, a {
        font-size: ${obj.font_size && `${obj.font_size}px`};
      }
      @container (width <= 500px) {
        p, a {
          font-size: ${obj.font_size_s && `${obj.font_size_s}px`};
        }
      }
    `
  );
};



// export const outlineStyle = (part) => {
//   return (
//     {
//       background: part?.back_color && `${part.back_color}`,
//       borderColor: part?.border_color && `${part.border_color}`,
//       borderWidth : part?.border && `${part.border}px`,
//       borderRadius: part?.radius && `${part.radius}px`,
//       // maxWidth: part.width && `${part.width}px`,
//       height: part?.height && `${part.height}px`,
//       marginBottom: part?.margin && `${part.margin}px`,
//     }
//   );
// };

// export const inputStyle = (part) => {
//   return (
//     {
//       color: part?.font_color && `${part.font_color}`,
//       fontSize: part?.size && `${part.size}px`,
//     }
//   );
// };

// export const outlineCss = (part) => {
//   return (
//     css`
//       background: ${part?.back_color && part.back_color};
//       border-color: ${part?.border_color && `${part.border_color}`};
//       border-width : ${part?.border && `${part.border}px`};
//       border-radius: ${part?.radius && `${part.radius}px`};
//       height: ${part?.height && `${part.height}px`};
//       margin-bottom: ${part?.margin && `${part.margin}px`};
//     `
//   );
// };