import React from "react"
import { Link, useParams } from 'react-router-dom';
import { ContactForm } from '@/contact/ContactForm';
import { Recaptcha } from '@/contact/Recaptcha';

export const ProductContact = ({ }) => {

  const { productId } = useParams();
  
  console.log("問い合わせ");

  return (
    <>
      <div className="topic_path">
        <Link to="/" className="">ホーム</Link>
      </div>
      
      <div className="base_area">
        <h1 className="H1">{productId}へのお問い合わせ</h1>
        
        <Recaptcha>
          <ContactForm path="product_contact" productId={productId}/>
        </Recaptcha>
        
      </div>
    </>
  );
  
};