import React from 'react';

import { BsTwitter, BsFacebook, BsInstagram, BsYoutube, BsLine } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";


export const DrawerMenu = React.memo(({ menuOpen, OpenToggle, drawerItems }) => {
  

  return (
    <div className={`drawer_menu ${menuOpen && "active"}`}>
      <div className="close_btn" onClick={OpenToggle}></div>
      <div className="drawer_menu_title">MENU</div>
      
      <ul>
        {drawerItems.map((item) => {
          return (
            <li onClick={OpenToggle} key={item.id} id={`dm_item_${item.id}`}>
              <a>
                {item.name || "未設定"}
                {item.sub_name && <span>{item.sub_name}</span>}
              </a>
            </li>
          );
        })}
      </ul>
      
      <div className="sns_btn_area">
        <a href="" id="dm_line"><BsLine/></a>
        <a href="" id="dm_twitter"><BsTwitter/></a>
        <a href="" id="dm_facebook"><BsFacebook/></a>
        <a href="" id="dm_instagram"><BsInstagram/></a>
        <a href="" id="dm_youtube"><BsYoutube/></a>
        <a href="" id="dm_tiktok"><FaTiktok/></a>
      </div>
      
      <div className="close_text" onClick={OpenToggle}>閉じる</div>
    </div>
  );
  
});