import React from "react"
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';


export const Navbtn_A = React.memo(({ part, textStates, textHandlers }) => {
  
  // console.log("ナビボタンパーツレンダ");
  const { title, setTitle } = textStates;
  const { titleHandler } = textHandlers
  
  const navbtnCSS = css`
    ${outlineCSS(part)}
    ${textCSS(title)}
  `
  
  return (
    <div
      className={`navbtn_1 ${part.class_name}`}
      css={navbtnCSS}
    >
      <Textarea
        part_id={part.id}
        text_type="title"
        obj={title}
        setObj={setTitle}
        placeholder="ボタン"
        clickHandler={titleHandler}
      />
    </div>
  );
  
});

// export const Navbtn_A = React.memo(({ part, InputChange }) => {
  
//   // console.log("ナビボタンパーツレンダ");
  
//   const navbtnCSS = css`
//     ${outlineCSS(part)}
//     ${fontCSS(part)}
//   `
  
//   return (
//     <div
//       className={`navbtn_1 ${part.class_name} ${part.add_class || ""}`}
//       css={navbtnCSS}
//       id={part.add_id}
//     >
//       <input
//         // className="title_input"
//         // style={inputStyle(part)}
//         type="text" 
//         name="title" 
//         value={part.title ||""}
//         onChange={InputChange} 
//         placeholder="ボタン"
//       />
//     </div>
//   );
  
// }); 