import React, { useState} from "react"
import { useParams } from 'react-router-dom';
import { HeaderSections } from '@/page/HeaderSections';
import { FooterSections } from '@/page/FooterSections';
import { Sections } from '@/section/Sections';
import { DummyForm } from '@/page/DummyForm';
import { DummyReserveForm } from '@/page/DummyReserveForm';
import { BiPlus } from "react-icons/bi";
import { settingCSS } from '@/part/type/Style';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { useRecoilState, useSetRecoilState } from 'recoil'
import { openSidebarAtom, sharingAtom, sectionIndexAtom, sectionsStatesAtom, pageWidthAtom } from '@/providers/Atoms';


export const PageEditView = React.memo(({ 
	headerSections, setHeaderSections, 
	mainSections, setMainSections,
	footerSections, setFooterSections, 
	setting }) => {
		
	// console.log("ページビュー");
  const { slug } = useParams();
  
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  const setSharing = useSetRecoilState(sharingAtom);
  const setSectionIndex = useSetRecoilState(sectionIndexAtom);
  const setSectionsStates = useSetRecoilState(sectionsStatesAtom);
  const [ pageWidth ] = useRecoilState(pageWidthAtom);
  
  // セクション追加サイドバーを表示
  const newSectionHandler = () => {
    setSectionsStates([ mainSections, setMainSections ]);
    setSharing(null);
    setSectionIndex(Object.keys(mainSections).length);
    setOpenSidebar("newSection");
  }
  
  
  const SettingCSS = css`
   ${settingCSS(setting)}
  `
  
  return (
		<div className="page_wrapper" style={{width: pageWidth}}>
      <HeaderSections 
        sections={headerSections} 
        setSections={setHeaderSections}
        SettingCSS={SettingCSS}
        // width={pageWidth}
      />
      
      { mainSections.length !== 0 
        ? 
          <div className="page_area" css={SettingCSS}>
          	<div className="page_label">メイン</div>
            <Sections 
              // page_id={page.id} 
              sections={mainSections} 
              setSections={setMainSections}
              sharing={null}
              />
          </div>
        :
          <div className="wrapper">
            <div className="step_guide" style={{borderRadius: "0 15px 15px 15px"}}>
            	<div className="page_label" style={{left:"0"}}>メイン</div>
              <div className="step">ステップ３</div>
              <p>ページタイトルを入力し、下記の<span className="color_key">「ブロックの追加」</span>からお好きなパーツ素材を選択してください。</p>
              <p>保存は自動保存されます。</p>
            </div>
          </div>
      }
      
      <div className="wrapper mb_4">
        <button className="btn_add" onClick={newSectionHandler}>
          <span><BiPlus/>ブロックを追加</span>
        </button>
        
        {/*
        { open &&
          <NewSection 
            page_id={page.id} 
            sections={mainSections} 
            setSections={setMainSections}
            sharing={null}
            index={Object.keys(mainSections).length}
            key={page.id}
            setOpen={setOpen}
          />
        }
        */}
      </div>
      
      { slug == "contact" &&
        <DummyForm/>
      }
      { slug == "reserve" &&
        <DummyReserveForm/>
      }
      
      <FooterSections 
        sections={footerSections} 
        setSections={setFooterSections}
        SettingCSS={SettingCSS}
        // width={pageWidth}
      />

  	</div>
  ); 
});

    //   {pageWidth == "360px" &&
    //   	<style>
    //   		{`
				// 		html {
				// 	    font-size: 50%;
				// 	  }
				// 	`}
				// </style>
    //   }