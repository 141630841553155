import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';


export const EditPages = React.memo(({ setOpen }) => {
  
  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  const [ pages, setPages ] = useState([]);
  
  
  useEffect(() => {
    client.get(`/api/v1/products/${productId}/edit_pages`)
    .then(resp => {
      setPages(resp.data.pages);
    })
    .catch(e => {
      setToastObj({message: "ページを取得できません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);

  // console.log(pages);
  
  return (
    <>
      <label className="mt_1">他のページ
        <div className="btn_clear" type="button" onClick={()=>setOpen(false)}>閉じる</div>
      </label>  
      <div className="page_edit_links">
        {pages.map((page) => {
          return (
            <Link to={`/product/${productId}/${page.slug}/page_redirect`} key={page.id}>
              {page.title ||"ページタイトル未設定"}
            </Link>
          );
        })}
      </div>
    </>
  );
});
