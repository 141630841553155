import React, { useContext } from 'react'
import { StateContext } from '@/providers/StateProviders';
import { AddonJudg } from '@/addon/AddonJudg';
// import { Link } from 'react-router-dom';
import { Url } from '@/app/Url';
// import { useForm } from 'react-hook-form';


export const DomainSetting = ({ product, InputChange, register, errors }) => {
  
  const { currentUser } = useContext(StateContext);
  
  return (
    <>
        <h2 className="H1 mb_2">ドメイン設定</h2>
        
        <div className="form_field">
          <label>現在のドメイン</label>
          <div className="product_badge"><Url/></div>
        </div>
        
        <div className="form_field">
          <label>プロダクトID（※初期のサイトURLになります。）</label>
          
          <div className="productid_input_box">
            <span>https://</span>
            <input
              id="subdomain"
              className={`input ${errors.subdomain?.message && "error"}`}
              // style={{border: errors.subdomain ? "1px solid red" :""}}
              type="text"
              placeholder="半角英数字を入力..."
              {...register('subdomain', {
                required: { value: true, message: '【エラー】必須項目です。'},
                minLength: { value: 4, message: '【エラー】4文字以上で入力してください。'},
                maxLength: { value: 30, message: '【エラー】30文字以内で入力してください。'},
                pattern: { value: /^[a-z0-9\-]+$/, message: '【エラー】半角英数字と「-」のみ使用可'},
                // pattern: { value: /blog|www|page/, message: '使用できない文字です。'},
              })}
            />
            <span>.pstock.me</span>
          </div>
          
          {errors.subdomain?.message && 
            <div className="color_red">{errors.subdomain.message}</div>
          }
          <p>このプロダクトIDが、ドメイン「〇〇〇.pstock.me」の「〇〇〇」部分に設定されます。<br/>
          ※半角英数字と「-」のみ使用可。<br/>
          ※変更するとサイトURLが変更されますのでご注意ください。</p>
        </div>
        
        <AddonJudg terms={/ライト|スタンダード|ビジネス|制作代行/} boolean={true}>
          <div className="form_field">
            <label>独自ドメイン</label>
            <input 
              id="domain"
              className={`input ${errors.domain?.message && "error"}`}
              // style={{border: errors.domain ? "1px solid red" :""}}
              type="text"
              placeholder="例）example.com"
              {...register('domain', {
                pattern: { value: /^[a-z0-9.:\-]+$/, message: '【エラー】半角英数字と「-」「.」のみ使用可'},
              })}
            />
            {errors.domain?.message && 
              <div className="color_red">{errors.domain.message}</div>
            }
            <p>ドメインを「独自ドメイン」に変更する場合は、取得したドメインをこちらに入力してください。<br/>
            ※入力例）example.com<br/>
          　※入力すると、設定した独自ドメインが優先されます。
            </p>
          </div>
          
          <div className="form_field">
            <label>独自ドメインの取得と設定方法</label>
            <a href="https://manual.pstock.jp/domain-setting" target="_blank" rel="noopener noreferrer" className="row">詳しくはこちら</a>
          </div>
          
          {product.domain &&
            <div className="form_field">
              <label>独自ドメインのSSL化（無料）</label>
              
              {product.ssl
                ? <div className="product_badge">SSL化済み</div>
                : <>
                    {product.nginx
                      ? <div className="product_badge">申請済み</div>
                      : <a href={`http://${product.domain}/ssl`} 
                          className="ssl_btn"
                          target="_blank" rel="noopener noreferrer"
                        >
                          SSL化の申請へ進む
                        </a>
                    }
                  </>
              }
              
              <p>独自ドメインのSSL化は、無料で自動設定されます。<br/>
              上記でSSL化申請後、サイトに反映されるまで約24時間程度お待ちください。
              </p>
            </div> 
          }
          
          {currentUser.admin &&
            <div className="input_fields">
              <div className="field_2">
                <label>SSLカラムの更新</label>
                <div className="selectbox">
                  <select
                    id="ssl"
                    {...register('ssl')}
                  >
                    <option value="">未設定</option>
                    <option value="true">true</option>
                    <option value="">解除</option>
                  </select>
                </div>
              </div>
            </div>
          }
          
        </AddonJudg>
    </>
  );
};


        // <div className="form_field">
        //   <label>プロダクトID（サブドメイン）</label>
        //   <input 
        //     className="input" 
        //     type="text" 
        //     name="subdomain" 
        //     value={product.subdomain || ''} 
        //     onChange={InputChange}
        //     placeholder="半角小文字英数字を入力..."
        //   />
        //   <p>プロダクトIDが、このサイトのサブドメインとして設定されます。<br/>半角小文字英数字「-」のみ使用可。変更するとサイトURLが変更されますのでご注文ください。</p>
        // </div>

        // <AddonJudg addons={AddonNames} terms={/ライト|ベース|ビジネス/} boolean={true}>
        //   <div className="form_field">
        //     <label>独自ドメイン</label>
        //       <input 
        //         className="input" 
        //         type="text"
        //         name="domain" 
        //         value={product.domain || ''} 
        //         onChange={InputChange}
        //         placeholder="例）example.com"
        //       />
        //     <p>ドメインを「独自ドメイン」に変更する場合は、取得したドメインをこちらに入力してください。</p>
        //   </div>
        // </AddonJudg>