import React, { useState, useContext, useEffect, useRef } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { UploadImgs } from '@/image/UploadImgs';
import { FreeImgs } from '@/image/FreeImgs';
import { BiX } from "react-icons/bi";
import { ImgEdit } from '@/editerMenu/ImgEdit';
import { ImgPreview } from '@/image/ImgPreview';
// import { isMobile } from "react-device-detect";
// import { useAutoSave } from '@/hooks/useAutoSave';

import { useRecoilState, useSetRecoilState } from 'recoil'
import { saveStatusAtom, blockImgStatesAtom, partImgStatesAtom, openSidebarAtom } from '@/providers/Atoms';


export const ImgGlobalSetting = React.memo(({ type }) => {
  
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  const [ blockImgStates, setBlockImgStates ] = useRecoilState(blockImgStatesAtom)
  const [ partImgStates, setPartImgStates ] = useRecoilState(partImgStatesAtom)
  
  let obj;
  let setObj;
  
  if (type == "part") {
    obj = partImgStates[0]
    setObj = partImgStates[1]
  } else if (type == "block") {
    obj = blockImgStates[0]
    setObj = blockImgStates[1]
  }

  // console.log("画像設定サイドバー", obj);
  
  const setStates = (newObj) => {
    setObj(newObj);
    
    if (type == "part") {
      setPartImgStates([ newObj, setObj ]);
    } else if (type == "block") {
      setBlockImgStates([ newObj, setObj ]);
    }
  } 
  
  const { client } = useContext(StateContext);
  const setSaveStatus = useSetRecoilState(saveStatusAtom);

  const [ imgType, setImgType ] = useState("upload");
  
  const [ show, setShow ] = useState(false);
  const showToggle = ()=> {
    setShow((prev)=> !prev);
  };

  const [ spSwitch, setSpSwitch ] = useState("BASE");


  const InputChange = event => {
    const { name, value } = event.target;
    console.log("選択");
    // setObj((prev)=> ({ ...prev, [name]: value}));
    const newObj = { ...obj, [name]: value};
    setStates(newObj);
  };
  
  const Img_sChange = event => {
    const { name, value } = event.target;
    // setObj((prev)=> ({ ...prev, img_s: value}));
    const newObj = { ...obj, img_s: value};
    setStates(newObj);
  };
  
  
  // 保存
  const update = () => {
    client.patch(`/api/v1/imgs/${obj.id}`, obj)
    .then(resp => {
      if(resp.data.id !== obj.id ) {
        setObj(resp.data);
        console.log("セット");
      }
      setSaveStatus(false);
      console.log("画像の保存完了");
    })
    .catch(e => {
      console.log(e);
    });
  };
  

  // refをtrueで初期化。
  const Ref = useRef("初回");
  // オートセーブ
  useEffect(() => {
    if (Ref.current === "初回") {
      Ref.current = true;
      console.log("初回スキップ");
      return;
    }
    
    if (Ref.current) {
      clearTimeout(Ref.current);
      setSaveStatus(true);
      console.log("画像自動保存待ち");
    }
    
    Ref.current = setTimeout(update, 3000); 
  }, [obj]);
  
  
  // パソコン時に切り替え
  const PcSwitchimg = ()=> {
    setSpSwitch("BASE")
  };
  // スマホ時に切り替え
  const SpSwitchimg = ()=> {
    setSpSwitch("SP")
  };
  

  // 閉じるボタン
  const closeHandler = ()=> {
    // setImgStates([]);
    setOpenSidebar("");
  };

  
  // クリア
  const Clear = (column) => {
    // setObj((prev)=> ({ ...prev, [column]: null}));
    const newObj = { ...obj, [column]: null };
    setStates(newObj);
  }
  
  
// <div className="cover" onClick={CloseHandler}/>
  return (
    <>
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            { type == "part" ? "パーツ画像" : "ブロック背景画像"}
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
        </div>

        <div className="items_box">
          
          { type == "part" &&
            <div className="tab_menu" style={{marginBottom: "10px"}}>
              <button 
                className={`tab_menu_btn ${spSwitch == "BASE" && "active"}`}
                onClick={PcSwitchimg}
              >通常設定</button> 
              <button
                className={`tab_menu_btn ${spSwitch == "SP" && "active"}`}
                onClick={SpSwitchimg}
              >スマホ時の設定</button>
            </div>
          }
          
          { type == "part" &&
            <>
              {spSwitch == "BASE"
                ? <label>デフォルトで表示される画像／動画</label>
                : <label>スマホ時に表示される画像／動画</label>
              }
            </>
          }

          <div className="img_preview">
            { spSwitch == "BASE"
              ?
                <>
                  { obj.img
                    ?
                      <>
                        <ImgPreview obj_img={obj.img}/>
                        {/* 
                        <textarea
                          className="input"
                          type="text"
                          name="img"
                          value={obj.img || ""} 
                          onChange={InputChange}
                          placeholder="画像はありません。"
                        />
                        */}
                        <button className="clear_btn mb_1" onClick={()=>Clear("img")}>画像をクリア</button>
                      </>
                    : <div className="color_red mb_2">画像が未設定です。</div>
                  }
                </>
              :
                <>
                  { obj.img_s
                    ?
                      <>
                        <ImgPreview obj_img={obj.img_s}/>
                        {/* 
                        <textarea
                          className="input"
                          type="text"
                          name="img_s"
                          value={obj.img_s || ""} 
                          onChange={InputChange}
                          placeholder="画像はありません。"
                        />
                        */}
                        <button className="clear_btn mb_1" onClick={()=>Clear("img_s")}>画像をクリア</button>
                      </>
                    : <div className="color_red mb_2">スマホ時の画像設定がない場合、<br/>【通常設定】の画像が表示されます。</div>
                  }
                </>
            }
          </div>
          
          {obj.img &&
            <button className="btn_line w_100 mb_2" onClick={showToggle}>{show ? "編集を閉じる" : "画像の編集"}</button>
          }
          
          { show && 
            <ImgEdit
              obj={obj}
              setObj={setObj}
              type={type}
              InputChange={InputChange}
              Clear={Clear}
              setStates={setStates}
            />
          }
          
          { !show && 
            <>
              {obj.img &&
                <>
                  <label>画像の説明（alt）
                    <div className="btn_clear" type="button" onClick={()=>Clear("alt")}>クリア</div>
                  </label>
                  <input 
                    className="input"
                    type="text"
                    name="alt"
                    value={obj.alt || ""} 
                    onChange={InputChange}
                    placeholder="画像の説明文を入力..."
                  />
                </>
              }
              
              {/*
              <label>写真
                <div className="btn_clear" type="button" onClick={()=>Clear("img")}>クリア</div>
              </label>
              */}
              
              <div className="tab_menu">
                <button 
                  className={`tab_menu_btn ${imgType == "upload" && "active"}`}
                  onClick={()=>setImgType("upload")}
                >アップロード</button> 
                <button
                  className={`tab_menu_btn ${imgType == "free" && "active"}`}
                  onClick={()=>setImgType("free")}
                >フリー</button>
              </div>
    
              <div className="select_type">
                { spSwitch == "BASE"
                  ?
                    <>
                      {imgType == "free" && 
                        <FreeImgs obj_img={obj.img} InputChange={InputChange}/>
                      }
                      {imgType == "upload" &&
                        <UploadImgs obj_img={obj.img} InputChange={InputChange}/>
                      }
                    </>
                  :
                    <>
                      {imgType == "free" && 
                        <FreeImgs obj_img={obj.img_s} InputChange={Img_sChange}/>
                      }
                      {imgType == "upload" &&
                        <UploadImgs obj_img={obj.img_s} InputChange={Img_sChange}/>
                      }
                    </>
                }
              </div>
            </>
          }
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn">閉じる</button>
        </div>
          
      </div>
    </>
  );

});