import React, { useState, createContext } from 'react'
import axios from "axios";


export const StateContext = createContext({});
export const StateProvider = ({ children }) => {

  const [ currentUser, setCurrentUser ] = useState("");
  const [ s3key, setS3key ] = useState("");
  const [ stripekey, setStripekey ] = useState("");
  const [ token, setToken ] = useState("");
  
  const client = axios.create({
    headers: {
      'x-csrf-token': token,
    },
  })
  // console.log("トークン" ,token)

  const states = {
    currentUser, setCurrentUser,
    s3key, setS3key,
    stripekey, setStripekey,
    token, setToken,
    client,
  };
  
  return (
    <StateContext.Provider value={ states }>
      {children}
    </StateContext.Provider>
  );

};

// ログイン検証
// export const LoginContext = createContext({});
// export const LoginProvider = ({ children }) => {
  
//   const [ isLogin, setIsLogin ] = useState(false);
//   const loginState = {
//     isLogin,
//     setIsLogin,
//   };
  
//   return (
//     <LoginContext.Provider value={ loginState }>
//       {children}
//     </LoginContext.Provider>
//   );

// };


// ブロックたち
// export const BlocksContext = createContext({});
// export const BlocksProvider = ({ children }) => {
  
//   const [blocks, setBlocks] = useState([]);
//   const blocksState = {
//     blocks,
//     setBlocks,
//   };
  
//   return (
//     <BlocksContext.Provider value={blocksState}>
//       {children}
//     </BlocksContext.Provider>
//   );

// };

// パーツたち
// export const PartsContext = createContext({});
// export const PartsProvider = ({ children }) => {
  
//   const [parts, setParts] = useState([]);
//   const partsState = { 
//     parts, 
//     setParts
//   };
  
//   return (
//     <PartsContext.Provider value={partsState}>
//       {children}
//     </PartsContext.Provider>
//   );

// };

// // 各パーツ
// export const PartContext = createContext({});
// export const PartProvider = ({ children }) => {
  
//   const [part, setPart] = useState([]);
//   const partState = { 
//     part, 
//     setPart
//   };
  
//   return (
//     <PartContext.Provider value={partState}>
//       {children}
//     </PartContext.Provider>
//   );

// };


// 選択したパーツ
// export const PickedPartContext = createContext({});
// export const PickedPartProvider = ({ children }) => {
  
//   const [ pickedPart, setPickedPart ] = useState("");
//   const pickedState = { 
//     pickedPart, setPickedPart
//   };
  
//   return (
//     <PickedPartContext.Provider value={pickedState}>
//       {children}
//     </PickedPartContext.Provider>
//   );

// };


// 画像リスト
// export const ImagesContext = createContext({});
// export const ImagesProvider = ({ children }) => {
  
//   const [ images, setImages ] = useState([]);
//   const imagesState = { 
//     images, setImages
//   };
  
//   return (
//     <ImagesContext.Provider value={imagesState}>
//       {children}
//     </ImagesContext.Provider>
//   );
// };


// export const SaveDataContext = createContext({});
// export const SaveDataProvider = ({ children }) => {
  
//   const [ saveData, setSaveData ] = useState([]);
//   const saveDataState = { 
//     saveData, setSaveData
//   };
  
//   return (
//     <SaveDataContext.Provider value={saveDataState}>
//       {children}
//     </SaveDataContext.Provider>
//   );
// };

// export const MenuDataContext = createContext({});
// export const MenuDataProvider = ({ children }) => {
  
//   // const [ menuData, setMenuData ] = useState({block: "", part: ""});
//   const [ menuData, setMenuData ] = useState([]);
//   const menuDataState = { 
//     menuData, setMenuData
//   };
  
//   return (
//     <MenuDataContext.Provider value={menuDataState}>
//       {children}
//     </MenuDataContext.Provider>
//   );
// };