import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { TopicPath } from '@/app/TopicPath';
import { PaymentShow } from '@/payment/PaymentShow';
import { useDateTime } from '@/hooks/useDateTime';
import { Invoices } from '@/user/Invoices';


export const Billing = () => {
  
  const { account } = useParams();
  // console.log(account);
  const { client, currentUser } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const [ payments, setPayments ] = useState([]);
  // const [ total, setTotal ] = useState([]);
  const [ invoices, setInvoices ] = useState([]);
  const [ open, setOpen ] = useState(false);
  
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  useEffect(() => {
    client.get(`/api/v1/payments/billing?account=${account}`)
    .then(resp => {
      setPayments(resp.data.payments);
      setInvoices(resp.data.invoice_list.data);
      // setTotal(resp.data.total);
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  
  // console.log(payments);

  return (
    <>
      <TopicPath topic="お支払い">
        <Link to={`/account/${currentUser.account}`}>アカウント</Link>
      </TopicPath>
      
      <h1 className="H1">お支払い情報</h1>
      
      { payments.length > 0
        ?
          <div className="billing">
            <div className="item">
              <div className="item_name">項目</div>
              <div className="item_status">ステータス</div>
              <div className="item_day">決済日</div>
              <div className="item_price">金額</div>
              <div className="item_invoice">請求書</div>
            </div>
          
            {payments.map((payment) => {
              return (
                  <div className="item" key={payment.id}>
                    <div className="item_name">{payment.addon_name || "NO NAME" }</div>
                    <div className="item_status">{payment.status || ""}</div>
                    <div className="item_day">{useDateTime(payment.payment_date) ||""}</div>
                    <div className="item_price">{payment.price}円</div>
                    <div className="item_invoice">
                      {payment.invoice_url &&
                        <a 
                          className="aside_right"
                          style={{fontSize: "12px"}}
                          href={payment.invoice_url} 
                          target="_blank" rel="noopener noreferrer"
                        >請求書／領収書
                        </a>
                      }
                    </div>
    
                    {currentUser.admin &&
                      <PaymentShow payment={payment}/>
                    }
                  </div>
              );
            })}
            
            {/*
            <div className="item">
              <div className="item_name"></div>
              <div className="item_price">合計：{total}円</div>
            </div>
            */}
          </div>
        : <div>お支払いはありません。</div>
      }
      
      {currentUser.admin &&
        <>
          <button className="" onClick={()=>setOpen(true)}>インボイス取得</button>
          { open && <Invoices invoices={invoices}/>}
        </>
      }
      
    </>
  );
};

// <div>{payment.subscription_id}<br/>
//               {payment.price_id}<br/>
//               {payment.payment_intent_id}<br/>
//               {payment.invoice_id}</div>