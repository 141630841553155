import React from "react"
import { Block } from '@/block/Block';


export const Normal = React.memo(({ section_id, blocksState }) => {
  
  const { blocks } = blocksState
  
  // console.log("blocks", blocks);
  return (
    <>
      {blocks.map((block, index) => {
        if(index == 0) {
          return (
            <Block 
              block_obj={block} 
              blocksState={blocksState} 
              section_id={section_id}
              index={index} 
              key={block.id}
            />
          );
        }
      })}
    </>
  );
});

// <PartsProvider key={index}>
//               <Block block_obj={block} blocksState={blocksState} index={index}/>
//             </PartsProvider>