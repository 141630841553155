import React, { useState } from 'react';
// import { PartsProvider } from '@/providers/StateProviders';
import { Block } from '@/block/Block';

export const Switch = React.memo(({ section_id, blocksState }) => {
  
  const { blocks } = blocksState
  const [ active, setActive ] = useState(true);
  // const [ display, setDisplay ] = useState("停止する");
  

  const StopHandler = ()=> {
    setActive((prev)=> !prev);
    // const newDisplay = active ? "再生する" : "停止する"
    // setDisplay(newDisplay);
  };
  
  const Style = {
    animationPlayState: active ? "running" : "paused",
    // position: active == false && "static",
    opacity: active == false && 1,
  };
  
  return (
    <>
      <button className="switch_stop_btn" onClick={StopHandler}>
        {active ? "編集する" : "編集終了"}
      </button>
      
      <div className="switch_header">
        {blocks.map((block, index) => {
          return (
            <div className="switch_wrap" style={Style} key={block.id}>
              <Block 
                block_obj={block} 
                blocksState={blocksState}
                section_id={section_id} 
                index={index}
              />
            </div>
          );
        })}
      </div>
    </>
  );
});
