import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Layout } from '@/page/Layout';
import { Nav } from '@/product/Nav';
import { ShareSections } from '@/sharing/ShareSections';


export const HeaderSections = React.memo(() => {
  
  const { productId } = useParams();

  return (
    <>
      <Layout type="sharing">
      
        <div className="wrapper">
          <div className="main_layer"></div>
          <Nav/>
          
          <div className="headline_field">
            <h1 className="H2 color_white">ヘッダーの編集</h1>
            <Link to={`/product/${productId}/footer/edit`}>
              <button className="btn_dark">フッター編集へ</button>
            </Link>
          </div>
          
          <div className="color_white mb_4">このヘッダーはすべてのページの上部に表示されます。</div>
        </div>
        
        <ShareSections sharing="header"/>
        
      </Layout>
      
      <style>
        {`
          .navbar .icon{
            display: none;
          }
          .main {
            width: calc(100% - 50px);
          }
          .main_area {
            max-width: 100%;
            padding: 0;
          }
          .sidebar {
            width: 50px;
          }
          @media screen and (max-width: 820px) {
            body {
              padding-bottom: 50px;
            }
            .main {
              width: 100%;
            }
            .sidebar {
              width: 100%;
            }
            .footer {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
});

// <Layout saveAll={saveAll} type="sharing">

                // <Sections sectionsState={sectionsState}/>