import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { 
  BiHome, BiBell, BiCustomize, BiHelpCircle, 
  BiMessageDetail, BiEnvelope, BiIdCard, BiBook, BiX, BiSelectMultiple
} from "react-icons/bi";

export const Sidebar = React.memo(({ menuOpen, setMenuOpen }) => {
  
  const {currentUser} = useContext(StateContext);
  
  const CloseHandler = ()=> {
    setMenuOpen(false);
  };

  return (
    <>
      <div className={`sidebar ${menuOpen && "active"}`}>
      
        <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          
        <ul className="sidebar_list">
        
          <li onClick={CloseHandler}>
            <NavLink to="/home" className="row">
              <div className="icon"><BiHome/></div>
              <div className="text">ホーム</div>
            </NavLink>
          </li>
          <li onClick={CloseHandler}>
            <NavLink to={`/account/${currentUser.account}`} className="row">
              <div className="icon"><BiIdCard/></div>
              <div className="text">アカウント設定</div>
            </NavLink>
          </li>

          <li onClick={CloseHandler}>
            <NavLink to="/template" className="row">
              <div className="icon"><BiCustomize/></div>
              <div className="text">テンプレート</div>
            </NavLink>
          </li>
          
          <li onClick={CloseHandler}>
            <NavLink to="/addons" className="row">
              <div className="icon"><BiSelectMultiple/></div>
              <div className="text">料金プラン</div>
            </NavLink>
          </li>
                    
          <li onClick={CloseHandler}>
            <NavLink to="/notices" className="row">
              <div className="icon"><BiBell/></div>
              <div className="text">お知らせ</div>
            </NavLink>
          </li>
          <li onClick={CloseHandler}>
            <a href="https://manual.pstock.jp/" target="_blank" rel="noopener noreferrer" className="row">
              <div className="icon"><BiBook/></div>
              <div className="text">ガイド</div>
            </a>
          </li>
          <li onClick={CloseHandler}>
            <a href="https://manual.pstock.jp/domain-setting" target="_blank" rel="noopener noreferrer" className="row">
              <div className="icon"><BiBook/></div>
              <div className="text">独自ドメイン設定</div>
            </a>
          </li>
          <li onClick={CloseHandler}>
            <NavLink to="/question" className="row">
              <div className="icon"><BiHelpCircle/></div>
              <div className="text">よくある質問</div>
            </NavLink>
          </li>
          <li onClick={CloseHandler}>
            <NavLink to="/contact" className="row">
              <div className="icon"><BiEnvelope/></div>
              <div className="text">お問い合わせ</div>
            </NavLink>
          </li>
          
          <li onClick={CloseHandler}>
            <div className="close">閉じる</div>
          </li>
          
        </ul>
        
      </div>
    </>
  );
});

          

// <li onClick={CloseHandler}>
//   <a href="/" className="row">
//     <div className="icon"><BiCustomize size={25}/></div>
//     <div className="text">新しい機能</div>
//   </a>
// </li>

// <li>
//             <NavLink to="/dashboard" className="row">
//               <div className="icon"><BiNews size={25}/></div>
//               <div className="text">ダッシュボード</div>
//             </NavLink>
//           </li>