import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { BiMenu, BiX } from "react-icons/bi";

export const Nabvar = React.memo(({ menuOpen, setMenuOpen }) => {

  const { currentUser } = useContext(StateContext);
  
  const initial = currentUser.email.substring(0, 1).toUpperCase() || ""

  const OpenToggle = ()=> {
    setMenuOpen((prev)=> !prev);
  };
  


  return (
    <>
      <nav className="navbar">
        
        <Link to="/home" className="logo">
          <img src="/logo_wide_white.png" />
        </Link>
        
        <div className="items">
          
          {currentUser.admin &&
            <a href={`/admin`} className="item">管理</a>
          }
          <Link to={`/account/${currentUser.account}`} className="item">{currentUser.name || "名前を設定する"}</Link>
          
          <div className="drop_btn">
            <div className="icon">
              {currentUser.icon == null 
                ? <>{initial}</>
                : <img src={currentUser.icon} />
              }
            </div>
            
            <div className="drop_box">
              <div className="drop_list" style={{width: "250px"}}>
                <div className="account_name" >
                  {currentUser.name || "NO NAME"}
                  <div className="account_email" >ID／{currentUser.account}</div>
                  <div className="account_email" >{currentUser.email}</div>
                </div>
                
                <Link to={`/account/${currentUser.account}`} className="drop_item">アカウント設定</Link>
                <a href={`/logout`} className="drop_item">ログアウト</a>
                
                {currentUser.admin &&
                  <>
                    <a href={`/admin`} className="drop_item">管理</a>
                    <Link to="/manage" className="drop_item">操作</Link>
                    <Link to="/products" className="drop_item">プロダクト一覧</Link>
                    <Link to="/users" className="drop_item">ユーザー一覧</Link>
                    <Link to="/freeimages" className="drop_item">フリー画像</Link>
                    <Link to="/sendmail" className="drop_item">一斉送信</Link>
                  </>
                }
              </div>
            </div>
          </div>
          
          <div className="nav_menu_btn" onClick={OpenToggle}>
            {menuOpen ? <BiX/> : <BiMenu/>}
          </div>
        </div>
        
      </nav>
    </>
  );
});

// <Link to="/plans" className="item">フリープラン</Link>

// <div className="item" onClick={OpenToggle}>{currentUser.name || "ネーム未設定"}</div>

      // <style>
      //   {`
      //     .sidebar {
      //       left: ${active && "0"};
      //       opacity: ${active && "1"};
      //       z-index: ${active && "1000"};
      //     }
      //   `}
      // </style>