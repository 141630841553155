import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';
import { CardForm } from '@/user/CardForm';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';


export const CardSetting = () => {
  
   const navigate = useNavigate();
  
  const { currentUser, stripekey } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const stripePromise = loadStripe(stripekey);
  // const stripePromise = loadStripe('pk_test_51N4NDkAnaRcsdqb70WLTWN4lXEkO6zMeNXK1g5i1kkW9MKTT3kNzds4auHhosOeZgyZkcH1HJcDjcp3OjgczqANF00GG0YYWOp');
  
  if (currentUser.customer_id == null) {
    navigate(`/account/${currentUser.account}`);
    setToastObj({message: "お支払い情報がありません。", color: "#ffedab"});
  }

  const appearance = {
    theme: 'stripe',
  };
  
  const options = {
    // mode: 'subscription',
    // amount: 990,
    // currency: "jpy",
    // clientSecret,
    appearance,
  };

  return (
    <>
    <Elements options={options} stripe={stripePromise}>
      <CardForm/>
    </Elements>
    </>
  );
}