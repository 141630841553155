import React from 'react';
import { useParams, Link, NavLink } from 'react-router-dom';
import { BiHome, BiImages, BiSpreadsheet, BiCog, BiGridAlt, BiNews, BiSelectMultiple } from "react-icons/bi";


export const Nav = React.memo(({ }) => {
  
  const { productId } = useParams();

  return (
    <div className="nav_menu">
      <Link to="/home"><BiHome/>ホーム</Link>
      <NavLink activeclassname="active" to={`/product/${productId}/dashboard`}><BiNews/>ダッシュボード</NavLink>
      <NavLink to={`/product/${productId}/menus`}><BiSpreadsheet/>メニュー</NavLink>
      <NavLink to={`/product/${productId}/images`}><BiImages/>画像</NavLink>
      <NavLink to={`/product/${productId}/blocks`}><BiGridAlt/>ブロック</NavLink>
      <NavLink to={`/product/${productId}/addons`}><BiSelectMultiple />プラン</NavLink>
      <NavLink to={`/product/${productId}/setting`}><BiCog/>設定</NavLink>
    </div>
  );
});