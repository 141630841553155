import React, { useState, useContext } from 'react';
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { StateContext} from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { Section } from '@/section/Section';
// import { NewSection } from '@/section/NewSection';
// import { BiPlus } from "react-icons/bi";


export const Sections = React.memo(({ page_id, sections, setSections, sharing }) => {
  
  // console.log("セクションズ", "レンダ");
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const sectionsState = { 
    sections, setSections
  };
  
  // const [ open, setOpen ] = useState("");
  // const OpenHandler = (sectionId)=> {
  //   setOpen(sectionId);
  // };
  
  
  //並び順を保存
  const OrderUpdate = (objs) => {
    setSections(objs);
    
    client.patch(`/api/v1/sections/order`, {sections: objs})
    .then(resp => {
      console.log("並び替え完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
    });
  };
  
  
  //並び順をUP
  const orderUp = (index) => {
    const objs = [...sections]
    const removed = objs.splice(index, 1);
    objs.splice(index-1, 0, removed[0]);
    OrderUpdate(objs);
  };
  
  const orderDown = (index) => {
    const objs = [...sections]
    const removed = objs.splice(index, 1);
    objs.splice(index+1, 0, removed[0]);
    OrderUpdate(objs);
  };
  
  

  return (
    <>
      {sections.map((section, index) => {
        return (
          <section className="section" key={section.id}>
            <Section 
              section_id={section.id}
              sectionsState={sectionsState}
              orderUp={orderUp}
              orderDown={orderDown}
              sharing={sharing}
              index={index}
              key={section.id}
            />
            
            {/*
            {sharing == null &&
              <>
                <button className="btn_section_add" onClick={()=>OpenHandler(section.id)}>
                  <span><BiPlus/>ブロックを追加</span>
                </button>
                { open == section.id &&
                  <NewSection 
                    page_id={page_id} 
                    sections={sections} 
                    setSections={setSections} 
                    sharing={sharing}
                    index={index}
                    key={`NewSection-${section.id}`}
                    setOpen={setOpen}
                  />
                }
              </>
            }
            */}

          </section>
        );
      })}
    </>
  );
});




// export const Sections = React.memo(({ page_id, sections, setSections, sharing }) => {
  
//   // console.log("セクションズ", "レンダ");
  
//   const { client } = useContext(StateContext);
//   const { setToastObj } = useContext(ToastContext);
//   // const setSaveData = useSetRecoilState(saveDataAtom)
//   const setClickPart = useSetRecoilState(clickPartAtom)
//   const setClickBlock = useSetRecoilState(clickBlockAtom)
  
//   // const { sections } = sectionsState;
//   // const [ sections, setSections ] = useState(sections_objs);
//   const sectionsState = { 
//     sections, setSections
//   };
  
//   //並び順を保存
//   const OrderUpdate = (objs) => {
//     client.patch(`/api/v1/sections/order`, {sections: objs})
//     .then(resp => {
//       // setToastObj({message: "並び替え完了", color: "#D1E7DD"});

//       // setSaveData((prev)=> ({ 
//       // ...prev, 
//       //   sections: resp.data.new_sections
//       // }));
//       console.log("並び替え完了");
//     })
//     .catch(e => {
//       setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
//     });
//   };

//   // 並び替え処置
//   const reorder = (list, startIndex, endIndex) => {
//     const objs = [...list]
//     const removed = objs.splice(startIndex, 1); //ドラッグ開始要素の削除
//     objs.splice(endIndex, 0, removed[0]); //ドロップした箇所に挿入
//     setSections(objs); //並び替え後をセット
    
//     //並び順を保存
//     OrderUpdate(objs);
//   };
  
//   // 並び替え後
//   const onDragEnd = (result) => {
//     if (!result.destination) {
//       return;
//     }
    
//     reorder(sections, result.source.index, result.destination.index);
//   };
  
//   // 並び替えスタート時
//   const onDragStart = () => {
//     setClickPart(0);
//     setClickBlock(0);
//   };
  
  
//   const [ open, setOpen ] = useState("");
//   const OpenHandler = (sectionId)=> {
//     setOpen(sectionId);
//   };

//   return (
//     <>
//       <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
//         <Droppable droppableId="section">
//           {(provided, snapshot) => (
//             <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>

//                 {sections.map((section, index) => {
//                     return (

//                       <Draggable key={section.id} draggableId={`SID-${section.id}`} index={index}>
//                         {(provided, snapshot) => (
//                           <div
//                             ref={provided.innerRef}{...provided.draggableProps}{...provided.dragHandleProps}
//                             style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
//                             className="section_draggable"
//                           >
                          
//                             <section className="section">
//                               <Section 
//                                 section_id={section.id}
//                                 sectionsState={sectionsState}
//                                 index={index}
//                                 key={section.id}
//                               />
                              
//                               {sharing == null &&
//                                 <>
//                                   <button className="btn_section_add" onClick={()=>OpenHandler(section.id)}>
//                                     <span><BiPlus/>ブロックを追加</span>
//                                   </button>
//                                   { open == section.id &&
//                                     <NewSection 
//                                       page_id={page_id} 
//                                       sections={sections} 
//                                       setSections={setSections} 
//                                       sharing={sharing}
//                                       index={index}
//                                       key={`NewSection-${section.id}`}
//                                       setOpen={setOpen}
//                                     />
//                                   }
//                                 </>
//                               }
//                             </section>
                            
//                           </div>
//                         )}
//                       </Draggable>
                      
//                     );
//                 })}
//                 {provided.placeholder}
                
//             </div>
//           )}
//         </Droppable>
//       </DragDropContext>
//     </>
//   );
// });

// const getListStyle = (isDraggingOver) => ({
//   background: isDraggingOver ? "#00FF00" : "",
//   // padding: grid,
//   // width: 500,
//   // flexGrow: 1.0,
// });

// const getItemStyle = (isDragging, draggableStyle) => ({
//   ...draggableStyle, //あらかじめ用意されている。
//   // userSelect: "none",
//   // margin: `0 0 ${grid} 0`,
//   background: isDragging ? "#eee" : "",
//   // border: isDragging ? 'solid 0px #fff' : "", 
//   boxShadow: isDragging ? "0px 2px 5px 5px rgba(0,0,0,0.2)" : "",
//   cursor: "pointer",
// });