import React from 'react';
import { BiPlus, BiMinus } from "react-icons/bi";

export const RangeSpin = React.memo(({ obj, setObj, InputChange, column, objValue, max, min, setStates }) => {
  
  const spinUp = (name, value) => {
    const newObj = { ...obj, [name]: ++value};
    setStates(newObj);
  };
  
  const spinDown = (name, value) => {
    const newObj = { ...obj, [name]: --value};
    setStates(newObj);
  };
  
  
  return (
    <div className="spin_btn range_spin">
      <span onClick={()=>spinDown(column, objValue)}><BiMinus/></span>
      <input 
      　type="range"
      　id={column}
      　name={column}
      　value={objValue || 0} 
      　onChange={InputChange}
      　max={max || 100}
      　min={min || 0}
      />
      <span onClick={()=>spinUp(column, objValue)}><BiPlus/></span>
    </div>
  );
});