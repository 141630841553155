import React from 'react';
import { Link } from 'react-router-dom';
import { Url } from '@/app/Url';
import { useRecoilValue } from 'recoil'
import { productAtom, optionsAtom } from '@/providers/Atoms';

// export const Subject = React.memo(({ product, options }) => {
export const Subject = React.memo(() => {
  
  // console.log("サブジェクトレンダ");
  
  const product = useRecoilValue(productAtom)
  const options = useRecoilValue(optionsAtom)
  
  // const objs = options || []
  
  return (
    <div className="product_badge_box">
      <div className="product_badge">ID：{product.subdomain || ""}</div>
      
      {product.ssl &&
        <div className="product_badge">SSL</div>
      }

      <div className="product_badge">ドメイン：<Url/></div>
      
      <div className="product_badge">
        {product.plan || "フリープラン"}：
        <Link to={`/product/${product.subdomain || ""}/addons`}>変更</Link>
      </div>
      
      {options.map((option) => {
        return (
          <div className="badge" key={option.id}>{option.addon_name}</div>
        );
      })}
    </div>
  );
});