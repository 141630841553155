import React, { useState, useEffect, useRef } from "react"

import { Headline_A } from '@/part/type/Headline_A';
// import { Headline_B } from '@/part/type/Headline_B';
import { Box_A } from '@/part/type/Box_A';
import { Card_A } from '@/part/type/Card_A';
import { Point_A } from '@/part/type/Point_A';
import { Image_A } from '@/part/type/Image_A';
import { Accordion_A } from '@/part/type/Accordion_A';
// import { Contact_A } from '@/part/type/Contact_A';
import { Button_A } from '@/part/type/Button_A';
import { Navbar_A } from '@/part/type/Navbar_A';
// import { Nabvar_B } from '@/part/type/Nabvar_B';
import { Navbtn_A } from '@/part/type/Navbtn_A';
import { MainVisual_A } from '@/part/type/MainVisual_A';
// import { MainVisual_B } from '@/part/type/MainVisual_B';
import { List_A } from '@/part/type/List_A';
import { Footer_A } from '@/part/type/Footer_A';
import { Text_A } from '@/part/type/Text_A';
import { Link_A } from '@/part/type/Link_A';
import { Comment_A } from '@/part/type/Comment_A';
import { Price_A } from '@/part/type/Price_A';
import { Profile_A } from '@/part/type/Profile_A';
import { Rating_A } from '@/part/type/Rating_A';
import { Notice_A } from '@/part/type/Notice_A';
import { Item_A } from '@/part/type/Item_A';
import { Step_A } from '@/part/type/Step_A';
import { Html_A } from '@/part/type/Html_A';
import { HtmlTable_A } from '@/part/type/HtmlTable_A';
import { Sns_A } from '@/part/type/Sns_A';
import { Sitemap_A } from '@/part/type/Sitemap_A';
import { NoPart } from '@/part/type/NoPart';

// import { TextSetting } from '@/text/TextSetting';
// import { ImgSetting } from '@/image/ImgSetting';

import { useSetRecoilState } from 'recoil'
import { textStatesAtom, openSidebarAtom } from '@/providers/Atoms';

export const PartComponents = React.memo(({partState, InputChange, texts, 
  imgs, openState, Clear, client}) => {

  const { part, setPart } = partState
  // const { imgs } = imgsState
  // const { open, setOpen } = openState
  // const [ textType, setTextType ] = useState("");
  
  const setTextStates = useSetRecoilState(textStatesAtom);
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  
  
  // console.log("img", imgs);
  
  // 各テキスト
  // const [ title, setTitle ] = useState([]);
  // const [ subtitle, setSubtitle ] = useState([]);
  // const [ content, setContent ] = useState([]);
  
  // useEffect(() => {
  //   texts.forEach((obj) => { 
  //     if (obj.text_type === "title") {
  //       setTitle(obj);
  //     } else if (obj.text_type === "subtitle") {
  //       setSubtitle(obj);
  //     } else if (obj.text_type === "content") {
  //       setContent(obj);
  //     }
  //   });
  // }, []);
  
  const [ title, setTitle ] = useState(
    texts.find((obj) => obj.text_type === "title") || { part_id: part.id, text_type: "title"}
  );
  
  const [ subtitle, setSubtitle ] = useState(
    texts.find((obj) => obj.text_type === "subtitle") || { part_id: part.id, text_type: "subtitle"}
  );
  
  const [ content, setContent ] = useState(
    texts.find((obj) => obj.text_type === "content") || { part_id: part.id, text_type: "content"}
  );
  
  const textStates = { title, setTitle, subtitle, setSubtitle, content, setContent }
  
  // useEffect(() => {
  //   setTextState([ title, setTitle ]);
  //   console.log("変更");
  // }, [title]);
  
  
  // 新規作成したテキストをセット
  const textSet = (obj) => {
    // console.log("text_type", obj.text_type);
    
    if (obj.text_type == "title") {
      setTitle(obj);
      setTextStates([ obj, setTitle ]);
      
    } else if (obj.text_type == "subtitle") {
      setSubtitle(obj);
      setTextStates([ obj, setSubtitle ]);
      
    } else if (obj.text_type == "content") {
      setContent(obj);
      setTextStates([ obj, setContent ]);
    }
  }
  
  // inputをクリックすると、テキストを新規作成
  const textCreate = (obj) => {
    if (obj?.id) {
      console.log("テキスト作成済み");
    } else {
      client.post(`/api/v1/texts`, obj)
      .then(resp => {
        textSet(resp.data);
        console.log("テキスト新規作成");
      })
      .catch(e => {
        console.log(e.response.data);
      });
    }
  };
   
  
  const titleHandler = ()=> {
    // setTextType("title");
    // setOpen("Text");
    textCreate(title)
    setTextStates([ title, setTitle ]);
    setOpenSidebar("partText")
  };
  
  const subtitleHandler = ()=> {
    // setTextType("subtitle");
    // setOpen("Text");
    textCreate(subtitle)
    setTextStates([ subtitle, setSubtitle ]);
    setOpenSidebar("partText")
  };
  
  const contentHandler = ()=> {
    // setTextType("content");
    // setOpen("Text");
    textCreate(content)
    setTextStates([ content, setContent ]);
    setOpenSidebar("partText")
  };
  
  const textHandlers = { titleHandler, subtitleHandler, contentHandler }
  


  const SelectPart = () => {
    
    if(part == null) {
      return;
    }

    switch(part.component_name) {
      case "MainVisual_A":
        return <MainVisual_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Navbar_A":
        return <Navbar_A part={part} InputChange={InputChange} openState={openState} Clear={Clear}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Headline_A":
        return <Headline_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Button_A":
        return <Button_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Footer_A":
        return <Footer_A part={part} InputChange={InputChange} openState={openState} Clear={Clear}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Point_A":
        return <Point_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
      case "Text_A":
        return <Text_A part={part} setPart={setPart} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Box_A":
        return <Box_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Card_A":
        return <Card_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Image_A":
        return <Image_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Accordion_A":
        return <Accordion_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "List_A":
        return <List_A part={part} InputChange={InputChange} Clear={Clear}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Comment_A":
        return <Comment_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Price_A":
        return <Price_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Profile_A":
        return <Profile_A part={part} imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Rating_A":
        return <Rating_A part={part} setPart={setPart}
        imgs={imgs} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Notice_A":
        return <Notice_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Item_A":
        return <Item_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Step_A":
        return <Step_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "HtmlTable_A":
        return <HtmlTable_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Html_A":
        return <Html_A part={part} InputChange={InputChange} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Link_A":
        return <Link_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break;
      case "Sns_A":
        return <Sns_A part={part}/>;
        break;
      case "Navbtn_A":
        return <Navbtn_A part={part} textStates={textStates} textHandlers={textHandlers}/>;
        break
      case "Sitemap_A":
        return <Sitemap_A part={part}/>;
        break;
      default:
        return <NoPart/>;
    };
  };

// <NoPart setOpen={setOpen}/>;

  return (
    <>
      {SelectPart()}
    </>
  );
});

// { open == "Text" &&
//   <TextSetting
//     obj={
//       textType == "title" && title ||
//       textType == "subtitle" && subtitle ||
//       textType == "content" && content
//     }
//     setObj={
//       textType == "title" && setTitle ||
//       textType == "subtitle" && setSubtitle ||
//       textType == "content" && setContent
//     }
//     setOpen={setOpen}
//   />
// }