import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';

// import { Item } from '@/menu/Item';
import { Items } from '@/menu/Items';
// import { NewItem } from '@/menu/NewItem';
import { BiDotsVerticalRounded, BiAddToQueue, BiTrash } from "react-icons/bi";


export const Menu = ({ menu_obj, menusState, index }) => {

  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const [ menu, setMenu ] = useState(menu_obj);
  const { menus, setMenus } = menusState
  
  const [ items, setItems ] = useState([]);
  const itemsState = {
    items, setItems
  }
  
  useEffect(() => {
    client.get(`/api/v1/menus/${menu_obj.id}`)
    .then(resp => {
      setMenu(resp.data.menu);
      setItems(resp.data.items);
    })
    // setMenu(menu_obj);
  }, [menus]);
  
  // console.log(`メニュー:${menu_obj.id}`);
  
  const InputChange = event => {
    const { name, value } = event.target;
    setMenu({ ...menu, [name]: value });
  };
  
  // メニュー更新
  const updateMenu = () => {
    client.patch(`/api/v1/menus/${menu.id}`, menu)
    .then(resp => {
      CloseHandler();
      setToastObj({message: "保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    });
  };
  
  // 削除
  const deleteMenu = () => {
    const sure = window.confirm(`このメニュー「${menu.name}」を本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/menus/${menu.id}`)
      .then(resp => {
        const newMenus = [...menus];
        newMenus.splice(index, 1);
        setMenus(newMenus);

        setToastObj({message: "削除しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  // 複製
  const copyMenu = () => {
    client.post(`/api/v1/menus/copy/${menu.id}`)
    .then(resp => {
      setMenus([resp.data, ...menus]);
      setToastObj({message: "複製しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };
  
  
  const [ active, setActive ] = useState(false);
  const [ display, setDisplay ] = useState("編集");
  
  const OpenToggle = ()=> {
    setActive((prev)=> !prev);
    setDisplay(active ? "編集" : "閉じる");
  };
  
  const CloseHandler = ()=> {
    setActive(false);
    setDisplay("編集");
  };


  return (
    <>
      <div className="base_box">
        
        <div className="menu_title_area">
            
            { !active
              ?
               <>
                <div className="menu_title">{menu?.name || "メニュー名"}</div>
                <button onClick={OpenToggle}>{display}</button>
               </>
              :
               <>
                <input 
                  className="input" 
                  type="text" 
                  name="name"
                  value={menu?.name || ''} 
                  onChange={InputChange}
                  placeholder="メニューの名前を入力..."
                />
                <button className="btn" onClick={updateMenu}>保存</button>
               </>
            }

            
            <div className="drop_btn">
              <button className="btn_icon"><BiDotsVerticalRounded/></button>
              <div className="drop_box">
                <div className="drop_list">
                  <div className="drop_item" onClick={copyMenu}><span><BiAddToQueue/>複製</span></div>
                  <div className="drop_item" onClick={deleteMenu}><span><BiTrash/>削除</span></div>
                </div>
              </div>
            </div>
    
        </div>

        <Items menu_obj={menu} itemsState={itemsState} key={menu.id}/>
      </div>
    </>
  );
};

// { active &&
//   <div className="menu_edit">
//     <label>メニュー名</label>
//     <input 
//       className="input" 
//       type="text" 
//       name="name"
//       value={menu?.name || ''} 
//       onChange={InputChange}
//       placeholder="メニューの名前を入力..."
//     />
    
//     <div style={{textAlign: "right"}}>
//       <button className="btn_text" onClick={deleteMenu}>削除</button>
//       <button className="btn" onClick={updateMenu}>保存</button>
//     </div>
//   </div>
// }