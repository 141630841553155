import React, { useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { Modal } from '@/app/Modal';
import { PartTypes } from '@/part/PartTypes';
import { BiPlus } from "react-icons/bi";
import { PartCategory } from '@/part/PartTypes';
import { PartSelect } from '@//part/PartSelect';
// import { useSetRecoilState } from 'recoil'
// import { saveDataAtom } from '@/providers/Atoms';


export const NewBlock = ({ page_id, blocksState, type }) => {
  
  // console.log("新規ブロックレンダリング");
  
  const { productId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  const { blocks, setBlocks } = blocksState;
  // const { saveData, setSaveData } = useContext(SaveDataContext);
  // const setSaveData = useSetRecoilState(saveDataAtom)

  const new_block_date = {
    subdomain: productId,
    page_id: page_id,
    status: "公開",
    order: Object.keys(blocks).length,
    column: 1,
    sharing: "sharing",
  };
  const [ block, setBlock ] = useState(new_block_date);
  
  // const InputChange = event => {
  //   const { name, value } = event.target;
  //   setBlock({ ...block, [name]: value });
  // };
  
  // const PartTypeChange = event => {
  //   const { name, value } = event.target;
  //   let type = value.split(" ")
  //   setBlock({ ...block, component_name: type[0], class_name: type[1] });
  // };
  
  const PartTypeChange = (value) => {
    let type = value.split(" ")
    setBlock((prev)=> ({ ...prev, component_name: type[0], class_name: type[1]}));
  };
  
  const navigate = useNavigate();

  const AddBlock = () => {
    client.post('/api/v1/blocks', block)
    .then(resp => {
      
      let newBlocks;
      if(type == "sharing") {
        newBlocks = [resp.data.block, ...blocks];
      }else{
        newBlocks = [...blocks, resp.data.block];
      };
      setBlocks(newBlocks);
    
      // const newParts = [ ...saveData.parts, resp.data.part]
      // setSaveData({ ...saveData, blocks: newBlocks , parts: newParts});
      
      // setSaveData((prev)=> ({ 
      // ...prev, blocks: newBlocks, parts: [ ...prev.parts, resp.data.part]
      // }));
      
      navigate(`/product/${productId}/block/${resp.data.block.id}`);
      setToastObj({message: "新しいブロックを作成しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };
  
  const [ show, setShow ] = useState("");
  
  
  return (
    <>
      <Modal btn_name="新しいブロックを作成" btn_class="btn_add">
        <div className="items_box select_type"  name="newBlock">
           {show == ""
             ?
              <>
                {PartCategory.map((category, index) => {
                  return (
                    <button key={index}
                      className={`type_btn ${show == category.type && "active"}`}
                      onClick={()=>setShow(category.type)}
                    >
                      {category.name}
                    </button>
                  );
                })}
              </>
            :
              <>
                <button className="clear_btn mb_3" onClick={()=>setShow("")}>戻る</button>
                <PartSelect 
                  obj={block}
                  show ={show}
                  PartTypeChange={PartTypeChange}
                />
                <div className="w_100">
                  <button className="btn_add mt_2" onClick={AddBlock}>
                    <span><BiPlus/>ブロックを作成</span>
                  </button>
                </div>
              </>
          }
        </div>
      </Modal>
    </>
  );

};


      // <Modal btn_name="新しいブロックを作成">
      
      //   <div className="select_box" name={`block_${block.id}`}>
        
      //     {PartTypes.map((item, index) => {
      //       return (
      //         <label 
      //           className="item" 
      //           key={index}
      //           style={{
      //             border: item.type == `${block.component_name} ${block.class_name}` ? "2px solid blue" : ""
      //           }}
      //         >
      //           <img src={`/${item.img}`} height="100" width="100"/>
      //           <input
      //             type="radio"
      //             name="component_name"
      //             value={item.type}
      //             onChange={PartTypeChange}
      //             checked={item.type == `${block.component_name} ${block.class_name}`}
      //           />
      //           {item.name}
      //         </label>
      //       );
      //     })}
          
      //   </div>

      //   <button className="base_btn" onClick={AddBlock}>
      //     <span><BiPlus/>ブロック作成</span>
      //   </button>
        
      // </Modal>