import React, { useState, useEffect, useContext, useRef } from "react"
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { ColorPicker } from '@/block/ColorPicker';
// import { ImgSelect } from '@/image/ImgSelect';
import { ImgSetting } from '@/image/ImgSetting';
import { BlockSetting } from '@/block/BlockSetting';
import { SpinBtn } from '@/app/SpinBtn';
// import { SaveAll } from '@/page/SaveAll';
import { SaveStatus } from '@/page/SaveStatus';
import { useAutoSave } from '@/hooks/useAutoSave';
// import { Url } from '@/app/Url';
import { RangeSpinSplit } from '@/app/RangeSpinSplit';


import { BiReset, BiChevronLeft, BiImageAlt, BiCog, BiCarousel } from "react-icons/bi";
import { MdFormatColorFill } from "react-icons/md";
import { TbBoxPadding } from "react-icons/tb";

import { useSetRecoilState } from 'recoil'
import { clickSectionAtom, saveStatusAtom } from '@/providers/Atoms';


export const BlockEdit = React.memo(({ blockState, blocksState, imgState, section_id }) => {
  
  // console.log(`ブロック編集メニュー`);
  
  const { productId, blockId } = useParams();
  // const { slug } = useParams();
  
  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const { block, setBlock } = blockState;
  const { img, setImg } = imgState;
  
  // const setSaveData = useSetRecoilState(saveDataAtom);
  const setClickSection = useSetRecoilState(clickSectionAtom);
  const setSaveStatus = useSetRecoilState(saveStatusAtom);
  // const product = useRecoilValue(productAtom);
  

  // カラー
  const [ color, setColor ] = useState("");
  const [ subColor, setSubColor ] = useState("");

  const [ colorStateSwitch, setColorStateSwitch ] = useState([]);
  const [ inputName, setInputName ] = useState("");

  // PC、スマホの切替
  const [ spSwitch, setSpSwitch ] = useState("BASE");
  const spSwitchState = { spSwitch, setSpSwitch }
  
  
  useEffect(() => {
    setColor(block?.color || "");
    setSubColor(block?.sub_color || "");
  }, []);
  
  
  // フォーカスを外す
  const removeFocus = () => {
    const elem = document.activeElement;
    elem.blur();
  };
  
  
  // セーブデータを更新
  // const SaveDataUpdate = (newData) => {
  //   setSaveData((prev)=> ({ 
  //   ...prev, 
  //     blocks: prev.blocks.map((obj) => (
  //       obj.id === block.id ? newData : obj
  //     ))
  //   }));
  // };
  

  const InputChange = event => {
    const { name, value } = event.target;
    setBlock((prev)=> ({ ...prev, [name]: value }));
    
    // const newBlock = { ...block, [name]: value };
    // setBlock(newBlock);
    // SaveDataUpdate(newBlock);
  };
  

  // refをtrueで初期化。
  const ref = useRef(true);
  
  // カラー変更時
  useEffect(() => {
    if (ref.current ||  block.color == color && block.sub_color == subColor) {
      ref.current = false;
      return;
    }
    
    console.log("カラー変更処理");
    setBlock((prev)=> ({ ...prev, color: color, sub_color: subColor }));
    
    // const newBlock = { ...block, color: color, sub_color: subColor };
    // setBlock(newBlock);
    // SaveDataUpdate(newBlock);
  }, [color, subColor]);


  // 保存
  const updateBlock = () => {
    client.patch(`/api/v1/blocks/${block.id}`, block)
    .then(resp => {
      // setToastObj({message: "保存しました。", color: "#D1E7DD"});
      setSaveStatus(false);
      console.log("保存完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】保存に失敗しました。", color: "#ffedab"});
      // setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(block, updateBlock, 1000);
  
  // // refをtrueで初期化。
  // const Ref = useRef(null);
  
  // // 自動保存
  // useEffect(() => {
  //   if (Ref.current === null) {
  //     setTimeout(() =>{
  //       Ref.current = true;
  //     }, 1000);
  //     return;
  //   }
    
  //   if (Ref.current) {
  //     clearTimeout(Ref.current); 
  //     console.log("Block自動保存待ち");
  //   }
    
  //   Ref.current = setTimeout(updateBlock, 3000);
  // }, [block]);


  // リセット
  const Reset = () => {
    setColor("");
    setSubColor("");
    
    const newBlock = {
      ...block, 
      color: null,
      sub_color: null,
      third_color: null,
      color_stop: null,
      deg: null,
      column: 1,
      img: null,
      padding: null,
      height: null,
      wrapper: null,
    };
    setBlock(newBlock);
    // SaveDataUpdate(newBlock);
  }
  
  // クリア
  const Clear = (column) => {
    setBlock((prev)=> ({ ...prev, [column]: null }));
    
    // const newBlock = {...block, [column]: null};
    // setBlock(newBlock);
    // SaveDataUpdate(newBlock);
  }
  
    // カラークリア
  const coloClear = (column) => {
    if(column == "color") {
      setColor("");
    } else if(column == "sub_color") {
      setSubColor("");
    }
  }
  
  // 公開する
  const Release = () => {
    setBlock((prev)=> ({ ...prev, status: "公開" }));
    
    // const newBlock = {...block, status: "公開"};
    // setBlock(newBlock);
    // SaveDataUpdate(newBlock);
  }
  
  
  const [ open, setOpen ] = useState("");
  
  const imgChangeHandler = ()=> {
    setOpen("Img");
    removeFocus();
  };
  
  const settingHandler = ()=> {
    setOpen("Setting");
    removeFocus();
  };
  
  const clickSectionHandler = ()=> {
    setClickSection(section_id)
    removeFocus();
  };
  

  // カラー設定オープン
  const ColorHandler = ()=> {
    setOpen("Color");
    setColorStateSwitch([color, setColor]);
    setInputName("color");
    removeFocus();
  };
  
  const ColorHandler2 = ()=> {
    setOpen("Color");
    setColorStateSwitch([subColor, setSubColor]);
    setInputName("sub_color");
    removeFocus();
  };
  
  // スライドオプションの判定
  // const AddonNames = addons.map((obj) => {
  //   return obj.addon_name
  // }); 
  // const slideJudg =  /ライト|スタンダード|ビジネス/.test(product.plan||"フリー") || /スライド/.test(AddonNames) || currentUser.admin == true
  
  
  // paddingの分割
  let paddings = block.padding ? block.padding.split("／") : [];
  
  const paddingChange = event => {
    const { name, value } = event.target;

    if (name == "padding_top") {
      setBlock((prev)=> ({ ...prev, padding: `${value}／${paddings[1] ||""}` }));
    } else if (name == "padding_bottom") {
      setBlock((prev)=> ({ ...prev, padding: `${paddings[0] ||""}／${value}` }));
    }
  };
  
  // paddingクリア
  const paddingClear = (column) => {
    if (column == "padding_top") {
      setBlock((prev)=> ({ ...prev, padding: `／${paddings[1] ||""}` }));
    } else if (column == "padding_bottom") {
      setBlock((prev)=> ({ ...prev, padding: `${paddings[0] ||""}` }));
    }
  }
  

  return (
    <>
      <div className="block_menu">
        <Link to={`/product/${productId}/dashboard`} className="back_btn"><BiChevronLeft/></Link>
        
        <div className="block_badge block_badge_color nobtn">ブロック／{block.id}</div>
        
        <div className="tooltip block_spin">
          <SpinBtn
            obj={block}
            setObj={setBlock}
            column="column"
            objValue={block.column ||1}
            InputChange={InputChange}
            // SaveDataUpdate={SaveDataUpdate}
          />
          <div className="tooltip_text">横並び数（カラム）</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={imgChangeHandler}><BiImageAlt/></button>
          <div className="tooltip_text">ブロック背景写真</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn"
            style={{ background: `${color}`, color: `${color ? "#ccc":""}`}}
            onClick={ColorHandler}
          ><MdFormatColorFill/></button>
          <div className="tooltip_text">ブロック背景色</div>
        </div>
        
        <div className="drop_btn nobtn">
          <button className="menu_btn"><TbBoxPadding/></button>
          <div className="drop_box drop_box_center">
            <div className="drop_list" style={{width: "300px"}}>
              <div className="drop_title">ブロックのスペース（余白）</div>
              
              <label>内側のスペース【上】（{paddings[0] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_top")}>クリア</div>
              </label>
              <RangeSpinSplit
                setObj={setBlock}
                InputChange={paddingChange}
                targetName="padding"
                column="padding_top"
                objValueMain={paddings[0] ||100}
                objValueOther={paddings[1] ||""}
                beforeAfter="before"
              　max="200"
              />
              
              <label>内側のスペース【下】（{paddings[1] ||""}px）
                <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_bottom")}>クリア</div>
              </label>
              <RangeSpinSplit
                setObj={setBlock}
                InputChange={paddingChange}
                targetName="padding"
                column="padding_bottom"
                objValueMain={paddings[1] ||100}
                objValueOther={paddings[0] ||""}
                beforeAfter="after"
              　max="200"
              />

            </div>
          </div>
        </div>
        
        { !blockId &&
          <div className="tooltip">
            <button className="menu_btn" onClick={clickSectionHandler}><BiCarousel/></button>
            <div className="tooltip_text">スライド設定</div>
          </div>
        }
        
        <div className="tooltip">
          <button className="menu_btn" onClick={settingHandler}><BiCog/></button>
          <div className="tooltip_text">ブロック設定</div>
        </div>
        
        <div className="tooltip">
          <button className="menu_btn" onClick={Reset}><BiReset/></button>
          <div className="tooltip_text">ブロックのリセット</div>
        </div>

        
        {block.status == "非公開" &&
          <button className="block_badge activity_btn" onClick={Release}>公開する</button>
        }
        
        <div className="t_view">
          <SaveStatus/>
        </div>
        
        <button className="block_menu_savebtn" onClick={removeFocus}>キーボード閉</button>
        
        {/*<SaveAll className="block_menu_savebtn"/>*/}
      </div>
      
      { open == "Color" &&
        <ColorPicker 
          blockState={blockState}
          InputChange={InputChange} 
          // colorState={colorState} 
          colorStateSwitch={colorStateSwitch}
          inputName={inputName}
          // inputValueState={inputValueState}
          ColorHandler={ColorHandler}
          ColorHandler2={ColorHandler2}
          updateBlock={updateBlock}
          // SaveDataUpdate={SaveDataUpdate}
          Clear={Clear}
          coloClear={coloClear}
          setOpen={setOpen}
        />
      }
      
      { open == "Img" &&
        <ImgSetting
          obj={img}
          setObj={setImg}
          type="block"
          setOpen={setOpen}
          spSwitchState={spSwitchState}
        />
      }
      
      { open == "Setting" &&
        <BlockSetting
          blockState={blockState} 
          blocksState={blocksState}
          InputChange={InputChange}
          updateBlock={updateBlock}
          // SaveDataUpdate={SaveDataUpdate}
          section_id={section_id}
          Clear={Clear}
          setOpen={setOpen}
        />
      }
    </>
  );
  
});

// <button onClick={updateBlock}>保存</button>
// <button onClick={deleteBlock}>削除</button>