import React from 'react';

import { useRecoilValue } from 'recoil'
import { saveStatusAtom } from '@/providers/Atoms';
import { BsCloudCheck } from "react-icons/bs";


export const SaveStatus = React.memo(() => {
  
  // console.log("保存ステータス")
  
  const saveStatus = useRecoilValue(saveStatusAtom)
  
  return (
    <div className="save_status">
      { saveStatus 
       ?
          <>
            Saving
            <div className="save_loader"></div>
          </>
        : 
          <div className="tooltip">
            <BsCloudCheck/>
            <div className="tooltip_text">保存済み</div>
          </div>
      }
    </div>
  );
  
});