import React, { useEffect, useRef } from "react"

import { useSetRecoilState } from 'recoil'
import { saveStatusAtom } from '@/providers/Atoms';


export const useAutoSave = ( obj, update, time ) => {
  
  const setSaveStatus = useSetRecoilState(saveStatusAtom)
  
  // refをtrueで初期化。
  const Ref = useRef("初回");
  
  // オートセーブ
  useEffect(() => {
    if (Ref.current === "初回") {
      setTimeout(() =>{
        Ref.current = true;
      }, time);
      return;
    }
    
    if (Ref.current) {
      clearTimeout(Ref.current); 
      console.log("自動保存待ち");
      setSaveStatus(true); 
    }
    
    Ref.current = setTimeout(update, 3000);
  }, [obj]);
  
  return;
};