import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import { PlanTypes, OptionTypes } from '@/addon/AddonTypes';
// import { Subject } from '@/product/Subject';

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";


export const CheckoutForm = () => {
  
  const stripe = useStripe();
  const elements = useElements();
  
  const { client, currentUser } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  let { productId } = useParams();
  if (productId == undefined) {
    productId = "account"
  }
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  const [ product, setProduct ] = useState([]);
  const [ customer, setCustomer ] = useState([]);
  const [ defaultPayment, setDefaultPayment ] = useState("");
  
  const initial = {
    addon_name: "Coming Soon（まだご利用いただけません。）"
  }
  const [ addon, setAddon ] = useState(initial);
  
  // パラメータ取得
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  
  useEffect(() => {
    if(key == "null") {
      return;
    }

    client.get(`/api/v1/addons/${key}?subdomain=${productId}`)
    .then(resp => {
      setProduct(resp.data.product);
      setAddon(resp.data.addon);
      setCustomer(resp.data.customer);
      setDefaultPayment(resp.data.customer.default_source || resp.data.customer.invoice_settings.default_payment_method);
      console.log("default_source", resp.data.customer.default_source ||"なし");
      console.log("default_payment_method", resp.data.customer.invoice_settings.default_payment_method ||"なし");
    })
  }, []);
  
  // console.log("カスタマー", customer);
  console.log("デフォルトぺイメント", defaultPayment);
  
  let OneClickDisplay = "前回と同じカード"
  if (/card_/.test(defaultPayment)) {
    OneClickDisplay = "設定中のカード"
  }
  
  // const AddonTypes = {...PlanTypes, ...OptionTypes }
  // const AddonType = Object.values(AddonTypes).find((obj) => {
  //   return obj.key == Type
  // });
  
  // let AddonType;
  // switch(Type) {
  //   case "LightPlan":
  //     AddonType = PlanTypes.LightPlan
  //     break;
  //   case "BasePlan":
  //     AddonType = PlanTypes.BasePlan
  //     break;
  //   case "BizPlan":
  //     AddonType = PlanTypes.BizPlan
  //     break;
  //   case "PaymentOption":
  //     AddonType = OptionTypes.PaymentOption
  //     break; 
  //   default:
  //     return "";
  // };
  
  // console.log("アドオン",addon);
  
  
  // ワンクリック決済
  const OneClickPay = () => {
    const sure = window.confirm(`本当に同じクレジットカードで決済をしますか？`);
    if (sure) {
      setLoading(true);
      client.post(`/api/v1/payments?subdomain=${productId}&pay_type=one_click_pay`, addon)
      .then(resp => {
        if (resp.data.invoice.status == "paid") {
          setLoading(false);
          console.log("決済成功");
          window.location.href = `/pay_completed?product=${productId}&addon=${addon.addon_name}&price=${addon.price}`; 
        } else {
          setLoading(false);
          setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
          setErrorMessage("【エラー】決済に失敗しました。");
        }
        // console.log("invoice", resp.data.invoice);
      })
      .catch(e => {
        setLoading(false);
        if (e.response.data) {
          setToastObj({message: `【エラー】${e.response.data}`, color: "#ffedab"});
        } else {
          setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        }
        console.log(e);
        setErrorMessage("【エラー】決済に失敗しました。");
      });
    }
  };
  
  
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }
    
    setLoading(true);

    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    
    // const resp = await client.post(`/api/v1/payments/subscription?subdomain=${productId}`, AddonType)
    const resp = await client.post(`/api/v1/payments?subdomain=${productId}`, addon)
    
    // console.log(resp.data);
  
    let clientSecret;
    if (resp.data.clientSecret) {
      // #決済進行
      clientSecret = resp.data.clientSecret
    } else if (resp.data.message){
      setLoading(false);
      setErrorMessage(resp.data.message);
      return;
    } else if (resp.response.status == 500){
      setLoading(false);
      setErrorMessage("[エラー]決済に失敗しました。");
      return;
    } else {
      setLoading(false);
      setErrorMessage("[エラー]決済に失敗しました。やり直してください。");
      return;
    }
    
    const result = await stripe.confirmPayment({
      elements,
      clientSecret: `${clientSecret}`,
      confirmParams: {
        return_url: `https://app.pstock.jp/product/${productId}/addons`,
      },
      redirect: 'if_required',
    });
    
    console.log("result", result.paymentIntent);
    
    if (result.paymentIntent.status == "succeeded") {
      setLoading(false);
      console.log("決済成功");
      window.location.href = `/pay_completed?product=${productId}&addon=${addon.addon_name}&price=${addon.price}`;
      // setToastObj({message: "決済が完了しました。", color: "#D1E7DD"});
    } else if (result.error) {
      setLoading(false);
      setErrorMessage(`決済に失敗しました。${result.error.message}`);
      console.log(result.error.message);
    } else {
      setLoading(false);
      setErrorMessage("決済に失敗しました。");
      console.log("決済失敗");
    }
  }

  const paymentElementOptions = {
    layout: "tabs"
  }  

  return (
    <>
      <div className="payment_area">
        <div className="payment_info">

          <div className="plan_name">{addon.addon_name ||""} </div>
          
          <div className="summary">
            <p>１ヶ月ごとに自動決済されます。</p>
            <p>解約はいつでも可能です。</p>
          </div>
          
          <div className="price">
            月額：￥{addon.price||""}
            <span className="overview">(税込)</span>
          </div>
          
          <div className="summary">
            対象：<div className="product_badge">{product.name || "プロダクト名：未設定"}</div>
          </div>
          
          <Link to={`/product/${productId}/addons`}>プラン＆オプションに戻る</Link>
          {/*
            <br/><a
              style={{color: "#fff"}}
              href={`/pay_completed?product=${productId}&addon=${addon.addon_name}&price=${addon.price}`} 
            >決済完了</a>
          */}

        </div>
        
        {key !== "null" &&
          <form className="payment_form" onSubmit={handleSubmit}>
            
            { defaultPayment &&
              <>
                <div className="one_click_btn" onClick={OneClickPay}>
                  <div id="button-text">
                    {loading && <div className="spinner" id="spinner"></div>}
                  </div>
                  <div>{loading ? "決済中..." : `${OneClickDisplay}で決済する`}</div>
                </div>
                
                <center className="mb_4">
                  <p className="info">{OneClickDisplay}を使用して決済します。</p>
                  <div>もしくは</div>
                </center>
              </>
            }
            
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            
            <button type="submit" disabled={loading || !stripe || !elements} id="submit">
              <div id="button-text">
                {loading && <div className="spinner" id="spinner"></div>}
              </div>
              <div>{loading ? "決済中..." : "支払う"}</div>
            </button>
            
            {errorMessage &&
              <div className="color_red mt_1">{errorMessage}</div>
            }
          </form>
        }
        
      </div>
    </>
  )
};

// <span id="button-text">
//   {loading ? <div className="spinner" id="spinner"></div> : "支払う"}
// </span>

// onClick={OneClickPay}

// <form className="payment_form" onSubmit={handleSubmit}>
//         <LinkAuthenticationElement
//           id="link-authentication-element"
//           onChange={(e) => setEmail(e.target.value)}
//         />
      
//         <PaymentElement id="payment-element" options={paymentElementOptions} />
        
        
//         <button disabled={isLoading || !stripe || !elements} id="submit">
//           <span id="button-text">
//             {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
//           </span>
//         </button>
        
//         {message && <div id="payment-message">{message}</div>}
//       </form>