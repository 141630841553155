import React from 'react';
import { BiPlus, BiMinus } from "react-icons/bi";

export const SpinBtn = React.memo(({ obj, setObj, InputChange, column, objValue}) => {
  
  const spinUp = (name, value) => {
    setObj((prev)=> ({ ...prev, [name]: ++value }));
    
    // let newObj = { ...obj, [name]: ++value };
    // setObj(newObj);
    // SaveDataUpdate(newObj);
  };
  
  const spinDown = (name, value) => {
    setObj((prev)=> ({ ...prev, [name]: --value }));
  
    // let newObj = { ...obj, [name]: --value };
    // setObj(newObj);
    // SaveDataUpdate(newObj);
  };
  
  
  return (
    <div className="spin_btn">
      <span onClick={()=>spinDown(column, objValue)}><BiMinus/></span>
      <input 
      　className="input" 
      　type="number"
      　name={column}
      　value={objValue ||""} 
      　onChange={InputChange}
        placeholder="未設定"
      />
      <span onClick={()=>spinUp(column, objValue)}><BiPlus/></span>
    </div>
  );
});