import React from 'react';
import { BiPlus, BiMinus } from "react-icons/bi";

export const SpinBtn = React.memo(({ obj, setObj, InputChange, column, objValue, setStates}) => {

  const spinUp = (name, value) => {
    const newObj = { ...obj, [name]: ++value};
    setStates(newObj);
  };
  
  const spinDown = (name, value) => {
    const newObj = { ...obj, [name]: --value};
    setStates(newObj);
  };
  
  
  return (
    <div className="spin_btn">
      <span onClick={()=>spinDown(column, objValue)}><BiMinus/></span>
      <input 
      　className="input" 
      　type="number"
      　name={column}
      　value={objValue ||""} 
      　onChange={InputChange}
      />
      <span onClick={()=>spinUp(column, objValue)}><BiPlus/></span>
    </div>
  );
});