import React, { useState, useEffect, useRef } from "react"
import { RgbaStringColorPicker } from "react-colorful";
import { PresetColors } from '@/app/PresetColors';
import { RangeSpin } from '@/editerMenu/RangeSpin';

import { BiX } from "react-icons/bi";


export const BlockColorSetting = React.memo(({ 
  blockState, InputChange, inputName,
  ColorHandler, ColorHandler2, Clear, setStates, setOpenSidebar }) => {

  // console.log("ブロックカラーピック" , "レンダ");

  const { block, setBlock } = blockState
  
  let colorValue = "";
  if(inputName == "color"){
    colorValue = block.color ||"";
  } else if(inputName == "sub_color"){
    colorValue = block.sub_color ||"";
  }
  
  // const PickerSwatch = (color) => {
  //   colorStateSwitch[1](color)
  // }
  
  
  // 変更カラーセット
  const colorSet = (color) => {
    let newBlock;
    if(inputName == "color") {
      newBlock = { ...block, color: color};
    } else if(inputName == "sub_color") {
      newBlock = { ...block, sub_color: color};
    }
    
    setStates(newBlock);
  }
  
  
  // カラークリア
  const coloClear = () => {
    let newBlock;
    if(inputName == "color") {
      newBlock = { ...block, color: null};
    } else if(inputName == "sub_color") {
      newBlock = { ...block, sub_color: null};
    }
    
    setStates(newBlock);
  }
  
  
  // カラーピッカーでの変更
  const [ colorPicker, setColorPicker ] = useState("");
  // refをtrueで初期化。
  const Reff = useRef(true);
  // カラー変更時
  useEffect(() => {
    // 初回レンダリング時はrefをfalseにして、return。
    if (Reff.current) {
      Reff.current = false;
      return;
    }

    console.log("ピッカーカラー変更処理");
    colorSet(colorPicker);

  }, [colorPicker]);
  
  
  
  const closeHandler = ()=> {
    setOpenSidebar("");
  };

  
// <div className="cover" onClick={closeHandler}/>
  return (
    <>
      <div className="items_bar">

        <div className="items_bar_top">
          <div className="sidebar_title">
            ブロック背景色
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
        
          <div className="tab_menu">
            <button 
              className={`tab_menu_btn ${inputName == "color" && "active"}`}
              onClick={ColorHandler}
            >背景色</button> 
            <button
              className={`tab_menu_btn ${inputName == "sub_color" && "active"}`}
              onClick={ColorHandler2}
            >グラデーション</button>
          </div>
          
          {inputName == "sub_color" && colorValue !== "" &&
            <>
              <label>グラデーションの角度（{block.deg ||""}度）
                <div className="btn_clear" type="button" onClick={()=>Clear("deg")}>クリア</div>
              </label>
              <RangeSpin
                obj={block}
                setObj={setBlock}
                column="deg"
                objValue={block.deg ||0} 
                InputChange={InputChange}
              　max="360"
              　setStates={setStates}
              />
              
              <label>グラデーションの位置（{block.color_stop ||""}%）
                <div className="btn_clear" type="button" onClick={()=>Clear("color_stop")}>クリア</div>
              </label>
              <RangeSpin
                obj={block}
                setObj={setBlock}
                column="color_stop"
                objValue={block.color_stop ||0}
                InputChange={InputChange}
                setStates={setStates}
              />
            </>
          }
          
          {inputName == "color" &&
            <>
              <label>背景色</label>
            </>
          }
          
          {inputName == "sub_color" &&
            <>
              <label>グラデーション色</label>
            </>
          }
          
          <div className="input_inline mb_1">
            <button className="color_preview_btn"
              style={{ background: colorValue || "#fff" }}
            />
            
            <input
              type="text"
              className="input"
              style={{ width: "100%" }}
              name={inputName}
              value={colorValue ||""}
              onChange={InputChange}
              placeholder="カラーコード"
            />
          </div>
          
          <div className="picker__swatches">
            <button className="clear_btn mb_1" onClick={coloClear}>クリア</button>
            <div
              className="picker__swatch"
              onClick={()=> colorSet("rgba(255, 255, 255, 0)")}
            >
              <img src="/transparent.jpg"/>
            </div>
            
            {PresetColors.map((Color) => (
              <button
                key={Color}
                className={`picker__swatch ${Color == colorValue && "active"}`}
                style={{ background: Color }}
                onClick={()=> colorSet(Color)}
              />
            ))}
          </div>
          
          <div className="picker">
            <RgbaStringColorPicker color={colorValue} onChange={setColorPicker}/>
            
            <input
              type="text"
              className="input"
              name={inputName}
              value={colorValue ||""}
              onChange={InputChange}
              placeholder="カラーコード"
            />
          </div>
          
        </div>
        
        <div className="items_bar_bottom">
        </div>

      </div>
    </>
  );

});