import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';


export const BlocksData = React.memo(() => {
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const [ blocks, setBlocks ] = useState([]);
  
  
  // データ取得
  const getBlocksData = () => {
    client.get(`/api/v1/blocks/no_section_blocks`)
    .then(resp => {
      setBlocks(resp.data);
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  
  // 削除
  const deleteBlock = (block_id) => {
    client.delete(`/api/v1/blocks/${block_id}`)
    .then(resp => {
      setBlocks((prev) =>
        prev.filter((obj) => obj.id !== block_id)
      );
    })
    .catch(e => {
      setToastObj({message: "【エラー】削除に失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
 
  return (
    <> 
      <h1>ブロック</h1>
      
      <button className="btn" onClick={getBlocksData}>ブロックデータ取得（{blocks.length}）</button>

      <div className="block_card_box">
        {blocks.map((block) => {
          return (
            <div className="block_card" key={block.id}>
              ID：{block.id}｜
              名前：{block.name}｜
              <strong>共有：{block.sharing}</strong>｜
              プロダクトID：{block.product_id}｜
              ページID：{block.page_id}｜
      
              <button className="btn" onClick={()=>deleteBlock(block.id)}>削除</button>
            </div>
          );
        })}
      </div>
    </>
  );
});

