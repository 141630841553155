import React, { useState, useContext } from "react"
import TextareaAutosize from 'react-textarea-autosize';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import { TestInput } from '@/contact/TestInput';


export const ContactForm = ({ path, productId }) => {

  const { client, currentUser } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  const [ message, setMessage ] = useState("");
  const [ errorMessage, setErrorMessage ] = useState("");
  
  // バリデーションフォーム
  const { 
    register, 
    handleSubmit,
    reset, 
    formState: { errors }, 
  } = useForm({
    criteriaMode: 'all',
    defaultValues: { name: currentUser?.name ||"", email: currentUser?.email ||""},
  });
  
  const { executeRecaptcha } = useGoogleReCaptcha();
  
  const DataSubmit = async (data) => {
    
    if (!executeRecaptcha) {
      setErrorMessage(`【認証エラー】送信できません。`);
      return;
    }
  
    const reCaptchaToken = await executeRecaptcha('contact');
    
    data.token = reCaptchaToken;
    console.log("データ", data);
    
    client.post(`/api/v1/mails/${path}?subdomain=${productId ||""}`, data)
    .then(resp => {
      console.log(resp.data)
      setErrorMessage("");
      setMessage("お問い合わせありがとうございます。メッセージを送信しました。");
      reset();
      setToastObj({message: "送信しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      setErrorMessage(`【エラー】${e.response.data}`);
      console.log(e)
    })
  }

  
  return (
    <form onSubmit={handleSubmit(DataSubmit)}>
    
      <div className="form_field">
        <label>お名前</label>
        <input 
          id="name"
          className={`input ${errors.name?.message && "error"}`}
          type="text"
          placeholder="お名前を入力..."
          {...register('name', {
            required: { value: true, message: '注）必須項目です。'},
          })}
        />
        {errors.name?.message && 
          <div className="color_red">{errors.name.message}</div>
        }
      </div>
      
      <div className="form_field">
        <label>メールアドレス（返信用）</label>
        <input 
          id="email"
          className={`input ${errors.email?.message && "error"}`}
          type="text"
          placeholder="例）info@example.com"
          {...register('email', {
            required: { value: true, message: '注）必須項目です。'},
            pattern: { 
              value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/, 
              message: '【エラー】メールアドレスが正しくありません。'
            },
          })}
        />
        {errors.email?.message && 
          <div className="color_red">{errors.email.message}</div>
        }
      </div>
      
      <div className="form_field">
        <label>電話番号</label> 
        <input 
          id="tel"
          className={`input ${errors.tel?.message && "error"}`}
          type="text"
          placeholder="例）090-1234-5678"
          {...register('tel')}
        />
        {errors.tel?.message && 
          <div className="color_red">{errors.tel.message}</div>
        }
      </div>
      
      <div className="form_field">
        <label>件名</label>
        <input 
          id="email"
          className={`input ${errors.title?.message && "error"}`}
          type="text"
          placeholder="件名を入力..."
          {...register('title', {
            required: { value: true, message: '注）必須項目です。'},
          })}
        />
        {errors.title?.message && 
          <div className="color_red">{errors.title.message}</div>
        }
      </div>
      
      <div className="form_field">
        <label>メッセージ内容</label>
        <TextareaAutosize
          minRows={15}
          id="content"
          className={`input ${errors.content?.message && "error"}`}
          type="text"
          placeholder="メッセージ内容を入力..."
          {...register('content', {
            required: { value: true, message: '注）必須項目です。'},
          })}
        />
        {errors.content?.message && 
          <div className="color_red">{errors.content.message}</div>
        }
      </div>

      <center>
        {message && 
          <div className="color_key mb_2" style={{fontWeight:"bold"}}>{message}</div>
        }
        {errorMessage && 
          <div className="color_red mb_2" style={{fontWeight:"bold"}}>{errorMessage}</div>
        }
      </center>
      
      <button type="submit" className="form_btn">送信する</button>
      
    </form>
  );
  
};

// <TestInput register={register} errors={errors}/>