import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';

import { Nav } from '@/product/Nav';
import { NewPage } from '@/page/NewPage';
import { BiChevronRight } from "react-icons/bi";
// import { AddonJudg } from '@/addon/AddonJudg';
import { Subject } from '@/product/Subject';
import { PageCard } from '@/product/PageCard';
// import { Url } from '@/app/Url';
import { CopyPage } from '@/page/CopyPage';

// import { AddonJudg } from '@/addon/AddonJudg';
import { useRecoilState, useSetRecoilState } from 'recoil'
import { productAtom, addonsAtom, optionsAtom, limitPagesAtom } from '@/providers/Atoms';


export const Product = React.memo(() => {

  // console.log("プロダクトレンダリング");

  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);
  const { productId } = useParams();

  const [ product, setProduct ] = useRecoilState(productAtom)
  const [ addons, setAddons ] = useRecoilState(addonsAtom)
  const [ limitPages, setLimitPages ] = useRecoilState(limitPagesAtom)
  const setOptions = useSetRecoilState(optionsAtom);

  const [ pages, setPages ] = useState([]);
  const pagesState = { pages, setPages };

  // const [ product, setProduct ] = useState([]);
  // const [ plan, setPlan ] = useState([]);
  // const [ options, setOptions ] = useState([]);
  // const [ totalSize, setTotalSize ] = useState([]);
  const [ warning, setWarning ] = useState(false);

  const FreePlan =  {
    addon_name: "フリープラン",
    pages: 1,
  }

  // 画面遷移のため
  const navigate = useNavigate();

  useEffect(() => {
    client.get(`/api/v1/products/${productId}`)
    .then(resp => {
      setProduct(resp.data.product);
      setPages(resp.data.pages);
      // setPlan(resp.data.plan || FreePlan);
      setOptions(resp.data.options);
      // プランとオプションを合体
      setAddons([resp.data.plan || FreePlan, ...resp.data.options]);
      setLimitPages(resp.data.limit_pages);
      // console.log("リミット", resp.data.limit_pages);
    })
    .catch(e => {
      navigate(`/home`);
      setToastObj({message: "アクセスできません。", color: "#ffedab"});
      console.log(e);
    });
  }, []);

  // console.log(addons);

  // プランとオプションを合体
  // const addons = [plan, ...options]

  // アドオンの名前を配列に
  const AddonNames = addons.map((obj) => {
    return obj.addon_name
  });
  // console.log(AddonNames);

  // アドオンの利用可能ページの合計
  // let LimitPages = addons.reduce(function(sum, obj){
  //   return sum + obj.pages;
  // }, 0);

  //許可ページ数の判定
  let pagesLength = pages.length
  let pagesJudg = limitPages > pagesLength || currentUser.admin == true

  // お問い合わせフォームの判定
  const formJudg = /ライト|スタンダード|ビジネス|制作代行/.test(product.plan||"フリー") || /お問い合わせ/.test(AddonNames) || currentUser.admin == true



  //ページの複製
  const PageCopy = (id) => {
    if(!pagesJudg) {
      setWarning(true);
      return;
    };

    const sure = window.confirm(`このページを複製しますか?`);
    if (sure) {
      // client.post(`/api/v1/pages/copy/${id}`, {slug: pages.length + 1} )
      client.post(`/api/v1/pages/copy/${id}`)
      .then(resp => {
        setPages((prev)=>[resp.data, ...prev]);
        setToastObj({message: "ページを複製しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
        console.log(e);
      });
    }
  };

  // ページの削除
  const PageDelete = (page) => {
    const sure = window.confirm(`このページ「${page.title || ""}」を本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/pages/${page.id}`)
      .then(resp => {
        console.log(resp.data);
        setPages((prev) =>prev.filter((obj) => obj.id !== page.id));
        setToastObj({message: "ページを削除しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };


  //並び順を保存
  const OrderUpdate = (objs) => {
    setPages(objs);//並び替え後をセット

    client.patch(`/api/v1/pages/order`, {pages: objs})
    .then(resp => {
      // setToastObj({message: "並替えを保存しました。", color: "#D1E7DD"});
      console.log("並替え保存");
    })
    .catch(e => {
      setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
    });
  };

  //並び順をUP
  const orderUp = (index) => {
    const objs = [...pages]
    const removed = objs.splice(index, 1); //ドラッグ開始要素の削除
    objs.splice(index-1, 0, removed[0]); //ドロップした箇所に挿入
    OrderUpdate(objs); //並び替え後を保存
  };

  const orderDown = (index) => {
    const objs = [...pages]
    const removed = objs.splice(index, 1); //ドラッグ開始要素の削除
    objs.splice(index+1, 0, removed[0]); //ドロップした箇所に挿入
    OrderUpdate(objs); //並び替え後を保存
  };


  // 各ページにトップページ判定カラムを配列に
  let toppage_true = true
  toppage_true = pages.some(obj => obj.toppage == true);

  // console.log("トップページ判定", toppage_true);


  return (
    <>
      <div className="main_layer"></div>

      <Nav/>
      <Subject/>

      {/*
      <Link to={`/product/${productId}/customforms`}>カスタムフォーム</Link>
      */}

      <div className="color_white">ダッシュボード</div>
      <h1 className="H1 color_white">{product.name}</h1>

      {/*
      <div className="card_area">
        <Link to={`/product/${productId}/header/edit`} className="card">
          <div className="card_title">ヘッダー</div>
          上部に表示されるヘッダーの編集<BiChevronRight/>
        </Link>
        <Link to={`/product/${productId}/footer/edit`} className="card">
          <div className="card_title">フッター</div>
          下部に表示されるフッターの編集<BiChevronRight/>
        </Link>

        {formJudg && pagesJudg
          ?
            <Link to={`/product/${productId}/contact/edit`} className="card">
              <div className="card_title">お問い合わせ</div>
              お問い合わせページの編集<BiChevronRight/>
            </Link>
          :
            <div className="card card_inactive">
              <div className="card_title">お問い合わせ</div>
              プランの変更が必要です。<BiChevronRight/>
            </div>
        }

          <a href={`https://${productId}.pstock.me/contact`} target="_blank" className="card">
            <div className="card_title">フォーム</div>
            お問い合わせフォーム<BiChevronRight/>
          </a>
        </div>
      */}

      <div className="product_menu">
        <Link to={`/product/${productId}/dashboard/#page-edit`} className="product_menu_btn product_menu_btn_active">
          <div className="btn_title">ページの編集</div>
          各ページの編集
        </Link>
        <Link to={`/product/${productId}/header/edit`} className="product_menu_btn">
          <div className="btn_title">ヘッダーの編集</div>
          上部に表示されるヘッダーの編集
        </Link>
        <Link to={`/product/${productId}/footer/edit`} className="product_menu_btn">
          <div className="btn_title">フッターの編集</div>
          下部に表示されるフッターの編集
        </Link>

        {formJudg && pagesJudg
          ?
            <Link to={`/product/${productId}/contact/edit`} className="product_menu_btn">
              <div className="btn_title">お問い合わせ</div>
              お問い合わせページの編集
            </Link>
          :
            <div className="product_menu_btn card_inactive">
              <div className="btn_title">お問い合わせ</div>
              プランの変更が必要です。
            </div>
        }
      </div>


      <h2 className="H2 mt_3" id="page-edit">各ページ（{pagesLength} / {limitPages}）</h2>

      <NewPage product_id={product.id} pagesState={pagesState} pagesJudg={pagesJudg} setWarning={setWarning}/>

      {warning &&
        <div className="advice_box mb_2">
          新しいページを追加するには「プランの変更」もしく「オプション」が必要です。
          <Link to={`/product/${productId}/addons`} className="overlay_btn">プラン・オプションの変更へ</Link>
        </div>
      }

      {!toppage_true &&
        <div className="advice_box mb_2">
          トップページがありません。トップページを設定してください。
        </div>
      }

      <div className="page_card_box" id="page-edit">
        {pages.map((page, index) => {
          return (
            <PageCard
              page={page}
              PageCopy={PageCopy}
              PageDelete={PageDelete}
              orderUp={orderUp}
              orderDown={orderDown}
              key={page.id}
              index={index}
            />
          );
        })}
      </div>
      
      <div className="step_guide">
        <div className="step">ステップ２</div>
        上記ボタンから「ページを追加」して、<span className="color_key">「ページの編集」</span>に進んでください。<br/>
        「ヘッダー」「フッター」の作成は必須ではありません。必要に応じて作成ください。
      </div>
      
      {currentUser.admin &&
        <CopyPage product_id={product.id} pagesState={pagesState}/>
      }

    </>
  );
});


// <a href={`/${page.slug}`} target="_blank" rel="noopener noreferrer">
//   /{page.slug}
// </a>