import React from "react"
import { RgbaStringColorPicker } from "react-colorful";
import { PresetColors } from '@/app/PresetColors';
import { RangeSpin } from '@/app/RangeSpin';
import { fontFamilys } from '@/app/DefaultData';
import { BiX } from "react-icons/bi";

export const ColorPicker = React.memo(({ 
  partState, InputChange, colorStateSwitch, 
  backColor_0_Handler, backColor_1_Handler,
  accentColor_0_Handler, accentColor_1_Handler,
  inputName, updatePart, Clear, coloClear, setOpen }) => {

  // console.log("パーツカラーピック", "レンダ");
  
  const { part, setPart } = partState;
  // const { inputValue, setInputValue } = inputValueState;
  
  let backColors =  part.back_color ? part.back_color.split("／") : [];
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];

  let colorValue = "";
  if(inputName == "font_color"){
    colorValue = part.font_color ||"";
  } else if(inputName == "border_color"){
    colorValue = part.border_color ||"";
  } else if(inputName === "back_color_0"){
    colorValue = backColors[0] ||"";
  } else if(inputName === "back_color_1"){
    colorValue = backColors[1] ||"";
  } else if(inputName === "accent_color_0"){
    colorValue = accentColors[0] ||"";
  } else if(inputName === "accent_color_1"){
    colorValue = accentColors[1] ||"";
  }
  
  // console.log(colorValue, "カラーヴァリュー");
  
  const PickerSwatch = (color) => {
    colorStateSwitch[1](color)
    // setInputValue(color)
  }
  
  
  // バックカラーのインプットチェンジ
  const colorChange = event => {
    const { name, value } = event.target;
    
    let newPart;
    if (name == "back_color_0") {
      newPart = {...part, back_color: `${value}／${backColors[1]}` };
    } else if (name == "back_color_1") {
      newPart = {...part, back_color: `${backColors[0]}／${value}` };
    } else if (name == "accent_color_0") {
      newPart = {...part, sub_color: `${value}／${accentColors[1]}` };
    } else if (name == "accent_color_1") {
      newPart = {...part, sub_color: `${accentColors[0]}／${value}` };
    }
    setPart(newPart);
    // SaveDataUpdate(newPart);
  };
  
  // // タイトルカラーのインプットチェンジ
  // const titleColorChange = event => {
  //   const { name, value } = event.target;
    
  //   let newPart;
  //   if (name == "title_color_0") {
  //     newPart = {...part, sub_color: `${value}／${titleColors[1]}` };
  //   } else if (name == "title_color_1") {
  //     newPart = {...part, sub_color: `${titleColors[0]}／${value}` };
  //   }
  //   setPart(newPart);
  //   SaveDataUpdate(newPart);
  // };
  
  
  const CloseHandler = () => {
    setOpen("");
  };
  

  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            {inputName == "font_color" && "文字（色・フォント）"}
            {inputName == "border_color" && "枠線（色・幅・角丸）"}
            {inputName == "back_color_0" && "背景色（パーツ）"}
            {inputName == "back_color_1" && "背景色（グラデーション）"}
            {inputName == "accent_color_0" && "アクセント色"}
            {inputName == "accent_color_1" && "サブアクセント色"}
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
          
          {/back/.test(inputName) &&
            <div className="tab_menu">
              <button 
                className={`tab_menu_btn ${inputName == "back_color_0" && "active"}`}
                onClick={backColor_0_Handler}
              >背景色</button> 
              <button
                className={`tab_menu_btn ${inputName == "back_color_1" && "active"}`}
                onClick={backColor_1_Handler}
              >グラデーション</button>
            </div>
          }
          
          {/accent/.test(inputName) &&
            <div className="tab_menu">
              <button 
                className={`tab_menu_btn ${inputName == "accent_color_0" && "active"}`}
                onClick={accentColor_0_Handler}
              >アクセント</button> 
              <button
                className={`tab_menu_btn ${inputName == "accent_color_1" && "active"}`}
                onClick={accentColor_1_Handler}
              >サブアクセント</button>
            </div>
          }
        
          {inputName == "font_color" &&
            <>
              {/*
              <label>文字サイズ（px）</label>
              <div className="input_fields">
                <div className="field_2">
                  <label>パソコン時
                    <div className="btn_clear" type="button" onClick={()=>Clear("font_size")}>クリア</div>
                  </label>
                  <SpinBtn
                    obj={part}
                    setObj={setPart}
                    column="font_size"
                    objValue={part.font_size || 16}
                    InputChange={InputChange}
                    SaveDataUpdate={SaveDataUpdate}
                  />
                </div>
                
                <div className="field_2">
                  <label>スマホ時
                    <div className="btn_clear" type="button" onClick={()=>Clear("font_size_s")}>クリア</div>
                  </label>
                  <SpinBtn
                    obj={part}
                    setObj={setPart}
                    column="font_size_s"
                    objValue={part.font_size_s || 16}
                    InputChange={InputChange}
                    SaveDataUpdate={SaveDataUpdate}
                  />
                </div>
              </div>
              */}
              
              <label>文字フォント
                <div className="btn_clear" type="button" onClick={()=>Clear("font")}>クリア</div>
              </label>
              <div className="selectbox">
                <select 
                  className="" 
                　name="font"
                　value={part?.font || ''} 
                　onChange={InputChange}
                > 
                  {fontFamilys.map((font, index) => {
                    return (
                      <option value={font.value} key={index}>{font.name}</option>
                    );
                  })}
                </select>
              </div>
              
              {/*
              <label>文字シャドウ（影）
                <div className="btn_clear" type="button" onClick={()=>Clear("text_shadow")}>クリア</div>
              </label>
              <SpinBtn
                obj={part}
                setObj={setPart}
                column="text_shadow"
                objValue={part.text_shadow || 0}
                InputChange={InputChange}
                SaveDataUpdate={SaveDataUpdate}
              />
              */}
              
              <label>文字色</label>
            </>
          }
          
          {inputName == "border_color" &&
            <>
              <label>線幅（{part.border ||""}px）
                <div className="btn_clear" type="button" onClick={()=>Clear("border")}>クリア</div>
              </label>
              <RangeSpin
                obj={part}
                setObj={setPart}
                column="border"
                objValue={part.border ||1}
                InputChange={InputChange}
              　max="20"
              />
            
              <label>角の丸み（{part.radius ||""}px）
                <div className="btn_clear" type="button" onClick={()=>Clear("radius")}>クリア</div>
              </label>
              <RangeSpin
                obj={part}
                setObj={setPart}
                column="radius"
                objValue={part.radius ||1}
                InputChange={InputChange}
              />

              <label>線色</label>
            </>
          }
          
          {inputName == "back_color" &&
            <>
              <label>背景色</label>
            </>
          }
          
          {inputName == "back_color0" &&
            <>
              <label>背景色</label>
            </>
          }
          
          {inputName == "back_color1" &&
            <>
              <label>背景のグラデーション色</label>
            </>
          }
          
          {inputName == "accent_color_0" &&
            <>
              <label>アクセント</label>
            </>
          }
          
          {inputName == "accent_color_1" &&
            <>
              <label>サブアクセント</label>
            </>
          }
          
          <div className="input_inline mb_1">
            <button className="" 
              style={{ background: colorValue || "#fff", margin:"0 10px 0 0", border: "solid 1px #ccc" }}
            />
              {/font|border/.test(inputName) &&
                <input
                  type="text"
                  className="input"
                  style={{ width: "100%" }}
                  name={inputName}
                  value={colorValue}
                  onChange={InputChange}
                  placeholder="カラーコード"
                />
              }
              
              {/back|accent/.test(inputName) &&
                <input
                  type="text"
                  className="input"
                  style={{ width: "100%" }}
                  name={inputName}
                  value={colorValue}
                  onChange={colorChange}
                  placeholder="カラーコード"
                />
              }
          </div>
          
          <div className="picker__swatches">
            <button className="clear_btn mb_1" onClick={()=>coloClear(inputName)}>クリア</button>
            <div
              className="picker__swatch"
              onClick={()=> PickerSwatch("rgba(255, 255, 255, 0)")}
            >
              <img src="/transparent.jpg"/>
            </div>
            
            {PresetColors.map((color) => (
              <button
                key={color}
                className={`picker__swatch ${color == colorValue && "active"}`}
                style={{ background: color }}
                onClick={()=> PickerSwatch(color)}
                // onMouseEnter={()=> PickerSwatch(color)}
              />
            ))}
          </div>
          
          <div className="picker">
            <RgbaStringColorPicker color={colorValue} onChange={colorStateSwitch[1]}/>
            
            {/font|border/.test(inputName) &&
              <input
                type="text"
                className="input"
                name={inputName}
                value={colorValue}
                onChange={InputChange}
                placeholder="カラーコード"
              />
            }
            
            {/back|accent/.test(inputName) &&
              <input
                type="text"
                className="input"
                name={inputName}
                value={colorValue}
                onChange={colorChange}
                placeholder="カラーコード"
              />
            }
          </div>
          
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={updatePart}>保存</button>
        </div>
        
      </div>
    </>
  );

});


  // refをtrueで初期化。
  // const ref = useRef(true);
   
  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current = false;
  //     return;
  //   }
  //   setInputValue(fontColor);
  // }, [part?.font_color]);
  
  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current = false;
  //     return;
  //   }
  //   setInputValue(backColor);
  // }, [part?.back_color]);
  
  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current = false;
  //     return;
  //   }
  //   setInputValue(borderColor);
  // }, [part?.border_color]);
  

  // const ValueChange = event => {
  //   colorStateSwitch[1](event.target.value)
  // };
  

                // <select 
                //   className="input w_100" 
                // 　name="font_size"
                // 　value={part.font_size} 
                // 　onChange={InputChange}
                // >
                //   <option value="">デフォルトの文字サイズ</option>
                //   <option value={10}>10px</option>
                //   <option value={12}>12px</option>
                //   <option value={14}>14px</option>
                //   <option value={16}>16px</option>
                //   <option value={18}>18px</option>
                //   <option value={20}>20px</option>
                //   <option value={22}>22px</option>
                //   <option value={24}>24px</option>
                //   <option value={26}>26px</option>
                //   <option value={28}>28px</option>
                //   <option value={30}>30px</option>
                //   <option value={32}>32px</option>
                //   <option value={34}>34px</option>
                // </select>

// <label>線幅
//   <div className="btn_clear" type="button" onClick={()=>Clear("border")}>クリア</div>
// </label>

// <label>線色
//   <div className="btn_clear" type="button" onClick={()=>Clear("border_color")}>クリア</div>
// </label>

// <label>背景色
//   <div className="btn_clear" type="button" onClick={()=>Clear("back_color")}>クリア</div>
// </label>


  // const ColorHandler = ()=> {
  //   setActive(true);
  //   setColorStateSwitch([fontColor, setFontColor]);
  //   // setNowColor(fontColor);
  //   setInputName("font_color");
  //   setInputValue(part?.font_color || '');
  // };
  
  // const ColorHandler2 = ()=> {
  //   setActive(true);
  //   setColorStateSwitch([backColor, setBackColor]);
  //   // setNowColor(backColor);
  //   setInputName("back_color");
  //   setInputValue(part?.back_color || '');
  // };
  
  // const ColorHandler3 = ()=> {
  //   setActive(true);
  //   setColorStateSwitch([borderColor, setBorderColor]);
  //   // setNowColor(borderColor);
  //   setInputName("border_color");
  //   setInputValue(part?.border_color || '');
  // };
  
  // const CloseHandler = ()=> {
  //   setActive(false);
  // };
  
  //     <div className="tooltip">
  //       <button
  //         className="menu_btn"
  //         style={{ background: `${fontColor}`, color: `${fontColor !== "" ? "#fff":""}`}}
  //         onClick={ColorHandler}
  //       ><BiFontColor/></button>
  //       <div className="tooltip_text">文字色・サイズ</div>
  //     </div>
      
  //     <div className="tooltip">
  //       <button
  //         className="menu_btn"
  //         style={{ background: `${backColor}`, color: `${backColor !== "" ? "#fff":""}`}}
  //         onClick={ColorHandler2}
  //       ><BiPaintRoll/></button>
  //       <div className="tooltip_text">背景色</div>
  //     </div>
      
  //     <div className="tooltip">
  //       <button
  //         className="menu_btn"
  //         style={{ background: `${borderColor}`, color: `${borderColor !== "" ? "#fff":""}`}}
  //         onClick={ColorHandler3}
  //       ><BiBorderRadius/></button>
  //       <div className="tooltip_text">枠線色・幅・角丸</div>
  //     </div>