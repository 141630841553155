import React, { useState, useContext } from 'react';
// import { ImgVideo } from '@/app/ImgVideo';
import { ImgPreview } from '@/image/ImgPreview';
import { StateContext } from '@/providers/StateProviders';
import { BiX } from "react-icons/bi";


export const Image = ({ image, index, s3FileDelete, s3FileGet, changeFreeImg }) => {
  
  // console.log("画像詳細");
  const [ open, setOpen ] = useState("");
  const { currentUser } = useContext(StateContext);
  
  const imageSize = Math.round(image.size/1024);
  
  return (
    <>
      <div className="item" onClick={()=>setOpen(image.id)}>
        <ImgPreview obj_img={image.img} obj_name={image.name}/>
        {currentUser.admin &&
          <div className="img_size">{imageSize || ""} KB</div>
        }
        <button className="delete_btn" onClick={()=>s3FileDelete(image.id, image.img, index)}>削除</button>
      </div>
        
      { open == image.id && 
        <div className="modal">
          <div className="close_btn" onClick={()=>setOpen("")}><BiX/></div>
          
          <div className="img_modal">
            {currentUser.admin &&
              <a href={image.img} target="_blank" rel="noopener noreferrer" className="color_white">{image.img}</a>
            }
            <div className="color_white">サイズ：{imageSize || ""} KB</div>
            <ImgPreview obj_img={image.img} obj_name={image.name}/>
            
            <button className="btn_delete" onClick={()=>s3FileDelete(image.id, image.img, index)}>削除</button>
            {currentUser.admin &&
              <>
                <button className="" onClick={()=>changeFreeImg(image)}>フリー画像に変更</button>
                <button className="" onClick={()=>s3FileGet(image.img)}>GET</button>
              </>
            }
          </div>
        </div>
      }
    </>
  );
};
