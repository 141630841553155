import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { TopicPath } from '@/app/TopicPath';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { useDateTime } from '@/hooks/useDateTime';
import parse from 'html-react-parser';
// import { HomeNotices } from '@/notice/HomeNotices';


export const Notice = React.memo(() => {
  
  const { noticeId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);

  const [ notice, setNotice ] = useState([]);
  // const [ notices, setNotices ] = useState([]);

  useEffect(() => {
    client.get(`/api/v1/notices/${noticeId}`)
    .then(resp => {
      setNotice(resp.data.notice);
      // setNotices(resp.data.notices);
    })
    .catch(e => {
      setToastObj({message: "エラー", color: "#ffedab"});
      console.log(e);
    });
  }, []);
  
    
  // 画面遷移のため
  const navigate = useNavigate();
  
  // 削除
  const deleteNotice = () => {
    const sure = window.confirm(`本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/notices/${notice.id}`)
      .then(resp => {
        navigate(`/notices`);
        setToastObj({message: "削除しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };

  
  return (
    <>
      <div className="main_layer"></div>
      <TopicPath topic="お知らせ"/>

      <div className="base_area">
        <span className="product_badge mr_2">{notice.subtitle || "お知らせ"}</span>
        <span className="">{useDateTime(notice.created_at)}</span>
        <h1 className="H1">{notice.title || "タイトル"}</h1>
        <p className="mb_3">{parse(`${notice.content}`)}</p>
        <Link to={`/notices`}>お知らせ一覧へ</Link>
        
        {currentUser.admin &&
          <div className="mt_3">
            <Link className="abtn" to={`/notice/${notice.id}/edit`}>編集</Link>
            <button className="" onClick={deleteNotice}>削除</button>
          </div>
        }
      </div>

    </>
  );
});