import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ToastContext } from '@/providers/ToastProvider';
import { StateContext } from '@/providers/StateProviders';


export const DefaultPayment = ({ adding, setOpen }) => {
  
  const { client, currentUser } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const [ sub, setSub ] = useState([]);
  const [ paymentMethods, setPaymentMethods ] = useState([]);
  
  
  useEffect(() => {
    client.get(`/api/v1/payments/stripe_subscription?adding_id=${adding.id}`)
    .then(resp => {
      // console.log(resp.data.subscription);
      // console.log(resp.data.payment_methods);
      setSub(resp.data.subscription);
      setPaymentMethods(resp.data.payment_methods);
    })
  }, []);
  
  
  // default_payment_method解除
  const updateDefaultPayment = (pm_id) => {
    
      const data = {
        adding_id: adding.id,
        pm_id: pm_id
      }
      // console.log("データ", data);
    
      client.patch(`/api/v1/payments/stripe_subscription_update`, data)
      .then(resp => {
        setSub(resp.data.subscription);
        setToastObj({message: "決済カードを変更しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        if (e.response.data) {
          setToastObj({message: `【エラー】${e.response.data}`, color: "#ffedab"});
        } else {
          setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        }
        console.log(e);
      });
  };
  

  return (
    <div className="payment_methods_box">
      
      <div>{adding.subscription_id}</div>
      
      { currentUser.admin &&
        <div>
          【default_payment_method】{sub.default_payment_method || "なし"}<br/>
          【default_source】{sub.default_source || "なし"}
        </div>
      }
      
      <p className="info mt_1">設定したクレジットカードがこの【{adding.addon_name}】の決済に使用されます。</p>
      <h4 className="mt_1 mb_1">決済カードを選択</h4>
      
      <div className="payment_methods">
        {paymentMethods.map((pm) => {
          return (
            <div className="item" key={pm.id}>
              <div className="brand">{pm.card.display_brand}</div>
              <div className="number">•••• {pm.card.last4}</div>
              <div className="date">有効期限：{pm.card.exp_year}年{pm.card.exp_month}月</div>
              <div className="setting">
                {sub.default_payment_method == pm.id
                  ? <div className="badge">有効</div>
                  : <button onClick={()=>updateDefaultPayment(pm.id)}>設定する</button>
                }
              </div>
            </div>
          );
        })}
        
        <div className="item">
          <div className="brand">デフォルトのカード</div>
          <div className="setting">
            {sub.default_payment_method == null
              ? <div className="badge">有効</div>
              : <button onClick={()=>updateDefaultPayment("")}>設定する</button>
            }
          </div>
        </div>
        
        <div className="item">
          <Link to="/account/card_setting" className="">新しいクレジットカードを設定する</Link>
          <div className="setting" onClick={()=>setOpen(false)}>閉じる</div>
        </div>
      </div>
      
    </div>
  );
};
