import React from 'react';

export const fontFamilys = [
  {name: "Noto Sans JP（初期）", value: ""},
  // {name: "Noto Sans JP（デフォルト）", value: "'Noto Sans JP', sans-serif"},
  {name: "Noto Serif JP", value: "'Noto Serif JP', sans-serif"},
  // {name: "Cormorant Garamond", value: "'Cormorant Garamond', sans-serif"},
  {name: "Roboto", value: "'Roboto', sans-serif"},
  {name: "Open Sans", value: "'Open Sans', sans-serif"},
  {name: "M PLUS Rounded 1c", value: "'M PLUS Rounded 1c', sans-serif"},
  {name: "游明朝", value: "'游明朝','Yu Mincho',YuMincho, sans-serif"},
  {name: "游ゴシック", value: "'游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', sans-serif"},
  {name: "メイリオ", value: "'メイリオ', 'Meiryo', 'ヒラギノ角ゴシック', sans-serif"},
  {name: "Meiryo UI", value: "'Meiryo UI', 'ヒラギノ角ゴシック', sans-serif"},
  {name: "system-ui", value: "system-ui, -apple-system, sans-serif"},
]


export const placeholder = {
  title: "はじめてのホームページ作成ならPAGE STOCK",
  subtitle: "No-Code Web Creation",
  content: "ページストックは専門知識がない方でも誰でも簡単に「WEBサイトが作成できる」ノーコードツール。集客につながるLP型のWEBサイト・ホームページ・イベントページ等が楽ラク作成可能。",
  img: "/cafe.jpg",
}