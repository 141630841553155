import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';

import { Block } from '@/block/Block';
// import { NewBlock } from '@/block/NewBlock';
import { Layout } from '@/page/Layout';
import { Nav } from '@/product/Nav';
// import { WidthSwitch } from '@/app/WidthSwitch';
import { PageNav } from '@/page/PageNav';
import { useAutoSave } from '@/hooks/useAutoSave';
import { GlobalMenus } from '@/editerMenu/GlobalMenus';
import { settingCSS } from '@/part/type/Style';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { useRecoilState, useSetRecoilState } from 'recoil'
import { productAtom, saveStatusAtom, pageWidthAtom } from '@/providers/Atoms';


export const ShareBlock = React.memo(() => {
  
  // const { blockId } = useParams();
  const { productId, blockId } = useParams();
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  // const { saveData, setSaveData } = useContext(SaveDataContext);
  // const setSaveData = useSetRecoilState(saveDataAtom)
  const setProduct = useSetRecoilState(productAtom)
  const setSaveStatus = useSetRecoilState(saveStatusAtom);
  const [ pageWidth ] = useRecoilState(pageWidthAtom);

  const [ block, setBlock ] = useState([]);
  const [ blocks, setBlocks ] = useState([]);
  const blocksState = { 
    blocks, setBlocks
  };
  
  // console.log("共有レンダリング", blockId);
  
  useEffect(() => {
    client.get(`/api/v1/blocks/${blockId}?subdomain=${productId}`)
    .then(resp => {
      setProduct(resp.data.product);
      setBlock(resp.data.block);
      setBlocks(resp.data.block);
      // setSaveData({blocks: [resp.data.block], parts: resp.data.parts});
    })
    .catch(e => {
      console.log(e);
    });
  }, []);
  

  // const [ width, setWidth ] = useState("");
  // const widthState = {
  //   width, setWidth
  // }
  
  // セーブデータの更新
  // const SaveDataUpdate = (newData) => {
  //   // const newBlocks = saveData.blocks.map(
  //   //   (obj) => (obj.id === block.id ? newData : obj)
  //   // );
  //   // setSaveData({ ...saveData, blocks: newBlocks});
    
  //   setSaveData((prev)=> ({ 
  //   ...prev, 
  //     blocks: prev.blocks.map((obj) => (
  //       obj.id === block.id ? newData : obj
  //     ))
  //   }));
  // };
  
  const InputChange = event => {
    const { name, value } = event.target;
     setBlock((prev)=> ({ ...prev, [name]: value }));
     
    // const newBlock = { ...block, [name]: value };
    // setBlock(newBlock);
    // SaveDataUpdate(newBlock);
  };
  
  
    // 保存
  const updateBlock = () => {
    client.patch(`/api/v1/blocks/${block.id}`, block)
    .then(resp => {
      setSaveStatus(false);
      console.log("保存完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】保存に失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(block, updateBlock, 1000);
  
  
  // 画面遷移のため
  const navigate = useNavigate();
  
  // 削除
  const deleteBlock = () => {
    const sure = window.confirm(`このブロックを本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/blocks/${block.id}`)
      .then(resp => {
        console.log("ブロックを削除しました");
        navigate(`/product/${productId}/blocks`);
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  const [ open, setOpen ] = useState(false);
  const [ display, setDisplay ] = useState("削除");
  
  const OpenToggle = ()=> {
    setOpen((prev)=> !prev);
    setDisplay(open ? "削除" : "閉じる");
  };
  
  
  // const SettingCSS = css`
  // ${settingCSS(setting)}
  // `

  return (
    <>
      <PageNav/>
      <GlobalMenus page_id={null}/>
      
      <Layout type="sharing">
        <div className="wrapper">
          
          <div className="main_layer"></div>
          <Nav/>
          
          <h1 className="H1 color_white">共有ブロックの編集</h1>
          
          <div className="badge badge_dark ">{block.status || "公開"}</div>

          <label className="color_white mt_2">ブロック名</label>
          <input 
            className="input input_lg"
            type="text"
            name="name" 
            value={block.name || ''} 
            onChange={InputChange} 
            placeholder="ブロック名を入力..."
          />
          
          <div className="page_wrapper" style={{width: pageWidth}}>
            <div 
              className="page_area"
              // style={{width: pageWidth}}
              // css={SettingCSS}
            >
              <Block block_obj={block} blocksState={blocksState} key={block.id}/>
            </div>
            
            <div style={{textAlign: "right"}}>
              <button className="" onClick={OpenToggle}>{display}</button>
            </div>
          </div>
          
          { open &&
            <div style={{textAlign: "center"}}>
              <button className="btn_delete" onClick={deleteBlock}>共有ブロックの削除</button>
              <div className="color_red mt_1">
                このブロックを使用している「すべてのページ」から、このブロックが削除されます。<br/>ご注意ください。
              </div>
            </div> 
          }
          
        </div>
      </Layout>
      
      <style>
        {`
          .navbar .icon{
            display: none;
          }
          .main {
            width: calc(100% - 50px);
          }
          .main_area {
            max-width: 100%;
            padding: 0;
          }
          .sidebar {
            width: 50px;
          }
          @media screen and (max-width: 820px) {
            body {
              padding-bottom: 50px;
            }
            .main {
              width: 100%;
            }
            .sidebar {
              width: 100%;
            }
            .footer {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
});

// <Layout saveAll={saveAll} type="sharing">