import React, { useState } from 'react';
import { Block } from '@/block/Block';
import { SectionSplide } from '@/section/SectionSplide';

import { useSetRecoilState } from 'recoil'
import { clickSectionAtom } from '@/providers/Atoms';


export const Slide = React.memo(({ section_id, section, blocksState, type }) => {
  
  const { blocks } = blocksState
  const [ active, setActive ] = useState(true);
  
  const setClickSection = useSetRecoilState(clickSectionAtom)
  // const setClickPart = useSetRecoilState(clickPartAtom)
  // const setClickBlock = useSetRecoilState(clickBlockAtom)
  
  const StopHandler = ()=> {
    setActive((prev)=> !prev);
    setClickSection(active && section.id);
    // setClickPart(0);
    // setClickBlock(0);
  };


 return (
    <>
      <button className="switch_stop_btn" onClick={StopHandler}>
        {active ? "編集する" : "編集終了"}
      </button>
      
      {active
        ? <SectionSplide 
            section={section} 
            blocksState={blocksState} 
            type={type}
          />
        :
        <>
          {blocks.map((block, index) => {
            return (
              <Block 
                block_obj={block} 
                blocksState={blocksState} 
                section_id={section.id}
                slide="splide"
                index={index}
                key={block.id}
              />
            );
          })}
        </>
      }
    </>
  );
  
});

  // const Style = {
  //   transform: active == false && "none",
  //   display: active == false && "block",
  // };
  
  // let Type;
  // if(section.section_type == "Fade") {
  //   Type = "fade";
  // } else {
  //   Type = "loop";
  // }
  

// <li className="splide__slide" key={index}>
//   <PartsProvider>
//     <Block block_obj={block} blocksState={blocksState} index={index}/>
//   </PartsProvider>
// </li>