import React, { useState, useEffect, useContext } from 'react';
// import TextareaAutosize from 'react-textarea-autosize';
// import { outlineStyle, inputStyle } from '@/part/type/Style';
import { StateContext } from '@/providers/StateProviders';
import { MenuSelect } from '@/part/MenuSelect';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, backgroundCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
// import { BiCheck } from "react-icons/bi";
// import { FaCheck } from "react-icons/fa";
import { BiCheckCircle } from "react-icons/bi";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';
import { PartBtn } from '@/part/type/PartBtn';

import { useSetRecoilState } from 'recoil'
import { openSidebarAtom } from '@/providers/Atoms';


export const List_A = React.memo(({ part, imgs, InputChange, Clear, textStates, textHandlers }) => {
  
  // console.log("リストパーツ", "レンダ");
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});

  const { client } = useContext(StateContext);
  const [ items, setItems ] = useState([]);

  // const GetMenu = (id) => {
  //   if(part?.menu_id !== null){
  //     client.get(`/api/v1/menus/${id}`)
  //     .then(resp => {
  //       setItems(resp.data.items);
  //     }) 
  //   }
  // };
  
  // useEffect(() => {
  //   GetMenu(part.menu_id);
  // }, []);
  
  useEffect(() => {
    if(part?.menu_id == null){
      setItems([])
    } else {
      client.get(`/api/v1/menus/${part.menu_id}`)
      .then(resp => {
        setItems(resp.data.items);
      })
    }
  }, [part.menu_id]);
  
  const menuSelectHandler = () => {
    setOpenSidebar("partMenu");
  };
  
  // const [ active, setActive ] = useState(false);
  
  // const OpenHandler = () => {
  //   setActive(true);
  // };

  // const List = (content) => {
  //   if(content == null) {
  //     return [];
  //   } else {
  //     const texts = content.split(/\n/);
  //     return texts; 
  //   }
  // }
  
  // const judge2_3 = part.class_name == "list_2-3"
  
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  let listCSS = css`
    ${outlineCSS(part)}
    
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      ${textCSS(subtitle)}
    }
    li svg {
      color: ${accentColors[0]};
    }
    a {
      color: ${part.font_color};
    }
    p {
      color: ${part.font_color};
      ${textCSS(content)}
    }
    
    ${part.class_name == "list_2-1" && `
      ul li{
        border-bottom: 1px solid ${accentColors[1]};
      }
    `}
    ${part.class_name == "list_2-2" && `
      ul li{
        border-bottom: 2px dashed ${accentColors[1]};
      }
    `}
  `
  
  if (part.class_name == "list_2-3") {
    listCSS = css`
      padding: ${part.padding && `${part.padding}px`};
      text-align: ${part.align};
      a {
        color: ${part.font_color};
      }
      p {
        color: ${part.font_color};
        ${textCSS(content)}
      }
      ul li {
        color: ${accentColors[0]};
        font-family: ${part.font};
        border-color: ${part.border_color};
        border-width : ${ part.border && `${part.border}px`};
        border-radius: ${part.radius && `${part.radius}px`};
        box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.2)`};
        ${backgroundCSS(part)}
      }
    `
  } else if(part.class_name == "list_3-1") {
    listCSS = css`
      font-family: ${part.font};
      color: ${part.font_color};
      height: ${part.height && `${part.height}px`};
      padding: ${part.padding && `${part.padding}px`};
      text-align: ${part.align};
      border-radius: ${part.radius && `${part.radius}px`};
      ${backgroundCSS(part)}
      
      .list_img {
        img, video {
          ${imgCSS(img_0)}
        }
        border-color: ${part.border_color};
        border-width : ${part.border && `${part.border}px`};
        box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.2)`};
      }
      .title {
        color: ${part.font_color};
        ${textCSS(title)}
      }
      .subtitle {
        ${textCSS(subtitle)}
      }
      li svg {
        color: ${accentColors[0]};
      }
      a {
        color: ${part.font_color};
      }
      p {
        color: ${part.font_color};
        ${textCSS(content)}
      }
    `
  }

  return (
    <>
      <div className="menu_select_btn" onClick={menuSelectHandler}>メニューから選択</div>
      {/* 
      {active &&
        <MenuSelect 
          part={part} 
          InputChange={InputChange} 
          GetMenu={GetMenu} 
          setItems={setItems} 
          Clear={Clear}
          setActive={setActive}
        />
      }
      */}

      <div
        className={`list_1 ${part.class_name}`}
        css={listCSS}
      >
        <div className="list_img">
          <PartImg
            obj={img_0}
            setObj={setImg_0}
          />
        </div>
        
        <div className="list_body">
          <h3 className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={placeholder.title}
              clickHandler={titleHandler}
            />
          </h3>
          
          <div className="subtitle">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder={placeholder.subtitle}
              clickHandler={subtitleHandler}
            />
          </div>
          
          {items.length > 0 
            ?
              <ul>
                {items.map((item) => {
                  return (
                    <li key={item.id}>
                      <BiCheckCircle/><a>{item.name}</a>
                    </li>
                  );
                })}
              </ul>
            :
              <p>
                <Textarea
                  part_id={part.id}
                  text_type="content"
                  obj={content}
                  setObj={setContent}
                  placeholder="こちらにリストの内容を「箇条書き」で入力してください。もしくは「メニュー」から選択してください。"
                  clickHandler={contentHandler}
                />
              </p>
          }
          
          {part.link && <PartBtn/>}
        </div>
        
      </div>
    </>
  );
  
});

// export const List_A = React.memo(({ part, InputChange, setOpen, Clear }) => {
  
//   // console.log("リストパーツ", "レンダ");

//   const { client } = useContext(StateContext);
//   const [ items, setItems ] = useState([]);

  
//   const GetMenu = (id) => {
//     if(part?.menu_id !== null){
//       client.get(`/api/v1/menus/${id}`)
//       .then(resp => {
//         setItems(resp.data.items);
//       }) 
//     }
//   };
  
//   useEffect(() => {
//     GetMenu(part.menu_id);
//   }, []);
  
//   const [ active, setActive ] = useState(false);
  
//   const OpenHandler = () => {
//     setActive(true);
//   };
  
  
//   const List = (content) => {
//     if(content == null) {
//       return [];
//     } else {
//       const texts = content.split(/\n/);
//       return texts; 
//     }
//   }
  
//   // const judge2_3 = part.class_name == "list_2-3"
  

//   let listCSS = css`
//     ${outlineCSS(part)}
//     .title, .subtitle {
//       color: ${part.font_color};
//       font-family: ${part.font};
//     }
//     li {
//       color: ${part.font_color};
//       color: ${part.border_color};
//     }
//     a, p {
//       ${fontCSS(part)}
//     }
//   `
  
//   if (part.class_name == "list_2-3") {
//     listCSS = css`
//       padding: ${part.padding && `${part.padding}px`};
//       text-align: ${part.align};
//       a, p {
//         ${fontCSS(part)}
//       }
//       ul li {
//         color: ${part.border_color};
//         font-family: ${part.font};
//         border-color: ${part.border_color};
//         border-width : ${ part.border && `${part.border}px`};
//         border-radius: ${part.radius && `${part.radius}px`};
//         box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.2)`};
//         ${backgroundCSS(part)}
//       }
//     `
//   } else if(part.class_name == "list_3-1") {
//     listCSS = css`
//       height: ${part.height && `${part.height}px`};
//       padding: ${part.padding && `${part.padding}px`};
//       text-align: ${part.align};
//       border-radius: ${part.radius && `${part.radius}px`};
//       ${backgroundCSS(part)}
      
//       .list_img img{
//         border-color: ${part.border_color};
//         border-width : ${part.border && `${part.border}px`};
//         border-radius: ${part.radius && `${part.radius}px`};
//         box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.2)`};
//       }
//       .title, .subtitle {
//         color: ${part.font_color};
//         font-family: ${part.font};
//       }
//       li {
//         color: ${part.font_color};
//         color: ${part.border_color};
//       }
//       a, p {
//         ${fontCSS(part)}
//       }
//     `
//   }

//   return (
//     <>
//       <button className="menu_select_btn" onClick={OpenHandler}>メニューから選択</button>
//       {active &&
//         <MenuSelect 
//           part={part} 
//           InputChange={InputChange} 
//           GetMenu={GetMenu} 
//           setItems={setItems} 
//           Clear={Clear}
//           setActive={setActive}
//         />
//       }
      
//       <div
//         className={`list_1 ${part.class_name} ${part.add_class||""}`}
//         css={listCSS}
//         id={part.add_id}
//       >
//         <div className="list_img" onClick={()=>setOpen("Img")}>
//           <img src={part.img || "/no_img2.png"} alt={part.alt}/>
//         </div>
        
//         <div className="list_body">
//           <h3 className="title">
//             <input
//               type="text" 
//               name="title" 
//               value={part.title || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.title}
//             />
//           </h3>
          
//           <div className="subtitle">
//             <TextareaAutosize
//               type="text" 
//               name="subtitle" 
//               value={part.subtitle || ''} 
//               onChange={InputChange}
//               placeholder="サブタイトル or 説明を入力..."
//             />
//           </div>
          
//           {items.length > 0 
//             ?
//               <ul>
//                 {items.map((item) => {
//                   return (
//                     <li key={item.id}>
//                       <FaCheck/><a>{item.name}</a>
//                     </li>
//                   );
//                 })}
//               </ul>
//             :
//               <p>
//                 <TextareaAutosize
//                   type="text"
//                   name="content" 
//                   value={part.content || ''} 
//                   onChange={InputChange} 
//                   placeholder="こちらにリストの内容を「箇条書き」で入力してください。もしくは「メニュー」から選択してください。"
//                 />
//               </p>
//           }
//         </div>
        
//       </div>
//     </>
//   );
  
// });