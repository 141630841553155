import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';


export const NewItem = ({ menu_id, itemsState }) => {

  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  
  const { items, setItems } = itemsState
  
  const new_item_data = {
    menu_id: menu_id,
  }
  
  //アイテム作成
  const AddItem = () => {
    client.post('/api/v1/items', new_item_data)
    .then(resp => {
      setItems([...items, resp.data]);
      setToastObj({message: "新しい項目を追加しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    });
  };


  return (
    <>
      <p className="info">ドラッグ＆ドロップで「並び替え」可能です。</p>
      <button className="btn aside_right" onClick={AddItem}>メニュー項目を追加</button>
    </>
  );
};
