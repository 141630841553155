import React, { useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { StateContext} from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';

import { Item } from '@/menu/Item';
import { NewItem } from '@/menu/NewItem';

export const Items = ({ menu_obj, itemsState }) => {
  
  // const [ items, setItems ] = useState(menu_obj?.items || []);
  // const itemsState = {
  //   items, setItems
  // }
  
  const { items, setItems } = itemsState

  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  //並び順を保存
  const OrderUpdate = (objs) => {
    client.patch(`/api/v1/items/order`, {items: objs})
    .then(resp => {
      // setToastObj({message: "並び替え完了", color: "#D1E7DD"});
      console.log("並替え完了");
    })
    .catch(e => {
      setToastObj({message: "【エラー】並び替えの保存に失敗しました。", color: "#ffedab"});
    });
  };

  // 並び替え処置
  const reorder = (list, startIndex, endIndex) => {
    const objs = [...list]
    const removed = objs.splice(startIndex, 1); //ドラッグ開始要素の削除
    objs.splice(endIndex, 0, removed[0]); //ドロップした箇所に挿入
    setItems(objs); //並び替え後をセット
    
    //並び順を保存
    OrderUpdate(objs);
  };
  
  // 並び替え後
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    reorder(items, result.source.index, result.destination.index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="section">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>

                {items.map((item, index) => {
                    return (

                      <Draggable key={item.id} draggableId={`item-${item.id}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}{...provided.draggableProps}{...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Item item_obj={item} itemsState={itemsState} index={index} key={item.id}/>
                          </div>
                        )}
                      </Draggable>
                      
                    );
                })}
                {provided.placeholder}
                
            </div>
          )}
        </Droppable>
      </DragDropContext>
      
      <NewItem menu_id={menu_obj.id} itemsState={itemsState} key={menu_obj.id} />
    </>
  );
};

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#00FF00" : "",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle, //あらかじめ用意されている。
  // border: isDragging ? 'none' : "", 
  cursor: "pointer",
});


// <>
//   {items.map((item, index) => {
//     return (
//       <Item item_obj={item} itemsState={itemsState} index={index} key={item.id}/>
//     );
//   })}
//   <NewItem menu_id={menu_obj.id} itemsState={itemsState} key={menu_obj.id} />
// </>