import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { AddonJudg } from '@/addon/AddonJudg';

export const CodeSetting = ({ register, errors, Clear }) => {
  
  
  return (
    <>
        <h2 className="H1 mb_2">外部コード設定</h2>
        
        <AddonJudg terms={/ビジネス|制作代行/} boolean={true} >
        
          <div className="form_field">
            <label>
              Google Analytics（グーグル アナリティクス）の設定
              <div className="btn_clear" type="button" onClick={()=>Clear("analytics")}>クリア</div>
            </label>
            <TextareaAutosize
              id="analytics"
              className={`input ${errors.analytics?.message && "error"}`}
              // style={{border: errors.analytics ? "1px solid red" :""}}
              type="text"
              placeholder="測定IDを入力..."
              {...register('analytics', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.analytics?.message && 
              <div className="color_red">{errors.analytics.message}</div>
            }
            <p>注）「測定ID」のみを入力してください。例）G-XXXXXXXX　又は　GTM-XXXXXXXX</p>
          </div>
        
          <div className="form_field">
            <label>
              Search Console（サーチコンソール）の設定
              <div className="btn_clear" type="button" onClick={()=>Clear("search_console")}>クリア</div>
            </label>
            <TextareaAutosize
              minRows={3}
              id="search_console"
              className={`input input_code ${errors.search_console?.message && "error"}`}
              // style={{border: errors.search_console ? "1px solid red" :""}}
              type="text"
              placeholder="HTMLタグを入力..."
              {...register('search_console', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.search_console?.message && 
              <div className="color_red">{errors.search_console.message}</div>
            }
            <p>注）「HTMLタグ」を入力してください。</p>
          </div>
          
          <div className="form_field">
            <label>
              headに出力する外部コードの設定
              <div className="btn_clear" type="button" onClick={()=>Clear("add_code")}>クリア</div>
            </label>
            <TextareaAutosize
              minRows={5}
              id="add_code"
              className={`input input_code ${errors.add_code?.message && "error"}`}
              // style={{border: errors.add_code ? "1px solid red" :""}}
              type="text"
              placeholder="コードを入力..."
              {...register('add_code', {
                // pattern: { value: /^[a-z0-9._]+$/, message: '【エラー】使用できない文字が含まれています。'},
              })}
            />
            {errors.add_code?.message && 
              <div className="color_red">{errors.add_code.message}</div>
            }
            <p>注）「script」タグは反映されません。</p>
          </div>
          
          <div className="form_field">
            <label>
              「script」コードを設定
              <div className="btn_clear" type="button" onClick={()=>Clear("add_script")}>クリア</div>
            </label>
            <TextareaAutosize
              minRows={5}
              id="add_script"
              className={`input input_code ${errors.add_code?.message && "error"}`}
              type="text"
              placeholder="コードを入力..."
              {...register('add_script', {
                // pattern: { value: /^(?!.*script).+$/, message: '【エラー】<script>タグを削除してください。'},
              })}
            />
            {errors.add_script?.message && 
              <div className="color_red">{errors.add_script.message}</div>
            }
            <p>注）&lt;script&gt; &lt;script/&gt;を削除して、「script」タグ以外のコードを設定してください。</p>
          </div>

        </AddonJudg>  
    </>
  );
};


// <label>Search Console（サーチコンソール）</label>
//           <TextareaAutosize
//             type="text" 
//             className="input" 
//             minRows={3}
//             name="search_console" 
//             value={setting.search_console || ''} 
//             onChange={SettingChange} 
//             placeholder="コードを入力..."
//           />
          
//           <label>Google Analytics（グーグル アナリティクス）</label>
//           <TextareaAutosize
//             type="text" 
//             className="input" 
//             minRows={3}
//             name="analytics" 
//             value={setting.analytics || ''} 
//             onChange={SettingChange} 
//             placeholder="コードを入力..."
//           />
          
//           <label>headに出力するコード</label>
//           <TextareaAutosize
//             type="text" 
//             className="input" 
//             minRows={3}
//             name="add_code" 
//             value={setting.add_code || ''} 
//             onChange={SettingChange} 
//             placeholder="コードを入力..."
//           />
          
//           <label>サイト全体に適用されるスタイル</label>
//           <TextareaAutosize
//             type="text" 
//             className="input" 
//             minRows={3}
//             name="add_style" 
//             value={setting.add_style || ''} 
//             onChange={SettingChange} 
//             placeholder="コードを入力..."
//           />
          
//           <label>javascriptを出力</label>
//           <TextareaAutosize
//             type="text" 
//             className="input" 
//             minRows={3}
//             name="add_script" 
//             value={setting.add_script || ''} 
//             onChange={SettingChange} 
//             placeholder="コードを入力..."
//           />