import React, { useState, useEffect, useContext } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { PutObjectCommand, DeleteObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { Nav } from '@/product/Nav';
import { Image } from '@/image/Image';

import { useRecoilState } from 'recoil'
import { freeImagesAtom } from '@/providers/Atoms';


export const FreeImages = () => {
  
  const { setToastObj } = useContext(ToastContext);
  const { client, s3key } = useContext(StateContext);
  
  // const [ images, setImages ] = useState([]);
  const [ images, setImages ] = useRecoilState(freeImagesAtom)

  useEffect(() => {
    if(images == ""){
      console.log("フリー画像取得");
      client.get(`/api/v1/images/free`)
      .then(resp => {
        setImages(resp.data);
      })
    }
  }, []);
  
  
  //アクセスキー設定
  const s3Client = new S3Client({
    region: 'ap-northeast-1',
    credentials: {
      accessKeyId: s3key.accesskey,
      secretAccessKey: s3key.secretkey,
    },
  });


  // 画像保存
  const AddImage = (save_date) => {
    client.post('/api/v1/images', save_date)
    .then(resp => {
      setImages([resp.data, ...images]);
      console.log("画像を保存しました");
      setToastObj({message: "画像を保存しました", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e)
    })
  };

  // s3に画像アップ
  const InputFileUp = (params, save_date) => {
    s3Client.send(new PutObjectCommand(params))
    .then(resp => {
      AddImage(save_date);
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  
  // 画像取得＆プレビュー
  const InputFile = e => {
    // const { name } = event.target;
    const file = e.target.files[0];
    
    const key = Math.random().toString(36).slice(-8);
    const params = {
      ACL: 'public-read',
      Bucket: s3key.bucket,
      // Key: `image/free/${file.name}`,
      Key: `image/free/${key}-${file.name}`,
      Body: file,
      ContentType: file.type,
    };
    
    const save_date = {
      img: `https://${params.Bucket}.s3.ap-northeast-1.amazonaws.com/${params.Key}`,
      img_type: "free",
      size: file.size,
      name: file.name,
      img_key: params.Key,
    };

    InputFileUp(params, save_date);
  };
  

  // 画像削除
  const deleteImage = (img_id, index) => {
    // データベースから削除
    client.delete(`/api/v1/images/${img_id}`)
    .then(resp => {
      const new_imgs = [...images];
      new_imgs.splice(index, 1);
      setImages(new_imgs);
      setToastObj({message: "削除しました", color: "#D1E7DD"});
      console.log("画像を削除しました");
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // s3のファイル削除
  const s3FileDelete = (img_id, img_url, index) => {
    const sure = window.confirm(`この写真を本当に削除しますか?`);
    
    if (sure) {
      const key = img_url.replace(`https://${s3key.bucket}.s3.ap-northeast-1.amazonaws.com/`, "");
      
      const Params = { 
        Bucket: s3key.bucket,
        Key: key
      };

      s3Client.send(new DeleteObjectCommand(Params))
      .then(resp => {
        deleteImage(img_id, index);
        console.log("s3から削除しました");
      })
      .catch(e => {
        setToastObj({message: "写真ファイルの削除に失敗しました。", color: "#ffedab"});
      });
    }
  };
  
  
  // すべての画像取得
  const getAllImgs = () => {
    client.get('/api/v1/images')
    .then(resp => {
      setImages(resp.data);
    })
  };
  
  const getFreeImgs = () => {
    client.get(`/api/v1/images/free`)
    .then(resp => {
      setImages(resp.data);
    })
  };
  
  // フリー画像に変更
  const changeFreeImg = (obj) => {
    client.patch(`/api/v1/images/${obj.id}`, { ...obj, img_type: "free" })
    .then(resp => {
      setToastObj({message: "フリー写真に変更しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      console.log(e);
    });
  }
  

  return (
    <>
      <Nav/>

      <h1 className="H1 pb_1">フリー写真</h1>
      <button className="" onClick={getAllImgs}>すべての写真を表示</button>
      <button className="" onClick={getFreeImgs}>フリー写真を表示</button>
      
      <div className="base_box">
        <label className="btn_add" style={{marginBottom: "0"}}>
          フリー写真をアップロード
          <input 
            type="file" 
            name="img" 
            accept="image/*, video/*"
            onChange={InputFile}
          />
        </label>
        
        { images.length > 0 &&
          <div className="img_list">
            {images.map((image, index) => {
              return (
                <Image 
                  image={image} 
                  index={index} 
                  s3FileDelete={s3FileDelete}
                  changeFreeImg={changeFreeImg}
                  key={index}
                />
              );
            })}
          </div>
        }
      </div>
    </>
  );
};
