import React, { useState } from "react"
// import { PartTypes } from '@/part/PartTypes';
import { PartCategory } from '@/part/PartTypes';
import { PartSelect } from '@/part/PartSelect';
import { BiX } from "react-icons/bi";


export const PartGlobalChange = React.memo(({ partState, setStates, setOpenSidebar }) => {
  
  console.log("タイプ変更");
  
  const { part, setPart } = partState;
  const [ show, setShow ] = useState("Part");

  const PartTypeChange = (value) => {
    let type = value.split(" ")
    // setPart((prev)=> ({ ...prev, component_name: type[0], class_name: type[1] }));

    const newPart = { ...part, component_name: type[0], class_name: type[1] };
    setStates(newPart);
  };
  
  // 閉じるボタン
  const CloseHandler = ()=> {
    setOpenSidebar("");
  };
  
  const componentName = part.component_name || ""
  

// <div className="cover" onClick={CloseHandler}/>
  return (
    <>
      <div className="items_bar">
      
        <div className="items_bar_top">
          <div className="sidebar_title">
            パーツタイプ変更
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box select_type" name={`part_${part.id}`}>
          {show == "Part" &&
            <>
              {PartCategory.map((category, index) => {
                return (
                  <button key={index}
                    className={`type_btn ${componentName.includes(category.type) && "active"}`}
                    onClick={()=>setShow(category.type)}
                  >
                    {category.name}
                  </button>
                );
              })}
            </>
          }
          
          {show !== "Part" &&
            <button className="clear_btn mb_2" onClick={()=>setShow("Part")}>戻る</button>
          }
          
          <PartSelect 
            obj={part}
            show ={show}
            PartTypeChange={PartTypeChange}
            CloseHandler={CloseHandler}
          />
        </div>

      </div>
    </>
  );
  
});