import React from 'react';
import { Link } from 'react-router-dom';
import { useDateTime } from '@/hooks/useDateTime';

export const HomeNotices = React.memo(({ notices }) => {

  return (
    <>
      <div className="notice_card">
        { notices.length == 0 &&
          <>
            <h2 className="title">お知らせ</h2>
            <div>ステップにそって作成ください。（全３ステップ）</div>
          </>
        }
        
        <div className="notices home_notices">
          {notices.map((notice) => {
            return (
              <Link className="item" key={notice.id} to={`/notice/${notice.id}`}>
                <span className="day">{useDateTime(notice.created_at)}</span>
                <span className="item_subtitle">{notice.subtitle || "お知らせ"}</span>
                <div className="item_title">{notice.title || "ノータイトル"}</div>
              </Link>
            );
          })}
        </div>
      </div> 
    </>
  );
});