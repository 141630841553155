import React from "react"

export const RichEditor = React.memo(({ setContent, textareaRef, open, setOpen }) => {

  
  const tagStrong = () => {
    // console.log("取得", textareaRef.current.value);
    let text = textareaRef.current.value;
    let start = textareaRef.current.selectionStart;
    let end = textareaRef.current.selectionEnd;
    
    let beforeText = text.substr(0, start)
    let selectedText = text.substr(start, end - start)
    let afterText = text.substr(end)
    let tag1 = "<strong>"
    let tag2 = "</strong>"
    
    let nowText = beforeText + tag1 + selectedText + tag2 + afterText;
    // setPart({...part, content: nowText });
    setContent((prev)=> ({ ...prev, content: nowText}));
  }
  
  const tagColorRed = () => {
    let text = textareaRef.current.value;
    let start = textareaRef.current.selectionStart;
    let end = textareaRef.current.selectionEnd;
    
    let beforeText = text.substr(0, start)
    let selectedText = text.substr(start, end - start)
    let afterText = text.substr(end)
    let tag1 = `<span class="color_red">`
    let tag2 = `</span>`
    
    let nowText = beforeText + tag1 + selectedText + tag2 + afterText;
    setContent((prev)=> ({ ...prev, content: nowText}));
  }
  
  const tagMarker = () => {
    let text = textareaRef.current.value;
    let start = textareaRef.current.selectionStart;
    let end = textareaRef.current.selectionEnd;
    
    let beforeText = text.substr(0, start)
    let selectedText = text.substr(start, end - start)
    let afterText = text.substr(end)
    let tag1 = `<span class="marker">`
    let tag2 = `</span>`
    
    let nowText = beforeText + tag1 + selectedText + tag2 + afterText;
    setContent((prev)=> ({ ...prev, content: nowText}));
  }
  
  const tagRemove = () => {
    let text = textareaRef.current.value;
    let start = textareaRef.current.selectionStart;
    let end = textareaRef.current.selectionEnd;
    
    let beforeText = text.substr(0, start)
    let selectedText = text.substr(start, end - start)
    let afterText = text.substr(end)
    
    let nowValue = selectedText.replace("<strong>", "").replace("</strong>", "");
    
    let nowText = beforeText + nowValue + afterText;
    // setPart({...part, content: nowText });
    setContent((prev)=> ({ ...prev, content: nowText}));
  }
  
  
  return (
    <div className="visual_switch">
      {open == "visual" &&
        <button onClick={()=>setOpen("text")}
          className={ open == "text" ? "active" :""}
        >入力に戻る</button>
      }
      
      {open == "text" &&
        <>
          <button onClick={()=>setOpen("visual")}
            className={ open == "visual" ? "active" :""}
          >ビジュアル</button>
          <button onClick={tagStrong}>太字</button>
          <button onClick={tagColorRed}>赤字</button>
          <button onClick={tagMarker}>マーカー</button>
          
          {/*<button onClick={tagRemove}>クリア</button>*/}
        </>
      }
    </div>
  );
  
});