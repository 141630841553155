import React from 'react';
// import { SpinBtn } from '@/app/SpinBtn';
import { RangeSpin } from '@/app/RangeSpin';


export const ImgEdit = React.memo(({ obj, setObj, type, InputChange, Clear, setShow }) => {
  
  
  // const SaveDataUpdate = () => {
  // };

  const flipValue = [
    { name: "左右", value: "scaleX(-1)"},
    { name: "上下", value: "scaleY(-1)"}, 
    { name: "上下左右", value: "scale(-1, -1)"}, 
  ]
  
  const aspectRatioValue = [
    { name: "1：1", value: "1/1"},
    { name: "4：3", value: "4/3"},
    { name: "3：2", value: "3/2"}, 
    { name: "16：9", value: "16/9"}, 
    { name: "2：1", value: "2/1"},
    { name: "728：90", value: "728/90"},
    { name: "9：10", value: "9/10"},
    { name: "5：6", value: "5/6"},
    { name: "3：4", value: "3/4"},
  ]
  
    // リセット
  const Reset = () => {
    const newImg = {
      ...obj, 
      aspect_ratio: null,
      flip: null,
      radius: null,
      opacity: null,
      blur: null,
      grayscale: null,
      brightness: null,
      sepia: null,
      saturate: null,
      shadow: null,
    };
    setObj(newImg);
  }

  return (
    <>
      <button className="clear_btn mb_2" onClick={()=>setShow("")}>戻る</button>
      
      <label>反転
        <div className="btn_clear" type="button" onClick={()=>Clear("flip")}>クリア</div>
      </label>
      <form className="radio_box">
        {flipValue.map((item) => {
          return (
            <label key={item.name} className={item.value == obj.flip ? "is_on": ""}>
              <input
                type="radio"
                id={item.name}
                name="flip"
                value={item.value}
                onChange={InputChange}
              />
              {item.name}
            </label>
          );
        })}
      </form>
      
      { type == "part" &&
        <>
          <label>縦横比（アスペクト比）
            <div className="btn_clear" type="button" onClick={()=>Clear("aspect_ratio")}>クリア</div>
          </label>
          <form className="radio_box">
            {aspectRatioValue.map((item) => {
              return (
                <label key={item.name} className={item.value == obj.aspect_ratio ? "is_on": ""}>
                  <input
                    type="radio"
                    id={item.name}
                    name="aspect_ratio"
                    value={item.value}
                    onChange={InputChange}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>
          
          <label>角丸（{obj.radius || ""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("radius")}>クリア</div>
          </label>
          <RangeSpin
            obj={obj}
            setObj={setObj}
            column="radius"
            objValue={obj.radius || 1}
            InputChange={InputChange}
          />
        </>
      }
      
      <label>シャドウ（{obj.shadow || ""}px）
        <div className="btn_clear" type="button" onClick={()=>Clear("shadow")}>クリア</div>
      </label>
      <RangeSpin
        obj={obj}
        setObj={setObj}
        column="shadow"
        objValue={obj.shadow || 0}
        InputChange={InputChange}
        max="40"
      />

      <label>透明度（{obj.opacity || ""}%）
        <div className="btn_clear" type="button" onClick={()=>Clear("opacity")}>クリア</div>
      </label>
      <RangeSpin
        obj={obj}
        setObj={setObj}
        column="opacity"
        objValue={obj.opacity || 50}
        InputChange={InputChange}
      />
      
      <label>グレースケール（{obj.grayscale || ""}%）
        <div className="btn_clear" type="button" onClick={()=>Clear("grayscale")}>クリア</div>
      </label>
      <RangeSpin
        obj={obj}
        setObj={setObj}
        column="grayscale"
        objValue={obj.grayscale ||50} 
        InputChange={InputChange}
      />
      
      <label>明度（{obj.brightness || ""}%）
        <div className="btn_clear" type="button" onClick={()=>Clear("brightness")}>クリア</div>
      </label>
      <RangeSpin
        obj={obj}
        setObj={setObj}
        column="brightness"
        objValue={obj.brightness || 100}
        InputChange={InputChange}
        max="200"
      />

      <label>彩度（{obj.saturate || ""}%）
        <div className="btn_clear" type="button" onClick={()=>Clear("saturate")}>クリア</div>
      </label>
      <RangeSpin
        obj={obj}
        setObj={setObj}
        column="saturate"
        objValue={obj.saturate || 100}
        InputChange={InputChange}
        max="200"
      />
      
      <label>セピア（{obj.sepia || ""}%）
        <div className="btn_clear" type="button" onClick={()=>Clear("sepia")}>クリア</div>
      </label>
      <RangeSpin
        obj={obj}
        setObj={setObj}
        column="sepia"
        objValue={obj.sepia || 50}
        InputChange={InputChange}
      />
      
      <label>ぼかし（{obj.blur || ""}px）
        <div className="btn_clear" type="button" onClick={()=>Clear("blur")}>クリア</div>
      </label>
      <RangeSpin
        obj={obj}
        setObj={setObj}
        column="blur"
        objValue={obj.blur ||10} 
        InputChange={InputChange}
        max="20"
      />
      
      <button className="clear_btn mb_2" onClick={Reset}>リセット</button>
    </>
  );

});



// <label>ぼかし
//   <div className="btn_clear" type="button" onClick={()=>Clear("blur")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="blur"
//   objValue={obj.blur}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>グレースケール
//   <div className="btn_clear" type="button" onClick={()=>Clear("grayscale")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="grayscale"
//   objValue={obj.grayscale}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>明度
//   <div className="btn_clear" type="button" onClick={()=>Clear("brightness")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="brightness"
//   objValue={obj.brightness}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>彩度
//   <div className="btn_clear" type="button" onClick={()=>Clear("saturate")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="saturate"
//   objValue={obj.saturate}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>透明度
//   <div className="btn_clear" type="button" onClick={()=>Clear("opacity")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="opacity"
//   objValue={obj.opacity}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>セピア
//   <div className="btn_clear" type="button" onClick={()=>Clear("sepia")}>クリア</div>
// </label>
// <SpinBtn
//   obj={obj}
//   setObj={setObj}
//   column="sepia"
//   objValue={obj.sepia}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />