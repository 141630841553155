import React, { useContext } from "react"
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { BiX } from "react-icons/bi";
import { SpinBtn } from '@/app/SpinBtn';
import { RangeSpin } from '@/app/RangeSpin';
import { RangeSpinSplit } from '@/app/RangeSpinSplit';
import { AddonJudg } from '@/addon/AddonJudg';

// import { useSetRecoilState } from 'recoil'
// import { saveDataAtom, clickSectionAtom } from '@/providers/Atoms';


export const BlockSetting = React.memo(({ 
  blockState, blocksState, InputChange, updateBlock, 
  dateBlock, section_id, Clear, setOpen }) => {

  // console.log("ブロック設定レンダ");
  
  const { productId, blockId, slug } = useParams();
  // const { blockId } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);
  // const setClickSection = useSetRecoilState(clickSectionAtom)
  
  const { block, setBlock } = blockState
  const { setBlocks } = blocksState;
  
  const CloseHandler = ()=> {
    setOpen("");
  };

  
  //ブロックを共有化
  const sharingBlock = () => {
    // const newBlock = { ...block, sharing: "sharing" }
    client.patch(`/api/v1/blocks/${block.id}`, { ...block, sharing: "sharing" })
    .then(resp => {
      setBlock(resp.data);
      setToastObj({message: "共有化しました。", color: "#D1E7DD"});
    })
  };

  
  // 共有ブロックを外す
  const offSharing = () => {
    const sure = window.confirm(`この共有ブロックを本当に外しますか?`);
    if (sure) {
      client.post(`/api/v1/blocks/off_block`, {section_id: section_id, block_id: block.id})
      .then(resp => {
        setBlocks((prev) =>
          prev.filter((obj) => obj.id !== block.id)
        );
        setToastObj({message: "共有ブロックを外しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  
  // 削除
  const deleteBlock = () => {
    const sure = window.confirm(`（${block.id}）このブロックを本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/blocks/${block.id}`)
      .then(resp => {
        setBlocks((prev) =>
          prev.filter((obj) => obj.id !== block.id)
        );
        // setToastObj({message: "ブロックを削除しました。", color: "#ffedab"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】削除に失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  // const pageData = {
  //   subdomain: productId,
  //   slug: slug,
  // }
    
  // 固定&解除
  const fixed = (type) => {
    client.patch(`/api/v1/blocks/fixed/${block.id}`, {
        subdomain: productId,
        slug: slug, 
        block_type: type
      })
    .then(resp => {
      setBlock(resp.data);
      // SaveDataUpdate(resp.data);
      
      if (resp.data.block_type == "fixed_header") {
        setToastObj({message: "上部に固定しました。", color: "#D1E7DD"});
      } else if (resp.data.block_type == "fixed_footer") {
        setToastObj({message: "下部に固定しました。", color: "#D1E7DD"});
      } else if (resp.data.block_type == null){
        setToastObj({message: "固定を解除しました。", color: "#D1E7DD"}); 
      }
    })
    .catch(e => {
      setToastObj({message: `${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  
  // 幅いっぱいにする
  const fullWidth = () => {
    const newBlock = {...block, wrapper: 2000};
    setBlock(newBlock);
    // SaveDataUpdate(newBlock);
  }

  
  // paddingの分割
  let paddings = block.padding ? block.padding.split("／") : [];
  // console.log(paddings);
  
  const paddingChange = event => {
    const { name, value } = event.target;

    if (name == "padding_top") {
      setBlock((prev)=> ({ ...prev, padding: `${value}／${paddings[1] ||""}` }));
    } else if (name == "padding_bottom") {
      setBlock((prev)=> ({ ...prev, padding: `${paddings[0] ||""}／${value}` }));
    }
  };
  
  // paddingクリア
  const paddingClear = (column) => {
    if (column == "padding_top") {
      setBlock((prev)=> ({ ...prev, padding: `／${paddings[1] ||""}` }));
    } else if (column == "padding_bottom") {
      setBlock((prev)=> ({ ...prev, padding: `${paddings[0] ||""}` }));
    }
  }



  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">
      
        <div className="items_bar_top">
          <div className="sidebar_title">
            ブロック設定
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
        
          <label>ステータス</label>
          <div className="radio_box">
            {["公開", "非公開"].map((item) => {
              return (
                <label key={item}
                  className={item == block.status ? "is_on": ""}
                  // style={{border: item == block?.status && "1px solid #00ff2c"}}
                >
                  <input
                    type="radio"
                    id={item}
                    name="status"
                    value={item}
                    onChange={InputChange}
                    checked={item == block?.status}
                  />
                  {item}
                </label>
              );
            })}
          </div>
          
          <div className="input_fields">
            <div className="field_2">
              <label>横並び数（カラム）</label>
              <SpinBtn
                setObj={setBlock}
                column="column"
                objValue={block.column ||1}
                InputChange={InputChange}
                // SaveDataUpdate={SaveDataUpdate}
              />
            </div>
          
            <div className="field_2">
              <label>スマホ時
                <div className="btn_clear" type="button" onClick={()=>Clear("column_s")}>クリア</div>
              </label>
              <SpinBtn
                setObj={setBlock}
                column="column_s"
                objValue={block.column_s ||1}
                InputChange={InputChange}
              />
            </div>
          </div>
          
          <label>内側のスペース【上】（{paddings[0] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_top")}>クリア</div>
          </label>
          <RangeSpinSplit
            setObj={setBlock}
            InputChange={paddingChange}
            targetName="padding"
            column="padding_top"
            objValueMain={paddings[0] ||100}
            objValueOther={paddings[1] ||""}
            beforeAfter="before"
            max="200"
          />
          
          <label>内側のスペース【下】（{paddings[1] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_bottom")}>クリア</div>
          </label>
          <RangeSpinSplit
            setObj={setBlock}
            InputChange={paddingChange}
            targetName="padding"
            column="padding_bottom"
            objValueMain={paddings[1] ||100}
            objValueOther={paddings[0] ||""}
            beforeAfter="after"
            max="200"
          />
          
          <div className="form_field">
            <label>全体幅（{block.wrapper ||""}px）
              <div className="btn_clear" type="button" onClick={()=>Clear("wrapper")}>クリア</div>
            </label>
            <RangeSpin
              setObj={setBlock}
              column="wrapper"
              objValue={block.wrapper ||1150} 
              InputChange={InputChange}
              max="2000"
              min="300"
            />

            <p style={{color:"#03c494", textAlign:"right"}} onClick={fullWidth}>
              横幅いっぱいにする
            </p>
          </div>
          
          <label>高さ（{block.height ||""}vh）
            <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
          </label>
          <RangeSpin
            setObj={setBlock}
            column="height"
            objValue={block.height ||55} 
            InputChange={InputChange}
            min="10"
          />

          { !blockId &&
            <div className="form_field">
              <label>ブロックの固定（上部 又は 下部）</label>
              {/fixed/.test(block.block_type)
                ? <button className="btn_line w_100" onClick={()=>fixed(null)}>固定を解除する</button>
                : <>
                    <button className="btn_line mr_1" onClick={()=>fixed("fixed_header")}>上部に固定</button>
                    <button className="btn_line" onClick={()=>fixed("fixed_footer")}>下部に固定</button>
                  </>
              }
              <p>固定の確認は「公開ページを見る」で確認ください。この編集ページでは固定されません。</p>
            </div>
          }
          
          
          <AddonJudg terms={/|スタンダード|ビジネス|共有ブロック/} boolean={true}>
            { !blockId &&
              <div className="form_field">
                { block.sharing !== "sharing"
                  ? 
                    <>
                      <label>ブロックの共有化</label>
                      <button className="btn_line w_100" onClick={sharingBlock}>共有化する</button>
                    </>
                  : 
                    <>
                      { block.sharing == "sharing" &&
                        <>
                          <label>共有化済みブロック
                            <div className="btn_clear" type="button" onClick={()=>Clear("sharing")}>解除</div>
                          </label>
                          <button className="btn_line w_100" onClick={offSharing}>共有ブロックを外す</button>
                          <div className="mt_1">
                            <p>削除されるわけではありません。共有ブロックがこのページで表示されなくなるだけです。</p>
                            <Link to={`/product/${productId}/block/${block.id}`} className="color_key">編集・削除はこちら</Link>
                          </div>
                        </>
                      }
                    </>
                }
              </div>
            }
          </AddonJudg>

          
          <AddonJudg terms={/ビジネス/} boolean={true}>
            <label>id追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_id")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_id"
              value={block?.add_id || ""} 
              onChange={InputChange}
              placeholder="追加するidを入力..."
            />
            
            <label>class追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_class")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_class"
              value={block?.add_class || ""} 
              onChange={InputChange}
              placeholder="追加するclassを入力..."
            />
          </AddonJudg>
          
          { block.sharing !== "sharing" &&
            <>
              <label>ブロックの削除</label>
              <button className="btn_delete w_100" onClick={deleteBlock}>削除する</button>
            </>
          }
          
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={updateBlock}>保存</button>
        </div>

      </div>
    </>
  );
});


// <label>余白：内側上下（{block.padding ||""}px）
//   <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
// </label>
// <RangeSpin
//   obj={block}
//   setObj={setBlock}
//   column="padding"
//   objValue={block.padding ||100} 
//   InputChange={InputChange}
//  max="200"
// />


// <label>余白：内側上下（padding）
//   <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
// </label>
// <SpinBtn
//   obj={block}
//   setObj={setBlock}
//   column="padding"
//   objValue={block.padding ||100}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <div className="form_field">
//   <label>全体幅（wrapper）
//     <div className="btn_clear" type="button" onClick={()=>Clear("wrapper")}>クリア</div>
//   </label>
//   <SpinBtn
//     obj={block}
//     setObj={setBlock}
//     column="wrapper"
//     objValue={block.wrapper ||1000}
//     InputChange={InputChange}
//     SaveDataUpdate={SaveDataUpdate}
//   />
//   <p 
//     style={{color:"#03c494", textAlign:"right"}}
//     onClick={fullWidth}
//   >横幅いっぱいにする</p>
// </div>

// <label>高さ（vh）
//   <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
// </label>
// <div className="selectbox">
//   <select 
//     className="" 
//   name="height"
//   value={block.height || ''} 
//   onChange={InputChange}
//   >
//     <option value={null}>未設定</option>
//     <option value={100}>100vh</option>
//     <option value={90}>90vh</option>
//     <option value={80}>80vh</option>
//     <option value={70}>70vh</option>
//     <option value={60}>60vh</option>
//     <option value={50}>50vh</option>
//     <option value={40}>40vh</option>
//     <option value={30}>30vh</option>
//     <option value={20}>20vh</option>
//   </select>
// </div>

          // <SpinBtn
          //   obj={block}
          //   setObj={setBlock}
          //   column="height"
          //   objValue={block.height ||200}
          //   InputChange={InputChange}
          //   SaveDataUpdate={SaveDataUpdate}
          // />


// <div className="form_field">
//   <label>ブロックを上部に固定</label>
//   <button className="btn_line" onClick={fixedTop}>上部に固定する</button>
//   <button className="btn_line ml_1" onClick={fixedTopOff}>解除する</button>
//   <p>固定の確認は「公開ページを見る」で確認ください。この編集ページでは固定されません。</p>
// </div>

  // // 上部に固定
  // const fixedTop = () => {
  //   client.patch(`/api/v1/sections/fixed_top/${section_id}`)
  //   .then(resp => {
  //     setToastObj({message: "上部に固定しました。", color: "#D1E7DD"});
  //   })
  //   .catch(e => {
  //     setToastObj({message: `${e.response.data}`, color: "#ffedab"});
  //     console.log(e);
  //   });
  // };
  
  // // 上部固定の解除
  // const fixedTopOff = () => {
  //   client.patch(`/api/v1/sections/fixed_top_off/${section_id}`)
  //   .then(resp => {
  //     setToastObj({message: "上部固定を解除しました。", color: "#D1E7DD"});
  //   })
  //   .catch(e => {
  //     setToastObj({message: `${e.response.data}`, color: "#ffedab"});
  //     console.log(e);
  //   });
  // };


// { block.sharing !== "sharing" &&
//   <>
//     <label>ブロックの共有化
//       <div className="btn_clear" type="button" onClick={()=>Clear("sharing")}>クリア</div>
//     </label>
//     <div className="radio_box">
//       <label
//         style={{border: block?.sharing == "sharing" && "1px solid #00ff2c"}}
//       >
//         <input
//           type="checkbox"
//           id="sharing"
//           name="sharing"
//           value={block?.sharing == "sharing" ? "" : "sharing"}
//           onChange={InputChange}
//           checked={block?.sharing == "sharing"}
//         />
//         {block?.sharing == "sharing" ? "共有化済み" : "共有化する"}
//       </label>
//     </div>
//   </>
// }

             
// { blockId == block.id 
//   ? ""
//   : 
//     <>
//       { block.sharing == "sharing" && blockId !== block.id &&
//        <div className="form_field">
//           <label>ブロックの共有化</label>
//           <button className="btn_line" onClick={offBlock}>共有ブロックを外す</button>
//           <div className="mt_1">
//             削除されるわけではありません。共有ブロックがこちらで使用されなくなるだけです。
//             <Link to={`/product/${productId}/block/${block.id}`} className="color_red">編集・削除はこちら</Link>
//           </div>
//         </div>
//       }
//     </>
// }
            


// <label>ブロックタイプ
//   <div className="btn_clear" type="button" onClick={()=>Clear("block_type")}>クリア</div>
// </label>
// <select 
//  className="input" 
//  name="block_type" 
//  value={block?.block_type || ""} 
//  onChange={InputChange}
// > 
//   <option value="">ノーマル</option>
//   <option value="loop">loop</option>
//   <option value="fade">fade</option>
//   <option value="slide">slide</option>
// </select>

// <label>切り替わる秒数
//   <div className="btn_clear" type="button" onClick={()=>Clear("seconds")}>クリア</div>
// </label>
// <SpinBtn
//   obj={block}
//   setObj={setBlock}
//   column="seconds"
//   objValue={block.seconds ||0}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

          

            // <label>共有
            //   <div className="btn_clear" type="button" onClick={()=>Clear("sharing")}>クリア</div>
            // </label>
            // <select
            //   className="input" 
            //  name="sharing" 
            //  value={block?.sharing || ""} 
            //  onChange={InputChange}
            // > 
            //   <option value="">未設定</option>
            //   <option value="sharing">共有する</option>
            // </select>
            
            
            
          // <label>カラム
          //   <div className="btn_clear" type="button" onClick={()=>Clear("column")}>クリア</div>
          // </label>
          // <input 
          //  className="input" 
          //  type="number" 
          //  name="column" 
          //  value={block?.column || 1} 
          //  onChange={InputChange}
          // />

          // <label>スペース（padding）
          //   <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
          // </label>
          // <div className="spin_btn">
          //   <span onClick={()=>spinDown("padding", block.padding||30)}><BiMinus/></span>
          //   <input 
          //    className="input" 
          //    type="number"
          //    name="padding"
          //    value={block?.padding || 30} 
          //    onChange={InputChange}
          //   />
          //   <span className="" onClick={()=>spinUp("padding", block.padding||30)}><BiPlus/></span>
          // </div>
          
          // <label>高さ（px）
          //   <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
          // </label>
          // <input 
          //  className="input" 
          //  type="number"
          //  min="50"
          //  name="height"
          //  value={block?.height || 300} 
          //  onChange={InputChange}
          // />

          // <label>全体幅（wrapper）
          //   <div className="btn_clear" type="button" onClick={()=>Clear("wrapper")}>クリア</div>
          // </label>
          // <input 
          //  className="input"
          //  type="number"
          //  max="1500"
          //  min="500"
          //  name="wrapper"
          //  value={block?.wrapper || 1000} 
          //  onChange={InputChange}
          // />
          
          // <label>切り替わる秒数
          //   <div className="btn_clear" type="button" onClick={()=>Clear("seconds")}>クリア</div>
          // </label>
          // <input 
          //  className="input"
          //  type="number"
          //  name="seconds"
          //  value={block?.seconds || 0} 
          //  onChange={InputChange}
          // />