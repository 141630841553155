import React, { useContext } from 'react';
import { BiX } from "react-icons/bi";
// import { SpinBtn } from '@/app/SpinBtn';
import { RangeSpin } from '@/app/RangeSpin';
import { RangeSpinSplit } from '@/app/RangeSpinSplit';
import { AddonJudg } from '@/addon/AddonJudg';

import { StateContext } from '@/providers/StateProviders';


export const PartSetting = React.memo(({ partState, InputChange, updatePart, Clear, setOpen }) => {
  
  // const { currentUser } = useContext(StateContext);
  const { part, setPart } = partState
  
  // console.log("パーツ設定", part.id,  "レンダ");
  
  const CloseHandler = ()=> {
    setOpen("");
  };
  
  const part_align = [
    {name: "左側", value: "left"},
    {name: "中央", value: "center"},
    {name: "右側", value: "right"},
  ]
  
  
  // marginの分割
  let margins = part.margin ? part.margin.split("／") : [];
  
  const marginChange = event => {
    const { name, value } = event.target;

    if (name == "margin_top") {
      setPart((prev)=> ({ ...prev, margin: `${value}／${margins[1] ||""}` }));
    } else if (name == "margin_bottom") {
      setPart((prev)=> ({ ...prev, margin: `${margins[0] ||""}／${value}` }));
    }
  };
  
  // marginクリア
  const marginClear = (column) => {
    if (column == "margin_top") {
      setPart((prev)=> ({ ...prev, margin: `／${margins[1] ||""}` }));
    } else if (column == "margin_bottom") {
      setPart((prev)=> ({ ...prev, margin: `${margins[0] ||""}` }));
    }
  }
  

  return (
    <>
      <div className="cover" onClick={CloseHandler}/>
      <div className="items_bar">

        <div className="items_bar_top">
          <div className="sidebar_title">
            パーツ設定
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>

        <div className="items_box">
        
          <label>ステータス</label>
          <form className="radio_box">
            {["公開", "非公開"].map((item) => {
              return (
                <label key={item}
                  className={item == part.status ? "is_on": ""} 
                  // style={{border: item == part.status && "1px solid #00ff2c"}}
                >
                  <input
                    type="radio"
                    id={item}
                    name="status"
                    value={item}
                    onChange={InputChange}
                    checked={item == part.status}
                  />
                  {item}
                </label>
              );
            })}
          </form>

          <label>横幅（{part.width ||""}%）
            <div className="btn_clear" type="button" onClick={()=>Clear("width")}>クリア</div>
          </label>
          <RangeSpin
            setObj={setPart}
            column="width"
            objValue={part.width ||100} 
            InputChange={InputChange}
            min="10"
          />

          <label>高さ（{part.height ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
          </label>
          <RangeSpin
            setObj={setPart}
            column="height"
            objValue={part.height ||415}
            InputChange={InputChange}
            max="800"
            min="30"
          />
          
          <label>文字の整列
            <div className="btn_clear" type="button" onClick={()=>Clear("align")}>クリア</div>
          </label>
          <form className="radio_box">
            {part_align.map((item) => {
              return (
                <label key={item.value}
                  className={item.value == part.align ? "is_on": ""} 
                >
                  <input
                    type="radio"
                    id="align"
                    name="align" 
                    value={item.value}
                    onChange={InputChange}
                    checked={item.value == part.align}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>
          
          <label>パーツの位置
            <div className="btn_clear" type="button" onClick={()=>Clear("position")}>クリア</div>
          </label>
          <form className="radio_box">
            {part_align.map((item) => {
              return (
                <label key={item.value}
                  className={item.value == part.position ? "is_on": ""} 
                >
                  <input
                    type="radio"
                    id="position"
                    name="position" 
                    value={item.value}
                    onChange={InputChange}
                    checked={item.value == part.position}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>

          <label>外側のスペース【上】（{margins[0] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>marginClear("margin_top")}>クリア</div>
          </label>
          <RangeSpinSplit
            setObj={setPart}
            InputChange={marginChange}
            targetName="margin"
            column="margin_top"
            objValueMain={margins[0] ||10}
            objValueOther={margins[1] ||""}
            beforeAfter="before"
            max="150"
          />
          
          <label>外側のスペース【下】（{margins[1] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>marginClear("margin_bottom")}>クリア</div>
          </label>
          <RangeSpinSplit
            setObj={setPart}
            InputChange={marginChange}
            targetName="margin"
            column="margin_bottom"
            objValueMain={margins[1] ||10}
            objValueOther={margins[0] ||""}
            beforeAfter="after"
            max="150"
          />
          
          <label>内側のスペース（{part.padding ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
          </label>
          <RangeSpin
            setObj={setPart}
            column="padding"
            objValue={part.padding ||30} 
            InputChange={InputChange}
            max="150"
          />

          
          <label>シャドウ（{part.box_shadow ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("box_shadow")}>クリア</div>
          </label>
          <RangeSpin
            setObj={setPart}
            column="box_shadow"
            objValue={part.box_shadow ||1}
            InputChange={InputChange}
            max="60"
          />
          
          <label>フェードイン開始までの遅延時間
            <div className="btn_clear" type="button" onClick={()=>Clear("delay_seconds")}>クリア</div>
          </label>
          <div className="selectbox">
            <select 
              className="" 
              name="delay_seconds"
              value={part.delay_seconds || ""} 
              onChange={InputChange}
            >
              <option value={null}>未設定</option>
              <option value={300}>0.3秒後に開始</option>
              <option value={600}>0.6秒後に開始</option>
              <option value={900}>0.9秒後に開始</option>
              <option value={1200}>1.2秒後に開始</option>
              <option value={1500}>1.5秒後に開始</option>
              <option value={1800}>1.8秒後に開始</option>
              <option value={2100}>2.1秒後に開始</option>
            </select>
          </div>
          
          <AddonJudg terms={/ビジネス/} boolean={true}>
            <label>id追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_id")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_id"
              value={part?.add_id || ""} 
              onChange={InputChange}
              placeholder="追加するidを入力..."
            />
            
            <label>class追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_class")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_class"
              value={part?.add_class || ""} 
              onChange={InputChange}
              placeholder="追加するclassを入力..."
            />
          </AddonJudg>
            
        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn" onClick={updatePart}>保存</button>
        </div>

      </div>
    </>
  );
});


// <label>余白：外側下部（{part.margin ||""}px）
//   <div className="btn_clear" type="button" onClick={()=>Clear("margin")}>クリア</div>
// </label>
// <RangeSpin
//   obj={part}
//   setObj={setPart}
//   column="margin"
//   objValue={part.margin ||10}
//   InputChange={InputChange}
// max="150"
// />
          

// <SpinBtn
//   obj={part}
//   setObj={setPart}
//   column="width"
//   objValue={part.width || 100}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />


// <SpinBtn
//   obj={part}
//   setObj={setPart}
//   column="height"
//   objValue={part.height || 100}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>余白：内側（padding）
//   <div className="btn_clear" type="button" onClick={()=>Clear("padding")}>クリア</div>
// </label>
// <SpinBtn
//   obj={part}
//   setObj={setPart}
//   column="padding"
//   objValue={part.padding}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>余白：外側下部（margin-bottom）
//   <div className="btn_clear" type="button" onClick={()=>Clear("margin")}>クリア</div>
// </label>
// <SpinBtn
//   obj={part}
//   setObj={setPart}
//   column="margin"
//   objValue={part.margin}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>影（box-shadow）
//   <div className="btn_clear" type="button" onClick={()=>Clear("box_shadow")}>クリア</div>
// </label>
// <SpinBtn
//   obj={part}
//   setObj={setPart}
//   column="box_shadow"
//   objValue={part.box_shadow}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

          



// <label>並び順</label>
// <SpinBtn
//   obj={part}
//   setObj={setPart}
//   column="order"
//   objValue={part.order || 0}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

// <label>リンクURL
//   <div className="btn_clear" type="button" onClick={()=>Clear("link")}>クリア</div>
// </label>
// <input 
// className="input" 
// type="text"
// name="link"
// value={part?.link || ""} 
// onChange={InputChange}
// placeholder="URLを入力..."
// />


// <label>レイヤー位置
//   <div className="btn_clear" type="button" onClick={()=>Clear("layer_position")}>クリア</div>
// </label>
// <select
//  className="input"
// name="layer_position" 
// value={part?.layer_position|| ""} 
// onChange={InputChange}
// > 
//   <option value="">未設定</option>
//   <option value="top">Top</option>
//   <option value="left">Left</option>
//   <option value="right">Right</option>
// </select>

// <label>フェードイン
//   <div className="btn_clear" type="button" onClick={()=>Clear("fadein")}>クリア</div>
// </label>
// <input 
// className="input"
// type="text"
// name="fadein"
// value={part?.fadein || ""} 
// onChange={InputChange}
// />

// <label>フェードイン開始までの秒数
//   <div className="btn_clear" type="button" onClick={()=>Clear("delay_seconds")}>クリア</div>
// </label>
// <SpinBtn
//   obj={part}
//   setObj={setPart}
//   column="delay_seconds"
//   objValue={part.delay_seconds || 0}
//   InputChange={InputChange}
//   SaveDataUpdate={SaveDataUpdate}
// />

          

// <label>位置
//   <div className="btn_clear" type="button" onClick={()=>Clear("align")}>クリア</div>
// </label>
// <select 
//   className="input"
// name="align" 
// value={part?.align || ""} 
// onChange={InputChange}
// > 
//   <option value="">未設定</option>
//   <option value="center">中央</option>
//   <option value="right">右寄せ</option>
//   <option value="left">左寄せ</option>
// </select>



// <label>横幅（％）
//   <div className="btn_clear" type="button" onClick={()=>Clear("width")}>クリア</div>
// </label>
// <input 
// className="input" 
// type="number"
// min="10"
// name="width"
// value={part?.width || 100} 
// onChange={InputChange}
// />

// <label>高さ（px）
//   <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
// </label>
// <input 
// className="input" 
// type="number"
// min="50"
// name="height"
// value={part?.height || 200} 
// onChange={InputChange}
// />

// <label>位置
//   <div className="btn_clear" type="button" onClick={()=>Clear("align")}>クリア</div>
// </label>
// <form className="radio_box">
//   {part_align.map((item) => {
//     return (
//       <label key={item.value}
//         style={{border: item.value == part.align && "1px solid #00ff2c"}}
//       >
//         <input
//           type="radio"
//           id="align"
//           name="align" 
//           value={item.value}
//           onChange={InputChange}
//           checked={item.value == part.align}
//         />
//         {item.name}
//       </label>
//     );
//   })}
// </form>

// <label>レイヤー位置
//   <div className="btn_clear" type="button" onClick={()=>Clear("position")}>クリア</div>
// </label>
// <select
//  className="input"
// name="position" 
// value={part?.position|| ""} 
// onChange={InputChange}
// > 
//   <option value="">未設定</option>
//   <option value="top">Top</option>
//   <option value="left">Left</option>
//   <option value="right">Right</option>
// </select>

// <label>マージン
//   <div className="btn_clear" type="button" onClick={()=>Clear("margin")}>クリア</div>
// </label>
// <input 
// className="input"
// type="number"
// name="margin"
// value={part?.margin || 0} 
// onChange={InputChange}
// />

// <label>フェードイン
//   <div className="btn_clear" type="button" onClick={()=>Clear("fadein")}>クリア</div>
// </label>
// <input 
// className="input"
// type="text"
// name="fadein"
// value={part?.fadein || ""} 
// onChange={InputChange}
// />

// <label>フェードイン開始までの秒数
//   <div className="btn_clear" type="button" onClick={()=>Clear("delay_seconds")}>クリア</div>
// </label>
// <input 
// className="input"
// type="number"
// name="delay_seconds"
// value={part?.delay_seconds || 0} 
// onChange={InputChange}
// />
          