import React, { useState, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';


export const CopyPage = React.memo(({ product_id, pagesState }) => {

  const { setToastObj } = useContext(ToastContext);
  const { client } = useContext(StateContext);
  const { pages, setPages } = pagesState;
  
  const [ pageId, setPageId ] = useState(0);

  const InputChange = event => {
    // const { name, value } = event.target;
    setPageId(event.target.value);
  };
  
  const copy_data = {
    product_id: product_id,
    slug: pages.length + 1,
  }
  // console.log(pageId);
  // console.log(copy_data);
  
  
  //ページのコピー
  const pageCopy = () => {
    const sure = window.confirm(`このページをコピーしますか?`);
    if (sure) {
      client.post(`/api/v1/pages/copy/${pageId}`, copy_data )
      .then(resp => {
        setPages((prev)=>[resp.data, ...prev]);
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };

  return (
    <>
      <div className="input_inline">
        <input 
          type="number"
          className="input"
          value={pageId} 
          onChange={InputChange}
        />
        <button onClick={pageCopy}>コピーする</button>
      </div>
    </>
  );
});
