import React from 'react'

export const DummyReserveForm = () => {
  
  // const date = new Date(); 
  // const nowDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  // console.log(nowDate)
  
  return (
    <section id="c_section">
      <div className="wrapper">
        <div className="contact_form" id="c_form">
          <h2 className="form_title">RESERVE<span>予約フォーム</span></h2>
          <p className="mb_3">
            下記に必要事項をご入力の上、ご予約ください。<br/>
            ご予約内容を確認し、担当者より折り返しご連絡させて頂きます。<br/>
            自動返信メールが届かない場合はご入力のメールアドレスが間違っている可能性があります。
          </p>
    
          <div className="form_field">
            <label>お名前</label>
            <input 
              id="name"
              className="input"
              type="text"
              placeholder="お名前を入力..."
            />
          </div>
          
          <div className="input_fields">
            <div className="field_2">
              <label>メールアドレス（返信用）</label>
              <input 
                id="email"
                className="input"
                type="text"
                placeholder="例）info@example.com"
              />
            </div>
            
            <div className="field_2">
              <label>電話番号</label> 
              <input 
                id="tel"
                className="input"
                type="text"
                placeholder="例）090-1234-5678"
              />
            </div>
          </div>
          
          <div className="input_fields">
            <div className="field_2">
              <label>第１希望の日時</label> 
              <input 
                id="datetime"
                className="input"
                type="datetime-local"
                placeholder="日時を選択"
                // value="2022-05-21T20:40"
                step="600"
              />
            </div>
            
            <div className="field_2">
              <label>第２希望の日時</label> 
              <input 
                id="datetime_2"
                className="input"
                type="datetime-local"
                placeholder="日時を選択"
                step="600"
              />
            </div>
            
          </div>
          
          <label>ご希望のプラン</label>
          <div className="selectbox">
            <select
              id="plan"
              className="" 
              name="plan"
            >
              <option value="">プランを選択してください。</option>
              {["プラン①", "プラン②"].map((item, index) => {
                return (
                  <option value={item} key={index}>{item}</option>
                );
              })}
            </select>
          </div>
          
          <div className="form_field">
            <label>メッセージ内容</label>
            <textarea 
              rows="6" 
              className="input"
              placeholder="メッセージ内容を入力..."
            />
          </div>

          <button type="submit" className="form_btn">送信する</button>
          <div className="form_notice ">このサイトはreCAPTCHAによって保護されています。</div>

        </div>
      </div>
    </section>
  );

};
